import React, { useContext, useState } from "react";
import { Modal, ModalHeader, ModalBody, SIZE } from "baseui/modal";
import { BsFillArrowLeftCircleFill } from "react-icons/bs";
import { carServiceContext } from "../../../../context/CarServiceContextProvider";
import "./FuelType.css";
import MyGateLoader from "../../../../Uis/MyGateLoader/MyGateLoader";

const fuel_Arr = [
  {
    type: "Petrol",
    image:
      "https://ik.imagekit.io/driveu/Fuel_Types/Group_784_huGe_iLN3.png?updatedAt=1684146610575",
    value: "petrol",
  },
  {
    type: "Diesel",
    image:
      "https://ik.imagekit.io/driveu/Fuel_Types/Group_781_du3XfRLwt.png?updatedAt=1684146610506",
    value: "diesel",
  },
  {
    type: "CNG",
    image:
      "https://ik.imagekit.io/driveu/Fuel_Types/Group_783_LCnfcv5rOn.png?updatedAt=1684146610518",
    value: "cng",
  },
  {
    type: "Electric",
    image:
      "https://ik.imagekit.io/driveu/Fuel_Types/Group_782_nqWng2QKQ.png?updatedAt=1684146610514",
    value: "electric",
  },
];

// fuelArr
function FuelType() {
  const [selected, setSelected] = useState(true);
  // const [fuelValue, setFuelValue] = useState("");
  const {
    isFuelModalOpen,
    closeFuelModal,
    fuelData,
    setIsFuelModalOpen,
    setIsCarModalOpen,
    handleServicePrice,
    setHandleLottie,
    carServiceData,
    fuelTypeLoader,
  } = useContext(carServiceContext);

  const handleFuelModal = () => {
    setIsFuelModalOpen(false);
    setIsCarModalOpen(true);
  };

  // const handleFuelType = (e) => {
  //   setFuelValue(e.target.value);
  // };

  const handelFuelType = (fuelType) => () => {
    carServiceData.fuel_type = fuelType;
    carServiceData.transmission = selected ? "manual" : "automatic";
    setHandleLottie(true);
    handleServicePrice(fuelType);
  };

  // const handleClick = () => {
  //   inputRef.current.inputMode = "text";
  // };
  const handleSelect = () => {
    setSelected((p) => !p);
  };

  return (
    <>
      <Modal
        onClose={closeFuelModal}
        isOpen={isFuelModalOpen}
        size={SIZE.default}
      >
        {/* Select Model */}
        <ModalHeader className="carServiceModalHeaderMainContainer">
          <BsFillArrowLeftCircleFill
            onClick={handleFuelModal}
            className="carServiceModalBackButton"
          />
          <div>Select Fuel Type</div>
        </ModalHeader>
        <ModalBody style={{ flex: "1 1 0" }}>
          {fuelTypeLoader ? (
            <div className="carServiceModalMainContainer">
              <MyGateLoader />
            </div>
          ) : (
            <div className="carServiceModalMainContainer">
              <div className="carServiceFuelMainContainer">
                <div
                  onClick={handleSelect}
                  style={{
                    color: selected ? "white" : "grey",
                    backgroundColor: selected ? "green" : "white",
                  }}
                >
                  Manual
                </div>
                <div
                  onClick={handleSelect}
                  style={{
                    color: selected ? "grey" : "white",
                    backgroundColor: selected ? "white" : "green",
                  }}
                >
                  Automatic
                </div>
              </div>
              {/* Modal option  */}

              <div className="carServiceModalOptionsMainContainer">
                {fuel_Arr.map((elem) => (
                  <div
                    key={elem.type}
                    style={{
                      display:
                        fuelData.fuel_types.includes(
                          elem.type.toLocaleLowerCase()
                        ) || fuelData.fuel_types.includes(elem.type)
                          ? ""
                          : "none",
                    }}
                    onClick={handelFuelType(elem.value)}
                    className="carServiceModalfirstOptionMainContainer"
                  >
                    <img src={elem.image} alt={elem.type.toLowerCase()} />
                    <div className="carServiceModalfirstOptionText">
                      {elem.type}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </ModalBody>
      </Modal>
    </>
  );
}

export default FuelType;
