import React from "react";
import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import styles from "./WhyDriveU.module.css";
function WhyDriveUnique({ cityLine, styleProps }) {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: true,
  };
  return (
    <>
      <div
        className={styles.WhyDriveU_Main_div}
        style={{
          marginTop: styleProps ? "0%" : "5%",
          marginBottom: styleProps ? "6%" : "0%",
        }}
      >
        <div className={styles.WhyDriveU_Main_div_first}>
          <div className={styles.WhyDriveU_Main_div_first_img}>
            <img
              src="https://ik.imagekit.io/driveu/Website_New/DriveUfirst_E-S3pJyHu.png"
              alt="Trust"
            />
          </div>
          <div className={styles.WhyDriveU_heading_text}>Trust</div>
          <div>
            {cityLine ? (
              <p className={styles.WhyDriveU_subHeading_text}>
                {`With our fleet of trained, verified and trusted drivers in
                ${cityLine}, we make the drive better and safer for you and your
                family.`}
              </p>
            ) : (
              <p className={styles.WhyDriveU_subHeading_text}>
                With our fleet of trained, verified and trusted drivers. we make
                the drive better and safer for you and your family.
              </p>
            )}
          </div>
        </div>

        <div className={styles.WhyDriveU_Main_div_sec}>
          <div className={styles.WhyDriveU_Main_div_sec_img}>
            <img
              src="https://ik.imagekit.io/driveu/Website_New/DriveU_sec_oFBvMg4Ox.png"
              alt="DriveU Secure"
            />
          </div>
          <div className={styles.WhyDriveU_heading_text}>Secure</div>
          <div className={styles.WhyDriveU_subHeading_text}>
            DriveU Secure helps you insure your drives from vehicle damages and
            personal injuries, while we insure our drivers for all trips.
          </div>
        </div>

        <div className={styles.WhyDriveU_Main_div_thi}>
          <div className={styles.WhyDriveU_Main_div_thi_img}>
            <img
              src="https://ik.imagekit.io/driveu/Website_New/DriveU_thi_uMgxY6Sgw.png"
              alt="Versatile"
            />
          </div>
          <div className={styles.WhyDriveU_heading_text}>Versatile</div>
          <div className={styles.WhyDriveU_subHeading_text}>
            Book a driver for daily office commutes, mid-day shopping,
            night-outs, airport transfers, or weekend getaways with our hourly
            packages.
          </div>
        </div>

        <div className={styles.WhyDriveU_Main_div_four}>
          <div className={styles.WhyDriveU_Main_div_four_img}>
            <img
              src="https://ik.imagekit.io/driveu/Website_New/DriveU_four_GRpkf7Eco.png"
              alt="Tracking"
            />
          </div>
          <div className={styles.WhyDriveU_heading_text}>Tracking</div>
          <div className={styles.WhyDriveU_subHeading_text}>
            Live track your driver as he reaches the pickup location and share
            your real-time trip status with family and friends along with your
            drive.
          </div>
        </div>
      </div>
      <Slider className={styles.WhyDriveU_Mobile_div} {...settings}>
        <div className={styles.WhyDriveU_Main_div_first}>
          <div className={styles.WhyDriveU_Main_div_first_img}>
            <img
              src="https://ik.imagekit.io/driveu/Website_New/DriveUfirst_E-S3pJyHu.png"
              alt="Trust"
            />
          </div>
          <div className={styles.WhyDriveU_heading_text}>Trust</div>
          <div>
            {cityLine ? (
              <p className={styles.WhyDriveU_subHeading_text}>
                {`With our fleet of trained, verified and trusted drivers in
                ${cityLine}, we make the drive better and safer for you and your
                family.`}
              </p>
            ) : (
              <p className={styles.WhyDriveU_subHeading_text}>
                With our fleet of trained, verified and trusted drivers. we make
                the drive better and safer for you and your family.
              </p>
            )}
          </div>
        </div>

        <div className={styles.WhyDriveU_Main_div_sec}>
          <div className={styles.WhyDriveU_Main_div_sec_img}>
            <img
              src="https://ik.imagekit.io/driveu/Website_New/DriveU_sec_oFBvMg4Ox.png"
              alt="DriveU Secure"
            />
          </div>
          <div className={styles.WhyDriveU_heading_text}>Secure</div>
          <div className={styles.WhyDriveU_subHeading_text}>
            DriveU Secure helps you insure your drives from vehicle damages and
            personal injuries, while we insure our drivers for all trips.
          </div>
        </div>

        <div className={styles.WhyDriveU_Main_div_thi}>
          <div className={styles.WhyDriveU_Main_div_thi_img}>
            <img
              src="https://ik.imagekit.io/driveu/Website_New/DriveU_thi_uMgxY6Sgw.png"
              alt="Versatile"
            />
          </div>
          <div className={styles.WhyDriveU_heading_text}>Versatile</div>
          <div className={styles.WhyDriveU_subHeading_text}>
            Book a driver for daily office commutes, mid-day shopping,
            night-outs, airport transfers, or weekend getaways with our hourly
            packages.
          </div>
        </div>

        <div className={styles.WhyDriveU_Main_div_four}>
          <div className={styles.WhyDriveU_Main_div_four_img}>
            <img
              src="https://ik.imagekit.io/driveu/Website_New/DriveU_four_GRpkf7Eco.png"
              alt="Tracking"
            />
          </div>
          <div className={styles.WhyDriveU_heading_text}>Tracking</div>
          <div className={styles.WhyDriveU_subHeading_text}>
            Live track your driver as he reaches the pickup location and share
            your real-time trip status with family and friends along with your
            drive.
          </div>
        </div>
      </Slider>
    </>
  );
}

export default WhyDriveUnique;
