import React, { createContext, useState } from "react";
export const RSAContext = createContext();
function RSAContextProvider({ children }) {
  const [showRSAPopUp, setShowRSAPopUp] = useState(false);
  const [sendRSALink, setSendRSALink] = useState("");
  const [showRSALinkPopUp, setShowRSALinkPopUp] = useState(false);
  const [getRSAID, setGetRSAID] = useState("");
  const [carDetails, setCarDetails] = useState({
    carBrand: "",
    carModel: "",
    carNum: "",
  });
  const handleCloseRSA = () => {
    setShowRSAPopUp(false);
  };
  return (
    <RSAContext.Provider
      value={{
        showRSAPopUp,
        setShowRSAPopUp,
        handleCloseRSA,
        sendRSALink,
        setSendRSALink,
        showRSALinkPopUp,
        setShowRSALinkPopUp,
        carDetails,
        setCarDetails,
        getRSAID,
        setGetRSAID,
      }}
    >
      {children}
    </RSAContext.Provider>
  );
}
export default RSAContextProvider;
