import { SIZE } from "baseui/button";
import {
  Modal,
  ModalBody,
  ModalButton,
  ModalFooter,
  ModalHeader,
  ROLE,
} from "baseui/modal";
import React, { useContext, useState } from "react";
import { BookingFlowDataContext } from "../../context/BookingFlowDataContextProvider";
import ShimmerPopUp from "../../utils/ShimmerPopUp";

class ModalStateContainer extends React.Component {
  state = {
    isConfirmationOpen: false,
  };
  toggleConfirm = (open = !this.state.isConfirmationOpen, cb = () => {}) => {
    this.setState({ isConfirmationOpen: open }, cb);
  };
  render() {
    return this.props.children({
      isConfirmationOpen: this.state.isConfirmationOpen,
      toggleConfirm: this.toggleConfirm,
    });
  }
}

const PromoPopUpSheet = ({
  promoPopUpSheet,
  setPromoPopUpSheet,
  setCoupanSucessMessage,
  coupanSucessMessage,
  couponCodeLength,
  setCouponCodeLength,
  setCoupanText,
  coupanText,
  checkingCouponApi,
  showCoupanMessage,
  showErrorColor,
  handleFillPromoCode,
  couponData,
}) => {
  const { couponLoading } = useContext(BookingFlowDataContext);
  const [selectedTNC, setSelectedTNC] = useState(null);
  return (
    <ModalStateContainer>
      {({ isConfirmationOpen, toggleConfirm }) => (
        <React.Fragment>
          <Modal
            onClose={() => {
              setPromoPopUpSheet(false);
              setCoupanSucessMessage("");
              setCouponCodeLength(false);
            }}
            closeable
            isOpen={promoPopUpSheet}
            animate
            autoFocus
            size={SIZE.default}
            role={ROLE.dialog}
            overrides={{
              Dialog: {
                style: {
                  width: "50vw",
                  height: "500px",
                  overflow: "scroll",
                },
              },
            }}
          >
            <ModalHeader>Coupons & Offers</ModalHeader>
            <ModalBody>
              {couponData.length === 0 && couponLoading ? (
                <ShimmerPopUp />
              ) : (
                <div>
                  <div className="stickyPromoCodePopUp">
                    <div className="Coupon">
                      <div className="inputCouponCode">
                        <input
                          type="text"
                          id="couponDiv"
                          className="couponDetails couponCodeBottomSheetInput"
                          onChange={(e) => {
                            setCoupanText(e.target.value);
                            e.target.value.length > 0
                              ? setCouponCodeLength(true)
                              : setCouponCodeLength(false);
                          }}
                          placeholder="Enter a Coupon Code"
                          autoComplete="off"
                          value={coupanText}
                        />
                        <button
                          onClick={checkingCouponApi}
                          id="couponBtn"
                          style={{
                            color: couponCodeLength ? "#137e40" : "",
                            fontWeight: "500",
                          }}
                        >
                          Apply
                        </button>
                      </div>
                      <div
                        style={{
                          display: showCoupanMessage ? "" : "none",
                          color: showErrorColor ? "green" : "red",
                          fontfamily: "Circular-Std-Book",
                        }}
                      >
                        {coupanSucessMessage}
                      </div>
                    </div>
                  </div>
                  <>
                    {couponData.map((codeData, index) => {
                      return (
                        <div className="bottomCouponContainer" key={index}>
                          <div className="bottomCouponWrapper">
                            <div className="firstBlockCouponWrapperPopUp">
                              <div
                                className="couponCodeBottomSheet"
                                id="getCouponCode"
                              >
                                {codeData.promo_code}
                              </div>
                              <button
                                className="firstF1PopUp"
                                style={{
                                  color:
                                    codeData.is_applicable === false
                                      ? "grey"
                                      : "#137e40",
                                }}
                                disabled={
                                  codeData.is_applicable === false
                                    ? true
                                    : false
                                }
                                onClick={() =>
                                  handleFillPromoCode(codeData.promo_code)
                                }
                              >
                                APPLY
                              </button>
                            </div>
                            <div className="secondBlockCouponWrapperPopUp">
                              <div className="bottomS1">
                                <img
                                  src={codeData.logo_url}
                                  alt="Offer Logo"
                                  width="100%"
                                  height="100%"
                                />
                              </div>
                              <div className="bottomS2">{codeData.title}</div>
                              <div className="bottomS3">
                                {codeData.description}
                              </div>
                            </div>
                            <div className="thirdBlockCouponWrapperPopUp">
                              <div
                                className="thirdT1"
                                onClick={() => {
                                  toggleConfirm(true);
                                  setSelectedTNC(codeData.offer_terms);
                                }}
                              >
                                View Terms and conditions
                              </div>
                            </div>
                            <div className="fourthBlockCouponWrapperPopUp">
                              {codeData.validity_text}
                            </div>
                            <div className="fiveT1PopUp"></div>
                          </div>
                        </div>
                      );
                    })}
                  </>
                </div>
              )}
            </ModalBody>
          </Modal>
          <Modal
            onClose={() => toggleConfirm(false)}
            isOpen={isConfirmationOpen}
          >
            <ModalHeader>Terms and Conditions</ModalHeader>
            {selectedTNC === null ? (
              "Loading..."
            ) : (
              <ModalBody>
                <ul className="thirdT2">
                  {selectedTNC.map((termsData, index) => {
                    return <li key={index}>{termsData}</li>;
                  })}
                </ul>
              </ModalBody>
            )}
            <ModalFooter>
              <ModalButton onClick={() => toggleConfirm(false)}>
                Okay
              </ModalButton>
            </ModalFooter>
          </Modal>
        </React.Fragment>
      )}
    </ModalStateContainer>
  );
};

export default PromoPopUpSheet;
