import React, { useContext, useEffect, useState } from "react";
import "./MobileAbout.css";
import { HamburgerContext } from "../../../context/HamburgerContextProvider";
import { Vector48 } from "../../../Components/AllImportFiles/ImportSvg";
import { Link, useNavigate } from "react-router-dom";
import { DownloadContext } from "../../../context/DownloadContextProvider";
import { BookingFlowParaContext } from "../../../context/BookingFlowParaProvider";

function MobileAbout() {
  const navigate = useNavigate();
  const { showMediaIcons, handleFalse } = useContext(HamburgerContext);
  const [toggle, setToggle] = useState(false);
  const [show, setShow] = useState(false);
  const [business, setBusiness] = useState(false);
  const { setShowPopUp, setPreviousChoice } = useContext(DownloadContext);
  const { isAuthenticated } = useContext(BookingFlowParaContext);
  const showAbout = () => {
    setTimeout(() => {
      setShow(false);
      setBusiness(false);
      setToggle(!toggle);
    }, 50);
  };
  const showService = () => {
    setTimeout(() => {
      setToggle(false);
      setBusiness(false);
      setShow(!show);
    }, 50);
  };
  const showBusiness = () => {
    setTimeout(() => {
      setShow(false);
      setToggle(false);
      setBusiness(!business);
    }, 50);
  };

  const handleHireDrivers = () => {
    if (isAuthenticated === true) {
      navigate("/book-driver/");
    } else if (isAuthenticated === false) {
      setPreviousChoice("hiredrivers");
      setShowPopUp(true);
    }
  };

  useEffect(() => {
    setToggle(false);
    setShow(false);
    setBusiness(false);
  }, [showMediaIcons]);

  return (
    <div className="MainDiv_MobileAbout">
      <div className="Mobile_Option">
        <div className="MobileNav_Heading">
          <div>
            <Link to="/" className="color-change" onClick={handleFalse}>
              Home
            </Link>
          </div>
        </div>
        <div className="MobileNav_Heading" onClick={showService}>
          <div className="Ham-subHeading">
            Services
            <div
              style={{
                display: show ? "block" : "none",
              }}
              className="Ham-subHeading-content"
            >
              <div className="Hover-effect-ham" onClick={handleHireDrivers}>
                <Link to="" className="color-change">
                  <div> Hire Drivers</div>
                </Link>
              </div>
              <div className="Hover-effect-ham">
                <Link to="/recharge-fastag" className="color-change">
                  <div> FASTag Recharge</div>
                </Link>
              </div>
              <div className="Hover-effect-ham">
                <Link
                  to = "/car-wash"
                  className="color-change"  
                >
                  <div>Car Wash</div>
                </Link>
              </div>
              <div className="Hover-effect-ham">
                <Link to="/wash-package" className="color-change">
                  <div>Car Wash Packages</div>
                </Link>
              </div>
              {/* <div className="Hover-effect-ham">
                <Link to="/sell-used-cars" className="color-change">
                  <div>Sell Car</div>
                </Link>
              </div> */}
              <div className="Hover-effect-ham">
                <a href="https://www.driveu.in/driveu-crew/" className="color-change">
                  <div>DriveU Crew</div>
                </a>
              </div>
              <div className="Hover-effect-ham">
                <Link className="color-change" to="/car-servicing">
                  <div>Car Servicing</div>
                </Link>
              </div>
              {/* <div className="Hover-effect-ham">
                <Link className="color-change" to="/roadside-assistance-plans">
                  <div>Roadside Assistance</div>
                </Link>
              </div> */}
            </div>
          </div>
          <Vector48 className={`${show ? "nav-rotate-arrow" : "arrow"}`} />
        </div>
        <div className="MobileNav_Heading" onClick={showBusiness}>
          <div>
            For Business
            <div
              style={{
                display: business ? "block" : "none",
              }}
              className="Ham-subHeading-content"
            >
              <div className="Hover-effect-ham">
                <Link to="/driver-partners" className="color-change">
                  <div>Join as Driver Partner</div>
                </Link>
              </div>
              <div className="Hover-effect-ham">
                <a
                  href="https://www.driveu.in/business/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="color-change"
                >
                  <div> Hire Drivers for B2B</div>
                </a>
              </div>
            </div>
          </div>
          <Vector48 className={`${business ? "nav-rotate-arrow" : "arrow"}`} />
        </div>
        <div className="MobileNav_Heading" onClick={showAbout}>
          <div>
            About us
            <div
              style={{ display: toggle ? "block" : "none", width: "460%" }}
              className="Ham-subHeading-content"
            >
              <div className="Hover-effect-ham">
                <Link className="color-change" to="/team">
                  <div>Team</div>
                </Link>
              </div>
              <div className="Hover-effect-ham">
                <a
                  className="color-change"
                  href="https://www.driveu.in/blog/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div> Blog</div>
                </a>
              </div>
              <div className="Hover-effect-ham">
                <Link to="/contact-us" className="color-change">
                  <div>Contact Us</div>
                </Link>
              </div>
            </div>
          </div>
          <Vector48 className={`${toggle ? "nav-rotate-arrow" : "arrow"}`} />
        </div>
      </div>
    </div>
  );
}

export default MobileAbout;
