import React, { useEffect } from "react";

function MyGateWashHelp() {
  useEffect(() => {
    function initFreshChat() {
      window.fcWidget.init({
        token: "3c14c103-416d-431f-a6bb-234ce68bc71d",
        host: "https://wchat.freshchat.com",
        widgetUuid: "1d953f48-9727-4967-8b2a-71b5f966c0fc",
        open: true,
        tags: ["my_gate_car_wash"],

        config: {
          showFAQOnOpen: false,
          hideFAQ: true,
        },
        faqTags: {
          tags: [
            "get_started",
            "booking",
            "car_wash",
            "insurance",
            "cancellation",
          ],
          filterType: "category",
        },
      });
    }
    function initialize(i, t) {
      var e;
      if (i.getElementById(t)) {
        return initFreshChat();
      } else {
        return (
          ((e = i.createElement("script")).id = t),
          (e.async = !0),
          (e.src = "https://wchat.freshchat.com/js/widget.js"),
          (e.onload = initFreshChat),
          i.head.appendChild(e)
        );
      }
    }
    function initiateCall() {
      initialize(document, "Freshchat-js-sdk");
    }
    window.addEventListener
      ? window.addEventListener("load", initiateCall, !1)
      : window.attachEvent("load", initiateCall, !1);
  }, []);
  return <div></div>;
}

export default MyGateWashHelp;
