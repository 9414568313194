import React, { useContext, useRef } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { addDays, format } from "date-fns";
import DatePicker from "react-datepicker";
import dateStyle from "../dateStyle.module.css";
import { BookingFlowDataContext } from "../../../../context/BookingFlowDataContextProvider";
import { ApplicationContext } from "../../../../context/ApplicationContextProvider";
import { MdOutlineArrowDropDown } from "react-icons/md";

const CalenderBox = () => {
  const { sendingDailyBookingData, setTrackPickUpDateTimeArr } = useContext(
    BookingFlowDataContext
  );
  const {
    selectedDate,
    setSelectedDate,
    PackageDayDate,
    setMappedDates,
    dailyPickupTime,
  } = useContext(ApplicationContext);
  const calRef = useRef(null);
  const handleCalRef = () => {
    calRef.current.handleFocus();
  };
  
  const handleDateChange = (dateElem) => {
    const value = dateElem;
    setSelectedDate(value);
    PackageDayDate.length = 0;
    sendingDailyBookingData.pickup_datetime = [];
    setTrackPickUpDateTimeArr([]);
    setMappedDates(new Set());
    for (let i = 0; i < 7; i++) {
      const date = new Date(value);
      date.setDate(date.getDate() + i);
      const day = date.getDay();

      const dayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
      const options = {
        month: "short",
        day: "numeric",
      };

      const formattedAllDate = format(date, "dd/MM/yyyy");
      const formattedDate = date.toLocaleDateString("en-IN", options);
      const formattedDay = dayNames[day];
      PackageDayDate.push([formattedDay, formattedDate, formattedAllDate]);
    }
  };

  const setNextValidDate = () => {
    // DON'T REMOVE THIS CODE THIS IS FOR 24HR LEAD TIME
    // const currentDate = new Date();
    // currentDate.setHours(19, 45, 0, 0);
    // const tomorrowDate = new Date(currentDate.getTime() + 12 * 60 * 60 * 1000);
    // const tomorrowHours = tomorrowDate.getHours();
    // const tomorrowMinutes = tomorrowDate.getMinutes();
    // const currentHours = currentDate.getHours();
    // const [hours, minutes] = dailyPickupTime.split(":").map(Number);

    // let nextDate;
    // if (tomorrowHours - hours < 0) {
    //   nextDate = tomorrowDate;
    //   console.log("hello");
    //   // console.log(tomorrowDate);
    // } else if (tomorrowHours - hours === 0) {
    //   if (tomorrowMinutes <= minutes) {
    //     nextDate = tomorrowDate;
    //   } else {
    //     const dayAfterTomorrow = new Date(tomorrowDate);
    //     dayAfterTomorrow.setDate(dayAfterTomorrow.getDate() + 1);
    //     nextDate = dayAfterTomorrow;
    //   }
    // } else {
    //   const dayAfterTomorrow = new Date(tomorrowDate);
    //   dayAfterTomorrow.setDate(dayAfterTomorrow.getDate() + 1);
    //   nextDate = dayAfterTomorrow;
    //   // console.log(dayAfterTomorrow);
    // }

    // DON'T REMOVE THIS CODE , THIS CODE FOR 24HR LEAD TIME
    // let newDate;
    // if (tomorrowHours === hours && tomorrowMinutes < minutes) {
    //   newDate = tomorrowDate;
    // } else if (tomorrowHours < hours) {
    //   newDate = tomorrowDate;
    // } else {
    //   const tomorrowDate = new Date(currentDate);
    //   tomorrowDate.setDate(tomorrowDate.getDate() + 1);
    //   const dayAfterTomorrow = new Date(tomorrowDate);
    //   dayAfterTomorrow.setDate(dayAfterTomorrow.getDate() + 1);
    //   newDate = tomorrowDate;
    // }

    // DON'T REMOVE THIS CODE THIS IS FOR 24HR LEAD TIME
    let newDate;
    var now = new Date();
    const [hours, minutes] = dailyPickupTime.split(":").map(Number);
    var currentDateTime = new Date();
    currentDateTime.setHours(hours, minutes, 0, 0);

    var timeDifference = currentDateTime - now;
    var minutesDifference = timeDifference / (1000 * 60);

    if (minutesDifference >= 720) {
      newDate = now;
    } else {
      currentDateTime.setDate(currentDateTime.getDate() + 1);
      timeDifference = currentDateTime - now;
      minutesDifference = timeDifference / (1000 * 60);

      if (minutesDifference >= 720) {
        const tomorrowDate = new Date(now);
        tomorrowDate.setDate(tomorrowDate.getDate() + 1);
        newDate = tomorrowDate;
      } else {
        const tomorrowDate = new Date(now);
        tomorrowDate.setDate(tomorrowDate.getDate() + 2);
        newDate = tomorrowDate;
      }
    }
    return new Date(newDate);
    // return new Date(nextDate);
  };

  const CustomDatePickerInput = React.forwardRef(({ value, onClick }, ref) => (
    <div style={{ display: "flex" }}>
      <input
        type="text"
        value={value}
        onClick={onClick}
        readOnly
        placeholder="Click to choose date"
        style={{ height: "30px" }}
        // autoComplete="off"
      />
      <MdOutlineArrowDropDown
        size={30}
        style={{ background: "#f6f6f7" }}
        onClick={handleCalRef}
      />
    </div>
  ));
  return (
    <>
      <div className={dateStyle["custom-datepicker"]}>
        <DatePicker
          ref={calRef}
          selected={selectedDate}
          onChange={handleDateChange}
          dateFormat="dd MMM yyyy"
          placeholderText="dd/mm/yyyy"
          minDate={setNextValidDate()}
          maxDate={addDays(new Date(), 30)}
          customInput={<CustomDatePickerInput />} // Optional custom input component
        />
      </div>
    </>
  );
};

export default CalenderBox;
