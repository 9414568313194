import React from 'react';
import MsilOrderProfilePage from './MsilOrderProfilePage/MsilOrderProfilePage';
import MsilOrderStatusPage from './MsilOrderPage/MsilOrderStatusPage';
import msilcss from "./msil.module.css"

const MsilPage = () => {
    
  return (
    <div className={msilcss.orderMainDiv}>
      {/* <MsilOrderProfilePage/> */}
      <MsilOrderStatusPage/>
    </div>
  )
}

export default MsilPage
