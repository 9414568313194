import React from "react";
import "./AboutUsDD.css";
import { Link } from "react-router-dom";
function AboutUsDD() {
  return (
    <div className="aboutus-wrapper">
      <ul className="about-us-section">
        <Link to="/team">
          {" "}
          <li className="about-us-option aboutus-story">Team</li>
        </Link>
        <a
          href="https://www.driveu.in/blog/"
          target="_blank"
          rel="noopener noreferrer"
        >
          {" "}
          <li className="about-us-option aboutus-blog">Blog</li>
        </a>
        <Link to="/contact-us">
          <li className="about-us-option aboutus-contact">Contact Us</li>
        </Link>
      </ul>
    </div>
  );
}

export default AboutUsDD;
