//  this is same as booking address refer that one
import React, {
  Fragment,
  useContext,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import "./SlotAddress.css";
import axios from "axios";
import { GrFormLocation } from "react-icons/gr";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import useOnclickOutside from "react-cool-onclickoutside";
import { carServiceContext } from "../../../../context/CarServiceContextProvider";

// main Function
function SlotAddress() {
  const inputRef = useRef(null);

  const BASE_URL_HULK = process.env.REACT_APP_HULK_URL;
  const [textCount, setTextCount] = useState(0);

  const {
    carServiceData,
    setEpocDateArr,
    setTimeSlots,
    setSelectedDate,
    setHideDateTime,
    serviceNotError,
    setServiceNotError,
    setTimeCompState,
    setShowSuccessMessage,
    // setPromoCode,
    setDisableButton,
  } = useContext(carServiceContext);

  //  first address call
  useLayoutEffect(() => {
    setValue(carServiceData.address);
    axios
      .get(
        `${BASE_URL_HULK}/care/pitstop-slots/?user_id=${carServiceData.user_id}&service_type=garrage&latitude=${carServiceData.latitude}&longitude=${carServiceData.longitude}`
      )
      .then(({ data }) => {
        carServiceData.city_id = data.city_id;
        if (data.status === "success") {
          //   setMyGatePriceData(data.wash_price);
          setTimeSlots(data.data[0].slots);
          setSelectedDate(data.data[0].epoch_date);
          setEpocDateArr(data.data);
          setHideDateTime(false);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  //  line break
  const {
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({ debounce: 300 });

  //    line break
  const ref = useOnclickOutside(() => {
    clearSuggestions();
  });

  //    line break
  const handleSuggestionSelect = ({ description }) => () => {
    setValue(description, false);
    clearSuggestions();
    carServiceData.address = description;

    getGeocode({ address: description }).then((results) => {
      const { lat, lng } = getLatLng(results[0]);
      carServiceData.latitude = lat;
      carServiceData.longitude = lng;

      axios
        .get(
          `https://apps.driveu.in/care/pitstop-slots/?user_id=${carServiceData.user_id}&service_type=garrage&latitude=${lat}&longitude=${lng}`
        )
        .then(({ data }) => {
          carServiceData.city_id = data.city_id;
          if (data.status === "success" && data.data.length !== 0) {
            setTimeCompState(-1);
            setHideDateTime(false);
            setTimeSlots(data.data[0].slots);
            setSelectedDate(data.data[0].epoch_date);
            setEpocDateArr(data.data);
          } else {
            setServiceNotError(data.message);
            setDisableButton(true);
            setHideDateTime(true);
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    });
  };

  //  line break
  const handleTextCount = (e) => {
    if (e.target.value === "") {
      setDisableButton(true);
      setHideDateTime(true);
      setShowSuccessMessage(false);
    }
    setServiceNotError("");
    setTextCount(e.target.value.length);
    setValue(e.target.value);
  };

  //  line break

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;
      setDisableButton(true);
      setHideDateTime(true);
      return (
        <li
          className="carServiceAddressList"
          key={place_id}
          onClick={handleSuggestionSelect(suggestion)}
        >
          <div style={{ display: "flex", flexDirection: "row", gap: "0.5rem" }}>
            <div style={{ lineHeight: "1rem", textAlign: "left" }}>
              <strong style={{ fontSize: "12px" }}>{main_text}</strong> &nbsp;
              <small style={{ fontSize: "10px" }}>{secondary_text}</small>
            </div>
          </div>
        </li>
      );
    });

  const handleClick = () => {
    inputRef.current.inputMode = "text";
  };

  return (
    <Fragment>
      <div ref={ref}>
        <div className="myGateFormAddSubMainContainer">
          <div className="myGateFormAddSubMainContainerLocation">
            <GrFormLocation style={{ height: "30px", width: "30px" }} />
          </div>
          <div
            onClick={handleClick}
            className="myGateFormAddSubMainContaineInput"
          >
            <input
              type="search"
              id="Search"
              ref={inputRef}
              value={value}
              placeholder="Enter your address"
              autoComplete="off"
              autoFocus={false}
              onChange={handleTextCount}
              inputMode="none"
            />
          </div>
        </div>
        <div>
          {status === "OK" && textCount > 3 && <ul>{renderSuggestions()}</ul>}
        </div>
      </div>
      <div
        style={{
          color: "red",
          display: serviceNotError.length !== 0 ? "" : "none",
        }}
      >
        {serviceNotError}
      </div>
    </Fragment>
  );
}

export default SlotAddress;
