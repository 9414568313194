import React from "react";
import Slider from "react-slick";
import imageStyle from "../../DriveUDaily/Landing.module.css";
const ImageScrollPartner = () => {
  const imageArr = [
    "https://ik.imagekit.io/driveu/illustration1_Z7_idPPeA.png?updatedAt=1697016733602",
    "https://ik.imagekit.io/driveu/Banner1_C9W6o8-qZ.png?updatedAt=1697016733935",
    "https://ik.imagekit.io/driveu/Banner2_Wcrif-guG.png?updatedAt=1697016733962",
    "https://ik.imagekit.io/driveu/Banner3_mG5OtDlbv_.png?updatedAt=1697016734192",
    "https://ik.imagekit.io/driveu/Banner5_9WLnRLpLg.png?updatedAt=1697016733329",
  ];
  const settings1 = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: false,
    autoplay: true,
    autoplaySpeed: 2000,
    appendDots: (dots) => (
      <div
        style={{
          width: "100%",
        }}
      >
        <ul
          style={{
            marginBottom: "2rem",
          }}
        >
          {dots}
        </ul>
      </div>
    ),
  };
  return (
    <div className={imageStyle.imageWrapper}>
      <Slider {...settings1}>
        {imageArr.map((elem, index) => {
          return <img key={index} src={elem} alt="DU DAILY PARTNER BANNER" />;
        })}
      </Slider>
    </div>
  );
};

export default ImageScrollPartner;
