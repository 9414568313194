import { ANCHOR, Drawer } from "baseui/drawer";
import React, { useContext } from "react";
import { BookingFlowDataContext } from "../../context/BookingFlowDataContextProvider";
import ShimmerBottom from "../../utils/ShimmerBottom";

const PromoBottomSheet = ({
  promoBottomSheet,
  setPromoBottomSheet,
  setCoupanSucessMessage,
  setCouponCodeLength,
  coupanText,
  setCoupanText,
  checkingCouponApi,
  showCoupanMessage,
  showErrorColor,
  coupanSucessMessage,
  handleFillPromoCode,
  couponCodeLength,
  couponData,
}) => {
  const { couponLoading } = useContext(BookingFlowDataContext);
  const handleChangeInputMode = (e) => {
    e.target.inputMode = "text";
  };
  return (
    <Drawer
      isOpen={promoBottomSheet}
      onClose={() => {
        setCoupanSucessMessage("");
        setPromoBottomSheet(false);
        setCouponCodeLength(false);
      }}
      anchor={ANCHOR.bottom}
    >
      {couponData.length === 0 && couponLoading ? (
        <ShimmerBottom />
      ) : (
        <div>
          <div className="stickyPromoCode">
            <div className="promoCodeBottomSheet">
              <div>Apply Promo Code</div>
              <div className="promoBottomSheetText">
                Enter a code below or select from the list
              </div>
            </div>
            <div>
              <div className="Coupon">
                <div className="inputCouponCode">
                  <input
                    id="couponDiv"
                    className="couponDetails couponCodeBottomSheetInput"
                    onChange={(e) => {
                      setCoupanText(e.target.value);
                      e.target.value.length > 0
                        ? setCouponCodeLength(true)
                        : setCouponCodeLength(false);
                    }}
                    type="text"
                    placeholder="Enter a Coupon Code"
                    autoComplete="off"
                    value={coupanText}
                    inputMode="none"
                    onClick={handleChangeInputMode}
                  />
                  <button
                    onClick={checkingCouponApi}
                    id="couponBtn"
                    style={{
                      color: couponCodeLength ? "#137e40" : "",
                      fontWeight: "500",
                    }}
                  >
                    Apply
                  </button>
                </div>
                <div
                  style={{
                    display: showCoupanMessage ? "" : "none",
                    color: showErrorColor ? "green" : "red",
                    fontfamily: "Circular-Std-Book",
                  }}
                >
                  {coupanSucessMessage}
                </div>
              </div>
            </div>
          </div>

          <>
            {couponData.map((codeData, index) => {
              return (
                <div className="bottomCouponContainer" key={index}>
                  <div className="bottomCouponWrapper">
                    <div className="firstBlockCouponWrapper">
                      <div className="couponCodeBottomSheet" id="getCouponCode">
                        {codeData.promo_code}
                      </div>
                      <button
                        className="firstF1PopUp"
                        style={{
                          color:
                            codeData.is_applicable === false
                              ? "grey"
                              : "#137e40",
                        }}
                        disabled={
                          codeData.is_applicable === false ? true : false
                        }
                        onClick={() => handleFillPromoCode(codeData.promo_code)}
                      >
                        APPLY
                      </button>
                    </div>
                    <div className="secondBlockCouponWrapper">
                      <div className="bottomS1">
                        <img
                          src={codeData.logo_url}
                          alt="Offer Logo"
                          width="100%"
                          height="100%"
                        />
                      </div>
                      <div className="bottomS2">{codeData.title}</div>
                      <div className="bottomS3">{codeData.description}</div>
                    </div>
                    <div className="thirdBlockCouponWrapper">
                      <div className="thirdT1">Terms and conditions</div>
                      <ul className="thirdT2">
                        {codeData.offer_terms.map((termsData, index) => {
                          return <li key={index}>&#x2022;&nbsp;{termsData}</li>;
                        })}
                      </ul>
                    </div>
                    <div className="fourthBlockCouponWrapper">
                      {codeData.validity_text}
                    </div>
                    <div className="fiveT1"></div>
                  </div>
                </div>
              );
            })}
          </>
        </div>
      )}
    </Drawer>
  );
};

export default PromoBottomSheet;
