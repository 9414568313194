// React Imports
import React, { useState, useEffect, useContext } from "react";

// Third-party Hooks and Libraries
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import useOnclickOutside from "react-cool-onclickoutside";
import axios from "axios";
import clevertap from "clevertap-web-sdk";

// BaseUI Imports
import { Spinner, SIZE } from "baseui/spinner";

// Icon Imports
import { MdClose } from "react-icons/md";

// Context Imports
import { ApplicationContext } from "../../../context/ApplicationContextProvider";
import { BookingFlowDataContext } from "../../../context/BookingFlowDataContextProvider";
import { BookingFlowParaContext } from "../../../context/BookingFlowParaProvider";

// Utils and Event Objects
import {
  continueReviewDailyCTA,
  continueScheduleCTA,
  dailyFinalReviewDailyCTA,
  dailyPageOpen,
} from "../../../utils/EventObject";

// Style Imports
import inputStyle from "./DDinput.module.css";

function DDInput() {
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  // context
  const { lat, lng } = useContext(BookingFlowParaContext);
  const {
    pubName,
    duDailyParams,
    showPaymentBox,
    setDailyPickUpLocationTracker,
    setPickUpLocationError,
  } = useContext(ApplicationContext);

  const {
    sendingDailyBookingData,
    setToggleDailyFareEstimate,
    setSendingDailyBookingData,
  } = useContext(BookingFlowDataContext);

  // state
  const [dailyLocationText, setDailyLocationText] = useState(0);
  const [showError, setShowError] = useState(false);
  const [toggleEmptyInput, setToggleEmptyInput] = useState(false);
  const [locationLoading, setLocationLoading] = useState(false);
  const [formData, setFormData] = useState({});
  const [previousInputValue, setPreviousInputValue] = useState("");
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  const {
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    debounce: 300,
  });

  const handleChange = (e) => {
    const { name } = e.target;
    setFormData({
      ...formData,
      [name]: e.target.value,
    });
  };

  const roundWayHandleInputDaily = (e) => {
    // When user edit location
    if (value !== previousInputValue) {
      // User deleted text, handle it here
      setPickUpLocationError(true);
    }

    const locationValue = e.target.value;
    setValue(locationValue);
    setDailyLocationText(locationValue.length);
  };

  const ref = useOnclickOutside(() => {
    clearSuggestions();
  });

  // This function use when user enter location(no pre filled)
  const handleCallNearestDriver = async (lat, lng) => {
    setLocationLoading(true);
    try {
      const latLngResponse = await axios.get(
        `${BASE_URL}/web/du-daily-availability/?user_id=${duDailyParams.user_id}&pickup_latitude=${lat}&pickup_longitude=${lng}`
      );
      const data = latLngResponse.data;
      if (data.status === "error") {
        setValue("");
        setShowError(true);
        setTimeout(() => {
          setShowError(false);
        }, [2000]);
        setLocationLoading(false);
      } else if (data.status === "success") {
        setDailyPickUpLocationTracker(false);
        setSendingDailyBookingData((prev) => ({
          ...prev,
          city: data.city_id,
        }));
        setLocationLoading(false);
        dailyPageOpen.pickup_zone = data.zone_name;
        dailyPageOpen.city = data.city_name;
        continueScheduleCTA.pickup_zone = data.zone_name;
        continueScheduleCTA.city = data.city_name;
        continueReviewDailyCTA.pickup_zone = data.zone_name;
        continueReviewDailyCTA.city = data.city;
        dailyFinalReviewDailyCTA.pickup_zone = data.zone_name;
        dailyFinalReviewDailyCTA.city = data.city;
        dailyPageOpen.page = showPaymentBox ? "form_2" : "form_1";
        clevertap.event.push("Daily page opened", dailyPageOpen);
      }
    } catch (error) {
      console.log(error.message);
      setLocationLoading(false);
    }
  };

  // This Function Call in start with lat long provided by url
  const handleCallNearestDriverWithLatLng = async (lat, lng) => {
    try {
      const latLngResponse = await axios.get(
        `${BASE_URL}/web/du-daily-availability/?user_id=${duDailyParams.user_id}&pickup_latitude=${lat}&pickup_longitude=${lng}`
      );
      const data = latLngResponse.data;

      if (data.status === "error") {
        setValue("");
        setShowError(true);
        setTimeout(() => {
          setShowError(false);
        }, [2000]);
        setLocationLoading(false);
      } else if (data.status === "success") {
        setDailyPickUpLocationTracker(false);
        dailyPageOpen.pickup_zone = data.zone_name;
        dailyPageOpen.city = data.city_name;
        continueScheduleCTA.pickup_zone = data.zone_name;
        continueScheduleCTA.city = data.city_name;
        continueReviewDailyCTA.pickup_zone = data.zone_name;
        continueReviewDailyCTA.city = data.city;
        dailyFinalReviewDailyCTA.pickup_zone = data.zone_name;
        dailyFinalReviewDailyCTA.city = data.city;
        dailyPageOpen.page = showPaymentBox ? "form_2" : "form_1";
        clevertap.event.push("Daily page opened", dailyPageOpen);
        setLocationLoading(false);
        getReverseGeocodingData(lat, lng);
        sendingDailyBookingData.city = data.city_id;
      }
    } catch (error) {
      setLocationLoading(false);
      console.log(error.message);
    }
  };

  const dailyHandleSelect = ({ description }) => () => {
    setPreviousInputValue(value);
    setPickUpLocationError(false);
    continueScheduleCTA.Location = description;
    setValue(description, false);
    clearSuggestions();
    getGeocode({ address: description }).then((results) => {
      const { lat, lng } = getLatLng(results[0]);
      const updatedData = {
        ...sendingDailyBookingData,
        pickup_address: description,
        pickup_latitude: lat,
        pickup_longitude: lng,
        user_id: duDailyParams.user_id,
      };

      // Update the state with the new data
      setSendingDailyBookingData(updatedData);
      handleCallNearestDriver(lat, lng);
    });
  };

  const dailyLocationSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <div key={place_id.toString()}>
          <ul
            className={inputStyle.roundsuggestion}
            key={place_id.toString()}
            onClick={dailyHandleSelect(suggestion)}
          >
            <strong style={{ marginRight: "5px" }} id="bold">
              {main_text}
            </strong>
            <small id="book">{secondary_text}</small>
          </ul>
        </div>
      );
    });
  function getReverseGeocodingData(lat, lng) {
    sendingDailyBookingData.pickup_longitude = lng;
    sendingDailyBookingData.pickup_latitude = lat;
    sendingDailyBookingData.user_id = duDailyParams.user_id;
    var latlng = new window.google.maps.LatLng(lat, lng);
    // This is making the Geocode request
    var geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ latLng: latlng }, (results, status) => {
      if (status !== window.google.maps.GeocoderStatus.OK) {
      }
      // This is checking to see if the Geoeode Status is OK before proceeding
      if (status === window.google.maps.GeocoderStatus.OK) {
        var address = results[0].formatted_address;

        if (pubName) {
          sendingDailyBookingData.pickup_address = pubName;
          setValue(pubName);
        } else {
          continueScheduleCTA.Location = address;
          dailyFinalReviewDailyCTA.Location = address;
          sendingDailyBookingData.pickup_address = address;
          setValue(address);
        }
      }
    });
  }
  const handleClearInput = () => {
    setValue("");
    sendingDailyBookingData.pickup_address = "";
    sendingDailyBookingData.city = "";
    sendingDailyBookingData.pickup_latitude = 0.0;
    sendingDailyBookingData.pickup_longitude = 0.0;
    setDailyPickUpLocationTracker(true);
  };

  useEffect(() => {
    if (value.length === 0) {
      setToggleEmptyInput(false);
      setToggleDailyFareEstimate(false);
    } else {
      setToggleEmptyInput(true);
      setToggleDailyFareEstimate(true);
    }
  }, [value]);

  useEffect(() => {
    if (lat === null || lng === null) {
      console.log("No Data");
    } else {
      handleCallNearestDriverWithLatLng(lat, lng);
    }
  }, [lat, lng]);

  useEffect(() => {
    const handleOnlineStatusChange = () => {
      setIsOnline(navigator.onLine);
    };

    window.addEventListener("online", handleOnlineStatusChange);
    window.addEventListener("offline", handleOnlineStatusChange);

    return () => {
      window.removeEventListener("online", handleOnlineStatusChange);
      window.removeEventListener("offline", handleOnlineStatusChange);
    };
  }, []);

  return (
    <>
      <div
        ref={ref}
        onChange={roundWayHandleInputDaily}
        className={inputStyle.secondInputLocation}
      >
        <div className={inputStyle.inputWrapper}>
          <label htmlFor="">
            Choose pick-up location<sup>*</sup>
          </label>
          <div className={inputStyle["roundWay_Input_Tag_Div"]}>
            <input
              className={`${inputStyle["first_Input_Tag"]}`}
              name="RoundWayLocation"
              type="text"
              value={value}
              onChange={handleChange}
              placeholder="Enter 4 letters to Search Pick-up Location"
              autoComplete="off"
            />
            <div
              style={{ display: toggleEmptyInput ? "" : "none" }}
              className={inputStyle["dailyCloseBtn"]}
              onClick={handleClearInput}
            >
              {locationLoading ? <Spinner $size={SIZE.small} /> : <MdClose />}
            </div>
          </div>
        </div>

        <>
          {dailyLocationText >= 4 && isOnline
            ? status === "OK" && (
                <div
                  style={{ width: "92%" }}
                  className={inputStyle.debounceBox}
                  id="debounceBox"
                >
                  {dailyLocationSuggestions()}
                </div>
              )
            : null}
        </>
      </div>
      <div
        style={{
          display: showError ? "" : "none",
          color: "red",
          width: "90%",
          margin: "auto",
        }}
      >
        Sorry, service not available
      </div>
    </>
  );
}

export default DDInput;
