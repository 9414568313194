import React, { useContext, useEffect } from "react";
import "./Fuelprice.css";
import { BsFillArrowLeftCircleFill } from "react-icons/bs";
import { BiChevronDown } from "react-icons/bi";
import StateDrawer from "./StateDrawer/StateDrawer";
import { ApplicationContext } from "../../context/ApplicationContextProvider";
import MyGateLoader from "../../Uis/MyGateLoader/MyGateLoader";
import CityDrawer from "./CityDrawer/CityDrawer";

const monthDay = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
const weekDay = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

function Fuelprice() {
  const {
    isFuelPrice,
    isCityPrice,
    setIsCityPrice,
    setIsFuelPrice,
    stateFuel,
    FuelPriceAPi,
    fuelLoader,
    cityName,
    stateFuelInfo,
    cityFuelInfo,
    fuelOption,
    fuelErrorPage,
    //
    // cityFuelPrice,
    stateValue,
    fuelPriceCoordinate,
    handleFuelPrice,
  } = useContext(ApplicationContext);

  var todayDate = new Date();
  var date = todayDate.getDate();
  var month = monthDay[todayDate.getMonth()];
  var year = todayDate.getFullYear();
  var day = weekDay[todayDate.getDay()];
  // const arr = [1, 2, 3, 4, 5];

  const handleStateModal = () => {
    setIsFuelPrice(true);
    setIsCityPrice(false);
  };

  const handleCityModal = () => {
    setIsCityPrice(true);
    setIsFuelPrice(false);
  };

  useEffect(() => {
    if (fuelPriceCoordinate.latitude !== "") {
      handleFuelPrice();
    }
  }, [fuelPriceCoordinate.latitude]);

  const handleRetry = () => {
    FuelPriceAPi(stateFuel);
  };

  const handleCloseMWeb = () => {
    window.open("mygate://close", "_self");
  };

  return (
    <>
      {isFuelPrice ? <StateDrawer /> : null}
      {isCityPrice ? <CityDrawer /> : null}

      {fuelErrorPage ? (
        <div className="fuelPriceApiFailMainContainer">
          <div className="fuelPriceApiFailImageMainContainer"></div>
          <div className="fuelPriceApiFailTextMainContainer">
            <div className="fuelPriceFailHeading">
              Oops... Something went wrong
            </div>
            <div className="fuelPriceFailSubHeading">
              We're sorry. This usually never happens.
            </div>
          </div>
          <div onClick={handleRetry} className="fuelPriceRetry">
            Retry
          </div>
        </div>
      ) : fuelLoader ? (
        <MyGateLoader />
      ) : (
        <div className="fuelPriceMainContainer">
          {/*  fuel price input divs main Container start from here */}
          <div className="fuelPriceSubMainContainer">
            <div className="fuelPriceContainer">
              {/* back button */}
              <div className="fuelPriceBackButtonMainContainer">
                <BsFillArrowLeftCircleFill
                  onClick={handleCloseMWeb}
                  className="fuelPriceBackButton"
                />
              </div>

              {/*  fuel price heading divs */}
              <div className="fuelPriceHeadingContainer">
                <div className="fuelPriceHeading">Fuel prices</div>
                <div className="fuelPriceSubHeading">{`${day}, ${date} ${month}, ${year}`}</div>
              </div>

              {/* input Divs */}
              <div className="fuelPriceInputMainContainer">
                {/* state modal */}
                <div
                  onClick={handleStateModal}
                  className="fuelPriceInputContainer"
                >
                  <div className="fuelPriceInputContainerText">
                    {stateValue}
                  </div>
                  <div className="fuelPriceInputContainerArrow">
                    <BiChevronDown />
                  </div>
                </div>

                {/*  second input city modal */}
                <div
                  onClick={handleCityModal}
                  className="fuelPriceInputContainer"
                >
                  <div className="fuelPriceInputContainerText">{cityName}</div>
                  <div className="fuelPriceInputContainerArrow">
                    <BiChevronDown />
                  </div>
                </div>
              </div>
            </div>

            {/* city wise fuel by the state // only state */}
            <div
              style={{ display: fuelOption ? "" : "none" }}
              className="cityWiseFuelMainDivContainer"
            >
              <div className="cityWiseFuelMainDivContainerHeading">
                City wise fuel prices
              </div>
              <div className="cityWiseFuelMainDivContainerTabel">
                <div className="cityWiseFuelFirstTabelOption">
                  <div></div>
                  <div>Petrol</div>
                  <div className="dieselPrice">Diesel</div>
                </div>
              </div>

              {/*  5 map div start from here */}
              {stateFuelInfo
                .filter((item, index) => index < 5)
                .map((elem) => (
                  <div
                    key={elem.city}
                    className="cityWiseFuelPriceMainDivContainerTabel"
                  >
                    <div className="cityWiseFuelPriceFirstTabelOption">
                      <div className="cityName">{elem.city}</div>
                      <div>₹ {elem.petrol_price}</div>
                      <div className="dieselPrice">₹ {elem.diesel_price}</div>
                    </div>
                  </div>
                ))}
              {/* bottom message */}
              <div className="fuelPriceBottomMessage">
                Note: There may be slight variation in the prices from outlet to
                outlet within a city.
              </div>
            </div>

            {/* city wise fuel by the state // including the city of the state */}
            <div
              style={{ display: fuelOption ? "none" : "" }}
              className="fuelPriceOptionMainContainer"
            >
              <div className="fuelPriceOptionContainer">
                {/* petrol */}
                <div
                  style={{ marginLeft: "0.5rem" }}
                  className="petrolFuelPriceMainContainer"
                >
                  <div className="petrolFuelImgContainer">
                    <img
                      src="https://ik.imagekit.io/driveu/Petrol_yJYwrYIIB.png?updatedAt=1682494917647"
                      alt="petrol_img"
                    />
                  </div>
                  <div>Petrol</div>
                  <div className="petrolPrice">
                    ₹ {cityFuelInfo.petrol_price}
                  </div>
                </div>
                {/* don't touch Diesel*/}
                <div
                  style={{ marginRight: "0.5rem" }}
                  className="petrolFuelPriceMainContainer"
                >
                  <div className="petrolFuelImgContainer">
                    <img
                      src="https://ik.imagekit.io/driveu/Disel_LkNkjnD60.png?updatedAt=1682494917834"
                      alt="petrol_img"
                    />
                  </div>
                  <div>Diesel</div>
                  <div className="petrolPrice">
                    ₹ {cityFuelInfo.diesel_price}
                  </div>
                </div>
                {/* end */}
              </div>

              <div className="fuelPriceBottomMessage">
                Note: There may be slight variation in the prices from outlet to
                outlet within a city.
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Fuelprice;
