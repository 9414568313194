import React from "react";
import "./Popular_place_ban.css";
import { Link } from "react-router-dom";

function PopularPlace() {
  const Popular_Place_1 =
    "https://ik.imagekit.io/driveu/Website_New/Popular_Place_1_SSI7jrorx.png";
  return (
    <div className="Popular_place_main_div_ban">
      <div className="Popular_place_heading_ban">Popular Places in Gurgaon</div>
      <div className="Popular_place_subheading_ban">
        We built DriveU around the belief that when people are treated better,
        they provide better service.
      </div>

      <div className="Popular_place_third_div_ban">
        <Link
          to="/car-drivers-for-hire-gurgaon/dlf-cyberhub"
          className="popular_first_div_ban"
        >
          <div className="popular_first_div_img_ban">
            <img src={Popular_Place_1} alt="DLF CyberHub" />
          </div>
          <div className="popular_first_div_text_ban">DLF CyberHub</div>
        </Link>

        <Link
          to="/car-drivers-for-hire-gurgaon/golf-course-extension"
          className="popular_first_div_ban"
        >
          <div className="popular_first_div_img_ban">
            <img src={Popular_Place_1} alt="Golf Course Extension" />
          </div>
          <div className="popular_first_div_text_ban">
            Golf Course Extension
          </div>
        </Link>

        <Link
          to="/car-drivers-for-hire-gurgaon/sikanderpur-ghosi"
          className="popular_first_div_ban"
        >
          <div className="popular_first_div_img_ban">
            <img src={Popular_Place_1} alt="Sikanderpur Ghosi" />
          </div>
          <div className="popular_first_div_text_ban">Sikanderpur Ghosi</div>
        </Link>

        <Link
          to="/car-drivers-for-hire-gurgaon/manesar"
          className="popular_first_div_ban"
        >
          <div className="popular_first_div_img_ban">
            <img src={Popular_Place_1} alt="Manesar" />
          </div>
          <div className="popular_first_div_text_ban">Manesar</div>
        </Link>

        <Link
          to="/car-drivers-for-hire-gurgaon/narsinghpur"
          className="popular_first_div_ban"
        >
          <div className="popular_first_div_img_ban">
            <img src={Popular_Place_1} alt="Narsinghpur" />
          </div>
          <div className="popular_first_div_text_ban">Narsinghpur</div>
        </Link>

        <Link
          to="/car-drivers-for-hire-gurgaon/ambience-mall"
          className="popular_first_div_ban"
        >
          <div className="popular_first_div_img_ban">
            <img src={Popular_Place_1} alt="Ambience Mall" />
          </div>
          <div className="popular_first_div_text_ban">Ambience Mall</div>
        </Link>

        <Link
          to="/car-drivers-for-hire-gurgaon/palam-vihar"
          className="popular_first_div_ban"
        >
          <div className="popular_first_div_img_ban">
            <img src={Popular_Place_1} alt="Palam Vihar" />
          </div>
          <div className="popular_first_div_text_ban">Palam Vihar</div>
        </Link>

        <Link
          to="/car-drivers-for-hire-gurgaon/southcity"
          className="popular_first_div_ban"
        >
          <div className="popular_first_div_img_ban">
            <img src={Popular_Place_1} alt="South City I" />
          </div>
          <div className="popular_first_div_text_ban">South City I</div>
        </Link>

        <Link
          to="/car-drivers-for-hire-gurgaon/sector54"
          className="popular_first_div_ban"
        >
          <div className="popular_first_div_img_ban">
            <img src={Popular_Place_1} alt="Gurgaon Sector 54" />
          </div>
          <div className="popular_first_div_text_ban">Gurgaon Sector 54</div>
        </Link>
        <Link
          to="/car-drivers-for-hire-gurgaon/sector82"
          className="popular_first_div_ban"
        >
          <div className="popular_first_div_img_ban">
            <img src={Popular_Place_1} alt="Gurgaon Sector 82" />
          </div>
          <div className="popular_first_div_text_ban">Gurgaon Sector 82</div>
        </Link>
        <Link
          to="/car-drivers-for-hire-gurgaon/nirvana-country"
          className="popular_first_div_ban"
        >
          <div className="popular_first_div_img_ban">
            <img src={Popular_Place_1} alt="Nirvana Country" />
          </div>
          <div className="popular_first_div_text_ban">Nirvana Country</div>
        </Link>
        <Link
          to="/car-drivers-for-hire-gurgaon/dlf-city"
          className="popular_first_div_ban"
        >
          <div className="popular_first_div_img_ban">
            <img src={Popular_Place_1} alt="DLF City" />
          </div>
          <div className="popular_first_div_text_ban">DLF City</div>
        </Link>
        <Link
          to="/car-drivers-for-hire-gurgaon/sohna-road"
          className="popular_first_div_ban"
        >
          <div className="popular_first_div_img_ban">
            <img src={Popular_Place_1} alt="Sohna Road" />
          </div>
          <div className="popular_first_div_text_ban">Sohna Road</div>
        </Link>
        <Link
          to="/car-drivers-for-hire-gurgaon/sushant-lok"
          className="popular_first_div_ban"
        >
          <div className="popular_first_div_img_ban">
            <img src={Popular_Place_1} alt="Sushant Lok 1" />
          </div>
          <div className="popular_first_div_text_ban">Sushant Lok 1</div>
        </Link>
      </div>
    </div>
  );
}

export default PopularPlace;
