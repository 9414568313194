import React, { useContext, useEffect, useRef, useState } from "react";
import "./Manufacture.css";
import { Modal, ModalHeader, ModalBody, SIZE } from "baseui/modal";
import { carServiceContext } from "../../../../context/CarServiceContextProvider";
import axios from "axios";
import MyGateLoader from "../../../../Uis/MyGateLoader/MyGateLoader";

function Manufacture() {
  const inputRef = useRef(null);
  const BASE_URL_HULK = process.env.REACT_APP_HULK_URL;
  const [manufacture, setManufacture] = useState([]);
  const [loader, setLoader] = useState(true);
  const [manufactureValue, setManufactureValue] = useState("");
  const {
    isManufactureModalOpen,
    closeManufactureModal,
    setIsCarModalOpen,
    setManufactureId,
  } = useContext(carServiceContext);

  const handleCarModal = (id) => () => {
    setManufactureId(id);
    closeManufactureModal();
    setIsCarModalOpen(true);
  };

  useEffect(() => {
    const fetchCarBrandData = () => {
      axios({
        method: "GET",
        baseURL: BASE_URL_HULK,
        url: `${BASE_URL_HULK}/care/car-manufacturers/?is_automovil=1`,
        withCredentials: false,
        headers: {
          "Content-Type": `application/json`,
        },
      })
        .then(({ data }) => {
          setManufacture(data.manufacturers);
          setLoader(false);
        })
        .catch((err) => {
          console.log(err.message);
        });
    };
    fetchCarBrandData();
  }, []);

  const handleManufacture = (e) => {
    setManufactureValue(e.target.value);
  };

  const handleClick = () => {
    inputRef.current.inputMode = "text";
  };

  return (
    <>
      <Modal
        onClose={closeManufactureModal}
        isOpen={isManufactureModalOpen}
        size={SIZE.default}
      >
        <ModalHeader>Select Manufacturer</ModalHeader>
        <ModalBody style={{ flex: "1 1 0" }}>
          <div className="carServiceManufactureMainContainer">
            <div
              onClick={handleClick}
              className="carServiceManufactureInputMainContainer"
            >
              {/* <div className="carServiceManufactureSearchContainer"></div> */}
              <input
                className="carServiceManufactureInputContainer"
                type="text"
                ref={inputRef}
                placeholder="Search Brands"
                value={manufactureValue}
                onChange={handleManufacture}
                inputMode="none"
              />
            </div>

            {/* manufacturer option  */}
            {loader ? (
              <MyGateLoader />
            ) : (
              <div className="carServiceManufactureOptionsMainContainer">
                {manufacture
                  .filter((brand) => {
                    if (manufactureValue === "") {
                      return brand;
                    } else if (
                      brand.make
                        .toLowerCase()
                        .includes(manufactureValue.toLowerCase())
                    ) {
                      return brand;
                    } else {
                      return false;
                    }
                  })
                  .map((elem) => {
                    return (
                      <div
                        key={elem.id}
                        onClick={handleCarModal(elem.id)}
                        className="carServiceManufacturefirstOptionMainContainer"
                      >
                        <img src={elem.make_logo} alt={elem.make} />
                        <div className="carServiceManufacturefirstOptionText">
                          {elem.make}
                        </div>
                      </div>
                    );
                  })}
              </div>
            )}
          </div>
        </ModalBody>
      </Modal>
    </>
  );
}

export default Manufacture;
