import axios from "axios";
import { useContext } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { ApplicationContext } from "../context/ApplicationContextProvider";
import { BookingFlowDataContext } from "../context/BookingFlowDataContextProvider";
import { BookingFlowParaContext } from "../context/BookingFlowParaProvider";
import clevertap from "clevertap-web-sdk";
import { requestDriverObj } from "../utils/EventObject";
function useBookDriverApi() {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const PROFILE_API_SRC = process.env.REACT_APP_PROFILE_API_SRC;
  const {
    sendingData,
    sendingRoundWayData,
    sendingOutstationData,
    setShowBookLoaderRound,
    dateTimeAllWay,
    setActiveKey,
    showETA,
    showWhen,
    oneWayETATimeString,
    showRoundETA,
    showRoundWhen,
    roundWayETATimeString,
    dropCityEtaDetails,
    cityEtaDetails,
    eventFareEstimate,
    setTrackDriverScreen,
  } = useContext(BookingFlowDataContext);
  const { setShowBookLoader } = useContext(BookingFlowParaContext);
  const [cookiesHireDrivers] = useCookies();
  const navigateToDrives = useNavigate();
  const { setMyGateBookingID, myGateToken, setMyGateBookingData } = useContext(
    ApplicationContext
  );
  function handleBookDriverBooking() {
    sendingData.pickup_datetime =
      showETA && showWhen[0].label.includes("Now")
        ? oneWayETATimeString
        : dateTimeAllWay;
    sendingData.user_id = cookiesHireDrivers.userid;
    setShowBookLoader(true);
    const dateString = sendingData.pickup_datetime;
    const timeString = dateString.split(" ")[1];
    const colonIndex = timeString.indexOf(":");
    const twoIndexesBeforeColon = timeString.slice(colonIndex - 2, colonIndex);
    axios({
      method: "POST",
      baseURL: BASE_URL,
      url: `${BASE_URL}/web/book-driver/?src=${PROFILE_API_SRC}&user_id=${cookiesHireDrivers.userid}`,
      data: sendingData,
      withCredentials: false,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(({ data }) => {
        if (data.status === "success" && myGateToken) {
          setMyGateBookingID(data.booking_id);
          setMyGateBookingData(data);
          setTrackDriverScreen("booking_redirection");
          window.location.href = data.redirect_url;
          setShowBookLoader(false);

          requestDriverObj.client = "mygate";
          requestDriverObj.status = "success";
          clevertap.event.push("Request Driver", requestDriverObj);
        } else if (data.status === "success") {
          requestDriverObj.client = "website";
          requestDriverObj.status = "success";
          clevertap.event.push("Request Driver", requestDriverObj);
          setShowBookLoader(false);
          navigateToDrives("/my-drives");
          setActiveKey("0");
        } else if (data.status === "error") {
          requestDriverObj.status = "failed";
          requestDriverObj.client = myGateToken ? "mygate" : "website";
          clevertap.event.push("Request Driver", requestDriverObj);
          alert(data.message);
          setShowBookLoader(false);
        }

        requestDriverObj["Booking Type"] = "one_way";
        requestDriverObj["Destination Zone"] = dropCityEtaDetails.zone_name;
        requestDriverObj.PromoCode =
          !sendingData.promo_code === true
            ? "Promocode not applied"
            : "Promocode applied";
        requestDriverObj["Now or later"] =
          cityEtaDetails.show_eta === true ? "Pick Now" : "Scheduled";
        requestDriverObj["Estimated Usage"] = `${twoIndexesBeforeColon} hr`;
        requestDriverObj.Date = sendingData.pickup_datetime;
        requestDriverObj["Fare Estimate"] = eventFareEstimate;
        requestDriverObj["DU Secure"] =
          sendingData.is_insured === true ? "Yes" : "No";
      })
      .catch((err) => {
        console.log(err.message);
        setShowBookLoader(false);
      });
  }
  const handleBookDriverBookingRoundTrip = () => {
    sendingRoundWayData.pickup_datetime =
      showRoundETA && showRoundWhen[0].label.includes("Now")
        ? roundWayETATimeString
        : dateTimeAllWay;
    // sendingData.pickup_datetime = dateTimeAllWay;
    sendingRoundWayData.user_id = cookiesHireDrivers.userid;
    setShowBookLoaderRound(true);
    axios({
      method: "POST",
      baseURL: BASE_URL,
      url: `${BASE_URL}/web/book-driver/?src=${PROFILE_API_SRC}&user_id=${cookiesHireDrivers.userid}`,
      data: sendingRoundWayData,
      withCredentials: false,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(({ data }) => {
        if (data.status === "success" && myGateToken) {
          requestDriverObj.status = "success";
          requestDriverObj.client = "mygate";
          clevertap.event.push("Request Driver", requestDriverObj);
          setMyGateBookingID(data.booking_id);
          setMyGateBookingData(data);
          setTrackDriverScreen("booking_redirection");
          window.location.href = data.redirect_url;
          setShowBookLoaderRound(false);
        } else if (data.status === "success") {
          requestDriverObj.status = "success";
          requestDriverObj.client = "website";
          clevertap.event.push("Request Driver", requestDriverObj);
          setShowBookLoaderRound(false);
          navigateToDrives("/my-drives");
          setActiveKey("0");
        } else if (data.status === "error") {
          requestDriverObj.status = "failed";
          requestDriverObj.client = myGateToken ? "mygate" : "website";
          clevertap.event.push("Request Driver", requestDriverObj);
          alert(data.message);
          setShowBookLoaderRound(false);
        }
        requestDriverObj["Booking Type"] = "round_trip";
        requestDriverObj["Destination Zone"] = cityEtaDetails.zone_name;
        requestDriverObj.PromoCode =
          !sendingRoundWayData.promo_code === true
            ? "Promocode not applied"
            : "Promocode applied";
        requestDriverObj["Now or later"] =
          cityEtaDetails.show_eta === true ? "Pick Now" : "Scheduled";
        requestDriverObj.Date = sendingRoundWayData.pickup_datetime;
        requestDriverObj["Fare Estimate"] = eventFareEstimate;
        requestDriverObj["DU Secure"] =
          sendingRoundWayData.is_insured === true ? "Yes" : "No";
        requestDriverObj["Booking Hour"] = sendingRoundWayData.booking_type;
        setShowBookLoaderRound(false);
      })
      .catch((err) => {
        console.log(err.message);
        setShowBookLoaderRound(false);
      });
  };

  const handleBookDriverOutstation = () => {
    sendingOutstationData.user_id = cookiesHireDrivers.userid;
    setShowBookLoader(true);
    axios({
      method: "POST",
      baseURL: BASE_URL,
      url: `${BASE_URL}/web/book-driver/?src=${PROFILE_API_SRC}&user_id=${cookiesHireDrivers.userid}`,
      data: sendingOutstationData,
      withCredentials: false,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(({ data }) => {
        if (data.status === "success" && myGateToken) {
          requestDriverObj.status = "success";
          requestDriverObj.client = "mygate";
          clevertap.event.push("Request Driver", requestDriverObj);
          setShowBookLoader(false);
          setMyGateBookingID(data.booking_id);
          setMyGateBookingData(data);
          setTrackDriverScreen("booking_redirection");
          window.location.href = data.redirect_url;
          setShowBookLoader(false);
        } else if (data.status === "success") {
          requestDriverObj.status = "success";
          requestDriverObj.client = "website";
          clevertap.event.push("Request Driver", requestDriverObj);
          setShowBookLoader(false);
          navigateToDrives("/my-drives");
          setActiveKey("0");
        } else if (data.status === "error") {
          requestDriverObj.status = "failed";
          requestDriverObj.client = myGateToken ? "mygate" : "website";
          clevertap.event.push("Request Driver", requestDriverObj);
          alert(data.message);
          setShowBookLoader(false);
        }

        requestDriverObj["Booking Type"] = "outstation";
        requestDriverObj["Destination Zone"] = "N/A";
        requestDriverObj.PromoCode =
          !sendingOutstationData.promo_code === true
            ? "Promocode not applied"
            : "Promocode applied";
        requestDriverObj["Now or later"] = "N/A";
        requestDriverObj.Date = sendingOutstationData.pickup_datetime;
        requestDriverObj["Fare Estimate"] = eventFareEstimate;
        requestDriverObj["DU Secure"] =
          sendingOutstationData.is_insured === true ? "Yes" : "No";
        requestDriverObj["Booking Hour"] = sendingOutstationData.booking_type;
      })
      .catch((err) => {
        console.log(err.message);
        setShowBookLoader(false);
      });
  };
  return {
    handleBookDriverBooking,
    handleBookDriverBookingRoundTrip,
    handleBookDriverOutstation,
  };
}

export default useBookDriverApi;
