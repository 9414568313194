// Library Imports
import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { Modal, ModalBody, ModalHeader } from "baseui/modal";
import { Button, KIND, SIZE } from "baseui/button";
import { Badge, COLOR, SHAPE } from "baseui/badge";
import { MdCall, MdInfoOutline } from "react-icons/md";
import { AiFillStar } from "react-icons/ai";
import Delete from "baseui/icon/delete";

// Context Imports
import { ApplicationContext } from "../../context/ApplicationContextProvider";
import { BookingFlowDataContext } from "../../context/BookingFlowDataContextProvider";

// Component Imports
import CarWashAppLoader from "../../Uis/CarWashAppLoader/CarWashAppLoader";
import CancelBookingModal from "./CancelBookingModal/CancelBookingModal";
import DailyFareModal from "../DailyBookingDefault/DailyFareModal/DailyFareModal";
import CancelPolicyDU from "./CancelPolicyDU";

// Utility Imports
import { duDailyTrackPageEvent } from "../../utils/EventObject";
import clevertap from "clevertap-web-sdk";

// SVG and CSS Imports
import {
  CarTypeDU,
  DuLocationGreen,
  DuLocationRed,
  TripTimeDU,
} from "../../Components/AllImportFiles/ImportSvg";
import trackStyle from "./TrackDu.module.css";
import defaultStyle from "../DailyBooking.module.css";

function TrackDuDaily() {
  // Env variables
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const PROFILE_API_SRC = process.env.REACT_APP_PROFILE_API_SRC;
  // state variables
  const [duDailyTrackData, setDuDailyTrackData] = useState([] || {});
  const [trackCancelPolicy, setTrackCancelPolicy] = React.useState(false);
  // context
  const {
    isOpenCancelModal,
    setIsOpenCancelModal,
    duOrderID,
    openDailyFareModal,
    setOpenDailyFareModal,
    appUserID,
  } = useContext(ApplicationContext);
  const { setFareEstimateDaily } = useContext(BookingFlowDataContext);

  /*
  handleTrackData => This Function use for getting Order Details

  handleIndividualFareData => This Function for getting Individual Fare Data for Booking
  1 Order => 3 Booking (Each Booking Have own Fare Data)

  fetchUserDetailsDU => This Function is getting User Data and Pushing in Clevertap Profile
  */
  const handleTrackData = () => {
    axios({
      method: "GET",
      baseURL: BASE_URL,
      url: `${BASE_URL}/web/track-du-daily-booking/?booking_id=${duOrderID}`,
      withCredentials: false,
      headers: {
        "Content-Type": `application/json`,
      },
    })
      .then(({ data }) => {
        if (data.status === "success") {
          setDuDailyTrackData(data);
          duDailyTrackPageEvent["order id"] = data.order_id;
          duDailyTrackPageEvent.pickup_zone = data.zone_name;
          duDailyTrackPageEvent.city = data.city_name;
          clevertap.event.push(
            "Daily package tracking screen opened",
            duDailyTrackPageEvent
          );
        } else {
          console.log(data.message);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  const handleIndividualFareData = (bookingID) => {
    setOpenDailyFareModal(true);
    axios({
      method: "GET",
      baseURL: BASE_URL,
      url: `${BASE_URL}/web/du-daily-fare-breakup/?booking_id=${bookingID}`,
      withCredentials: false,
      headers: {
        "Content-Type": `application/json`,
      },
    })
      .then(({ data }) => {
        if (data.status === "success") {
          setFareEstimateDaily(data.breakup);
        } else {
          console.log(data.message);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  function fetchUserDetailsDU() {
    axios({
      method: "GET",
      baseURL: BASE_URL,
      url: `${BASE_URL}/web/profile/?src=${PROFILE_API_SRC}&user_id=${appUserID}`,
      withCredentials: false,
      headers: {
        "Content-Type": `application/json`,
      },
    })
      .then(({ data }) => {
        if (data.status === "success") {
          clevertap.profile.push({
            Site: {
              Name: data.full_name,
              Identity: data.username, // String or number
              Email: data.email, // Email address of the user
              Phone: `+91${data.username}`, // Phone (with the country code)
            },
          });
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  }

  const handleTrackBookingStatus = (data) => {
    // Tracking page Redirection with MapBox
    window.location.href = data.track_url;
  };
  // Don't Invoke when duOrderID is falsy Value or empty value
  React.useEffect(() => {
    if (duOrderID === "") {
    } else {
      fetchUserDetailsDU();
      handleTrackData();
    }
  }, [duOrderID]);

  return (
    <div className={trackStyle.dutrackContainer}>
      <div className={trackStyle.headerTrack}>
        {/* <img
          src="https://plus.unsplash.com/premium_photo-1680127401632-dcc0f571d4b9?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwzfHx8ZW58MHx8fHx8&auto=format&fit=crop&w=800&q=60"
          alt="jj"
          width={"100%"}
          height={"100%"}
        /> */}
        <strong>
          {duDailyTrackData.length !== 0 && (
            <>{`Track your DU Daily ${
              duDailyTrackData.drop_address ? "OneWay Trip" : "Round Trip"
            } here`}</>
          )}
        </strong>
      </div>
      {duDailyTrackData.length === 0 ? (
        <CarWashAppLoader />
      ) : (
        <>
          <div className={trackStyle.duTrackDetailsWrapper}>
            <div className={trackStyle.orderIdShow}>
              <strong>Order ID: {duDailyTrackData.order_id}</strong>
              <div>Booked on {duDailyTrackData.booked_on}</div>
            </div>

            <>
              {Object.keys(duDailyTrackData.driver).length === 0 ? null : (
                <div className={trackStyle.driverDetailBlock}>
                  <div className={trackStyle.driverDetailBlockOne}>
                    <div style={{ width: "40px", height: "40px" }}>
                      <img
                        src={duDailyTrackData.driver.photo_url}
                        alt="Driver Profile"
                        width="100%"
                        height="100%"
                        style={{ borderRadius: "50%" }}
                      />
                    </div>
                    <div className={trackStyle.nameRating}>
                      <div>
                        <strong>{duDailyTrackData.driver.name}</strong>
                        <div className={trackStyle.ratingBlock}>
                          <AiFillStar style={{ color: "#127e40" }} />
                          &nbsp;{duDailyTrackData.driver.rating} |{" "}
                          {duDailyTrackData.driver.booking_type}
                        </div>
                      </div>
                      <a
                        href={`tel:${duDailyTrackData.driver.contact_number}`}
                        className={trackStyle.callIconStyle}
                      >
                        <MdCall style={{ color: "#fff" }} />
                      </a>
                    </div>
                  </div>
                  {!duDailyTrackData.track_url === true ? null : (
                    <Button
                      onClick={() => handleTrackBookingStatus(duDailyTrackData)}
                      size={SIZE.mini}
                      kind={KIND.secondary}
                    >
                      Track booking here
                    </Button>
                  )}
                </div>
              )}
            </>

            <div className={trackStyle.duBookingDetailsWrapper}>
              <section>Booking Details</section>
              <div className={trackStyle.duBookingContainer}>
                <div className={trackStyle.duBookDetailsBlock}>
                  <DuLocationGreen />
                  <div style={{ width: "90%" }}>
                    <div style={{ fontSize: "12px", opacity: 0.7 }}>PickUp</div>
                    <div>{duDailyTrackData.pickup_address}</div>
                  </div>
                </div>

                <>
                  {duDailyTrackData.drop_address && (
                    <>
                      <div className={trackStyle.duBookDetailsBlock}>
                        <DuLocationRed />
                        <div style={{ width: "90%" }}>
                          <div style={{ fontSize: "12px", opacity: 0.7 }}>
                            Drop
                          </div>
                          <div> {duDailyTrackData.drop_address}</div>
                        </div>
                      </div>
                    </>
                  )}
                </>

                <div className={trackStyle.duBookDetailsBlock}>
                  <CarTypeDU style={{ width: "20px", height: "24px" }} />
                  <div style={{ width: "90%" }}>
                    {duDailyTrackData.car_type}
                  </div>
                </div>
                <div className={trackStyle.duBookDetailsBlock}>
                  <TripTimeDU style={{ width: "20px", height: "24px" }} />
                  <div style={{ width: "90%" }}>
                    <div>{duDailyTrackData.booking_type_display}</div>
                    {duDailyTrackData.completed_bookings.length === 0 ? null : (
                      <div>
                        <span style={{ color: "#009D61" }}>Completed: </span>
                        <span>
                          {duDailyTrackData.completed_bookings.map(
                            (elem, idx) => (
                              <span key={idx}>
                                {elem.date}
                                {idx !==
                                  duDailyTrackData.completed_bookings.length -
                                    1 && ","}
                              </span>
                            )
                          )}
                        </span>
                      </div>
                    )}
                    {duDailyTrackData.upcoming_bookings.length === 0 ? null : (
                      <div>
                        <span style={{ color: "#3F69FF" }}>Upcoming: </span>
                        <span>
                          {duDailyTrackData.upcoming_bookings.map(
                            (elem, idx) => (
                              <span key={idx}>
                                {elem.date}
                                {idx !==
                                  duDailyTrackData.upcoming_bookings.length -
                                    1 && ","}
                              </span>
                            )
                          )}
                        </span>
                      </div>
                    )}
                    {duDailyTrackData.cancelled_bookings.length === 0 ? null : (
                      <div>
                        <span style={{ color: "#F15355" }}>Cancelled: </span>
                        <span>
                          {duDailyTrackData.cancelled_bookings.map(
                            (elem, idx) => (
                              <span key={idx}>
                                {elem.date}
                                {idx !==
                                  duDailyTrackData.cancelled_bookings.length -
                                    1 && ","}
                              </span>
                            )
                          )}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <>
              {duDailyTrackData.instructions ? (
                <div className={trackStyle.instructionDU}>
                  <strong>Instruction</strong>
                  <div>{duDailyTrackData.instructions}</div>
                </div>
              ) : null}
            </>

            <div
              style={{ marginTop: "30px" }}
              className={defaultStyle.billDetailsContainer}
            >
              <strong>
                <span>Bill Details</span>
                {!duDailyTrackData.package_status === true ? null : (
                  <Badge
                    content={duDailyTrackData.package_status}
                    shape={SHAPE.pill}
                    color={COLOR.positive}
                  />
                )}
              </strong>
              <div className={defaultStyle.billDetailsWrapper}>
                <div className={defaultStyle.fareBreakUpDaily}>
                  <div>
                    <strong>Est. Package Cost</strong>
                    <span>{duDailyTrackData.estimated_cost}</span>
                  </div>
                  {duDailyTrackData.payment_details.map((fareData, idx) => (
                    <>
                      <div
                        key={idx}
                        style={{ color: fareData.color, paddingBottom: "0" }}
                      >
                        <p>
                          {fareData.label}&nbsp;
                          {fareData.amount !== "₹0" && (
                            <span>
                              <MdInfoOutline
                                onClick={() =>
                                  handleIndividualFareData(fareData.booking_id)
                                }
                              />
                            </span>
                          )}
                        </p>
                        <span>{fareData.amount}</span>
                      </div>
                      <p style={{ padding: "0 0.5rem", fontSize: "12px" }}>
                        #{fareData.booking_id}
                      </p>
                    </>
                  ))}
                </div>

                <hr />
                <div className={defaultStyle.fareBreakUpDaily}>
                  <div>
                    <strong>Payment Collection</strong>
                  </div>
                  {duDailyTrackData.version === "v2" ? (
                    <>
                      {duDailyTrackData.payment_collection.map(
                        ({ label, amount, sub_label, booking_id }, idx) => (
                          <>
                            <div
                              key={idx}
                              style={{
                                paddingBottom: "0",
                              }}
                            >
                              <span>
                                {label}&nbsp;
                                {amount !== "₹0" && booking_id !== "" && (
                                  <MdInfoOutline
                                    onClick={() =>
                                      handleIndividualFareData(booking_id)
                                    }
                                  />
                                )}
                              </span>
                              <span>{amount}</span>
                            </div>
                            <p
                              style={{ padding: "0 0.5rem", fontSize: "12px" }}
                            >
                              {sub_label}
                            </p>
                          </>
                        )
                      )}
                    </>
                  ) : (
                    <>
                      <div>
                        <span>{duDailyTrackData.advance_paid.label}</span>
                        <strong>{duDailyTrackData.advance_paid.amount}</strong>
                      </div>
                      {Object.keys(duDailyTrackData.paid_amount).length ===
                      0 ? null : (
                        <div>
                          <span>{duDailyTrackData.paid_amount.label}</span>
                          <strong>{duDailyTrackData.paid_amount.amount}</strong>
                        </div>
                      )}
                    </>
                  )}
                </div>
                <hr />
                <div className={defaultStyle.fareText}>
                  <div>
                    <strong>{duDailyTrackData.amount_to_pay_label}</strong>
                    <strong>{duDailyTrackData.amount_to_pay}</strong>
                  </div>

                  <ul style={{ color: "#61616C" }}>
                    {duDailyTrackData.version === "v2" ? (
                      <>
                        <li>
                          Actual cost of trips & package will be recalculated
                          after each trip end
                        </li>
                        <li>
                          Driver needs to be paid at the end of each trip.
                        </li>
                      </>
                    ) : (
                      <>
                        <li>
                          Actual cost of trips & package will be recalculated
                          after each trip end
                        </li>
                        <li>
                          Unpaid amount will need to be paid to the driver at
                          the end of the last trip of the package
                        </li>
                      </>
                    )}
                  </ul>
                </div>
              </div>
            </div>

            <div
              className={defaultStyle.bookingShowDate}
              style={{ color: "#61616C" }}
              onClick={() => setTrackCancelPolicy(true)}
            >
              <u>Cancellation Policy</u>
            </div>
            <>
              {duDailyTrackData.upcoming_bookings.length === 0 ? null : (
                <div className={trackStyle.cancelRedirectionContainer}>
                  <Button
                    size={SIZE.compact}
                    startEnhancer={() => <Delete size={24} />}
                    onClick={() => setIsOpenCancelModal(true)}
                  >
                    Cancel
                  </Button>
                </div>
              )}
            </>
          </div>
        </>
      )}

      <Modal
        onClose={() => setTrackCancelPolicy(false)}
        isOpen={trackCancelPolicy}
      >
        <ModalHeader>How it works?</ModalHeader>
        <ModalBody>
          <CancelPolicyDU version={duDailyTrackData.version} />
        </ModalBody>
      </Modal>

      <>
        {/* Cancel Booking Component for Cancelling Booking */}
        {isOpenCancelModal && (
          <CancelBookingModal
            cancelBookingData={duDailyTrackData}
            handleTrackData={handleTrackData}
          />
        )}
      </>
      <>
        {/* Daily Fare Modal for Showing Pricing */}
        {openDailyFareModal && (
          <DailyFareModal
            openDailyFareModal={openDailyFareModal}
            setOpenDailyFareModal={setOpenDailyFareModal}
          />
        )}
      </>
    </div>
  );
}

export default TrackDuDaily;
