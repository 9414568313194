import React, { useContext, useEffect, useState } from "react";
import { HamburgerContext } from "../../context/HamburgerContextProvider";
import { Vector48 } from "../../Components/AllImportFiles/ImportSvg";
import { Link } from "react-router-dom";
import "./HamburgurMenu.css";
import { isMobile } from "react-device-detect";
// import { DownloadContext } from "../../context/DownloadContextProvider";
// import { BookingFlowParaContext } from "../../context/BookingFlowParaProvider";
function HamburgurMenu() {
  // const navigate = useNavigate();
  const { showMediaIcons } = useContext(HamburgerContext);
  const [toggle, setToggle] = useState(false);
  const [show, setShow] = useState(false);
  const [business, setBusiness] = useState(false);
  // const { setShowPopUp, setPreviousChoice } = useContext(DownloadContext);
  // const { isAuthenticated } = useContext(BookingFlowParaContext);

  const utmSource = localStorage.getItem("utm_source") || "website";
  const utmMedium = localStorage.getItem("utm_medium") || "storebadge";
  const utmCampaign = localStorage.getItem("utm_campaign") || "homepage";

  const [playStore, setPlayStore] = useState(`https://play.google.com/store/apps/details?id=com.humblemobile.consumer&utm_source=${utmSource}&utm_medium=${utmMedium}&utm_campaign=${utmCampaign}&page_title=${window.location.pathname}`);
  const [appStore, setAppStore] = useState(`https://apps.apple.com/in/app/driveu-hire-drivers-services/id1037756294?utm_source=${utmSource}&utm_medium=${utmMedium}&utm_campaign=${utmCampaign}&page_title=${window.location.pathname}`);

  useEffect(() => {
    const generateLink = window.generatedLink;

    if (generateLink) {
      if (isMobile) {
        setPlayStore(generateLink);
        setAppStore(generateLink);
      }
    }
  }, []);

  const showAbout = () => {
    setTimeout(() => {
      setShow(false);
      setBusiness(false);
      setToggle(!toggle);
    }, 50);
  };
  const showService = () => {
    setTimeout(() => {
      setToggle(false);
      setBusiness(false);
      setShow(!show);
    }, 50);
  };
  const showBusiness = () => {
    setTimeout(() => {
      setShow(false);
      setToggle(false);
      setBusiness(!business);
    }, 50);
  };

  // const handleHireDrivers = () => {
  //   if (isAuthenticated === true) {
  //     navigate("/book-driver");
  //   } else if (isAuthenticated === false) {
  //     setPreviousChoice("hiredrivers");
  //     setShowPopUp(true);
  //   }
  // };

  useEffect(() => {
    setToggle(false);
    setShow(false);
    setBusiness(false);
  }, [showMediaIcons]);

  return (
    <div className="hamburgur-booking-wrapper">
      <div className="Mobile_Option_booking">
        <div className="MobileNav_Heading_Booking">
          <Link to="/" className="color-change-booking">
            Home
          </Link>
        </div>
        <div className="MobileNav_Heading_Booking" onClick={showService}>
          <div className="Ham-subHeading-booking">
            Services
            <div
              style={{
                display: show ? "block" : "none",
              }}
              className="Ham-subHeading-content"
            >
              <div className="Hover-effect-ham">
                <Link to="/hire-drivers" className="color-change">
                  <div> Hire Drivers</div>
                </Link>
              </div>
              <div className="Hover-effect-ham">
                <Link to="/recharge-fastag" className="color-change">
                  <div> FASTag Recharge</div>
                </Link>
              </div>

              <div className="Hover-effect-ham">
                <Link
                  to = "/car-wash"
                  className="color-change"
                >
                  <div>Car Wash</div>
                </Link>
              </div>
              {/* <div className="Hover-effect-ham">
                <Link to="/sell-used-cars" className="color-change">
                  <div>Sell Car</div>
                </Link>
              </div> */}
              <div className="Hover-effect-ham">
                <a href="https://www.driveu.in/driveu-crew/" className="color-change">
                  <div>DriveU Crew</div>
                </a>
              </div>
              <div className="Hover-effect-ham">
                <Link className="color-change" to="/car-servicing">
                  <div>Car Servicing</div>
                </Link>
              </div>
              
              {/* <div className="Hover-effect-ham">
                <Link className="color-change" to="/roadside-assistance-plans">
                  <div>Roadside Assistance</div>
                </Link>
              </div> */}
            </div>
          </div>
          <Vector48 className={`${show ? "nav-rotate-arrow" : "arrow"}`} />
        </div>
        <div className="MobileNav_Heading_Booking" onClick={showBusiness}>
          <div className="Ham-subHeading-booking">
            For Business
            <div
              style={{
                display: business ? "block" : "none",
              }}
              className="Ham-subHeading-content"
            >
              <div className="Hover-effect-ham">
                <Link to="/driver-partners" className="color-change">
                  <div>Join as Driver Partner</div>
                </Link>
              </div>
              <div className="Hover-effect-ham">
                <a
                  href="https://www.driveu.in/business/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="color-change"
                >
                  <div> Hire Drivers for B2B</div>
                </a>
              </div>
            </div>
          </div>
          <Vector48 className={`${business ? "nav-rotate-arrow" : "arrow"}`} />
        </div>
        <div className="MobileNav_Heading_Booking" onClick={showAbout}>
          <div className="Ham-subHeading-booking">
            About us
            <div
              style={{ display: toggle ? "block" : "none", width: "460%" }}
              className="Ham-subHeading-content"
            >
              <div className="Hover-effect-ham">
                <Link className="color-change" to="/team">
                  <div>Team</div>
                </Link>
              </div>
              <div className="Hover-effect-ham">
                <a
                  className="color-change"
                  href="https://www.driveu.in/blog/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div> Blog</div>
                </a>
              </div>
              <div className="Hover-effect-ham">
                <Link to="/contact-us" className="color-change">
                  <div>Contact Us</div>
                </Link>
              </div>
            </div>
          </div>
          <Vector48 className={`${toggle ? "nav-rotate-arrow" : "arrow"}`} />
        </div>
        <div className="App_Store_Image">
          <a href={playStore}>
            <img
              src="https://ik.imagekit.io/driveu/Website/1_kx2JKsHQC.png"
              alt="PlayStore"
              className="GooglePlay"
            />
          </a>
          <a href={appStore}>
            <img
              src="https://ik.imagekit.io/driveu/Website/2_9snS8wDMo.png"
              alt="AppStore"
              className="GooglePlay ApplePlay"
            />
          </a>
        </div>
      </div>
    </div>
  );
}

export default HamburgurMenu;
