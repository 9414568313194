import React, { useState, Fragment, useContext } from "react";
import "./RegistrationForm.css"; // Importing RegistrationForm CSS
import { useSelector, useDispatch } from "react-redux";
import { getOtp } from "../../../../Redux/APICALL/action";
import PopUpOtp from "../PopUpOtp/PopUpOtp";
import PopUpDetails from "../PopUpDetails/PopUpDetails";
import { CloseBtn } from "../../../../Components/AllImportFiles/ImportSvg"; // Importing CloseBtn SVG
import { DownloadContext } from "../../../../context/DownloadContextProvider";
import { WashSubscriptionContext } from "../../../../context/WashSubscriptionContextProvider";

function RegistrationForm() {
  const [userNumber, setUserNumber] = useState({
    mobile: "",
  });

  // Accessing context values from DownloadContextProvider and WashSubscriptionContextProvider
  const { showPopUp, handleClosePopUpOTP } = useContext(DownloadContext);
  const { popupClose } = useContext(WashSubscriptionContext);

  const [errMsg, setErrMsg] = useState("");
  const dispatch = useDispatch();

  // Retrieving data from Redux store using useSelector
  const activeOtp = useSelector((state) => state.otp.mobile);
  const showModal = useSelector((state) => state.otp.viewModal);

  const handleOtpNumber = (event) => {
    const { name, value } = event.target;
    setUserNumber({ ...userNumber, [name]: value });
  };

  const [change, setChange] = useState(false);

  const changeFunction = () => {
    setChange(false);
  };

  // Function to validate input to accept only numbers
  function validate(e) {
    let key;
    var theEvent = e || window.event;
    if (theEvent.type === "paste") {
      key = e.clipboardData.getData("text/plain");
    } else {
      key = theEvent.keyCode || theEvent.which;
      key = String.fromCharCode(key);
    }
    var regex = /[0-9]|\./;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  }

  const handleRegistration = (e) => {
    e.preventDefault();
    if (e.target.mobile.value === "") {
      setErrMsg("Please enter your mobile number");
      setTimeout(() => {
        setErrMsg("");
      }, 10000);
    } else if (e.target.mobile.value.length !== 10) {
      setErrMsg("Please enter your 10 Digit mobile number");
      setTimeout(() => {
        setErrMsg("");
      }, 10000);
    } else {
      setChange(true);
      dispatch(getOtp(userNumber)); // Dispatching action to get OTP using Redux
    }
  };

  // Handling body overflow based on showPopUp value
  React.useEffect(() => {
    if (showPopUp) {
      document.body.style.overflow = "hidden";
    }
    return () => {
      document.body.style.overflow = "scroll";
    };
  }, [showPopUp]);

  return (
    <Fragment>
      {change ? (
        activeOtp === "" ? (
          // OTP Popup when OTP is not yet received
          <div className="regi-popup">
            <div
              style={{ display: popupClose === true ? "none" : "" }}
              className="close"
            >
              {/* Close button for OTP Popup */}
              <CloseBtn className="closeBtn" onClick={handleClosePopUpOTP} />
            </div>
            <div className="content-phone">
              <div className="top-content">
                <center>
                  <img
                    src="https://ik.imagekit.io/driveu/Website_New/phoneotp_rmbOpU-BI.png"
                    alt="OtpImage"
                    className="otpimg"
                  />
                </center>
                <h2 id="phone-number">Please enter your phone number</h2>
              </div>
              <div className="enterNumber">
                {/* Form for entering mobile number */}
                <form
                  className="numberBlock"
                  onSubmit={handleRegistration}
                  autoFocus
                >
                  <input
                    type="text"
                    maxLength={10}
                    name="mobile"
                    value={userNumber.mobile}
                    onChange={handleOtpNumber}
                    pattern="^[0-9]+$"
                    className="phone-number"
                    placeholder="Enter your phone number"
                    title="Please write number only"
                    autoComplete="off"
                    inputMode="numeric"
                    onKeyPress={validate}
                    autoFocus
                  />
                  <button className="contBtn" type="submit">
                    Continue
                  </button>
                </form>
                {/* Error message */}
                <div className="errMsg_mobile">{errMsg}</div>
              </div>
            </div>
          </div>
        ) : showModal === "email" ? (
          // Popup for showing email details
          <PopUpDetails userNumber={userNumber} />
        ) : (
          // Popup for entering OTP
          <PopUpOtp changeFunction={changeFunction} userNumber={userNumber} />
        )
      ) : (
        // Popup for entering mobile number
        <div className="regi-popup">
          <div
            style={{ display: popupClose === true ? "none" : "" }}
            className="close"
          >
            {/* Close button for mobile number entry popup */}
            <CloseBtn className="closeBtn" onClick={handleClosePopUpOTP} />
          </div>
          <div className="content-phone">
            <div className="top-content">
              <center>
                <img
                  src="https://ik.imagekit.io/driveu/Website_New/phoneotp_rmbOpU-BI.png"
                  alt="OtpImage"
                  className="otpimg"
                />
              </center>
              <h2 id="phone-number">Please enter your phone number</h2>
            </div>
            <div className="enterNumber">
              {/* Form for entering mobile number */}
              <form className="numberBlock" onSubmit={handleRegistration}>
                <input
                  type="text"
                  maxLength={10}
                  name="mobile"
                  value={userNumber.mobile}
                  onChange={handleOtpNumber}
                  pattern="^[0-9]+$"
                  className="phone-number"
                  placeholder="Enter your phone number"
                  title="Please write number only"
                  autoComplete="off"
                  inputMode="numeric"
                  onKeyPress={validate}
                  autoFocus
                />
                <button className="contBtn" type="submit">
                  Continue
                </button>
              </form>
              {/* Error message */}
              <div className="errMsg_mobile">{errMsg}</div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
}

export default RegistrationForm;
