import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import DateStringComponent from "./DateStringComponent";
import { BookingFlowParaContext } from "../../context/BookingFlowParaProvider";
import { ApplicationContext } from "../../context/ApplicationContextProvider";
import { DownArrowSvg } from "../../Components/AllImportFiles/ImportSvg";

const CarWashSlots = ({ mapData }) => {
  const {
    carWashModifyData,
    setCarWashModifyData,
    carWashDate,
    carWashModifySlotTimeData,
    setCarWashDate,
  } = useContext(BookingFlowParaContext);
  const { washErrorMessage } = useContext(ApplicationContext);
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [toggleDateComponent, setToggleDateComponent] = useState(false);
  const [toggleTimeComponent, setToggleTimeComponent] = useState(false);

  const handleCarWashDateModify = (timeSlot) => (e) => {
    setCarWashDate({
      ...carWashDate,
      show_time: e.target.innerText,
      epoch_date: timeSlot.slot_start_time,
    });
    setToggleTimeComponent(false);
  };
  useEffect(() => {
    axios
      .get(
        `${BASE_URL}/wash-slots/?src=my_gate&latitude=${mapData.pickup_details.pickup_latitude}&longitude=${mapData.pickup_details.pickup_longitude}&is_water_less=${mapData.is_water_less}`
      )
      .then(({ data }) => {
        if (data.status === "success") {
          setCarWashModifyData(data.data);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);
  return (
    <>
      <div className="OneWayDateAndTime fontMedium">Date & Time</div>
      <div
        style={{
          color: "red",
          marginTop: "0.3rem",
          display: washErrorMessage ? "block" : "none",
        }}
      >
        Please Select Date and Time
      </div>
      <div className="DataAndTimeInput">
        <div className="Date">
          <div style={{ width: "70%" }}>
            <input
              placeholder="Select Date"
              className="dateInput"
              type="none"
              value={carWashDate.show_date}
              readOnly
              onClick={() => {
                setToggleDateComponent((p) => !p);
                setToggleTimeComponent(false);
              }}
            />
          </div>
          <div className="dateDropDown">
            {/* <img
              height="5"
              width="10px"
              src="https://ik.imagekit.io/bksgfrlfg/DropDown-Icon_MPnhInvgo.png"
              alt="arrow"
            /> */}
            <DownArrowSvg/>
          </div>
        </div>

        <div className="Date">
          <div style={{ width: "70%" }}>
            <input
              placeholder="Select Time"
              className="dateInput"
              type="none"
              value={carWashDate.show_time}
              readOnly
              onClick={() => {
                setToggleTimeComponent((p) => !p);
                setToggleDateComponent(false);
              }}
            />
          </div>
          <div className="dateDropDown">
            {/* <img
              height="5"
              width="10px"
              src="https://ik.imagekit.io/bksgfrlfg/DropDown-Icon_MPnhInvgo.png"
              alt="arrow"
            /> */}
            <DownArrowSvg/>

          </div>
        </div>
      </div>

      <div style={{ marginTop: "0.5rem" }} className="dateDivReschedule">
        {toggleDateComponent && (
          <>
            {carWashModifyData.length === 0
              ? null
              : carWashModifyData.map((elem, index) => (
                  <DateStringComponent
                    key={index}
                    dateData={elem}
                    setToggleDateComponent={setToggleDateComponent}
                  />
                ))}
          </>
        )}

        {toggleTimeComponent && (
          <div className="carWashTimeWrapper">
            {carWashModifySlotTimeData.length === 0
              ? "Please Select Date First"
              : carWashModifySlotTimeData.slots.map((elem, index) => {
                  return (
                    <div
                      className="carWashTimeModify"
                      onClick={handleCarWashDateModify(elem)}
                      key={index}
                    >
                      {elem.slot}
                    </div>
                  );
                })}
          </div>
        )}
      </div>
    </>
  );
};

export default CarWashSlots;
