import React from "react";
import { Link } from "react-router-dom";
import {
  DriverPartner,
  HireDriverB2B,
} from "../../../Components/AllImportFiles/ImportSvg";
import "./BusinessDD.css";
function BusinessDD() {
  return (
    <div className="business-wrapper">
      <div className="business-content-box">
        <Link to="/driver-partners" className="main-business">
          <div className="business-svg">
            <DriverPartner className="svg-option" />
          </div>
          <div className="business-dd-content">
            <section className="section-business">
              Join as Driver Partner
            </section>
            <article className="article-business">
              Earn with DriveU with zero investments
            </article>
          </div>
        </Link>
        <a
          href="https://www.driveu.in/business/"
          className="main-business"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="business-svg">
            <HireDriverB2B className="svg-option" />
          </div>
          <div className="business-dd-content">
            <section className="section-business">Hire Drivers for B2B</section>
            <article className="article-business">
              Need help with vehicle movements for your biz?
            </article>
          </div>
        </a>
      </div>
    </div>
  );
}

export default BusinessDD;
