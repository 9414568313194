// React Imports
import React, {
  Fragment,
  useEffect,
  useState,
  useRef,
  useContext,
} from "react";

// BaseUI Imports
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
} from "baseui/modal";
import { ALIGN, Radio, RadioGroup } from "baseui/radio";

// Icon Imports
import { IoLocationSharp } from "react-icons/io5";
import { MdCall } from "react-icons/md";
import { AiFillStar } from "react-icons/ai";
import { BsShieldFillCheck, BsCheckCircleFill } from "react-icons/bs";

// Utility and Third-party Library Imports
import { toast } from "react-hot-toast";
import axios from "axios";
import lottie from "lottie-web";
import mapboxgl from "mapbox-gl";
import MapboxDirections from "@mapbox/mapbox-gl-directions/dist/mapbox-gl-directions";

// Context Imports
import { ApplicationContext } from "../../context/ApplicationContextProvider";
import { BookingFlowDataContext } from "../../context/BookingFlowDataContextProvider";
import { BookingFlowParaContext } from "../../context/BookingFlowParaProvider";
import { InsuranceClaimContext } from "../../context/InsuranceClaimContextProvider";

// Component and Asset Imports
import CarWashAppLoader from "../../Uis/CarWashAppLoader/CarWashAppLoader";
import LoadingSpinner from "../../Uis/Spinner/Spinner";
import LottieLoaderanim from "../../Uis/LottieLoader/LottieLoaderanim";
import MyGateDriverDetails from "../../OrderPage/DriverDetails/MyGateDriverDetails";
import MyGatePaymentDetails from "../../OrderPage/PaymentDetails/MyGatePaymentDetails";
import { DriverMsg } from "../../Components/AllImportFiles/ImportSvg";
import { Cash } from "../../Components/AllImportFiles/ImportPng";
import PendingPickNowLottie from "../../Assets/lottie/PendingPickNowLottie.json";
import ConfirmDriverLottie from "../../Assets/lottie/ConfirmDriverLottie.json";
import DoneTripLottie from "../../Assets/lottie/DoneTripLottie.json";

function DuBookingTrack() {
  /*
   errMsgStyle,RadioOption,radioOverrides,lottieStyle => This all are for styling purpose
   */
  const errMsgStyle = {
    fontFamily: "Circular-Std-Book",
    height: "26px",
    marginTop: "0.2rem",
    marginBottom: "0.2rem",
    color: "red",
  };
  const RadioOption = {
    fontSize: "15px",
  };
  const radioOverrides = {
    RadioMarkOuter: {
      style: ({ $theme }) => ({
        backgroundColor: "#127e40",
      }),
    },
    Root: {
      style: ({ $theme }) => ({
        display: "flex",
        alignItems: "center",
        width: "250px",
      }),
    },
  };
  const lottieStyle = {
    height: "100%",
    width: "100%",
  };

  // Env variables
  const BASE_URL_HULK = process.env.REACT_APP_HULK_URL;
  mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

  // State Variables
  const [mapData, setMapData] = useState([]);
  const [isCancelModal, setIsCancelModal] = React.useState(false);
  const [isCancelModalChat, setIsCancelModalChat] = React.useState(false);
  const [showErrMsgCancel, setShowErrMsgCancel] = React.useState("");
  const [showCancelLoader, setShowCancelLoader] = React.useState(false);
  const [openFreshChat, setOpenFreshChat] = useState(false);
  const [chatNowLoader, setChatNowLoader] = useState(false);
  const [value, setValue] = React.useState("");

  // Ref
  const pendingLoader = useRef(null);
  const confirmLoader = useRef(null);
  const tripEndLoader = useRef(null);
  const mapRef = useRef(null);

  // Context
  const { bookingId } = useContext(InsuranceClaimContext);
  const {
    showMyGateDriverDetailsPopUp,
    setShowMyGateDriverDetailsPopUp,
    appUserID,
  } = useContext(ApplicationContext);
  const { sendingData } = useContext(BookingFlowDataContext);
  const { setShowPaymentDetails, showPaymentDetails } = useContext(
    BookingFlowParaContext
  );

  // mapIconStatus for icon in live map
  const mapIconStatus = {
    status: "",
  };

  // use for map
  function rad2degr(rad) {
    return (rad * 180) / Math.PI;
  }
  function degr2rad(degr) {
    return (degr * Math.PI) / 180;
  }

  /*
  mapData => useEffect with mapData dependency use for Lottie Animation according to status
  bookingId => useEffect with bookingID dependency use for Showing Live Map with pickup/drop and driver location
  */
  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: pendingLoader.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: PendingPickNowLottie,
    });
    return () => anim.destroy();
  }, [mapData]);
  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: confirmLoader.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: ConfirmDriverLottie,
    });
    return () => anim.destroy();
  }, [mapData]);
  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: tripEndLoader.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: DoneTripLottie,
    });
    //setShowMyGateDriverDetailsPopUp(false);
    return () => anim.destroy();
  }, [mapData]);

  useEffect(() => {
    let interval;
    const handleFetchBookingDetails = () => {
      const origin = [];
      const destination = [];

      axios({
        method: "GET",
        baseURL: BASE_URL_HULK,
        url: `${BASE_URL_HULK}/app/booking-info-v2/?booking_id=${bookingId}&user_id=${appUserID}`,
        withCredentials: false,
        headers: {
          "Content-Type": `application/json`,
        },
      })
        .then((res) => {
          if (res.status === 200) {
            origin.push(res.data.driver_details.driver_longitude);
            origin.push(res.data.driver_details.driver_latitude);
            destination.push(res.data.pickup_details.pickup_longitude);
            destination.push(res.data.pickup_details.pickup_latitude);
            setMapData(res.data);
            if (res.data.pending_actions.payment_done === false) {
              setShowMyGateDriverDetailsPopUp(true);
            }
            mapIconStatus.status = res.data.booking_details.booking_status;

            const bookingStatus = [
              "pending",
              "confirmed",
              "driver_assigned",
              "driver_on_way",
              "running",
            ];

            if (
              bookingStatus.includes(res.data.booking_details.booking_status)
            ) {
              interval = setTimeout(handleFetchBookingDetails, 30000);
            }
            gettingCoordinate([origin, destination]);
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    };
    if (!bookingId === true && !appUserID === true) {
      console.log(null);
    } else {
      handleFetchBookingDetails();
    }
    return () => clearTimeout(interval);
  }, [bookingId]);


  function gettingCoordinate(latLngInDegr) {
    var LATIDX = 0;
    var LNGIDX = 1;
    var sumX = 0;
    var sumY = 0;
    var sumZ = 0;

    for (var i = 0; i < latLngInDegr.length; i++) {
      const lat = degr2rad(latLngInDegr[i][LATIDX]);
      const lng = degr2rad(latLngInDegr[i][LNGIDX]);
      // sum of cartesian coordinates
      sumX += Math.cos(lat) * Math.cos(lng);
      sumY += Math.cos(lat) * Math.sin(lng);
      sumZ += Math.sin(lat);
    }

    var avgX = sumX / latLngInDegr.length;
    var avgY = sumY / latLngInDegr.length;
    var avgZ = sumZ / latLngInDegr.length;

    // convert average x, y, z coordinate to latitude and longitude
    var lng = Math.atan2(avgY, avgX);
    var hyp = Math.sqrt(avgX * avgX + avgY * avgY);
    var lat = Math.atan2(avgZ, hyp);

    showMapBox([rad2degr(lat), rad2degr(lng)], latLngInDegr);
  }

  function showMapBox(centreCoordinate, latLngInDegr) {
    // const showMap = document.getElementById("showMap");
    // Define custom icons for customers and drivers
    const customerIconUrl =
      mapIconStatus.status === "running" ||
      sendingData.booking_type === "roundtrip"
        ? "https://ik.imagekit.io/9xamykm1snt/pickup_tI_c3hnzk.png?updatedAt=1680606485387"
        : "https://ik.imagekit.io/9xamykm1snt/drop_WoXvQmdnJ.png?updatedAt=1680606485282";

    const driverIconUrl =
      mapIconStatus.status === "running"
        ? "https://ik.imagekit.io/9xamykm1snt/car_R3pzmyBE41.png?updatedAt=1680606485280"
        : "https://ik.imagekit.io/9xamykm1snt/driver_TjlTRZc7t.png?updatedAt=1680606485283";

    // Create a GeoJSON object
    const geojson = {
      type: "FeatureCollection",
      features: [
        {
          type: "Feature",
          user_type: "customer",
          properties: {
            icon: customerIconUrl,
            iconSize: [20, 25],
          },
          geometry: {
            type: "Point",
            coordinates: latLngInDegr[1], // pg lng lat
          },
        },
        {
          type: "Feature",
          user_type: "driver",
          properties: {
            icon: driverIconUrl,
            iconSize: [25, 20],
          },
          geometry: {
            type: "Point",
            coordinates: latLngInDegr[0], // ofc lng lat
          },
        },
      ],
    };

    const bounds = new mapboxgl.LngLatBounds();
    geojson.features.forEach((marker) => {
      bounds.extend(marker.geometry.coordinates);
    });

    //  mapbox initiate

    let map = new mapboxgl.Map({
      container: mapRef.current,
      style: "mapbox://styles/mapbox/streets-v11",
      center: centreCoordinate,
      zoom: 8,
      attributionControl: false,
    });
    map.fitBounds(bounds, {
      padding: 50, // You can adjust this padding as needed
      maxZoom: 15, // Set a maximum zoom level if necessary
    });

    // mapBox marker
    for (const marker of geojson.features) {
      const el = document.createElement("div");
      const width = marker.properties.iconSize[0];
      const height = marker.properties.iconSize[1];
      el.style.width = `${width}px`;
      el.style.height = `${height}px`;
      el.className = "marker";
      el.style.backgroundSize = "100%";
      el.style.backgroundImage = `url("${marker.properties.icon}")`;
      el.style.borderRadius = "40%";
      el.style.objectFit = "contain";

      if (marker.user_type === "customer") {
        el.style.backgroundColor = "#8a8bc9";
        new mapboxgl.Marker(el)
          .setLngLat(marker.geometry.coordinates)
          .addTo(map);
      } else {
        el.style.backgroundColor = "#3bb3d1";
        new mapboxgl.Marker(el)
          .setLngLat(marker.geometry.coordinates)
          .addTo(map);
      }
    }

    // mapBox Direction

    const directions = new MapboxDirections({
      accessToken: mapboxgl.accessToken,
      unit: "metric",
      profile: "mapbox/driving",
      alternatives: false,
      controls: {
        instructions: false,
        inputs: false, // Disable origin and destination inputs
      },
      interactive: false,
      flyTo: false,
    });

    map.addControl(directions, "top-left");

    map.on("load", function() {
      directions.setOrigin(latLngInDegr[0]);
      directions.setDestination(latLngInDegr[1]);
      map.resize();
      map.addSource("route", {
        type: "geojson",
        data: {
          type: "Feature",
          properties: {},
          geometry: {
            type: "LineString",
            coordinates: [latLngInDegr[0], latLngInDegr[1]],
          },
        },
      });
    });

    map.addLayer({
      id: "route",
      type: "line",
      source: "route",
      layout: {
        "line-join": "round",
        "line-cap": "round",
      },
      paint: {
        "line-color": "green",
        "line-width": 4,
      },
    });
  }

  const handleCancelBooking = () => {
    setIsCancelModal((s) => !s);
  };

  const handleCancelBookingWay = (e) => {
    mapData.cancellation_reason = e.currentTarget.value;
    setValue(e.currentTarget.value);
  };
  const handleCancelModal = () => {
    setIsCancelModal(false);
    setValue("");
  };
  const handleCancelApiCall = () => {
    if (value === "") {
      setShowErrMsgCancel("Please Select a Cancellation Reason");
      setTimeout(() => {
        setShowErrMsgCancel("");
      }, 3000);
    } else {
      setShowCancelLoader(true);
      axios({
        method: "POST",
        baseURL: BASE_URL_HULK,
        url: `${BASE_URL_HULK}/app/cancel_booking/?user_id=${appUserID}`,
        data: { booking_id: bookingId, cancellation_reason: value },
        withCredentials: false,
        headers: {
          "Content-Type": `multipart/form-data`,
        },
      })
        .then(({ data }) => {
          if (data.status === "success") {
            setShowCancelLoader(false);
            handleCancelModal();
            (function handleFetchBookingDetails() {
              axios({
                method: "GET",
                baseURL: BASE_URL_HULK,
                url: `${BASE_URL_HULK}/app/booking-info-v2/?booking_id=${bookingId}`,
                withCredentials: false,
                headers: {
                  "Content-Type": `application/json`,
                },
              })
                .then((res) => {
                  if (res.status === 200) {
                    setMapData(res.data);
                  }
                })
                .catch((err) => {
                  console.log(err.message);
                });
            })();
          } else if (data.status === "error") {
            toast.error(data.message);
          }
        })
        .catch((err) => {
          setShowCancelLoader(false);
          console.log(err.message);
        });
    }
  };

  const handleRedirectPackage = (data) => {
    window.location.href = data.track_url;
  };
  const handleFreshChat = () => {
    setChatNowLoader(true);
    setTimeout(() => {
      setChatNowLoader(false);
    }, 2000);
    setOpenFreshChat(true);
    setIsCancelModalChat(true);
  };

  const handleCloseChatModal = () => {
    setIsCancelModalChat(false);
    setOpenFreshChat(false);
  };

  React.useEffect(() => {
    if (showMyGateDriverDetailsPopUp) {
      document.body.style.overflow = "hidden";
    }
    return () => {
      document.body.style.overflow = "scroll";
    };
  }, [showMyGateDriverDetailsPopUp]);
  return (
    <React.Fragment>
      <React.Fragment>
        <div className="mapbox-map">
          <div className="mapContainer">
            {mapData.length === 0 ? (
              <img
                src="https://ik.imagekit.io/driveu/Map_Loader_0o_KoGrj9P.png?updatedAt=1679661660741"
                width="100%"
                height="100%"
                alt="Loader Map"
              />
            ) : mapData.booking_details.booking_status === "pending" &&
              mapData.is_picknow === true ? (
              <div id="loader_lottie" ref={pendingLoader} style={lottieStyle} />
            ) : mapData.booking_details.booking_status === "pending" &&
              mapData.is_picknow === false ? (
              <div id="loader_lottie" ref={confirmLoader} style={lottieStyle} />
            ) : mapData.booking_details.booking_status === "confirmed" ? (
              <div id="loader_lottie" ref={confirmLoader} style={lottieStyle} />
            ) : mapData.booking_details.booking_status === "done" ? (
              <div id="loader_lottie" ref={tripEndLoader} style={lottieStyle} />
            ) : mapData.booking_details.booking_status === "cancelled" ? (
              <h2>Your booking has been cancelled.</h2>
            ) : null}

            <div ref={mapRef} className="mapShower">
              {mapData.length === 0 ? null : mapData.booking_details
                  .booking_status === "running" ||
                mapData.booking_details.booking_status === "driver_assigned" ||
                mapData.booking_details.booking_status === "driver_on_way" ? (
                <div
                  style={{
                    height: "45vh",
                    width: "100vw",
                  }}
                />
              ) : null}
            </div>
          </div>
        </div>
        <>
          {mapData.length === 0 ? (
            <CarWashAppLoader />
          ) : (
            <div
              className="locationDetails"
              style={{
                backgroundColor:
                  mapData.booking_details.booking_status === "cancelled"
                    ? "white"
                    : "#127e40",
              }}
            >
              <>
                {mapData.booking_details.booking_status ===
                "cancelled" ? null : (
                  <div
                    className="bookDriverMsg"
                    style={{
                      backgroundColor: "#127e40",
                    }}
                  >
                    <div className="msgPopUpDiv">
                      <DriverMsg />
                    </div>
                    <span className="livePopUpMsg">
                      {mapData.booking_status_message}
                    </span>
                  </div>
                )}
              </>

              <div className="locationWrapper">
                <div className="bookDriverDetails">
                  <div
                    style={{
                      width: "98%",
                      margin: "auto",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <span>
                      {`Booking ID: ${mapData.booking_details.booking_id}`}
                    </span>
                    {!mapData.track_url === true ? null : (
                      <span
                        style={{ color: "#127e40" }}
                        onClick={() => handleRedirectPackage(mapData)}
                      >
                        Manage Package
                      </span>
                    )}
                  </div>
                  <>
                    {mapData.booking_details.booking_status === "done" ||
                    mapData.booking_details.booking_status ===
                      "driver_assigned" ||
                    mapData.booking_details.booking_status ===
                      "driver_on_way" ||
                    mapData.booking_details.booking_status === "running" ? (
                      <div className="DriverDetailsContainer">
                        <div className="DriverImageWrapper">
                          <div style={{ width: "40px", height: "40px" }}>
                            <img
                              src={mapData.driver_details.photo_url}
                              alt={mapData.driver_details.driver_name}
                              width="100%"
                              height="100%"
                              style={{ borderRadius: "50%" }}
                            />
                          </div>
                          <div
                            style={{
                              color: "#09081a",
                              fontSize: "14px",
                              fontWeight: 700,
                              marginBottom: "5px",
                              objectFit: "container",
                            }}
                          >
                            {mapData.driver_details.driver_name}
                          </div>
                        </div>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <span>Rating:</span>
                            &nbsp;
                            <>{mapData.driver_details.driver_rating}</>
                            <AiFillStar style={{ color: "#127e40" }} />
                          </div>
                          <button
                            // className="CallDriver"
                            className={
                              mapData.booking_details.booking_status ===
                                "done" && mapData.is_wash_booking === false
                                ? "CallDriver"
                                : "call_details"
                            }
                            style={{
                              backgroundColor: "#127e40",
                            }}
                          >
                            {mapData.booking_details.booking_status ===
                              "done" && mapData.is_wash_booking === false ? (
                              <div
                                onClick={() => {
                                  setShowMyGateDriverDetailsPopUp(true);
                                  setShowPaymentDetails(false);
                                }}
                                className="show_details"
                              >
                                Show Details
                              </div>
                            ) : (
                              <a
                                href={`tel:${mapData.driver_details.contact_number}`}
                                style={{
                                  textDecoration: "none",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  color: "#ffffff",
                                }}
                              >
                                <MdCall />
                              </a>
                            )}
                          </button>
                        </div>
                        <div className="DriverDetailsWrapper">
                          <div className="TripBtn">
                            <div
                              className="oneWayTrip"
                              // style={{ width: "45%", border: "2px solid red" }}
                            >
                              {mapData.booking_details.booking_type_display +
                                " - " +
                                mapData.booking_details.service_type_display}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : mapData.booking_details.booking_status ===
                      "cancelled" ? (
                      <div className="bookingFirstBlock">
                        <div className="bookScheduledMsg">
                          Booking Cancelled
                        </div>
                        {/* <div className="date-scheduled">
                      Booking ID: {mapData.booking_details.booking_id}
                    </div> */}
                        <div className="bookTypeApp">
                          <div className="oneWayTrip">
                            {mapData.booking_details.booking_type_display +
                              " - " +
                              mapData.booking_details.service_type_display}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="bookingFirstBlock">
                        <div className="bookScheduledMsg">
                          <span>Booking Scheduled</span>
                          <span className="tickMark">
                            <BsCheckCircleFill />
                          </span>
                        </div>
                        <div className="date-scheduled">
                          <span>{mapData.pickup_details.datetime_string}</span>
                        </div>
                        <div className="bookTypeApp">
                          <div className="oneWayTrip">
                            {mapData.booking_details.booking_type_display +
                              " - " +
                              mapData.booking_details.service_type_display}
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                  <div className="locationFirstBlock">
                    <div className="pickUpDropBlock">
                      {mapData.drop_details.drop_address !== "" ? (
                        <Fragment>
                          <div className="locationDot">
                            <IoLocationSharp
                              style={{
                                color: "green",
                                width: "24px",
                                height: "24px",
                              }}
                            />
                            <div className="location-dotted-line" />
                            <IoLocationSharp
                              style={{
                                color: "red",
                                width: "24px",
                                height: "24px",
                              }}
                            />
                          </div>
                          <div className="locationWrapperBlock">
                            <div className="pickupBlock">
                              <div className="pickup-Place">
                                Your Pickup Location
                              </div>
                              <div className="pickup-place-first">
                                {mapData.pickup_details.pickup_address}
                              </div>
                            </div>

                            <div className="pickupBlock">
                              <div className="drop-location">
                                Your Drop Location
                              </div>
                              <div className="drop-place">
                                {mapData.drop_details.drop_address}
                              </div>
                            </div>
                          </div>
                        </Fragment>
                      ) : mapData.drop_details.drop_address === "" ? (
                        <Fragment>
                          <div className="locationDot">
                            <IoLocationSharp style={{ color: "green" }} />
                          </div>
                          <div className="locationWrapperBlock">
                            <div className="pickupBlock">
                              <div className="pickup-Place">
                                Your Pickup & Drop Location
                              </div>
                              <div className="pickup-place-first">
                                {mapData.pickup_details.pickup_address}
                              </div>
                            </div>
                          </div>
                        </Fragment>
                      ) : mapData.booking_details.category === "" ? (
                        <Fragment>
                          <div className="locationDot">
                            <IoLocationSharp style={{ color: "green" }} />
                          </div>
                          <div className="locationWrapperBlock">
                            <div className="pickupBlock">
                              <div className="pickup-Place">Your Location</div>
                              <div className="pickup-place-first">
                                {mapData.pickup_details.pickup_address}
                              </div>
                            </div>
                          </div>
                        </Fragment>
                      ) : null}
                    </div>
                  </div>
                  <>
                    {mapData.is_insured === false ? null : (
                      <div className="InsuranceContainer">
                        <div className="InsuranceTitle">
                          <div
                            style={{
                              width: "20px",
                              height: "20px",
                            }}
                          >
                            <BsShieldFillCheck />
                          </div>
                          <div
                            style={{
                              fontSize: "16px",
                              color: "black",
                              fontWeight: "700",
                            }}
                          >
                            Opted for Secured Drive
                          </div>
                        </div>

                        <div className="InsuranceText">
                          <div style={{ width: "8%" }} />
                          <div
                            style={{
                              width: "92%",
                              fontSize: "13px",
                              color: "black",
                              opacity: "0.6",
                            }}
                          >
                            Trip policy is generated. The coverage will begin
                            when the trip starts.
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                  <>
                    {mapData.booking_details.booking_status === "cancelled" ||
                    !mapData.is_last_booking === true ? null : (
                      <>
                        <div className="InsuranceContainer">
                          <div className="InsuranceTitle">
                            <div
                              style={{
                                width: "20px",
                                height: "20px",
                              }}
                            >
                              <img
                                src={Cash}
                                alt="Cash"
                                width="100%"
                                height="100%"
                                style={{ objectFit: "contain" }}
                              />
                            </div>
                            <div
                              style={{
                                fontSize: "16px",
                                color: "black",
                                fontWeight: "700",
                              }}
                            >
                              Pay at the end of the your DU Daily Package
                            </div>
                          </div>

                          <div className="InsuranceText">
                            <div style={{ width: "8%" }} />
                            <div
                              style={{
                                width: "92%",
                                fontSize: "13px",
                                color: "black",
                                opacity: "0.6",
                              }}
                            >
                              Please pay your driver in UPI or Cash once your
                              last trip ends. The fare will be displayed on your
                              driver's device
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                  {/* <h4>HELP AND SUPPORT</h4> */}
                  <button
                    className="chatNow"
                    onClick={handleFreshChat}
                    style={{
                      color: "#000",
                    }}
                  >
                    Need help?
                  </button>
                  <>
                    {mapData.booking_details.booking_status === "cancelled" ||
                    mapData.booking_details.booking_status === "done" ||
                    mapData.booking_details.booking_status ===
                      "running" ? null : mapData.booking_details
                        .booking_status === "driver_on_way" ||
                      mapData.booking_details.booking_status ===
                        "driver_assigned" ||
                      mapData.booking_details.booking_status === "confirmed" ||
                      mapData.booking_details.booking_status === "pending" ? (
                      <div className="HelpModifyContainer">
                        <div className="ModifyButtonContainer">
                          <button
                            className="ModifyBtn"
                            style={{
                              backgroundColor: "#127e40",
                            }}
                            onClick={handleCancelBooking}
                          >
                            Cancel Booking
                          </button>

                          {/* Cancel Booking Modal */}
                          <Modal
                            onClose={handleCancelModal}
                            isOpen={isCancelModal}
                          >
                            <ModalHeader>Cancel Booking?</ModalHeader>
                            <ModalBody>
                              <div style={errMsgStyle}>{showErrMsgCancel}</div>
                              <div className="RadioGroupBlock">
                                {mapData.cancellation_reasons.map(
                                  (elem, index) => (
                                    <div key={index} style={{ width: "40%" }}>
                                      <RadioGroup
                                        value={value}
                                        onChange={handleCancelBookingWay}
                                        name="CancelOrder"
                                        align={ALIGN.vertical}
                                      >
                                        <Radio
                                          value={elem.slug}
                                          overrides={radioOverrides}
                                        >
                                          <div style={RadioOption}>
                                            {elem.title}
                                          </div>
                                        </Radio>
                                      </RadioGroup>
                                    </div>
                                  )
                                )}
                              </div>
                            </ModalBody>
                            <ModalFooter>
                              <ModalButton
                                kind="tertiary"
                                onClick={handleCancelModal}
                              >
                                Cancel
                              </ModalButton>
                              {showCancelLoader ? (
                                <ModalButton autoFocus>
                                  <LoadingSpinner />
                                </ModalButton>
                              ) : (
                                <ModalButton
                                  autoFocus
                                  onClick={handleCancelApiCall}
                                  style={{
                                    backgroundColor: "#127e40",
                                  }}
                                >
                                  Confirm
                                </ModalButton>
                              )}
                            </ModalFooter>
                          </Modal>
                        </div>
                      </div>
                    ) : null}
                  </>
                </div>
              </div>
            </div>
          )}
        </>
      </React.Fragment>

      {openFreshChat ? (
        <Modal
          onClose={handleCloseChatModal}
          isOpen={isCancelModalChat}
          overrides={{
            Dialog: {
              style: ({ $theme }) => ({
                outline: "none",
                height: "600px",
                width: "350px",
              }),
            },
          }}
        >
          <ModalHeader>DU Daily Help</ModalHeader>

          <ModalBody>
            {chatNowLoader === true ? (
              <LottieLoaderanim />
            ) : (
              <iframe
                src="/du-daily-help"
                title="DU Daily Help"
                width="100%"
                height="500px"
              ></iframe>
            )}
          </ModalBody>
        </Modal>
      ) : null}
      {mapData.length === 0 ? null : showMyGateDriverDetailsPopUp &&
        showMyGateDriverDetailsPopUp &&
        mapData.booking_details.booking_status === "done" ? (
        <MyGateDriverDetails mapData={mapData} />
      ) : null}
      {showPaymentDetails ? <MyGatePaymentDetails mapData={mapData} /> : null}
      {mapData.length === 0 ? null : showMyGateDriverDetailsPopUp &&
        showMyGateDriverDetailsPopUp &&
        mapData.booking_details.booking_status === "done" ? (
        <div className="overlay" />
      ) : null}
    </React.Fragment>
  );
}

export default DuBookingTrack;
