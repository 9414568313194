import React, { useContext, useState, useEffect } from "react";
import "../../OneWayBooking/OneWayInput/OneWayInput.css";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import useOnclickOutside from "react-cool-onclickoutside";
import axios from "axios";
import { BookingFlowDataContext } from "../../../context/BookingFlowDataContextProvider";
import { MdClose } from "react-icons/md";
import { BookingFlowParaContext } from "../../../context/BookingFlowParaProvider";
import { ApplicationContext } from "../../../context/ApplicationContextProvider";
import clevertap from "clevertap-web-sdk";
import {
  LocationClearedObj,
  LocationUpdateObj,
} from "../../../utils/EventObject";
function OutstationPickup({ handleRemoveSuccessCoupon }) {
  // all state define
  const [outStationPickupText, setOutStationPickupText] = useState(0);
  const [locationError, setLocationError] = useState(false);
  const [toggleEmptyInput, setToggleEmptyInput] = useState(false);
  const [outStationPickup, setOutStationPickup] = useState(false);
  const { lat, lng, couponLatLngObj } = useContext(BookingFlowParaContext);
  const { pubName, myGateToken } = useContext(ApplicationContext);
  const [formData, setFormData] = useState({});
  const {
    setFirstCityId,
    sendingOutstationData,
    secondCityId,
    toggleOutstationFare,
    cityEtaDetails,
    setCityEtaDetails,
    fareParams,
  } = useContext(BookingFlowDataContext);

  const BASE_URL = process.env.REACT_APP_BASE_URL;
  // all function and const here
  const handleChange = (e) => {
    const { name } = e.target;
    setFormData({
      ...formData,
      [name]: e.target.value,
    });
  };
  const oneWayHandleInput = (e) => {
    setValue(e.target.value);
    let a = e.target.value.length;
    setOutStationPickupText(a);
  };
  const {
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    debounce: 300,
  });

  const ref = useOnclickOutside(() => {
    clearSuggestions();
  });
  useEffect(() => {
    if (value.length === 0) {
      setToggleEmptyInput(false);
      toggleOutstationFare.outstationFirstToggle = false;
    } else {
      setToggleEmptyInput(true);
      toggleOutstationFare.outstationFirstToggle = true;
    }
  }, [value]);

  const oneWayHandleSelect = ({ description }) => () => {
    setValue(description, false);
    clearSuggestions();
    getGeocode({ address: description }).then((results) => {
      const { lat, lng } = getLatLng(results[0]);
      sendingOutstationData.pickup_address = description;
      sendingOutstationData.pickup_latitude = lat;
      sendingOutstationData.pickup_longitude = lng;

      fareParams.pickup_address = description;
      fareParams.pickup_latitude = lat;
      fareParams.pickup_longitude = lng;

      axios
        .get(`${BASE_URL}/web/nearest-driver/?latitude=${lat}&longitude=${lng}`)
        .then(({ data }) => {
          if (+data.city_id !== +couponLatLngObj.city_id) {
            handleRemoveSuccessCoupon();
          }
          LocationUpdateObj.location_type = "pickup";
          LocationUpdateObj.pickup_zone = data.zone_name;
          LocationUpdateObj.city = data.city;
          LocationUpdateObj.client = "mygate";
          clevertap.event.push("location_updated", LocationUpdateObj);
          if (!data.city_id === true) {
            sendingOutstationData.pickup_address = "";
            fareParams.pickup_address = "";
            setValue("");
            setLocationError(true);
            setTimeout(() => {
              setLocationError(false);
            }, [1000]);
          } else if (data.message === "") {
            setFirstCityId(data.city_id);
            setCityEtaDetails({ ...data });

            if (!secondCityId === true) {
              setLocationError(false);
            } else if (data.city_id === secondCityId) {
              setOutStationPickup(true);
              sendingOutstationData.pickup_address = "";
              fareParams.pickup_address = "";
              setValue("");
              setTimeout(() => {
                setOutStationPickup(false);
              }, 2000);
            }
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    });
  };
  const handleOutstationSuggestion = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <div key={place_id.toString()}>
          <ul
            className="suggestion"
            style={{ padding: "10px" }}
            key={place_id.toString()}
            onClick={oneWayHandleSelect(suggestion)}
          >
            <strong style={{ marginRight: "5px" }} id="bold">
              {main_text}
            </strong>{" "}
            <small id="book">{secondary_text}</small>
          </ul>
        </div>
      );
    });

  function getReverseGeocodingData(lat, lng) {
    sendingOutstationData.pickup_longitude = lng;
    sendingOutstationData.pickup_latitude = lat;

    fareParams.pickup_longitude = lng;
    fareParams.pickup_latitude = lat;
    var latlng = new window.google.maps.LatLng(lat, lng);
    // This is making the Geocode request
    var geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ latLng: latlng }, (results, status) => {
      if (status !== window.google.maps.GeocoderStatus.OK) {
        // alert(status);
      }
      // This is checking to see if the Geoeode Status is OK before proceeding
      if (status === window.google.maps.GeocoderStatus.OK) {
        var address = results[0].formatted_address;
        // setAddress(address); //inputbox
        // sendingOutstationData.pickup_address = address;
        if (pubName) {
          sendingOutstationData.pickup_address = pubName;
          fareParams.pickup_address = pubName;
          setValue(pubName);
        } else {
          sendingOutstationData.pickup_address = address;
          fareParams.pickup_address = address;
          setValue(address);
        }
      }
    });
  }

  const handleClearDropLocation = () => {
    setValue("");
    setFirstCityId("");
    sendingOutstationData.pickup_address = "";
    fareParams.pickup_address = "";
    toggleOutstationFare.outstationFirstToggle = false;
    fareParams.distance = 0;
    fareParams.duration = 0;
    sendingOutstationData.distance = 0;
    sendingOutstationData.duration = 0;
    LocationClearedObj.client = myGateToken ? "mygate" : "website";
    LocationClearedObj.location_type = "pickup";
    LocationClearedObj.pickup_zone = cityEtaDetails.zone_name;
    clevertap.event.push("location_cleared", LocationClearedObj);
  };
  useEffect(() => {
    if (lat === null || lng === null) {
    } else {
      axios
        .get(`${BASE_URL}/web/nearest-driver/?latitude=${lat}&longitude=${lng}`)
        .then(({ data }) => {
          if (data.message !== "") {
            sendingOutstationData.pickup_address = "";
            fareParams.pickup_address = "";
            setValue("");
            setLocationError(true);
            setTimeout(() => {
              setLocationError(false);
            }, [1000]);
          } else if (data.message === "") {
            setFirstCityId(data.city_id);
            getReverseGeocodingData(lat, lng);
            if (!secondCityId === true) {
              setLocationError(false);
            } else if (data.city_id === secondCityId) {
              setOutStationPickup(true);
              sendingOutstationData.pickup_address = "";
              fareParams.pickup_address = "";
              setValue("");
              setTimeout(() => {
                setOutStationPickup(false);
              }, 2000);
            }
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
  }, [lat, lng]);
  return (
    <>
      <div className="location" ref={ref} onChange={oneWayHandleInput}>
        <div className="first_Input_Tag_Div">
          <input
            style={{ border: locationError ? "" : "none" }}
            className="location first_Input_Tag"
            name="PickUpLocation"
            autoComplete="off"
            type="text"
            value={value}
            onChange={handleChange}
            placeholder="Enter 4 letters to Search PickUp Location"
          />
          <div
            style={{ display: toggleEmptyInput ? "" : "none" }}
            className="empty_input_div"
            onClick={handleClearDropLocation}
          >
            <MdClose />
          </div>
        </div>

        {outStationPickupText >= 4
          ? status === "OK" && (
              <div
                style={{ width: "92%" }}
                className="debounceBox"
                id="debounceBox"
              >
                {handleOutstationSuggestion()}
              </div>
            )
          : null}
      </div>
      <div
        style={{
          display: locationError ? "" : "none",
          color: "red",
          marginBottom: "0.5rem",
          fontfamily: "Circular-Std-Book",
        }}
      >
        Sorry, service not available
      </div>
      <div
        style={{
          display: outStationPickup ? "" : "none",
          color: "red",
          marginBottom: "0.5rem",
          fontSize: "14px",
          fontfamily: "Circular-Std-Book",
        }}
      >
        Pick up and drop address must be a different city.
      </div>
    </>
  );
}

export default OutstationPickup;
