import React, { createContext, useState } from "react";
export const BookingFlowParaContext = createContext();
function BookingFlowParaContextProvider({ children }) {
  const [showCarType, setShowCarType] = useState([
    { label: "Manual", id: "#F0F8FF" },
  ]);
  const [showCarModel, setShowCarModel] = useState([
    { label: "Hatchback", id: "#F0F8FF" },
  ]);
  const [tripType, setTripType] = useState([
    { label: "Round Trip", id: "#F0F8FF" },
  ]);
  const [estimatedUsageRoundTrip, setEstimatedUsageRoundTrip] = useState([
    { label: "12 Hrs", id: "#F0F8FF" },
  ]);
  const [estimatedUsageOneWayTrip, setEstimatedUsageOneWayTrip] = useState([
    { label: "4 Hrs", id: "#F0F8FF" },
  ]);
  const [
    estimatedUsageRoundTripString,
    setEstimatedUsageRoundTripString,
  ] = useState("");
  const [estimatedUsageOneWayString, setEstimatedUsageOneWayString] = useState(
    ""
  );
  const [showBookLoader, setShowBookLoader] = useState(false);
  const [oneWayCarTypeString, setOneWayCarTypeString] = useState("");
  const [roundWayCarTypeString, setRoundWayCarTypeString] = useState("");
  const [outstationBookingType, setOutstationBookingType] = useState("");
  const [dailyBookingType, setDailyBookingType] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [showUserId, setShowUserId] = useState("");
  const [userName, setUserName] = useState("");
  const [premium, setPremium] = useState("15");
  const [gst, setGst] = useState("2.70");
  const [premiumOutstation, setPremiumOutstation] = useState("");
  const [gstOutstation, setGstOutstation] = useState("");
  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);
  const [cityIds, setCityIds] = useState(null);
  const [showPaymentDetails, setShowPaymentDetails] = useState(false);
  const [promoBottomSheet, setPromoBottomSheet] = useState(false);
  const [promoPopUpSheet, setPromoPopUpSheet] = useState(false);
  const [couponLatLngObj, setCouponLatLngObj] = useState({
    lat: 12.9716,
    lng: 77.5946,
    city: "Bangalore",
    city_id: 1,
  });
  const [carWashModifyData, setCarWashModifyData] = useState([] || {});
  const [carWashModifySlotTimeData, setCarWashModifySlotTimeData] = useState(
    [] || {}
  );
  const [carWashDate, setCarWashDate] = useState({
    show_date: "",
    epoch_date: "",
    show_time: "",
  });
  const [dailyFareData, setDailyFareData] = useState([] || {});
  const handleGetValueToolTip = () => {
    let carType = showCarType[0].label + "-" + showCarModel[0].label;
    if (
      carType === "Manual-Hatchback" ||
      carType === "Manual-Sedan" ||
      carType === "Automatic-Hatchback" ||
      carType === "Automatic-Sedan"
    ) {
      setPremium("15");
      setGst("2.70");
    } else if (
      carType === "Manual-Luxury" ||
      carType === "Manual-SUV" ||
      carType === "Automatic-Luxury" ||
      carType === "Automatic-SUV"
    ) {
      setPremium("15");
      setGst("2.70");
    }
  };
  const handleGetValueToolTipOutstation = () => {
    let tripTypeText = tripType[0].label;
    if (tripTypeText === "outstation") {
      setPremiumOutstation("99");
      setGstOutstation("17.82");
    } else if (tripTypeText === "one_way_outstation") {
      setPremiumOutstation("49");
      setGstOutstation("8.82");
    }
  };

  return (
    <BookingFlowParaContext.Provider
      value={{
        premium,
        setPremium,
        gst,
        setGst,
        handleGetValueToolTip,
        oneWayCarTypeString,
        setOneWayCarTypeString,
        showCarType,
        setShowCarType,
        showCarModel,
        setShowCarModel,
        roundWayCarTypeString,
        setRoundWayCarTypeString,
        isAuthenticated,
        setIsAuthenticated,
        userName,
        setUserName,
        showBookLoader,
        setShowBookLoader,
        showUserId,
        setShowUserId,
        lat,
        setLat,
        lng,
        setLng,
        cityIds,
        setCityIds,
        showPaymentDetails,
        setShowPaymentDetails,
        tripType,
        setTripType,
        handleGetValueToolTipOutstation,
        outstationBookingType,
        setOutstationBookingType,
        estimatedUsageRoundTrip,
        setEstimatedUsageRoundTrip,
        estimatedUsageOneWayTrip,
        setEstimatedUsageOneWayTrip,
        estimatedUsageRoundTripString,
        setEstimatedUsageRoundTripString,
        estimatedUsageOneWayString,
        setEstimatedUsageOneWayString,
        premiumOutstation,
        setPremiumOutstation,
        gstOutstation,
        setGstOutstation,

        promoBottomSheet,
        setPromoBottomSheet,
        promoPopUpSheet,
        setPromoPopUpSheet,

        couponLatLngObj,
        setCouponLatLngObj,

        carWashModifyData,
        setCarWashModifyData,

        carWashDate,
        setCarWashDate,

        carWashModifySlotTimeData,
        setCarWashModifySlotTimeData,

        dailyBookingType,
        setDailyBookingType,

        dailyFareData,
        setDailyFareData,
      }}
    >
      {children}
    </BookingFlowParaContext.Provider>
  );
}

export default BookingFlowParaContextProvider;
