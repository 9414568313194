import React, { useContext, useEffect, useRef } from "react";
import { Modal, ModalHeader, ModalBody, SIZE, ROLE } from "baseui/modal";
import { TimePicker } from "baseui/timepicker";
import { ApplicationContext } from "../../../context/ApplicationContextProvider";
import { BookingFlowDataContext } from "../../../context/BookingFlowDataContextProvider";
import {
  continueScheduleCTA,
  dailyFinalReviewDailyCTA,
} from "../../../utils/EventObject";
// This use for Pickup Time, Always give default time in required format in pickupTime
const PickupTime = () => {
  const [dailyPickupTimeInfo, setDailyPickupTimeInfo] = React.useState(false);
  const {
    sendingDailyBookingData,
    pkgType,
    setTrackPickUpDateTimeArr,
  } = useContext(BookingFlowDataContext);

  const { setDailyPickupTime, dailyPickupTime } = useContext(
    ApplicationContext
  );
  const timeRef = useRef(null);

  const now = new Date();
  const defaultTime = new Date(now);
  defaultTime.setHours(7, 0, 0, 0); // Set default time to 8:00 AM

  const [pickupTime, setPickupTime] = React.useState(defaultTime);

  const minTime = new Date(now);
  minTime.setHours(7, 0, 0, 0); // Set minTime to 8:00 AM

  const maxTime = new Date(now);
  maxTime.setHours(20, 0, 0, 0); // Set maxTime to 8:00 PM

  useEffect(() => {
    const getHour = pickupTime.getHours();
    const getMins = pickupTime.getMinutes();
    let pickUpTime;

    if (!getMins === true && getHour <= 9) {
      pickUpTime = `0${getHour}:0${getMins}`;
      setDailyPickupTime(pickUpTime);
    } else if (!getMins === true) {
      pickUpTime = `${getHour}:0${getMins}`;
      setDailyPickupTime(pickUpTime);
    } else if (getHour <= 9) {
      pickUpTime = `0${getHour}:${getMins}`;
      setDailyPickupTime(pickUpTime);
    } else {
      pickUpTime = `${getHour}:${getMins}`;
      setDailyPickupTime(pickUpTime);
    }

    if (sendingDailyBookingData.pickup_datetime.length > 2 && pkgType) {
      setTrackPickUpDateTimeArr((prevSelected) => {
        let newSelected = [...prevSelected];
        const updatedTime = pickUpTime; // Extract the time part from pickupTime

        newSelected = newSelected.map((date) => {
          const dateParts = date.split(" ");
          dateParts[1] = updatedTime; // Replace the time part with updatedTime
          return dateParts.join(" ");
        });
        return newSelected;
      });
    }
  }, [pickupTime, dailyPickupTime]);

  useEffect(() => {
    const timeKeyboard =
      timeRef.current.children[0].children[0].children[0].children[1]
        .children[0];
    timeKeyboard.inputMode = "none";
  }, []);
  const pickupTimeStyleProps = {
    display: "flex",
    alignItems: "center",
    color: "#000",
    fontWeight: "600",
  };

  return (
    <div style={{ width: "90%", margin: "auto" }}>
      <>
        <div style={pickupTimeStyleProps}>
          Select pickup time<sup>*</sup> &nbsp;
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            onClick={() => setDailyPickupTimeInfo(true)}
          >
            <path
              d="M6.33203 3.66536H7.66537V4.9987H6.33203V3.66536ZM6.33203 6.33203H7.66537V10.332H6.33203V6.33203ZM6.9987 0.332031C3.3187 0.332031 0.332031 3.3187 0.332031 6.9987C0.332031 10.6787 3.3187 13.6654 6.9987 13.6654C10.6787 13.6654 13.6654 10.6787 13.6654 6.9987C13.6654 3.3187 10.6787 0.332031 6.9987 0.332031ZM6.9987 12.332C4.0587 12.332 1.66536 9.9387 1.66536 6.9987C1.66536 4.0587 4.0587 1.66536 6.9987 1.66536C9.9387 1.66536 12.332 4.0587 12.332 6.9987C12.332 9.9387 9.9387 12.332 6.9987 12.332Z"
              fill="#B5B5BA"
            />
          </svg>
          <Modal
            onClose={() => setDailyPickupTimeInfo(false)}
            closeable
            isOpen={dailyPickupTimeInfo}
            animate
            autoFocus
            size={SIZE.default}
            role={ROLE.dialog}
          >
            <ModalHeader>Pickup Time</ModalHeader>
            <ModalBody>
              The Driver Partner will reach the selected time and location
              everyday.
            </ModalBody>
          </Modal>
        </div>
        <div style={{ marginTop: "0.5rem" }} ref={timeRef}>
          <TimePicker
            value={pickupTime}
            onChange={(date) => {
              setPickupTime(date);
              continueScheduleCTA[
                "pickup time"
              ] = `${pickupTime.getHours()}:${pickupTime.getMinutes()}`;
              dailyFinalReviewDailyCTA[
                "pickup time"
              ] = `${pickupTime.getHours()}:${pickupTime.getMinutes()}`;
            }}
            minTime={minTime}
            maxTime={maxTime}
            format="12"
            step={900}
            overrides={{
              Select: {
                props: {
                  overrides: {
                    ControlContainer: {
                      style: ({ $theme }) => ({
                        backgroundColor: $theme.colors.menuFill,
                      }),
                    },
                  },
                },
              },
            }}
          />
        </div>
      </>
    </div>
  );
};

export default PickupTime;
