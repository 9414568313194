import React, { useContext } from "react";
import "./FareEstimate.css";
import { BookingFlowDataContext } from "../../../context/BookingFlowDataContextProvider";
import { Modal, ModalHeader, ModalBody, FocusOnce } from "baseui/modal";
import { StatefulPopover, PLACEMENT } from "baseui/popover";
function FairEstimate({ setIsFairEstimate, isFairEstimate }) {
  const { fareEstimateDetail, fareExtraInfo, cancelFarePopOver } = useContext(
    BookingFlowDataContext
  );
  return (
    <>
      <Modal onClose={() => setIsFairEstimate(false)} isOpen={isFairEstimate}>
        <FocusOnce>
          <ModalHeader>FARE ESTIMATE</ModalHeader>
        </FocusOnce>
        <ModalBody>
          <table className="fareTable">
            <tbody>
              {fareEstimateDetail.map((elem, idx) => (
                <tr className="fareTableRow" key={idx}>
                  <td className="fareTableCol" style={{ color: "#09081a" }}>
                    {elem.heading}
                  </td>
                  <td className="fareTableCol" style={{ color: "#09081a" }}>
                    {elem.component_value}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="fareExtraText">
            {fareExtraInfo.map(({ text }, id) => {
              return (
                <div key={id} className="fareText">
                  {text}
                </div>
              );
            })}
          </div>
          <StatefulPopover
            content={() => (
              <div className="farePopUpText">{cancelFarePopOver}</div>
            )}
            placement={PLACEMENT.top}
            returnFocus
            autoFocus
            overrides={{
              Inner: {
                style: ({ $theme }) => ({
                  outline: `none`,
                  backgroundColor: "#ffffff",
                }),
              },
            }}
          >
            <div className="cancelPolicyText">
              View Cancellation Policy <span>&#9432;</span>
            </div>
          </StatefulPopover>
        </ModalBody>
      </Modal>
    </>
  );
}

export default FairEstimate;
