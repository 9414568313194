import React, { Fragment, useContext } from "react";
import NavbarContainer from "../../parts/Navbar/NavbarContainer";
import "../../Components/Home/LottieContent/LottieContent.css";
import RegistrationForm from "../../Components/Home/PopUpWindow/RegistrationForm/RegistrationForm";
import { DownloadContext } from "../../context/DownloadContextProvider";
import BuyAndSell from "../../Components/Home/BuyAndSell/BuyAndSell";
import Footer from "../../parts/Footer/Footer";
import { HamburgerContext } from "../../context/HamburgerContextProvider";
import MobileAbout from "../../parts/DropDown/MobileAbout/MobileAbout";
import TermConditionNew from "./TermConditionNew/TermConditionNew";

function TermAndCondition() {
  const { showMediaIcons } = useContext(HamburgerContext);
  const { showPopUp } = useContext(DownloadContext);

  return (
    <div>
      <Fragment>
        <NavbarContainer />
        <div
          style={{ display: showMediaIcons ? "" : "none" }}
          className="MobileView_Option"
        >
          <MobileAbout />
        </div>
        {/* <TermCondition /> */}
        <TermConditionNew />
        <BuyAndSell />
        <Footer />
        {showPopUp ? <div className="overlay" /> : null}
        {showPopUp ? <RegistrationForm className="form_number" /> : null}
      </Fragment>
    </div>
  );
}

export default TermAndCondition;
