import React, { useContext } from "react";
import { BookingFlowParaContext } from "../../context/BookingFlowParaProvider";
import { ApplicationContext } from "../../context/ApplicationContextProvider";

const DateStringComponent = ({ dateData, setToggleDateComponent }) => {
  const {
    carWashDate,
    setCarWashDate,
    setCarWashModifySlotTimeData,
  } = useContext(BookingFlowParaContext);
  const { setWashErrorMessage } = useContext(ApplicationContext);
  // const convertEpochToDate = (epochDate) => {
  //   const dateObj = new Date(epochDate);
  //   const dateString = dateObj.toLocaleDateString();
  //   const day = dateObj.toLocaleString("default", { weekday: "short" });
  //   const month = dateObj.toLocaleString("default", { month: "short" });
  //   const date = dateString.split("/")[0]; // Extracting the date part
  //   return { dateString, day, month, date };
  // };
  const convertEpochToDate = (epochDate) => {
    const dateObj = new Date(epochDate);
    const options = { weekday: "short", month: "short", day: "numeric" };
    const dateString = dateObj.toLocaleDateString("en-US", options);
    // Split the date string by comma and space to separate the day from the rest
    const [dayPart, monthDatePart] = dateString.split(", ");
    // Extract the day (e.g., "Tue")
    const day = dayPart.trim();
    // Split the monthDatePart by space to separate the month and date
    const [month, date] = monthDatePart.split(" ");

    return { day, month, date: parseInt(date) };
    // const day = dateObj.toLocaleString("en-US", { weekday: "short" });
    // const month = dateObj.toLocaleString("en-US", { month: "short" });
    // const date = dateString.split("/")[0]; // Extracting the date part
    // return { dateString, day, month, date };
  };

  const handleDateString = (e) => {
    setCarWashDate({
      ...carWashDate,
      show_date: e.target.innerText,
    });
    setWashErrorMessage(false);
    setToggleDateComponent(false);
    setCarWashModifySlotTimeData(dateData);
  };

  return (
    <div className="dateOptionReschedule" onClick={handleDateString}>
      {`${convertEpochToDate(+dateData.epoch_date).month} \n ${
        convertEpochToDate(+dateData.epoch_date).date
      } \n ${convertEpochToDate(+dateData.epoch_date).day}`}
    </div>
  );
};

export default DateStringComponent;
