import { useContext, useState } from "react";
import { useCookies } from "react-cookie";
import { BookingFlowDataContext } from "../context/BookingFlowDataContextProvider";
import axios from "axios";
import { BookingFlowParaContext } from "../context/BookingFlowParaProvider";
import { ApplicationContext } from "../context/ApplicationContextProvider";

const useGetRoundTripData = () => {
  const BASE_URL_HULK = process.env.REACT_APP_HULK_URL;
  const [cookiesHireDrivers] = useCookies();
  const { setCouponLoading, sendingRoundWayData } = useContext(
    BookingFlowDataContext
  );
  const [roundWayCouponData, setRoundWayCouponData] = useState([] || {});
  const { couponLatLngObj } = useContext(BookingFlowParaContext);
  const { myGateToken } = useContext(ApplicationContext);

  const handleGetAllPromoCode = () => {
    if (!sendingRoundWayData) {
      return; // Return early if `sendingData` is not available
    }
    setCouponLoading(true);
    axios({
      method: "GET",
      baseURL: BASE_URL_HULK,
      // url:`https://apps.driveu.in/discount/promo-codes/?user_id=8b2cf815-acdd-468a-8355-02d5b80d4fa0&category=booking&latitude=12.981996&longitude=77.610084&booking_type=12hr&date_and_time=10/09/2022%2010:00`,
      url:
        !myGateToken === true
          ? `https://apps.driveu.in/discount/promo-codes/?user_id=${
              cookiesHireDrivers.userid
            }&category=booking&latitude=${sendingRoundWayData.pickup_latitude ||
              couponLatLngObj.lat}&longitude=${sendingRoundWayData.pickup_longitude ||
              couponLatLngObj.lng}&booking_type=${sendingRoundWayData.booking_type ||
              "2hr"}&date_and_time=${sendingRoundWayData.pickup_datetime}`
          : `${BASE_URL_HULK}/discount/promo-codes/?user_id=${
              cookiesHireDrivers.userid
            }&category=booking&latitude=${sendingRoundWayData.pickup_latitude ||
              couponLatLngObj.lat}&longitude=${sendingRoundWayData.pickup_longitude ||
              couponLatLngObj.lng}&booking_type=${
              sendingRoundWayData.booking_type
            }&date_and_time=${sendingRoundWayData.pickup_datetime}&src=my_gate`,
      withCredentials: false,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((data) => {
        if (data.data.status === "success") {
          setRoundWayCouponData(data.data.data);
          //   setCouponData(data.data.data);
          setCouponLoading(false);
          // setPromoBottomSheet(true);
        }
      })
      .catch((err) => {
        console.log(err.message);
        setCouponLoading(false);
      });
  };

  return { handleGetAllPromoCode, roundWayCouponData };
};
export default useGetRoundTripData;
