import React, { useContext } from "react";
import {
  BlackDot,
  CloseBtn,
  GreenDot,
} from "../../Components/AllImportFiles/ImportSvg";
import { BookingFlowParaContext } from "../../context/BookingFlowParaProvider";

import { RatingPage } from "../RatingPage/RatingPage";
import "./DriverDetails.css";
import { ApplicationContext } from "../../context/ApplicationContextProvider";
function MyGateDriverDetails({ mapData }) {
  const { setShowMyGateDriverDetailsPopUp } = useContext(ApplicationContext);
  const { setShowPaymentDetails } = useContext(BookingFlowParaContext);
  const Booking_Type =
    mapData.length === 0 ? null : mapData.booking_details.booking_type;
  const handleShowFareDetails = () => {
    setShowPaymentDetails(true);
    setShowMyGateDriverDetailsPopUp(false);
  };
  return (
    <>
      <div className="viewDetails">
        <CloseBtn
          className="closeDriverDetails"
          onClick={() => setShowMyGateDriverDetailsPopUp(false)}
        />
        {mapData.length === 0 ? null : (
          <>
            <div className="viewDetailsDiv">
              <div className="combineViewDiv">
                <div className="detailsImage">
                  <img src={mapData.driver_details.photo_url} alt="DriveU" />
                </div>
                <div className="detailsduration">
                  <div>{mapData.driver_details.driver_name}</div>

                  <div>
                    {Booking_Type.includes("hr") ? "Round Trip" : "One Way"}
                  </div>
                  <div className="driverRating">
                    <RatingPage
                      value={+mapData.booking_details.booking_rating}
                    />
                  </div>
                </div>
              </div>
              <div className="detailsButton">
                <div
                  style={{
                    backgroundColor: "rgb(65, 152, 102)",
                    fontSize: "14px",
                  }}
                >
                  {mapData.booking_details.booking_status_display}
                </div>
              </div>
            </div>
            <div className="detailsAddress">
              <div className="detailsDateAndDuration">
                <div>{mapData.trip_details.trip_started_at_title}</div>
                <div>Duration : {mapData.trip_details.usage_time}</div>
              </div>

              <div className="viewDetailsTitleAndAddress">
                <div className="LocationDotsPopUp">
                  <GreenDot className="LocationDotsSvgPopUp" />
                  <div className="LocationLinePopUp"></div>
                  <BlackDot className="LocationDotsSvgPopUp" />
                </div>
                <div style={{ marginLeft: "0.5rem" }}>
                  <div className="viewAddressHeading">
                    {mapData.trip_details.trip_started_at}
                  </div>
                  <div className="viewAddress">
                    {mapData.pickup_details.pickup_address}
                  </div>
                </div>
                <div style={{ marginLeft: "0.5rem" }}>
                  <div className="viewAddressHeading">
                    {mapData.trip_details.trip_ended_at}
                  </div>
                  <div
                    style={{
                      marginBottom: "0.3rem",
                    }}
                    className="viewAddress"
                  >
                    {mapData.drop_details.drop_address}
                  </div>
                </div>
              </div>
              <div className="fareANDpayment">
                <div className="viewDetailsFare">
                  <div style={{ fontWeight: "bold" }}>
                    {mapData.showCloseButton === false ? "Total" : "Paid"}
                    &nbsp;:- &nbsp; ₹{mapData.payable_fare}
                  </div>
                  <div
                    style={{
                      fontWeight: "bold",
                      color: "rgb(65, 152, 102)",
                      textDecoration: "underline",
                      marginRight: "1.5rem",
                      cursor: "pointer",
                    }}
                    onClick={handleShowFareDetails}
                  >
                    Fare Details
                  </div>
                </div>

                <button
                  style={{display : mapData.pending_actions.payment_done === false ? "block" : "none"}}
                  onClick={() => setShowMyGateDriverDetailsPopUp(false)}
                  className="onlinePaymentButton"
                >
                  Pay ₹{mapData.collect_fare} to Driver
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default MyGateDriverDetails;
