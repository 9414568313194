import React from "react";
import "./CarWashLoader.css";

export default function CarWashAppLoader() {
  return (
    <div className="car-wash-spinner-container">
      <div className="car-wash-payment-loading-spinner"></div>
    </div>
  );
}
