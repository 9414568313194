import React, { useContext } from "react";
import { Modal, ModalHeader, ModalBody } from "baseui/modal";
import { BookingFlowParaContext } from "../../context/BookingFlowParaProvider";
function MyGatePaymentDetails({ mapData }) {
  const { showPaymentDetails, setShowPaymentDetails } = useContext(
    BookingFlowParaContext
  );
  function close() {
    setShowPaymentDetails(false);
  }
  return (
    <React.Fragment>
      <Modal onClose={close} isOpen={showPaymentDetails}>
        <ModalHeader>Fare Details</ModalHeader>
        <ModalBody>
          <center>
            <table
              width="80%"
              style={{ border: "none", borderCollapse: "collapse" }}
            >
              <tbody>
                {mapData.items[0]
                  .map((el, idx) => {
                    return (
                      <tr key={idx}>
                        <td style={{ border: "none" }}>{el.heading}&nbsp;</td>
                        <td style={{ border: "none" }}>{el.component_value}</td>
                      </tr>
                    );
                  })}
                <tr style={{ border: "1px solid #e4e4e4" }}></tr>
                {mapData.items[1]
                  .map((el, idx) => {
                    if (el.heading === "DU Money") {
                      return (
                        <tr key={idx}>
                          <td style={{ border: "none" }}>{el.heading}&nbsp;</td>
                          <td style={{ border: "none" }}>
                            -{el.component_value}
                          </td>
                        </tr>
                      );
                    }
                    return (
                      <tr key={idx}>
                        <td style={{ border: "none" }}>{el.heading}&nbsp;</td>
                        <td style={{ border: "none" }}>{el.component_value}</td>
                      </tr>
                    );
                  })}
                <tr style={{ border: "1px solid #e7e7e7" }}></tr>
                {mapData.items[2].map((el, idx) => {
                  return (
                    <tr key={idx}>
                      <td
                        style={{
                          fontWeight: el.font_size,
                          border: "none",
                        }}
                      >
                        {el.heading}&nbsp;
                      </td>
                      <td
                        style={{
                          fontWeight: el.font_size,
                          border: "none",
                        }}
                      >
                        {el.component_value}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </center>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
}

export default MyGatePaymentDetails;
