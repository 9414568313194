import React from "react";

import "./CustomerStatics.css";
function CustomerStatics() {
  return (
    <>
      <div className="Static_main_Div">
        <div className="Static_first_Div">
          <div className="Static_first_Div_text">
            <div className="Static_text_first">5M+</div>
            <div className="cs-content">Comfortable Drives</div>
          </div>
          <div className="Static_first_Div_img">
            <div className="Static_first_Div_img_Div cs-image-div">
              <img
                src="https://ik.imagekit.io/driveu/Website_New/Comfortable_Drives__QFknZ20U.png"
                alt="Comfortable"
                className="cs-image"
              />
            </div>
          </div>
        </div>
        <div className="Static_sec_Div">
          <div className="Static_sec_Div_text">
            <div className="Static_text_sec">50K+</div>
            <div className="cs-content">Trusted Drivers</div>
          </div>
          <div className="Static_sec_Div_img">
            <div className="Static_sec_Div_img_Div cs-image-div">
              <img
                src="https://ik.imagekit.io/driveu/Website_New/Trusted_Drivers_pr7rrCyii.png"
                alt="trusted"
                className="cs-image"
              />
            </div>
          </div>
        </div>
        <div className="Static_thi_Div">
          <div className="Static_thi_Div_text">
            <div className="Static_text_thi">650K+</div>
            <div className="cs-content">Happy Customers</div>
          </div>
          <div className="Static_thi_Div_img">
            <div className="Static_thi_Div_img_Div cs-image-div">
              <img
                src="https://ik.imagekit.io/driveu/Website_New/Like_QF2-OpdJS.png"
                alt="like"
                className="cs-image"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CustomerStatics;
