import React, { useRef, Fragment } from "react";
import "./Bookingpage.css";
import { Tabs, Tab, FILL } from "baseui/tabs-motion";
import OneWayBooking from "../OneWayBooking/OneWayBooking";
import { useContext } from "react";
import { BookingFlowDataContext } from "../../context/BookingFlowDataContextProvider";
import { useEffect } from "react";
import RoundWayBooking from "../RoundWayBooking/RoundWayBooking";
import { ApplicationContext } from "../../context/ApplicationContextProvider";
import { BsFillArrowLeftCircleFill } from "react-icons/bs";
import OutstationBooking from "../OutstationBooking/OutstationBooking";
import { MyGateHelp } from "../../Components/AllImportFiles/ImportSvg";
import { FocusOnce, Modal, ModalBody, ModalHeader } from "baseui/modal";
import LottieLoaderanim from "../../Uis/LottieLoader/LottieLoaderanim";
import DriverFAQs from "../../MyGate/DriverFAQs.json";
import DriveuVideo from "./DriveUVideo/DriveUVideo";
import StaticFAQs from "./StaticFAQs";
import { WindmillsGIF } from "../../Components/AllImportFiles/ImportPng";

function BookingPage() {
  const zIndexRef = useRef(null);
  const {
    sendingData,
    setOneWayChecked,
    setTwoWayChecked,
    setOutstationCheckIns,
    activeKey,
    setActiveKey,
    setFirstCityId,
    setSecondCityId,
    fareParams,
    setFareChangeOutstation,
  } = useContext(BookingFlowDataContext);
  const { myGateToken, setConsentFlag,msilTokenData } = useContext(ApplicationContext);
  const [openFreshChat, setOpenFreshChat] = React.useState(false);
  const [isCancelModal, setIsCancelModal] = React.useState(false);
  const [chatNowLoader, setChatNowLoader] = React.useState(false);
  useEffect(() => {
    sendingData.booking_type = activeKey === "0" ? "one_way_trip" : "roundtrip";
    setOneWayChecked(true);
    setTwoWayChecked(true);
    setOutstationCheckIns(true);
    setFirstCityId(undefined);
    setSecondCityId("");
    setFareChangeOutstation("");
    fareParams.distance = 0;
    fareParams.duration = 0;
  }, [activeKey]);

  const backBtnStyle = {
    marginTop: "0.5rem",
    height: "30px",
    width: "30px",
    backgroundColor: "#FFFFFF",
    color: msilTokenData.token && msilTokenData.utm_source == "msil" ? "#2C3394" : myGateToken ? " #f93356" : "#127e40",
    marginLeft: "0.5rem",
    marginBottom: "0.5rem",
    cursor : "pointer"
  };
  const handleCloseMWeb = () => {
    setConsentFlag(false);
    window.open("mygate://close", "_self");
  };
  const handleFreshChat = () => {
    const MyGateTextDiv = zIndexRef.current;
    MyGateTextDiv.style.zIndex = 0;
    setChatNowLoader(true);
    setTimeout(() => {
      setChatNowLoader(false);
    }, 2000);
    setOpenFreshChat(true);
    setIsCancelModal(true);
  };
  const handleCloseChatModal = () => {
    const MyGateTextDiv = zIndexRef.current;
    MyGateTextDiv.style.zIndex = 1;
    setIsCancelModal(false);
    setOpenFreshChat(false);
  };

  const handleTabChange = (activeKey) => {
    setActiveKey(activeKey);
  };

  return (
    <>
      {!myGateToken === true ? (
        <div className="mainDiv">
          <div className="secondMainDiv">
            <div className={"smallDiv"}>
              <div className="tabDiv">
                <Tabs
                  activeKey={activeKey}
                  onChange={({ activeKey }) => handleTabChange(activeKey)}
                  fill={FILL.fixed}
                  activateOnFocus
                  overrides={{
                    TabHighlight: {
                      style: ({ $theme }) => ({
                        backgroundColor: "#127e40",
                        fontFamily: "Circular-Std-Medium",
                      }),
                    },
                  }}
                >
                  <Tab
                    style={{ color: +activeKey === 0 ? "#000" : "#9B9B9B" }}
                    title="One Way"
                  ></Tab>
                  <Tab
                    style={{ color: +activeKey === 1 ? "#000" : "#9B9B9B" }}
                    title="Round Trip"
                  ></Tab>
                  <Tab
                    style={{ color: +activeKey === 2 ? "#000" : "#9B9B9B" }}
                    title="Outstation"
                  ></Tab>
                </Tabs>
              </div>
              {window.location.pathname ===
                "/book-driver/windmills_craftworks" ||
              window.location.pathname ===
                "/book-driver/windmills_craftworks/" ? (
                <div className="imageVideoMainContainerWindmill">
                  <img
                    src={WindmillsGIF}
                    loading="lazy"
                    alt="Windmill"
                    width={"100%"}
                    height={"100%"}
                  />
                </div>
              ) : (
                <DriveuVideo />
              )}
              &nbsp;
              {activeKey === "0" ? (
                <OneWayBooking />
              ) : activeKey === "1" ? (
                <RoundWayBooking />
              ) : (
                <OutstationBooking />
              )}
            </div>
          </div>
        </div>
      ) : null}
      {myGateToken === "" ? null : myGateToken ? (
        <>
          <div className="MyGateTextHeader" ref={zIndexRef}>
            <BsFillArrowLeftCircleFill
              onClick={handleCloseMWeb}
              style={backBtnStyle}
            />
            <span
              style={{
                fontSize: "18px",
                fontFamily: "Circular-Std-Bold",
              }}
            >
              Hire Professional Drivers
            </span>
            <span style={{ marginRight: "10px" }} onClick={handleFreshChat}>
              <MyGateHelp style = {{fill : msilTokenData.token && msilTokenData.utm_source == "msil" ? "#2C3394" : myGateToken ? " #f93356" : "#127e40", cursor :"pointer"}} />
            </span>
            {openFreshChat ? (
              <Modal
                onClose={handleCloseChatModal}
                isOpen={isCancelModal}
                overrides={{
                  Dialog: {
                    style: ({ $theme }) => ({
                      height: "600px",
                      width: "350px",
                      outline: "none",
                    }),
                  },
                }}
              >
                <FocusOnce>
                  <ModalHeader>DriveU Help</ModalHeader>
                </FocusOnce>
                <ModalBody>
                  {chatNowLoader === true ? (
                    <LottieLoaderanim />
                  ) : (
                    <iframe
                      src="/mygate-driver-help"
                      title="DriveU Help"
                      width="100%"
                      height="500px"
                    />
                  )}
                </ModalBody>
              </Modal>
            ) : null}
          </div>
          &nbsp;
          <div className="mainDiv">
            <div className="secondMainDiv">
              <div className={myGateToken ? "smallMyGateDiv" : "smallDiv"}>
                <div className="tabDiv">
                  <Tabs
                    activeKey={activeKey}
                    onChange={({ activeKey }) => setActiveKey(activeKey)}
                    fill={FILL.fixed}
                    activateOnFocus
                    overrides={{
                      TabHighlight: {
                        style: ({ $theme }) => ({
                          // backgroundColor: "#f93356",
                          backgroundColor : msilTokenData.token && msilTokenData.utm_source == "msil" ? "#2C3394" : myGateToken ? " #f93356" : "#127e40",

                          fontFamily: "Circular-Std-Medium",
                        }),
                      },
                    }}
                  >
                    <Tab
                      style={{ color: +activeKey === 0 ? "#000" : "#9B9B9B" }}
                      title="One Way"
                    ></Tab>
                    <Tab
                      style={{ color: +activeKey === 1 ? "#000" : "#9B9B9B" }}
                      title="Round Trip"
                    ></Tab>
                    <Tab
                      style={{ color: +activeKey === 2 ? "#000" : "#9B9B9B" }}
                      title="Outstation"
                    ></Tab>
                  </Tabs>
                </div>
                {window.location.pathname ===
                  "/book-driver/windmills_craftworks" ||
                window.location.pathname ===
                  "/book-driver/windmills_craftworks/" ? (
                  <div className="imageVideoMainContainerWindmill">
                    <img
                      src="https://scontent.fblr4-1.fna.fbcdn.net/v/t39.30808-6/327442332_547405407353761_7688049899120641005_n.jpg?_nc_cat=110&ccb=1-7&_nc_sid=09cbfe&_nc_ohc=KVq40XCB3ZsAX9HDqR2&_nc_ht=scontent.fblr4-1.fna&oh=00_AfBoSJbNez2J6b_rWE31AToRaZyqAooTlGDWQJa4USAliw&oe=648303DE"
                      loading="lazy"
                      alt="Windmill"
                      width={"100%"}
                      height={"100%"}
                    />
                  </div>
                ) : (
                  <DriveuVideo />
                )}
                &nbsp;
                {activeKey === "0" ? (
                  <OneWayBooking />
                ) : activeKey === "1" ? (
                  <RoundWayBooking />
                ) : (
                  <OutstationBooking />
                )}
              </div>
            </div>
          </div>
          &nbsp;
          <div style={{ width: "90%", margin: "auto" }}>
            {Object.entries(DriverFAQs.FAQsData[0].staticfaqs).map(
              (elem, index) => {
                return (
                  <Fragment key={index}>
                    <h4>HELP AND SUPPORT</h4>
                    <div className="supportContainer">
                      {elem[1].map((quesData) => {
                        return (
                          <StaticFAQs
                            key={quesData.id}
                            id={quesData.id}
                            quesData={quesData}
                          />
                        );
                      })}
                    </div>
                  </Fragment>
                );
              }
            )}
          </div>
        </>
      ) : null}
    </>
  );
}

export default BookingPage;
