import React, { Fragment, useContext, useEffect } from "react";
import NavbarContainer from "../../parts/Navbar/NavbarContainer";
import "../../Components/Home/LottieContent/LottieContent.css";
import RegistrationForm from "../../Components/Home/PopUpWindow/RegistrationForm/RegistrationForm";
import { DownloadContext } from "../../context/DownloadContextProvider";
import BuyAndSell from "../../Components/Home/BuyAndSell/BuyAndSell";
import Footer from "../../parts/Footer/Footer";
import { HamburgerContext } from "../../context/HamburgerContextProvider";
import MobileAbout from "../../parts/DropDown/MobileAbout/MobileAbout";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { BookingFlowParaContext } from "../../context/BookingFlowParaProvider";
import GurgaonCityPage from "../../Components/Cities/Gurgaon/GurgaonCity";
import { seoJson } from "../../utils/SEOText";

const GurgaonAllCity = () => {
  const navigate = useNavigate();
  const { showMediaIcons, handleFalse } = useContext(HamburgerContext);
  const { showPopUp, setShowPopUp, handleScroll } = useContext(DownloadContext);
  const { isAuthenticated } = useContext(BookingFlowParaContext);
  const pathname = window.location.pathname;
  const handleBookDriver = () => {
    if (isAuthenticated === true) {
      navigate("/book-driver/");
    } else if (isAuthenticated === false) {
      setShowPopUp(true);
    }
  };
  useEffect(() => {
    if (showPopUp) {
      document.body.style.overflow = "hidden";
    }
    return () => {
      document.body.style.overflow = "scroll";
    };
  }, [showPopUp]);

  const hideHamburger = () => {
    handleFalse();
  };

  return (
    <div>
      {seoJson
        .filter((elem) => elem.path === pathname)
        .map((elem) => {
          return (
            <Helmet>
              <meta charSet="utf-8" />
              <title>{elem.content.title}</title>
              <meta name="description" content={elem.content.description} />
              <meta
                name="keywords"
                content="car drivers in Gurgaon , Gurgaon car driver services, car drivers in Gurgaon for hire, car driver services in Gurgaon , car drivers for hire in Gurgaon , car drivers for rent in Gurgaon , car driver service in Gurgaon , car driver for hire in Gurgaon , car driver agency Gurgaon , car drivers in Gurgaon for rent, driver agency in Gurgaon , car driver service Gurgaon , Gurgaon car drivers services, hire a car driver in Gurgaon , hire car drivers in Gurgaon , book car drivers in Gurgaon"
              />
            </Helmet>
          );
        })}

      <NavbarContainer />
      <div
        style={{ display: showMediaIcons ? "" : "none" }}
        className="MobileView_Option"
      >
        <MobileAbout />
      </div>
      <div onClick={hideHamburger}>
        <Fragment>
          <div className="container">
            <div className="wrapper-content-lottie">
              <div className="wrapper-content">
                {seoJson
                  .filter((elem) => elem.path === pathname)
                  .map((elem) => {
                    return (
                      <div className="content_hire">
                        <div className="content_subsection">
                          {elem.content.heading}
                        </div>
                        <div className="content_section_heading">
                          {elem.content.sub_heading}
                        </div>
                        <div className="content_subsection_heading">
                          Let us DriveU to your favourite people and places
                        </div>
                        <div className="btn_download_bookDriver">
                          <button
                            onClick={handleBookDriver}
                            className="book_driver"
                          >
                            Book Driver
                          </button>
                          <button
                            className="download_app"
                            onClick={handleScroll}
                          >
                            Download App
                          </button>
                        </div>
                      </div>
                    );
                  })}
              </div>
              <div className="wrapper-lottie">
                <div
                  className="content_lottie"
                  id="content_lottie"
                  style={{ width: "90%" }}
                >
                  <img
                    style={{ height: "100%", width: "100%" }}
                    src="https://ik.imagekit.io/driveu/Web/genericcitypage_e5HlCU-4R.png"
                    alt="DelhiNCRCity"
                  />
                </div>
                <div className="btn-app-download-mobile">
                  <button
                    onClick={handleBookDriver}
                    className="book_now_mobile"
                  >
                    Book Now
                  </button>
                  <button className="download_app_mobile">
                    <a
                      href={window.generatedLink}
                      style={{
                        textDecoration: "none",
                        color: "#09081a",
                      }}
                    >
                      Download App
                    </a>
                  </button>
                </div>
              </div>
            </div>
            <div>
              {showPopUp ? <div className="overlay" /> : null}
              {showPopUp ? <RegistrationForm className="form_number" /> : null}
            </div>
          </div>
        </Fragment>
        <GurgaonCityPage />
        <BuyAndSell />
        <Footer />
      </div>
    </div>
  );
};

export default GurgaonAllCity;
