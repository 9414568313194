import React, { useContext } from "react";
import "react-datepicker/dist/react-datepicker.css";
import packageStyle from "../../../DailyBooking.module.css";
import { BookingFlowDataContext } from "../../../../context/BookingFlowDataContextProvider";
import { ApplicationContext } from "../../../../context/ApplicationContextProvider";
import { MiniCheck } from "../../../../Components/AllImportFiles/ImportSvg";

const DailyDate = () => {
  const {
    setSendingDailyBookingData,
    trackPickUpDateTimeArr,
    setTrackPickUpDateTimeArr,
  } = useContext(BookingFlowDataContext);
  const {
    selectedDate,
    PackageDayDate,
    mappedDates,
    setMappedDates,
    dailyPickupTime,
  } = useContext(ApplicationContext);

  const handleDailyTripDate = (elem) => {
    const date = elem[2];

    if (mappedDates.has(date)) {
      // If the date is already selected, unselect it
      const newMappedDates = new Set(mappedDates);
      newMappedDates.delete(date);
      setMappedDates(newMappedDates);
    } else {
      // If the date is not selected, select it
      const newMappedDates = new Set(mappedDates);
      newMappedDates.add(date);
      setMappedDates(newMappedDates);
    }

    setTrackPickUpDateTimeArr((prevSelected) => {
      let date = elem[2] + " " + dailyPickupTime;
      let newSelected = [...prevSelected];

      if (newSelected.includes(date)) {
        // If the date is already in the array, remove it
        newSelected = newSelected.filter((d) => d !== date);
      } else {
        // If the date is not in the array, add it
        newSelected.push(date);
      }

      return newSelected;
    });

    setSendingDailyBookingData((prevSelected) => {
      const newSelected = { ...prevSelected };

      if (newSelected.pickup_datetime.includes(date)) {
        // If the date is already in the array, remove it
        newSelected.pickup_datetime = newSelected.pickup_datetime.filter(
          (d) => d !== date
        );
      } else {
        // If the date is not in the array, add it
        newSelected.pickup_datetime.push(date);
      }

      return newSelected;
    });
  };

  return (
    <div className={packageStyle.packageWrapperDate}>
      {selectedDate &&
        PackageDayDate.length !== 0 &&
        PackageDayDate.map((elem, index) => (
          <div
            className={packageStyle.packageBoxDate}
            style={{
              backgroundColor: trackPickUpDateTimeArr.includes(
                elem[2] + " " + dailyPickupTime
              )
                ? "#E0F3EC"
                : "#ffffff",
            }}
            onClick={() => handleDailyTripDate(elem)}
            key={index}
          >
            <div
              className={packageStyle.miniCheck}
              style={{
                display: trackPickUpDateTimeArr.includes(
                  elem[2] + " " + dailyPickupTime
                )
                  ? ""
                  : "none",
              }}
            >
              <MiniCheck />
            </div>
            <div className={packageStyle.dateBookBox}>
              <div>{elem[0]}</div>
              <div>{elem[1].slice(0, 6)}</div>
            </div>
          </div>
        ))}
    </div>
  );
};

export default DailyDate;
