//  this is same as slotform in car wash my gate ; see that one
import React, { Fragment, useContext, useRef, useState } from "react";
import "./SlotForm.css";
import LoadingSpinner from "../../../../Uis/Spinner/Spinner";
import { carServiceContext } from "../../../../context/CarServiceContextProvider";
import SlotAddress from "../SlotAddress/SlotAddress";

const monthday = [
  "JAN",
  "FEB",
  "MAR",
  "APR",
  "MAY",
  "JUN",
  "JUL",
  "AUG",
  "SEP",
  "OCT",
  "NOV",
  "DEC",
];
const weekDay = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"];

function SlotForm() {
  const inputRef = useRef(null);
  const [comment, setComment] = useState("");
  // const [carNumber, setCarNumber] = useState("");

  const {
    timeCompState,
    setTimeCompState,
    epochDateArr,
    timeSlots,
    setTimeSlots,
    selectedDate,
    setSelectedDate,
    carServiceData,
    setDisableButton,
    hideDateTime,

    //
    carNumber,
    setCarNumber,
    viewDetailPrice,
  } = useContext(carServiceContext);
  //

  let dateArr = [];

  for (let i = 0; i < epochDateArr.length; i++) {
    let convertInteger = parseInt(epochDateArr[i].epoch_date);
    let new_Date = new Date(convertInteger);
    let date = new_Date.getDate();
    let month = monthday[new_Date.getMonth()];
    let week = weekDay[new_Date.getDay()];
    dateArr.push({
      Date: date,
      Month: month,
      Week: week,
      TimeSlots: epochDateArr[i].slots,
      EpochDate: epochDateArr[i].epoch_date,
    });
  }

  const handleSlotTime = (slotTime, epoch_date) => (e) => {
    setDisableButton(true);
    setSelectedDate(epoch_date);
    setTimeCompState(-1);
    setTimeSlots(slotTime);
  };

  const handleTimeColor = (slot, elem) => () => {
    setTimeCompState(slot);
    carServiceData.slot_time = elem.slot_start_time;
    setDisableButton(false);
  };

  const handleCarNumber = (e) => {
    carServiceData.vehicle_number = e.target.value.toLowerCase().trim();
    setCarNumber(
      e.target.value
        .toUpperCase()
        .trim()
        .replace(/[^\w\s]/gi, "")
    );
    // setDisableButton(false);
  };

  const handleComment = (e) => {
    carServiceData.comments = e.target.value.replace(/[^\w\s]/gi, "");
    setComment(e.target.value.replace(/[^\w\s]/gi, ""));
  };

  const handleClick = () => {
    inputRef.current.inputMode = "text";
  };

  return (
    <Fragment>
      {/* <div className="max-w-6xl h-auto overflow-scrol"> */}
      <div className="myGateFormMainContainer">
        <div className="viewDetailsHeading">{viewDetailPrice.name}</div>
        <div className="viewDetailTime">
          Car Service • Garage • {viewDetailPrice.eta}
        </div>
        <div>
          <span className="viewDetailPrice">₹ {viewDetailPrice.price}</span>
          &nbsp; (Incl of all taxes)
        </div>

        {/* car number */}
        <div className="myGateFormAddMainContainer">
          <div className="myGateFormAddHeadingContainer">
            Car registration number
          </div>
          <input
            style={{ fontSize: "12px" }}
            type="text"
            ref={inputRef}
            className="carServiceCarNumberInput"
            value={carNumber}
            // onClick={handleClick}
            placeholder="Eg : KA01BD2525"
            onChange={handleCarNumber}
            minLength="9"
            maxLength="10"
            required
            autoFocus={false}
            autoComplete="off"
          />
        </div>

        {/* Address */}
        <div className="myGateFormAddMainContainer">
          <div className="myGateFormAddHeadingContainer">
            Location for pickup
          </div>
          <SlotAddress />
        </div>

        {/*  Date */}
        <div
          style={{ display: hideDateTime ? "none" : "" }}
          className="myGateFormAddMainContainer"
        >
          <div className="myGateFormAddHeadingContainer">
            Select Prefered Date
          </div>

          {dateArr.length === 0 ? (
            <LoadingSpinner />
          ) : (
            <div className="myGateFormDateMainContainer">
              {dateArr.map((elem) => {
                return (
                  <div
                    key={elem.EpochDate}
                    onClick={handleSlotTime(elem.TimeSlots, elem.EpochDate)}
                    className="myGateFormDateContainer"
                    style={{
                      border:
                        selectedDate === elem.EpochDate
                          ? "1px solid #f93356"
                          : "1px solid white",
                      backgroundColor: "white",
                    }}
                  >
                    <div style={{ fontSize: "10px" }}>{elem.Week}</div>
                    <div style={{ fontSize: "14px" }}>{elem.Date}</div>
                    <div style={{ fontSize: "8px" }}>{elem.Month}</div>
                  </div>
                );
              })}
            </div>
          )}
        </div>

        {/*  Time */}
        <div
          style={{ display: hideDateTime ? "none" : "" }}
          className="myGateFormAddMainContainer"
        >
          <div className="myGateFormAddHeadingContainer">
            Select Prefered Time Slot
          </div>

          {timeSlots.length === 0 ? (
            <LoadingSpinner />
          ) : (
            <div className="myGateFormTimeSubMainContainer">
              {timeSlots.map((elem) => {
                return (
                  <div
                    key={elem.slot_start_time}
                    className="SlotTimeMainContainer"
                  >
                    <div
                      onClick={handleTimeColor(elem.slot, elem)}
                      style={{
                        backgroundColor:
                          timeCompState === elem.slot
                            ? "#f93356"
                            : "rgba(9, 8, 26, 0.04)",
                        color:
                          timeCompState === elem.slot ? "white" : "#09081a",
                      }}
                      className="SlotTimeSubMainContainer"
                    >
                      {elem.slot}
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>

        {/* comments */}
        <div onClick={handleClick} className="myGateFormAddMainContainer">
          <div className="myGateFormAddHeadingContainer">
            Any Specific Complaints
          </div>
          <textarea
            style={{ fontSize: "12px" }}
            name=""
            ref={inputRef}
            type="text"
            cols="40"
            rows="3"
            className="carServiceCommentBox carServiceCarNumberInput"
            value={comment}
            autoFocus={false}
            autoComplete="off"
            onChange={handleComment}
            inputMode="none"
          ></textarea>
        </div>
      </div>
      {/* </div> */}
    </Fragment>
  );
}

export default SlotForm;
