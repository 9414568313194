import React, { useContext, useEffect, useRef } from "react";
import "./StateDrawer.css";
import { Drawer, ANCHOR } from "baseui/drawer";
import { ApplicationContext } from "../../../context/ApplicationContextProvider";
import { IoSearchOutline } from "react-icons/io5";
function StateDrawer() {
  const {
    isFuelPrice,
    closeFuelPrice,
    fuelData,
    FuelPriceAPi,
    stateFuel,
    stateValue,
    setStateValue,
  } = useContext(ApplicationContext);

  const searchFocus = useRef();

  const handleCity = (elem) => () => {
    stateFuel.state = elem.value;
    setStateValue(elem.title);
    FuelPriceAPi(stateFuel);
  };

  const handleStateArr = (e) => {
    searchFocus.current.focus();
    setStateValue(e.target.value);
  };

  useEffect(() => {
    if (isFuelPrice) {
      document.body.style.overflow = "hidden";
    }
    return () => {
      document.body.style.overflow = "scroll";
    };
  }, [isFuelPrice]);

  return (
    <Drawer
      isOpen={isFuelPrice}
      autoFocus
      onClose={closeFuelPrice}
      anchor={ANCHOR.bottom}
      // size={SIZE.auto}
      overrides={{
        DrawerBody: {
          style: ({ $theme }) => ({
            // marginLeft: "none",
            marginRight: "none",
            width: "95%",
            marginLeft: "0.5rem",
          }),
        },
      }}
    >
      <div className="stateMainContainerHeading">Select state</div>
      <div className="stateInputMainContainer">
        <IoSearchOutline className="stateInputSearch" />
        <input
          style={{ border: "none", backgroundColor: "white" }}
          type="text"
          ref={searchFocus}
          onChange={handleStateArr}
          value={stateValue}
          placeholder="Search state"
          autoComplete="off"
          autoFocus
        />
      </div>

      <div className="stateOptionMainContainer">
        {fuelData.state_and_cities
          .filter((elem, index) => {
            if (stateValue === "") {
              return elem.title;
            } else if (
              elem.title.toLowerCase().includes(stateValue.toLowerCase())
            ) {
              return elem.title;
            } else {
              return false;
            }
          })
          .map((elem) => (
            <div
              onClick={handleCity(elem)}
              className="stateOptionContainer"
              key={elem.title}
            >
              {elem.title}
            </div>
          ))}
      </div>
    </Drawer>
  );
}

export default StateDrawer;
