import React, { useState, useEffect, useContext } from "react";
import "./OneWaySecondInput.css";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import useOnclickOutside from "react-cool-onclickoutside";
import axios from "axios";
import { MdClose } from "react-icons/md";
import { BookingFlowDataContext } from "../../../context/BookingFlowDataContextProvider";
import clevertap from "clevertap-web-sdk";
import { ApplicationContext } from "../../../context/ApplicationContextProvider";
import {
  LocationClearedObj,
  LocationUpdateObj,
} from "../../../utils/EventObject";
function OneWaySecondInput() {
  const [roundtext, setRoundText] = useState(0);
  const [formData, setFormData] = useState({});
  const [showError, setShowError] = useState(false);
  const [toggleEmptyInput, setToggleEmptyInput] = useState(false);
  const { myGateToken } = useContext(ApplicationContext);
  const {
    firstCityId,
    misMatchCityId,
    setMisMatchCityId,
    setSecondCityId,
    sendingData,
    toggleFareEstimate,
    dropCityEtaDetails,
    setDropCityEtaDetails,
    fareParams,
  } = useContext(BookingFlowDataContext);
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const handleChange = (e) => {
    const { name } = e.target;
    setFormData({
      ...formData,
      [name]: e.target.value,
    });
  };
  const {
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    debounce: 300,
  });
  const roundHandleInput = (e) => {
    setValue(e.target.value);
    let a = e.target.value.length;
    setRoundText(a);
  };
  useEffect(() => {
    if (value.length === 0) {
      setToggleEmptyInput(false);
      setSecondCityId(undefined);
      toggleFareEstimate.oneWaySecondToggle = false;
    } else {
      setToggleEmptyInput(true);
      toggleFareEstimate.oneWaySecondToggle = true;
    }
  }, [value]);
  const roundHandleSelect = ({ description }) => () => {
    setValue(description, false);
    clearSuggestions();
    getGeocode({ address: description }).then((results) => {
      const { lat, lng } = getLatLng(results[0]);
      sendingData.drop_address = description;
      sendingData.drop_latitude = lat;
      sendingData.drop_longitude = lng;

      fareParams.drop_address = description;
      fareParams.drop_latitude = lat;
      fareParams.drop_longitude = lng;

      axios
        .get(`${BASE_URL}/web/nearest-driver/?latitude=${lat}&longitude=${lng}`)
        .then(({ data }) => {
          LocationUpdateObj.location_type = "drop";
          LocationUpdateObj.pickup_zone = data.zone_name || "N/A";
          LocationUpdateObj.city = data.city || "N/A";
          LocationUpdateObj.client = myGateToken ? "mygate" : "website";
          clevertap.event.push("location_updated", LocationUpdateObj);
          if (data.message !== "") {
            setValue("");
            setShowError(true);
            setTimeout(() => {
              setShowError(false);
            }, 1000);
          } else if (data.message === "") {
            setSecondCityId(data.city_id);
            setDropCityEtaDetails({ ...data });
            if (!firstCityId === true) {
              setMisMatchCityId(false);
            } else if (data.city_id !== firstCityId) {
              setMisMatchCityId(true);
              setValue("");
              setTimeout(() => {
                setMisMatchCityId(false);
              }, 2000);
            }
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    });
  };

  const roundRenderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <div key={place_id.toString()}>
          <ul
            className="suggestion"
            style={{ padding: "10px" }}
            key={place_id.toString()}
            onClick={roundHandleSelect(suggestion)}
          >
            <strong style={{ marginRight: "5px" }} id="bold">
              {main_text}
            </strong>
            <small id="book">{secondary_text}</small>
          </ul>
        </div>
      );
    });
  const ref = useOnclickOutside(() => {
    clearSuggestions();
  });
  return (
    <>
      <div
        className="location secondInput"
        ref={ref}
        onChange={roundHandleInput}
      >
        <div className="second_Input_Tag_Div">
          <input
            style={{ border: showError ? "" : "none" }}
            className="location second_Input_Tag"
            name="DropOffLocation"
            autoComplete="off"
            type="text"
            value={value}
            onChange={handleChange}
            placeholder="Enter 4 letters to Search Drop Location"
          />
          <div
            style={{ display: toggleEmptyInput ? "" : "none" }}
            className="empty_input_div"
            onClick={() => {
              setValue("");
              setSecondCityId("");
              LocationClearedObj.client = myGateToken ? "mygate" : "website";
              LocationClearedObj.location_type = "drop";
              LocationClearedObj.pickup_zone = dropCityEtaDetails.zone_name;
              LocationClearedObj.city = dropCityEtaDetails.city;
              clevertap.event.push("location_cleared", LocationClearedObj);
            }}
          >
            <MdClose />
          </div>
        </div>

        {roundtext >= 4
          ? status === "OK" && (
              <div
                style={{ width: "92%" }}
                className="debounceBox"
                id="debounceBox"
              >
                {roundRenderSuggestions()}
              </div>
            )
          : null}
      </div>
      <div
        style={{
          display: misMatchCityId ? "" : "none",
          color: "red",
          marginTop: "-0.5rem",
          marginBottom: "0.5rem",
          fontSize: "14px",
          fontfamily: "Circular-Std-Book",
        }}
      >
        Pick up and drop address must be in the same city.
      </div>
      <div
        style={{
          display: showError ? "" : "none",
          color: "red",
          marginTop: "-0.5rem",
          marginBottom: "0.5rem",
          fontfamily: "Circular-Std-Book",
        }}
      >
        Sorry, service not available
      </div>
    </>
  );
}

export default OneWaySecondInput;
