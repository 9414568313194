import React, { useContext } from "react";
import { Checkbox, LABEL_PLACEMENT } from "baseui/checkbox";
import pickupStyle from "../../DailyBooking.module.css";
import {
  Modal,
  ModalBody,
  ModalButton,
  ModalFooter,
  ModalHeader,
  ROLE,
  SIZE,
} from "baseui/modal";
import { MdArrowDropDown } from "react-icons/md";
import { BookingFlowDataContext } from "../../../context/BookingFlowDataContextProvider";
const LanguageCheck = () => {
  const [openLanguageBox, setOpenLanguageBox] = React.useState(false);
  const [languages] = React.useState([
    [1, "English"],
    [2, "Hindi"],
    [3, "Telugu"],
    [4, "Tamil"],
    [5, "Kannada"],
    [6, "Marathi"],
    [7, "Gujarati"],
    [8, "Punjabi"],
    [9, "Malayalam"],
    [10, "Bengali"],
  ]);

  const {
    setSendingDailyBookingData,
    sendingDailyBookingData,
    setCheckedLanguages,
  } = useContext(BookingFlowDataContext);

  const handleSetLanguage = (e, language, languageName) => {
    const isChecked = e.target.checked;
    // Update the checkedLanguages state
    setCheckedLanguages((prevChecked) => {
      if (isChecked) {
        return [...prevChecked, languageName];
      } else {
        return prevChecked.filter((elem) => elem !== languageName);
      }
    });

    // Update the sendingDailyBookingData state
    setSendingDailyBookingData((prev) => {
      if (isChecked) {
        // When adding a language, you might want to make sure the 'languages' field is an array.
        return { ...prev, languages: [...prev.languages, language] };
      } else {
        // When removing a language, you can use filter to exclude the specified language.
        return {
          ...prev,
          languages: prev.languages.filter((elem) => elem !== language),
        };
      }
    });
  };

  return (
    <>
      <div className={pickupStyle.dateLanguageDiv}>
        <div style={{ width: "50%" }}>
          Set driver languages<sup>*</sup>
        </div>
        <div
          className={pickupStyle.languageDiv}
          onClick={() => setOpenLanguageBox(true)}
        >
          {sendingDailyBookingData.languages.length === 0
            ? "Choose"
            : `${sendingDailyBookingData.languages.length} ${
                sendingDailyBookingData.languages.length === 1
                  ? "language"
                  : "languages"
              } chosen`}
          {/* Choose */}
          <MdArrowDropDown size={24} />
        </div>
      </div>
      <Modal
        onClose={() => setOpenLanguageBox(false)}
        closeable
        isOpen={openLanguageBox}
        animate
        autoFocus
        size={SIZE.default}
        role={ROLE.dialog}
      >
        <ModalHeader>Driver Languages</ModalHeader>
        <ModalBody>
          <div>
            You can choose multiple languages that you can communicate with
            driver partners.
          </div>
          &nbsp;
          {languages &&
            languages.map((language, index) => (
              <Checkbox
                key={index}
                checked={sendingDailyBookingData.languages.includes(
                  language[0]
                )}
                onChange={(e) => handleSetLanguage(e, language[0], language[1])}
                labelPlacement={LABEL_PLACEMENT.right}
              >
                {language[1]}
              </Checkbox>
            ))}
        </ModalBody>
        <ModalFooter>
          <ModalButton onClick={() => setOpenLanguageBox(false)}>
            Confirm
          </ModalButton>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default LanguageCheck;
