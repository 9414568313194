import ReactGA from 'react-ga';

export const getClientId = () => {
  return new Promise((resolve) => {
    ReactGA.ga((tracker) => {
      const clientId = tracker.get('clientId');
      resolve(clientId);
    });
  });
};
