import React, { useContext, useEffect, useRef, useState } from "react";
import "./CarService.css";
import axios from "axios";
import Slider from "react-slick";
import { BsFillArrowLeftCircleFill } from "react-icons/bs";
import { BiCar, BiChevronDown } from "react-icons/bi";
import { carServiceContext } from "../../../context/CarServiceContextProvider";
import Manufacture from "../SelectCarOption/Manufacture/Manufacture";
import CarModal from "../SelectCarOption/CarModal/CarModal";
import ViewDetailModal from "../ViewDetailModal/ViewDetailModal";
import FuelType from "../SelectCarOption/FuelType/FuelType";
import MyGateLoader from "../../../Uis/MyGateLoader/MyGateLoader";
import FilterCarService from "../FilterCarService/FilterCarService";
import { ApplicationContext } from "../../../context/ApplicationContextProvider";
import ConsentPopUp from "../../../MyGate/ConsentBox/ConsentPopUp";
import SlotInfo from "../SlotModal/SlotInfo/SlotInfo";
import SlotSucess from "../SlotModal/SlotSucess/SlotSucess";
import empty_Lottie from "../empty.json";
import Lottie from "lottie-web";
import PromoModal from "../PromoModal/PromoModal";

function CarService() {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const animationRef = useRef(null);
  const [coupanCount, setCoupanCount] = useState(0);
  const [serviceData, setServiceData] = useState([]);
  const [tabsValue, setTabsValue] = useState("");
  const [loader, setLoader] = useState(true);
  const [imageArr, setImageArr] = useState([]);

  // coupanArr imageArr
  // context
  const {
    carServiceData,
    selectCar,
    isManufactureModalOpen,
    setIsManufactureModalOpen,
    isCarModalOpen,
    isFuelModalOpen,
    isViewDetailModalOpen,
    setIsViewDetailModalOpen,
    //  service main Data
    servicePageData,
    setServicePageData,
    // service filter arr
    setFilterServiceArr,
    //  toggle service
    toogleService,
    setToogleService,
    //  view modal
    viewModalData,
    setViewModalData,

    //  coupan arr
    coupanArr,
    setCoupanArr,
    //  filter car service banner
    setFilterCarBanner,

    //  conset
    carServiceConset,

    //  slot modal
    isSlot,
    setIsSlot,

    // filter by the price
    setPriceFilter,

    // viewDetals
    viewDetailPrice,

    //
    setFilterOffer,

    //  filter loading
    setFilterLoading,
    isSuccessModalOpen,

    // for getting the index
    filterDataIndex,

    //
    category,
    //
    handleLottie,

    //
    isnewPromoModalOpen,
  } = useContext(carServiceContext);

  const { setMyGatePopUp, myGatePopUp } = useContext(ApplicationContext);

  //this is used to open the car option modal popup
  const handleCarOptionToggle = () => {
    setIsManufactureModalOpen(true);
  };

  //  this is used to fetch the data which we are showing in the home page ; i.e. inital rendering
  const homeApi = () => {
    axios({
      method: "GET",
      url: `${BASE_URL}/web/care-pitstop-home/?latitude=${carServiceData.latitude}&longitude=${carServiceData.longitude}`,
      withCredentials: false,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then(({ data }) => {
        if (data.status === "success") {
          //  particular service link from here
          // changing map to forEach
          data.categories.forEach((elem) => {
            if (elem.name === category) {
              setFilterLoading(true);
              setTabsValue(elem.name);
              setPriceFilter(elem.name);
              //  used to getting the filter data
              const filterArr = data.services.filter((el) => {
                if (elem.name === "General Checkup") {
                  return (
                    el.extra_data.category_name === "General Health Checkup"
                  );
                } else {
                  return el.extra_data.category_name === elem.name;
                }
              });

              //  this is we are using for the separate link ; in this we are if the service is ac service we are collecting the data regarding that like banner and coupan
              data.categories.filter((el) => {
                if (category === el.name) {
                  setFilterCarBanner(el.banners);
                  setFilterOffer(el.offers);
                  return true; // Add this line to indicate that the element should be included in the filtered array
                }
                return false; // Add this line to indicate that the element should be excluded from the filtered array
              });
              setFilterServiceArr(filterArr);
              setToogleService(true);
              setFilterLoading(false);
            }
          });
          //end

          setServiceData(data.categories);
          setImageArr(data.banners);
          setCoupanArr(data.offers);
          setServicePageData({ ...servicePageData, data });
          setLoader(false);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  //  we are checking if the latitude is not there we are not calling the funciton
  useEffect(() => {
    if (carServiceData.latitude !== "") {
      homeApi();
    }
  }, [carServiceData.latitude]);

  //  this is for the lottie if we are not giving the service on any fuel or car then we are showing the service not available
  useEffect(() => {
    setTimeout(() => {
      const anim = Lottie.loadAnimation({
        container: animationRef.current,
        renderer: "svg",
        loop: true,
        autoplay: true,
        animationData: empty_Lottie,
      });
      return () => anim.destroy();
    }, 100);
  }, [toogleService, handleLottie]);

  //in this we are used to handle the color of the div when used select the particular div
  const handleDivColor = (elem) => () => {
    setFilterLoading(true);
    setTabsValue(elem.name);
    setPriceFilter(elem.name);

    const filterArr = servicePageData.data.services.filter((el) => {
      if (elem.name === "General Checkup") {
        return el.extra_data.category_name === "General Health Checkup";
      } else {
        return el.extra_data.category_name === elem.name;
      }
    });

    // changing from filter to forEach // remove return keyword if error
    servicePageData.data.categories.filter((el) => {
      if (elem.name === el.name) {
        setFilterCarBanner(el.banners);
        setFilterOffer(el.offers);
        return true;
      }
      return false;
    });

    setFilterServiceArr(filterArr);
    setToogleService(true);
    setFilterLoading(false);
  };

  //  this is we are using for knowing the index in view detail
  const handelViewModal = (elem) => () => {
    viewDetailPrice.name = elem.name;
    viewDetailPrice.eta = elem.extra_data.eta;
    viewDetailPrice.price = elem.price;

    filterDataIndex.index1 = elem.extra_data.details.findIndex(
      (obj) => obj.title === "Get 500 DriveU Coins"
    );
    filterDataIndex.index2 = elem.extra_data.details.findIndex(
      (obj) => obj.title === "What Is Included?"
    );
    filterDataIndex.index3 = elem.extra_data.details.findIndex(
      (obj) => obj.title === "How it works?"
    );
    filterDataIndex.index4 = elem.extra_data.details.findIndex(
      (obj) => obj.title === "When Is It Needed?"
    );
    filterDataIndex.index5 = elem.extra_data.details.findIndex(
      (obj) => obj.title === "About the service"
    );
    filterDataIndex.index6 = elem.extra_data.details.findIndex(
      (obj) => obj.title === "Frequently Asked Questions"
    );
    setViewModalData({ ...viewModalData, data: elem });
    setIsViewDetailModalOpen(true);
  };

  useEffect(() => {
    if (isManufactureModalOpen || isCarModalOpen) {
      document.body.style.overflow = "hidden";
    }
    return () => {
      document.body.style.overflow = "scroll";
    };
  }, [isManufactureModalOpen, isCarModalOpen]);

  // this is we are using for knowing which popup will open in between conset ,select car and Book now
  const handleModalPopUp = (elem) => () => {
    // setIsSlot(true);
    viewDetailPrice.name = elem.name;
    viewDetailPrice.eta = elem.extra_data.eta;
    viewDetailPrice.price = elem.price;
    carServiceData.service_name = elem.name;
    carServiceData.price = elem.price;
    if (selectCar === "Select car") {
      setIsManufactureModalOpen(true);
    } else if (selectCar !== "Select car" && !carServiceConset) {
      setMyGatePopUp(true);
    } else {
      setIsSlot(true);
    }
  };

  // this is used to going back to the home main page
  const handlePrevious = () => {
    setTabsValue("");
    setToogleService(false);
  };

  //  this is using to exiting the app
  const handleBackButton = () => {
    window.open("mygate://close", "_self");
  };

  //  this is image slider setting
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    vertical: false,
    autoplaySpeed: 1500,
    beforeChange: (current, next) => setCoupanCount(next),
    appendDots: (dots) => (
      <div
        style={{
          width: "90%",
          marginLeft: "12%",
        }}
      >
        <ul
          style={{
            margin: "0px",
            marginBottom: "2rem",
            marginRight: "0.5rem",
            float: "right",
            display: coupanArr.length === 1 ? "none" : "",
            // border: "2px solid teal",
          }}
        >
          <div className="carServiceCoupanCountsMainContainer">
            {coupanCount + 1 + "/" + coupanArr.length}
          </div>
          <div>{dots}</div>
        </ul>
      </div>
    ),
  };

  //  this is coupan slider setting
  const settings1 = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: false,
    autoplay: true,
    autoplaySpeed: 2000,
    appendDots: (dots) => (
      <div
        style={{
          width: "100%",
          // border: "2px solid teal",
          position: "static",
          marginTop: "-2rem",
        }}
      >
        <ul
          style={{
            margin: "0px",
            marginBottom: "2rem",
          }}
        >
          {dots}
        </ul>
      </div>
    ),
  };

  return (
    <>
      {isManufactureModalOpen ? <Manufacture /> : null}
      {isCarModalOpen ? <CarModal /> : null}
      {isFuelModalOpen ? <FuelType /> : null}
      {isSlot ? <SlotInfo /> : null}
      {isSuccessModalOpen ? <SlotSucess /> : null}
      {myGatePopUp ? <ConsentPopUp type="carService" /> : null}
      {isViewDetailModalOpen ? <ViewDetailModal data={viewModalData} /> : null}
      {isnewPromoModalOpen ? <PromoModal /> : null}

      {loader ? (
        <MyGateLoader />
      ) : (
        <div
          style={{
            height:
              isManufactureModalOpen || isCarModalOpen ? "700px" : "100vh",
          }}
          className="carServiceMainContainer"
        >
          <div className="carServiceHeadingMainContainer">
            {/* left side car care div */}
            <div className="carServiceLeftHeadingMainContainer">
              <div
                onClick={handleBackButton}
                className="carServiceBackButtonMainContainer"
              >
                <BsFillArrowLeftCircleFill className="carServiceBackButton" />
              </div>
              <div
                onClick={handlePrevious}
                className="carServiceHeadingTextMainContainer"
              >
                Car Care
              </div>
            </div>

            {/* right side car div don't touch */}
            <div
              onClick={handleCarOptionToggle}
              className="carServiceRightHeadingMainContainer"
            >
              <div className="carServiceCarMainContainer">
                <BiCar />
              </div>
              <div className="carServiceRightHeading">{selectCar}</div>
              <div className="carServiceHeadingDownMainContainer">
                <BiChevronDown />
              </div>
            </div>
          </div>
          {/* service tab options start */}
          <div className="carServiceTabOptionMainContainer">
            {serviceData.map((elem) => (
              <div
                onClick={handleDivColor(elem)}
                key={elem.value}
                style={{
                  borderBottom:
                    tabsValue === elem.name ? "4px solid #009d61" : "none",
                }}
                className="generalServiceMainContainer"
              >
                <img src={elem.icon} alt="img" />
                <div
                  style={{
                    color: tabsValue === elem.name ? "black" : "#9b9b9b",
                  }}
                  className="generalServiceTextContainer"
                  // api call homeApi
                >
                  {elem.name}
                </div>
              </div>
            ))}
          </div>
          {/* service tab opiton end  */}
          {/* Bottom Main div end */}
          <div className="carServiceBottomMainContainer">
            {toogleService ? (
              <FilterCarService handleDivColor={handleDivColor} />
            ) : (
              <>
                {/*  car Service Videos start */}
                <Slider className="carServiceVideoMainContainer" {...settings1}>
                  {imageArr.map((elem, index) => {
                    return (
                      <img
                        onClick={handleDivColor(elem)}
                        key={index}
                        src={elem.banner}
                        alt={elem.name}
                      />
                    );
                  })}
                </Slider>
                {/*  offer div main Container start */}
                <Slider {...settings} className="carServiceOfferMainContainer">
                  {coupanArr.map((elem, index) => {
                    return (
                      <div key={index} className="carServiceOfferMapContainer">
                        <div className="carServiceOfferLeftMainContainer">
                          <img
                            id="carServiceCoupanImg"
                            src={elem.icon}
                            alt="img"
                          />
                        </div>
                        <div
                          style={{
                            width: coupanArr.length <= 2 ? "76%" : "75%",
                          }}
                          className="carSerivceOfferCentreMainContaier"
                        >
                          <div
                            id="carServiceCoupanTitle"
                            className="carSerivceOfferCentreMainHeading"
                          >
                            {elem.title}
                          </div>
                          <div
                            id="carServiceCoupanSubTitle"
                            className="carSerivceOfferCentreSubMainHeading"
                          >
                            {elem.sub_title}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </Slider>

                {/*  pistop service array in empty */}

                <div
                  style={{
                    display:
                      servicePageData.data.services.length === 0 ? "" : "none",
                  }}
                  className="carServiceEmptyMainContainer"
                >
                  <div
                    className="carServiceEmptyLottie"
                    ref={animationRef}
                  ></div>
                  <div className="carServiceEmptyMainHeading">
                    Uh-oh! No Service <br /> under this category.
                  </div>
                </div>

                {/* car service all option start */}
                {servicePageData.data.services.map((elem) => {
                  return (
                    <div
                      key={elem.name}
                      className="carServiceAllOptionMainContainer"
                    >
                      {/* upward div */}
                      <div className="carServiceAllOptionTopMainContainer">
                        <div className="carServiceAllOptionTopContainer">
                          {/*  left div start */}
                          <div className="carServiceAllOptionTopLeftContainer">
                            <div className="carServiceAllOptionTopLeftHeading">
                              {elem.name}
                            </div>
                            <div className="carServiceAllOptionTopLeftRating">
                              <div className="carServiceAllOptionImg">
                                <img
                                  src="https://ik.imagekit.io/driveu/Vector_Q9OxOp1a4.png?updatedAt=1684924899241"
                                  alt="star"
                                />
                              </div>
                              <div> {elem.extra_data.rating_text}</div>
                            </div>
                            <div
                              style={{
                                display:
                                  selectCar === "Select car" ? "none" : "",
                              }}
                              className="carServiceAllOptionTopLeftPrice"
                            >
                              ₹{elem.price}
                            </div>
                            <div className="carServiceAllOptionTopLeftETATime">
                              {elem.extra_data.eta}
                            </div>
                          </div>
                          {/* right div  */}
                          <div className="carServiceAllOptionTopRightContainer">
                            <img src={elem.extra_data.image_url} alt="img" />
                          </div>
                        </div>
                      </div>
                      {/* what_is_included downward div dont touch */}
                      <div className="carServiceAllOptionBottomMainContainer">
                        <div className="carServiceAllOptionBottomOptionContainer">
                          {elem.extra_data.what_is_included
                            .filter((item, index) => index < 4)
                            .map((elem) => {
                              return (
                                <div
                                  key={elem}
                                  className="carServiceAllOptionBottomFirstOptions"
                                >
                                  • &nbsp;
                                  <div>{elem}</div>
                                </div>
                              );
                            })}
                        </div>
                        <div className="carServiceViewDetailsMainContainer">
                          <div
                            onClick={handelViewModal(elem)}
                            className="carServiceViewDetailsContaier"
                          >
                            View Details
                          </div>
                          <div
                            onClick={handleModalPopUp(elem)}
                            className="carServiceBookNowContaier"
                          >
                            {/* Book Now */}
                            {selectCar === "Select car"
                              ? "Select car"
                              : "Book Now"}
                          </div>
                        </div>
                        {/* car service view Details */}
                      </div>
                    </div>
                  );
                })}
                {/* car service all option end */}
              </>
            )}

            {/* Bottom Main div end  */}
          </div>
        </div>
      )}
    </>
  );
}

export default CarService;
