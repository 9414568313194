import React from "react";
import DailyBookingDefault from "../../DriveUDaily/DailyBookingDefault/DailyBookingDefault";

const DriveuDaily = () => {
  return (
    <div style={{ height: "100vh" }}>
      <DailyBookingDefault />
    </div>
  );
};

export default DriveuDaily;
