import React from "react";

const useWindowSize = () => {
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);
  React.useEffect(() => {
    // Add event listener to track window width changes
    window.addEventListener("resize", handleWindowResize);

    // Clean up event listener when component unmounts
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  function handleWindowResize() {
    setWindowWidth(window.innerWidth);
  }

  return { windowWidth, handleWindowResize };
};

export default useWindowSize;
