import {
  Calender,
  DailyTick,
  LanguageDU,
} from "../../../Components/AllImportFiles/ImportPng";

export const featuresObject = [
  {
    id: 1,
    content: "Guaranteed earnings for up to a week from same customer",
    image: DailyTick,
  },
  {
    id: 2,
    content: "Same pickup location and pickup time for all dates",
    image: Calender,
  },
  {
    id: 3,
    content: "No language issue",
    image: LanguageDU,
  },
];
