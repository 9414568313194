import React, { createContext, useRef } from "react";

export const FooterContext = createContext();
function FooterContextProvider({ children }) {
  const navbarRef = useRef();
  const handleFooter = () => {
    navbarRef.current.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <FooterContext.Provider value={{ handleFooter, navbarRef }}>
      {children}
    </FooterContext.Provider>
  );
}

export default FooterContextProvider;
