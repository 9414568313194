import React from "react";
import { useLocation } from "react-router-dom";

const CancelPolicyDU = ({version}) => {
  const location = useLocation();
  console.log(location.pathname);
  return (
    <>
      <ul>
        <li>Pay the advance amount to create the DU Daily Package Booking</li>
        <li>
          Wait for Confirmation after successful payment of advance payment
        </li>
        <li>
          After DU Daily Package is assigned to a driver you can access driver
          details DU Daily Package tracking screen. This can be accessed from
          Account → Order History
        </li>
        <li>Driver will start visiting you on your chosen date and time</li>
        <li>
          You can track individual bookings of DU Daily Package using ‘Track
          Order Status’ cards from ‘Home’ section in the app
        </li>
      </ul>
      <br />
      <p>
        <strong>Cancellations:</strong>
      </p>
      <ul>
        <li>
          You can cancel individual bookings of the DU Daily Package Order
          anytime.
        </li>
      </ul>

      <p>
        <strong>How to do the cancellations:</strong>
      </p>
      <ul>
        <li>
          Access your DU Daily Package Order IDs from DriveU HomePage App or
          from Account → Order History
        </li>
        <li>
          <ul>
            <li>
              In an unlikely case, DU Daily Package OrderID is not confirmed
              before 6 hrs of first booking, Order for DU Daily will get
              automatically canceled. Refunds will be credited to the source
              within 3-7 business days.
            </li>
            <li>
              In an unlikely case, you decide to cancel booking(s) for a
              date(s), it will attract a cancellation fee(₹100) if pickup time of that
              booking is within 12 hrs from cancellation time. Any cancellation
              done by you which is done well before 12 hrs before pickup time of
              bookings will not attract cancellation fee.
            </li>
          </ul>
        </li>
      </ul>

      <p>
        <strong>Package Cost:</strong>
      </p>
      <ul>
        <li>
          Actual cost of individual trips & DU Daily package will be
          recalculated upon each trip completion (done/canceled)
        </li>
        <li>
          Fares will be calculated as plus drivers fares as of now. This
          includes extra time usage, distance, night charges, etc., as
          applicable.
        </li>
      </ul>

      <p>
        <strong>Payments:</strong>
      </p>
      <ul>
        <li>
          {version === "v2" || location.pathname === "/create-daily-bookings" ? <>Driver needs to be paid at the end of each trip.</> :  <>Final balance amount has to be paid to the driver upon the last trip
          completion of the package</>}
        </li>
      </ul>
      <br />
      <div>
        You can change the drivers if you don't like. Just reach out to our
        customer support, and we will find a replacement driver.
      </div>
    </>
  );
};

export default CancelPolicyDU;
