import React, { useContext } from "react";
import { BookingFlowDataContext } from "../../../context/BookingFlowDataContextProvider";
import { Modal, ModalHeader, ModalBody } from "baseui/modal";
function DailyFareModal({ openDailyFareModal, setOpenDailyFareModal }) {
  const { fareEstimateDaily } = useContext(BookingFlowDataContext);
  return (
    <Modal
      onClose={() => setOpenDailyFareModal(false)}
      isOpen={openDailyFareModal}
    >
      <ModalHeader>FARE ESTIMATE</ModalHeader>

      <ModalBody>
        <table className="fareTable">
          <tbody>
            {fareEstimateDaily.map((elem, idx) => (
              <tr className="fareTableRow" key={idx}>
                <td
                  className="fareTableCol"
                  style={{ fontSize: elem.heading === "Total" ? "bold" : "" }}
                >
                  {elem.heading}
                </td>
                <td
                  className="fareTableCol"
                  style={{ fontSize: elem.heading === "Total" ? "bold" : "" }}
                >
                  {elem.component_type === "addition"
                    ? "+"
                    : elem.component_type === "deduction"
                    ? "-"
                    : ""}
                  {elem.component_value}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </ModalBody>
    </Modal>
  );
}

export default DailyFareModal;
