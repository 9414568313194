//  this is succss modal after payment get success

import React, { useContext, useEffect, useRef, useState } from "react";
import "./SlotSucess.css";
import { Drawer, ANCHOR, SIZE } from "baseui/drawer";
import { carServiceContext } from "../../../../context/CarServiceContextProvider";
import success_lottie from "./Success.json";
import Lottie from "lottie-web";
import { success } from "../../../../Components/AllImportFiles/ImportPng";
import LoadingSpinner from "../../../../Uis/Spinner/Spinner";

function SlotSucess() {
  const [loading, setLoading] = useState(false);
  const animationRef = useRef(null);
  const {
    isSuccessModalOpen,
    closeSuccessModal,
    trackingCarService,
    viewDetailPrice,
  } = useContext(carServiceContext);

  useEffect(() => {
    setTimeout(() => {
      const anim = Lottie.loadAnimation({
        container: animationRef.current,
        renderer: "svg",
        loop: true,
        autoplay: true,
        animationData: success_lottie,
      });
      return () => anim.destroy();
    }, 100);
  }, []);

  const handleLoader = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 5000);
  };
  return (
    <Drawer
      isOpen={isSuccessModalOpen}
      onClose={closeSuccessModal}
      anchor={ANCHOR.bottom}
      size={SIZE.auto}
      overrides={{
        DrawerBody: {
          style: ({ $theme }) => ({
            marginBottom: "5px",
            marginLeft: "10px",
            marginRight: "10px",
          }),
        },
        // DrawerContainer: {
        //   style: ({ $theme }) => ({
        //     height: "80%",
        //   }),
        // },
      }}
    >
      <div>
        <div className="viewDetailsHeading">{viewDetailPrice.name}</div>
        <div className="viewDetailTime">
          Car Service • Garage • {viewDetailPrice.eta}
        </div>

        <div className="successLoader" ref={animationRef}></div>

        <div className="successOrderPlaced">
          <div className="successOrderPlacedHeading">Order Placed</div>
          <div className="successOrderPlacedImageContainer">
            <img src={success} alt="img" />
          </div>
        </div>

        <div className="successTextContainer">
          <div>
            A car service representative will call you shorlty to confirm your
            appointment.
          </div>
          <div>
            <div>You'ill receive an email confirmation about your order.</div>
            <div>
              Please write at{" "}
              <span style={{ color: "green" }}>care@driveu.in</span> for any
              queries.
            </div>
          </div>
        </div>

        <div
          style={{ backgroundColor: "#f93356", width: "100%" }}
          className="successTrackMainContainer"
        >
          {loading ? (
            <div
              className="carServiceSuccesLoader successTrackMainContainer"
              style={{ width: "100%" }}
            >
              <LoadingSpinner />
            </div>
          ) : (
            <a
              href={trackingCarService}
              style={{
                backgroundColor: "#F93356",
                color: "white",
                width: "100%",
                // border: "none",
                height: "100%",
                border: "1px solid #f93356",
              }}
              className="carServiceSuccesLoader successTrackMainContainer"
              onClick={handleLoader}
            >
              TRACK ORDER STATUS
            </a>
          )}
        </div>

        {/* <a
          href={trackingCarService}
          style={{
            backgroundColor: "#F93356",
            color: "white",
          }}
          className="successTrackMainContainer"
        >
          TRACK ORDER STATUS
        </a> */}
      </div>
    </Drawer>
  );
}

export default SlotSucess;
