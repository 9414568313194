import React, { useState, useContext, useRef, useEffect } from "react";
import "./PopUpDetails.css";
import axios from "axios";
import { CloseBtn } from "../../../AllImportFiles/ImportSvg";
import Spinner from "../../../../Uis/Spinner/Spinner";
import { useCookies } from "react-cookie";
import { Link, useNavigate } from "react-router-dom";
// CONTEXT API
import { DownloadContext } from "../../../../context/DownloadContextProvider";
import { FastagContext } from "../../../../context/FastagContextProvider";
import { RSAContext } from "../../../../context/RSAContextProvider";
import { BookingFlowDataContext } from "../../../../context/BookingFlowDataContextProvider";
import { BookingFlowParaContext } from "../../../../context/BookingFlowParaProvider";
import { WashSubscriptionContext } from "../../../../context/WashSubscriptionContextProvider";

function PopUpDetails({ userNumber }) {
  // ENV VARIABLE
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const BASE_URL_HULK = process.env.REACT_APP_HULK_URL;
  const PROFILE_API_SRC = process.env.REACT_APP_PROFILE_API_SRC;
  // REF
  const inputElement = useRef(null);
  // REACT ROUTER DOM AND COOKIE
  const navigate = useNavigate();
  const [, setCookie] = useCookies(["userid"]);
  const [cookiesUser] = useCookies();
  // STATE
  const [userData, setUserData] = useState({
    name: "",
    mobile: userNumber.mobile,
    email: "",
    tncCheck: true,
    imei_number: userNumber.mobile,
    otp_code: userNumber.otp_code,
  });
  const [errMsg, setErrMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  //CONTEXT API
  const { setIsAuthenticated, setUserName, setShowBookLoader } = useContext(
    BookingFlowParaContext
  );
  const {
    previousChoice,
    setIsFastTagLoading,
    setShowPopUp,
    handleClosePopUpOTP,
  } = useContext(DownloadContext);
  const { setActiveArray, setSubScriptionPlan, setLottieLoader } = useContext(
    WashSubscriptionContext
  );
  const { setShowRSAPopUp } = useContext(RSAContext);
  const {
    sendingData,
    sendingRoundWayData,
    setUserInfo,
    setShowBookLoaderRound,
    sendingOutstationData,
  } = useContext(BookingFlowDataContext);
  const {
    setSendLink,
    setShowRecharge,
    setCloudUserId,
    compareWidth,
  } = useContext(FastagContext);

  // FUNCTION FOR INPUT FIELD
  const handleClosePopUpDetail = () => {
    handleClosePopUpOTP();
    window.location.reload();
  };
  const handleField = (field) => (e) => {
    const { value } = e.target;
    setUserData({
      ...userData,
      [field]: value,
    });
  };
  // NEW USER SIGN-UP FUNCTION AND REDIRECT ACCORDING TO PREVIOUS CHOICE FROM WHERE POPUP INITIATED
  const handleSignUp = (e) => {
    e.preventDefault();
    const userID = cookiesUser.userid;
    const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (e.target.name.value === "") {
      setErrMsg("Enter your name");
      setTimeout(() => {
        setErrMsg("");
      }, 10000);
    } else if (e.target.name.value.length < 4) {
      setErrMsg(
        "That doesn't seem to be a valid name, please enter your full name"
      );
      setTimeout(() => {
        setErrMsg("");
      }, 10000);
    } else if (e.target.email.value === "") {
      setErrMsg("Enter your email");
      setTimeout(() => {
        setErrMsg("");
      }, 10000);
    } else if (!e.target.email.value.match(validRegex)) {
      setErrMsg("Enter valid email address");
      setTimeout(() => {
        setErrMsg("");
      }, 10000);
    } else if (e.target.tncCheck.value === true) {
      setErrMsg("You must agree to the terms and condition");
      setTimeout(() => {
        setErrMsg("");
      }, 10000);
    } else {
      setIsLoading(true);
      axios({
        method: "POST",
        baseURL: BASE_URL,
        url: `/web/complete-signup/?user_id=${cookiesUser.userid}`,
        data: userData,
        headers: {
          "Content-Type": `multipart/form-data`,
        },
      })
        .then(({ data }) => {
          let user_status = data.status;
          let user_message = data.message;
          if (
            user_status === "success" &&
            user_message === "SignUp successful" &&
            previousChoice === "fasttag"
          ) {
            setIsFastTagLoading(true);
            axios({
              method: "GET",
              baseURL: BASE_URL_HULK,
              url: `${BASE_URL_HULK}/care/setu/links/?category=Fastag&user_id=${cookiesUser.userid}`,
              withCredentials: false,
              headers: {
                "Content-Type": `multipart/form-data`,
              },
            })
              .then(({ data }) => {
                setCloudUserId(userID);
                setSendLink(data.link);
                if (compareWidth > 1279) {
                  setShowRecharge(true);
                } else {
                  window.open(data.link, "_blank");
                }
                setIsFastTagLoading(false);
                setShowPopUp(false);
              })
              .catch((error) => {
                alert(error.message);
              });
          } else if (
            user_status === "success" &&
            user_message === "SignUp successful" &&
            previousChoice === "hiredrivers"
          ) {
            // navigate("/book-driver");
            window.location.href = "/book-driver/";
          } else if (
            user_status === "success" &&
            user_message === "SignUp successful" &&
            previousChoice === "road_side_assistance"
          ) {
            axios({
              method: "GET",
              baseURL: BASE_URL,
              url: `${BASE_URL}/web/profile/?src=${PROFILE_API_SRC}&user_id=${userID}`,
              withCredentials: false,
              headers: {
                "Content-Type": `application/json`,
              },
            })
              .then(({ data }) => {
                setIsAuthenticated(data.is_authenticated);
                setUserName(data.full_name);
                setUserInfo(data);
                setCookie("userid", userID, { path: "/" });
              })
              .catch((err) => {
                console.log(err.message);
              });
            setShowRSAPopUp(true);
            setShowPopUp(false);
          } else if (
            user_status === "success" &&
            user_message === "SignUp successful" &&
            previousChoice === "RequestBooking"
          ) {
            sendingData.user_id = cookiesUser.userid;
            axios({
              method: "GET",
              baseURL: BASE_URL,
              url: `${BASE_URL}/web/profile/?src=${PROFILE_API_SRC}&user_id=${cookiesUser.userid}`,
              withCredentials: false,
              headers: {
                "Content-Type": `application/json`,
              },
            })
              .then(({ data }) => {
                setIsAuthenticated(data.is_authenticated);
                setUserName(data.full_name);
                setUserInfo(data);
              })
              .catch((err) => {
                console.log(err.message);
              });
            setShowPopUp(false);

            setShowBookLoader(true);
            axios({
              method: "POST",
              baseURL: BASE_URL,
              url: `${BASE_URL}/web/book-driver/?src=${PROFILE_API_SRC}&user_id=${cookiesUser.userid}`,
              data: sendingData,
              withCredentials: false,
              headers: {
                "Content-Type": `application/json`,
              },
            })
              .then(({ data }) => {
                setShowBookLoader(false);
                navigate("/my-drives");
              })
              .catch((err) => {
                console.log(err.message);
                setShowBookLoader(false);
              });
          } else if (
            user_status === "success" &&
            user_message === "SignUp successful" &&
            previousChoice === "RequestBookingRoundWay"
          ) {
            sendingRoundWayData.user_id = cookiesUser.userid;
            axios({
              method: "GET",
              baseURL: BASE_URL,
              url: `${BASE_URL}/web/profile/?src=${PROFILE_API_SRC}&user_id=${cookiesUser.userid}`,
              withCredentials: false,
              headers: {
                "Content-Type": `application/json`,
              },
            })
              .then(({ data }) => {
                setIsAuthenticated(data.is_authenticated);
                setUserName(data.full_name);
                setUserInfo(data);
              })
              .catch((err) => {
                console.log(err.message);
              });
            setShowPopUp(false);
            setShowBookLoaderRound(true);
            axios({
              method: "POST",
              baseURL: BASE_URL,
              url: `${BASE_URL}/web/book-driver/?src=${PROFILE_API_SRC}&user_id=${cookiesUser.userid}`,
              data: sendingRoundWayData,
              withCredentials: false,
              headers: {
                "Content-Type": `application/json`,
              },
            })
              .then(({ data }) => {
                setShowBookLoaderRound(false);
                navigate("/my-drives");
                // window.location.href = "/my-drives";
              })
              .catch((err) => {
                console.log(err.message);
                setShowBookLoaderRound(false);
              });
          } else if (
            user_status === "success" &&
            user_message === "SignUp successful" &&
            previousChoice === "RequestBookingOutstation"
          ) {
            sendingOutstationData.user_id = cookiesUser.userid;
            axios({
              method: "GET",
              baseURL: BASE_URL,
              url: `${BASE_URL}/web/profile/?src=${PROFILE_API_SRC}&user_id=${cookiesUser.userid}`,
              withCredentials: false,
              headers: {
                "Content-Type": `application/json`,
              },
            })
              .then(({ data }) => {
                setIsAuthenticated(data.is_authenticated);
                setUserName(data.full_name);
                setUserInfo(data);
              })
              .catch((err) => {
                console.log(err.message);
              });
            setShowPopUp(false);
            setShowBookLoaderRound(true);
            axios({
              method: "POST",
              baseURL: BASE_URL,
              url: `${BASE_URL}/web/book-driver/?src=${PROFILE_API_SRC}&user_id=${cookiesUser.userid}`,
              data: sendingOutstationData,
              withCredentials: false,
              headers: {
                "Content-Type": `application/json`,
              },
            })
              .then(({ data }) => {
                setShowBookLoaderRound(false);
                navigate("/my-drives");
                // window.location.href = "/my-drives";
              })
              .catch((err) => {
                console.log(err.message);
                setShowBookLoaderRound(false);
              });
          } else if (
            user_status === "success" &&
            user_message === "SignUp successful" &&
            previousChoice === "NavbarSignUp"
          ) {
            sendingData.user_id = cookiesUser.userid;
            sendingRoundWayData.user_id = cookiesUser.userid;
            sendingOutstationData.user_id = cookiesUser.userid;
            axios({
              method: "GET",
              baseURL: BASE_URL,
              url: `${BASE_URL}/web/profile/?src=${PROFILE_API_SRC}&user_id=${cookiesUser.userid}`,
              withCredentials: false,
              headers: {
                "Content-Type": `application/json`,
              },
            })
              .then(({ data }) => {
                setIsAuthenticated(data.is_authenticated);
                setUserName(data.full_name);
                setUserInfo(data);
                setShowPopUp(false);
              })
              .catch((err) => {
                console.log(err.message);
                setShowPopUp(false);
              });
          } else if (
            previousChoice === "NavbarSignUpWash" &&
            user_status === "success" &&
            user_message === "SignUp successful"
          ) {
            axios({
              method: "GET",
              baseURL: BASE_URL,
              url: `${BASE_URL}/web/profile/?src=${PROFILE_API_SRC}&user_id=${cookiesUser.userid}`,
              withCredentials: false,
              headers: {
                "Content-Type": `application/json`,
              },
            })
              .then(({ data }) => {
                setIsAuthenticated(data.is_authenticated);
                setUserName(data.full_name);
                setUserInfo(data);
                setShowPopUp(false);
              })
              .catch((err) => {
                console.log(err.message);
                setShowPopUp(false);
              });
          } else if (
            // data.status === "success"
            user_status === "success" &&
            user_message === "SignUp successful" &&
            previousChoice === "WashActive"
          ) {
            axios({
              method: "GET",
              baseURL: BASE_URL,
              url: `${BASE_URL}/web/profile/?src=${PROFILE_API_SRC}&user_id=${cookiesUser.userid}`,
              withCredentials: false,
              headers: {
                "Content-Type": `application/json`,
              },
            })
              .then(({ data }) => {
                setIsAuthenticated(data.is_authenticated);
                setUserName(data.full_name);
                setUserInfo(data);

                setShowPopUp(false);
              })
              .catch((err) => {
                console.log(err.message);
                setShowPopUp(false);
              });

            axios({
              method: "GET",
              baseURL: BASE_URL,
              url: `${BASE_URL}/web/apartment-bookings/?src=${PROFILE_API_SRC}&user_id=${cookiesUser.userid}`,
              headers: {
                "Content-Type": `multipart/form-data`,
              },
              withCredentials: false,
            })
              .then(({ data }) => {
                if (data.status === "success") {
                  if (data.bookings.length !== 0) {
                    if (
                      cookiesUser.userid === "" ||
                      cookiesUser.userid === undefined ||
                      !cookiesUser.userid
                    ) {
                      setShowPopUp(true);
                    } else {
                      setActiveArray(data.bookings);
                      navigate("/active-packages/");
                    }
                    // setShowPopUp(false);
                  } else {
                    if (
                      cookiesUser.userid === "" ||
                      cookiesUser.userid === undefined ||
                      !cookiesUser.userid
                    ) {
                      setShowPopUp(true);
                    } else {
                      setLottieLoader(true);
                      axios({
                        method: "GET",
                        baseURL: BASE_URL_HULK,
                        url:
                          // "https://b2b.driveubox.com/wash/apartment-bookings/plans/",
                          `${BASE_URL_HULK}/wash/apartment-bookings/plans/`,
                        withCredentials: false,
                        headers: {
                          "Content-Type": `application/json`,
                        },
                      })
                        .then(({ data }) => {
                          if (data.status === "success") {
                            setSubScriptionPlan(data.plans);
                            navigate("/buy-wash-packages/");
                            setLottieLoader(false);
                          }
                        })
                        .catch((err) => {
                          console.log(err.message);
                        });
                    }
                  }
                }
              })
              .catch((err) => {
                console.log(err.message);
                setShowPopUp(false);
              });
          } else if (
            user_status === "success" &&
            user_message === "SignUp successful" &&
            previousChoice === "InsuranceOneWay"
          ) {
            sendingData.user_id = cookiesUser.userid;
            axios({
              method: "GET",
              baseURL: BASE_URL,
              url: `${BASE_URL}/web/profile/?src=${PROFILE_API_SRC}&user_id=${cookiesUser.userid}`,
              withCredentials: false,
              headers: {
                "Content-Type": `application/json`,
              },
            })
              .then(({ data }) => {
                setIsAuthenticated(data.is_authenticated);
                setUserName(data.full_name);
                setUserInfo(data);
              })
              .catch((err) => {
                console.log(err.message);
              });
            setShowPopUp(false);
            setShowBookLoader(true);
            axios({
              method: "POST",
              baseURL: BASE_URL,
              url: `${BASE_URL}/web/book-driver/?src=${PROFILE_API_SRC}&user_id=${cookiesUser.userid}`,
              data: sendingData,
              withCredentials: false,
              headers: {
                "Content-Type": `application/json`,
              },
            })
              .then(({ data }) => {
                setShowBookLoader(false);
                navigate("/my-drives");
              })
              .catch((err) => {
                console.log(err.message);
                setShowBookLoader(false);
              });
          } else if (
            user_status === "success" &&
            user_message === "SignUp successful" &&
            previousChoice === "InsuranceRoundTrip"
          ) {
            sendingRoundWayData.user_id = cookiesUser.userid;
            axios({
              method: "GET",
              baseURL: BASE_URL,
              url: `${BASE_URL}/web/profile/?src=${PROFILE_API_SRC}&user_id=${cookiesUser.userid}`,
              withCredentials: false,
              headers: {
                "Content-Type": `application/json`,
              },
            })
              .then(({ data }) => {
                setIsAuthenticated(data.is_authenticated);
                setUserName(data.full_name);
                setUserInfo(data);
              })
              .catch((err) => {
                console.log(err.message);
              });
            setShowPopUp(false);
            setShowBookLoaderRound(true);
            axios({
              method: "POST",
              baseURL: BASE_URL,
              url: `${BASE_URL}/web/book-driver/?src=${PROFILE_API_SRC}&user_id=${cookiesUser.userid}`,
              data: sendingRoundWayData,
              withCredentials: false,
              headers: {
                "Content-Type": `application/json`,
              },
            })
              .then(({ data }) => {
                setShowBookLoaderRound(false);
                navigate("/my-drives");
              })
              .catch((err) => {
                console.log(err.message);
                setShowBookLoaderRound(false);
              });
          } else if (
            user_status === "success" &&
            user_message === "SignUp successful" &&
            previousChoice === "InsuranceOutstation"
          ) {
            sendingOutstationData.user_id = cookiesUser.userid;
            axios({
              method: "GET",
              baseURL: BASE_URL,
              url: `${BASE_URL}/web/profile/?src=${PROFILE_API_SRC}&user_id=${cookiesUser.userid}`,
              withCredentials: false,
              headers: {
                "Content-Type": `application/json`,
              },
            })
              .then(({ data }) => {
                setIsAuthenticated(data.is_authenticated);
                setUserName(data.full_name);
                setUserInfo(data);
              })
              .catch((err) => {
                console.log(err.message);
              });
            setShowPopUp(false);
            setShowBookLoaderRound(true);
            axios({
              method: "POST",
              baseURL: BASE_URL,
              url: `${BASE_URL}/web/book-driver/?src=${PROFILE_API_SRC}&user_id=${cookiesUser.userid}`,
              data: sendingOutstationData,
              withCredentials: false,
              headers: {
                "Content-Type": `application/json`,
              },
            })
              .then(({ data }) => {
                setShowBookLoaderRound(false);
                navigate("/my-drives");
              })
              .catch((err) => {
                console.log(err.message);
                setShowBookLoaderRound(false);
              });
          } else if (
            user_status === "success" &&
            user_message === "SignUp successful"
          ) {
            // navigate("/book-driver");
            window.location.href = "/book-driver/";
          }
          setTimeout(() => {
            setIsLoading(false);
          }, 2000);
        })
        .catch((err) => {
          setTimeout(() => {
            setIsLoading(false);
          }, 2000);
          setTimeout(() => {
            alert(err.message);
          }, 4000);
        });
    }
  };
  useEffect(() => {
    if (inputElement.current) {
      inputElement.current.focus();
    }
  }, []);
  return (
    <div className="signupdetails_popup">
      <div
        style={{ display: previousChoice === "WashActive" ? "none" : "" }}
        className="closedetails"
      >
        <CloseBtn className="close_Btn" onClick={handleClosePopUpDetail} />
      </div>
      <div className="content-phone-signup">
        <div className="top-content-signup">
          <center>
            <img
              src="https://ik.imagekit.io/driveu/Website_New/signuptick_MaZ3pDfCl.png"
              alt="OtpImage"
              className="otpimg2"
            />
          </center>

          <h2 className="head-signup">Sign Up</h2>
        </div>
        <div className="popupdetails">
          <form className="user_details_block" onSubmit={handleSignUp}>
            <input
              ref={inputElement}
              autoFocus
              type="text"
              placeholder="Enter your name"
              name="name"
              value={userData.name}
              onChange={handleField("name")}
              autoComplete="off"
              pattern="^\s*\S+(\s+\S+)*\s*$"
            />
            <input
              autoFocus
              type="email"
              placeholder="Enter Email-ID"
              pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
              name="email"
              value={userData.email}
              onChange={handleField("email")}
              title="Write valid email address"
              autoComplete="off"
            />
            <div className="check_term">
              <span>
                <input
                  type="checkbox"
                  className="checkbox_term"
                  name="tncCheck"
                  onChange={handleField("tncCheck")}
                  required
                />
              </span>
              <span className="terms_services">
                I agree all statements in{" "}
                <Link to="/tnc" target="_blank" className="tnc">
                  terms of services
                </Link>
              </span>
            </div>
            {isLoading ? (
              <button
                className="signup"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Spinner />
              </button>
            ) : (
              <button
                className="signup"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                disabled={isLoading}
              >
                Sign Up
              </button>
            )}
          </form>
          <div className="err_msg_signup">{errMsg}</div>
        </div>
      </div>
    </div>
  );
}

export default PopUpDetails;
