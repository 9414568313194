import React, { useContext, useState } from "react";
import { ApplicationContext } from "../../context/ApplicationContextProvider";
import { Button } from "baseui/button";
import MapBoxBlock from "../../DuDailyPartner/MapBoxBlock/MapBoxBlock";
import PackageDetails from "../../DuDailyPartner/PackageDetails/PackageDetails";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

const StatusPackage = () => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const { partnerBookData, driverData } = useContext(ApplicationContext);
  const [actionText, setActionText] = useState(null);
  const navigate = useNavigate();
  const [actionLoading, setActionLoading] = useState(false);
  const contentStyle = {
    padding: "16px",
    marginBottom: "80px", // Adjust the margin to make room for the fixed footer
    overflowY: "scroll", // Enable vertical scrolling for content
    maxHeight: "calc(100vh - 80px)", // Set the maximum height for scrollable content
  };
  const footerStyle = {
    textAlign: "center",
    padding: "8px",
    position: "fixed",
    bottom: "0",
    width: "100%",
  };
  const instructionsStyle = {
    borderRadius: " 0.3125rem",
    border: "1px solid #e6e6e8",
    background: "#fff",
    marginTop: "1rem",
    padding: "0.75rem",
  };
  const handleReservePost = (data, actionText) => {
    setActionLoading(true);
    if (data.ACTION === "RESERVE" && actionText === null) {
      const postData = {
        driver_id: driverData.driver_id,
        imei_number: driverData.imei_number,
        booking_id: data.booking_id,
        latitude: data.package_latitude,
        longitude: data.package_longitude,
      };
      const ReservePOSTData = axios({
        method: "POST",
        url: `${BASE_URL}/web/du-daily/reserve-package/`,
        data: postData,
        headers: {
          "Content-Type": `multipart/form-data`,
        },
        withCredentials: false,
      });
      ReservePOSTData.then(({ data }) => {
        if (data.status === "success") {
          setActionText(data);
          setActionLoading(false);
        } else {
          toast.error(data.message);
          setActionLoading(false);
        }
      }).catch((err) => {
        console.log(err.message);
        toast.error(err.message);
        setActionLoading(false);
      });
    } else if (data.ACTION === "TAKE" || actionText.action === "TAKE") {
      const postData = {
        driver_id: driverData.driver_id,
        imei_number: driverData.imei_number,
        booking_id: data.upcoming_booking_id,
        latitude: data.package_latitude,
        longitude: data.package_longitude,
      };
      const AssignedPOSTData = axios({
        method: "POST",
        url: `${BASE_URL}/web/du-daily/assign-package/`,
        data: postData,
        headers: {
          "Content-Type": `multipart/form-data`,
        },
        withCredentials: false,
      });
      AssignedPOSTData.then(({ data }) => {
        if (data.status === "success") {
          navigate("/du-daily-partner-assigned");
          setActionLoading(false);
        } else {
          setActionLoading(false);
          toast.error(data.message);
        }
      }).catch((err) => {
        console.log(err.message);
        setActionLoading(false);
        toast.error(err.message);
      });
    }
  };

  return (
    <div>
      <MapBoxBlock
        latitude={partnerBookData.package_latitude}
        longitude={partnerBookData.package_longitude}
      />
      <div style={contentStyle}>
        <PackageDetails partnerBookData={partnerBookData} />
        {partnerBookData.instructions ? (
          <div style={instructionsStyle}>
            <strong>Instruction</strong>
            <div>{partnerBookData.instructions}</div>
          </div>
        ) : null}
      </div>

      <div style={footerStyle}>
        {actionText === null ? (
          <>
            {partnerBookData.ACTION === "RESERVE" ||
            partnerBookData.ACTION === "TAKE" ? (
              <>
                {actionLoading ? (
                  <Button style={{ width: "100%" }} isLoading>
                    {partnerBookData.button_text}
                  </Button>
                ) : (
                  <Button
                    style={{ width: "100%" }}
                    onClick={() =>
                      handleReservePost(partnerBookData, actionText)
                    }
                  >
                    {partnerBookData.button_text}
                  </Button>
                )}
              </>
            ) : (
              <Button style={{ width: "100%" }} disabled>
                {partnerBookData.button_text}
              </Button>
            )}
          </>
        ) : (
          <>
            {actionText.action === "" ? (
              <Button style={{ width: "100%" }} disabled>
                {actionText.button_text}
              </Button>
            ) : (
              <>
                {actionLoading ? (
                  <Button style={{ width: "100%" }} isLoading>
                    {actionText.button_text}
                  </Button>
                ) : (
                  <Button
                    style={{ width: "100%" }}
                    onClick={() =>
                      handleReservePost(partnerBookData, actionText)
                    }
                  >
                    {actionText.button_text}
                  </Button>
                )}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default StatusPackage;
