import React, { createContext, useState } from "react";
import { useRef } from "react";

export const WashSubscriptionContext = createContext();
function WashSubscriptionContextProvider({ children }) {
  const [isBikeChecked, setIsBikeChecked] = useState(false);
  const [formData, setFormData] = useState({});
  const [dateArray, setDateArray] = useState([]);
  const [subScriptionPlan, setSubScriptionPlan] = useState([]);
  const [anotherCar, setAnotherCar] = useState();
  const [popupClose, setPopupClose] = useState();
  const [activeArray, setActiveArray] = useState([]);
  const [lottieLoader, setLottieLoader] = useState(false);
  const [updatePutId, setUpdateId] = useState("");
  const [checkStatus, setCheckStatus] = useState(false);
  const [checkRoute, setCheckRoute] = useState(false);
  const [source, setSource] = useState("");
  const [applicationUserId, setApplicationUserId] = useState("");
  const storeDataRef = useRef();
  const [washApplicationParamas, setWashApplicationParams] = useState({});

  return (
    <WashSubscriptionContext.Provider
      value={{
        isBikeChecked,
        setIsBikeChecked,
        formData,
        setFormData,
        dateArray,
        setDateArray,
        anotherCar,
        setAnotherCar,
        popupClose,
        setPopupClose,
        activeArray,
        setActiveArray,
        updatePutId,
        setUpdateId,
        checkStatus,
        setCheckStatus,
        storeDataRef,
        checkRoute,
        setCheckRoute,
        source,
        setSource,
        subScriptionPlan,
        setSubScriptionPlan,
        lottieLoader,
        setLottieLoader,
        applicationUserId,
        setApplicationUserId,
        washApplicationParamas,
        setWashApplicationParams,
      }}
    >
      {children}
    </WashSubscriptionContext.Provider>
  );
}

export default WashSubscriptionContextProvider;
