import React, { useContext } from "react";
import { ApplicationContext } from "../../context/ApplicationContextProvider";
import MapBoxBlock from "../../DuDailyPartner/MapBoxBlock/MapBoxBlock";
import TrackPackagePartner from "../../DuDailyPartner/PackageDetails/TrackPackagePartner";

const AssignedPartnerPage = () => {
  const { partnerBookData } = useContext(ApplicationContext);
  const contentStyle = {
    // padding: "16px",
    marginBottom: "1rem", // Adjust the margin to make room for the fixed footer
    // overflowY: "scroll", // Enable vertical scrolling for content
    // maxHeight: "calc(100vh - 80px)", // Set the maximum height for scrollable content
    height: "auto",
  };
  return (
    <div style={{ height: "auto", marginBottom: "1rem", overflowY: "scroll" }}>
      <MapBoxBlock
        latitude={partnerBookData.package_latitude}
        longitude={partnerBookData.package_longitude}
      />
      <div style={contentStyle}>
        <TrackPackagePartner partnerBookData={partnerBookData} />
      </div>
    </div>
  );
};

export default AssignedPartnerPage;
