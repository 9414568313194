import React, { useContext, useState, useEffect } from "react";
import "./OneWayInput.css";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import useOnclickOutside from "react-cool-onclickoutside";
import axios from "axios";
import { BookingFlowDataContext } from "../../../context/BookingFlowDataContextProvider";
import { MdClose } from "react-icons/md";
import { BookingFlowParaContext } from "../../../context/BookingFlowParaProvider";
import clevertap from "clevertap-web-sdk";
import { ApplicationContext } from "../../../context/ApplicationContextProvider";
import {
  LocationClearedObj,
  LocationUpdateObj,
  pageOpenObj,
} from "../../../utils/EventObject";
function OneWayInput({ handleRemoveSuccessCoupon }) {
  // all state define
  const [oneWaytext, setOneWayText] = useState(0);
  const [showError, setShowError] = useState(false);
  const [toggleEmptyInput, setToggleEmptyInput] = useState(false);
  const [pickUpError, setPickUpError] = useState(false);
  const { lat, lng, couponLatLngObj } = useContext(BookingFlowParaContext);
  const { pubName, myGateToken } = useContext(ApplicationContext);
  const [formData, setFormData] = useState({});
  const {
    setFirstCityId,
    sendingData,
    secondCityId,
    toggleFareEstimate,
    // ETA
    setShowETA,
    setEtaOneWayTime,
    setShowWhen,
    cityEtaDetails,
    setCityEtaDetails,
    fareParams,
  } = useContext(BookingFlowDataContext);

  const BASE_URL = process.env.REACT_APP_BASE_URL;
  // all function and const here
  const handleChange = (e) => {
    const { name } = e.target;
    setFormData({
      ...formData,
      [name]: e.target.value,
    });
  };
  const oneWayHandleInput = (e) => {
    setValue(e.target.value);
    let a = e.target.value.length;
    setOneWayText(a);
  };
  const {
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    debounce: 300,
  });

  const ref = useOnclickOutside(() => {
    clearSuggestions();
  });

  useEffect(() => {
    if (value.length === 0) {
      setToggleEmptyInput(false);
      setFirstCityId(undefined);
      toggleFareEstimate.oneWayFirstToggle = false;
    } else {
      setToggleEmptyInput(true);
      toggleFareEstimate.oneWayFirstToggle = true;
    }
  }, [value]);

  const oneWayHandleSelect = ({ description }) => () => {
    setValue(description, false);
    clearSuggestions();
    getGeocode({ address: description }).then((results) => {
      const { lat, lng } = getLatLng(results[0]);
      sendingData.pickup_address = description;
      sendingData.pickup_latitude = lat;
      sendingData.pickup_longitude = lng;

      fareParams.pickup_address = description;
      fareParams.pickup_latitude = lat;
      fareParams.pickup_longitude = lng;
      // axios
      //   .get(
      //     `https://www.driveu.in/web/nearest-driver/?latitude=${lat}&longitude=${lng}`
      //   )
      axios
        .get(`${BASE_URL}/web/nearest-driver/?latitude=${lat}&longitude=${lng}`)
        .then(({ data }) => {
          if (+data.city_id !== +couponLatLngObj.city_id) {
            handleRemoveSuccessCoupon();
          }
          LocationUpdateObj.location_type = "pickup";
          LocationUpdateObj.pickup_zone = data.zone_name;
          LocationUpdateObj.city = data.city;
          LocationUpdateObj.client = "mygate";
          clevertap.event.push("location_updated", LocationUpdateObj);
          setShowETA(data.show_eta);
          setEtaOneWayTime(data.eta);
          if (data.message !== "") {
            setValue("");
            setShowError(true);
            setTimeout(() => {
              setShowError(false);
            }, [1000]);
          } else if (data.message === "") {
            setFirstCityId(data.city_id);
            setCityEtaDetails({ ...data });
            if (!secondCityId === true) {
              setShowError(false);
            } else if (data.city_id !== secondCityId) {
              setPickUpError(true);
              setValue("");
              setTimeout(() => {
                setPickUpError(false);
              }, 2000);
            }
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    });
  };
  const oneWayrenderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <div key={place_id.toString()}>
          <ul
            className="suggestion"
            style={{ padding: "10px" }}
            key={place_id.toString()}
            onClick={oneWayHandleSelect(suggestion)}
          >
            <strong style={{ marginRight: "5px" }} id="bold">
              {main_text}
            </strong>{" "}
            <small id="book">{secondary_text}</small>
          </ul>
        </div>
      );
    });

  function getReverseGeocodingData(lat, lng) {
    sendingData.pickup_longitude = lng;
    sendingData.pickup_latitude = lat;

    fareParams.pickup_longitude = lng;
    fareParams.pickup_latitude = lat;

    var latlng = new window.google.maps.LatLng(lat, lng);
    // This is making the Geocode request
    var geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ latLng: latlng }, (results, status) => {
      if (status !== window.google.maps.GeocoderStatus.OK) {
        // alert(status);
      }
      // This is checking to see if the Geoeode Status is OK before proceeding
      if (status === window.google.maps.GeocoderStatus.OK) {
        var address = results[0].formatted_address;
        // setAddress(address); //inputbox
        // sendingData.pickup_address = address;
        if (pubName) {
          sendingData.pickup_address = pubName;
          fareParams.pickup_address = pubName;
          setValue(pubName);
        } else {
          sendingData.pickup_address = address;
          fareParams.pickup_address = address;
          setValue(address);
        }
      }
    });
  }
  useEffect(() => {
    if (!lat === true || !lng === true) {
      pageOpenObj.client = "website";
      pageOpenObj.pickup_zone = "N/A";
      pageOpenObj.city = "N/A";
      pageOpenObj.nearby_driver_eta = "N/A";
      clevertap.event.push("page_opened", pageOpenObj);
    } else {
      axios
        .get(`${BASE_URL}/web/nearest-driver/?latitude=${lat}&longitude=${lng}`)
        .then(({ data }) => {
          pageOpenObj.pickup_zone = data.zone_name || "N/A";
          pageOpenObj.city = data.city || "N/A";
          pageOpenObj.nearby_driver_eta = data.show_eta || "N/A";
          pageOpenObj.client = "mygate";
          clevertap.event.push("page_opened", pageOpenObj);
          setShowETA(data.show_eta);
          setEtaOneWayTime(data.eta);
          if (data.message !== "") {
            setValue("");
            setShowError(true);
            setTimeout(() => {
              setShowError(false);
            }, [1000]);
          } else if (data.message === "") {
            setFirstCityId(data.city_id);
            getReverseGeocodingData(lat, lng);
            setCityEtaDetails({ ...data });
            if (!secondCityId === true) {
              setShowError(false);
            } else if (data.city_id !== secondCityId) {
              setPickUpError(true);
              setValue("");
              setTimeout(() => {
                setPickUpError(false);
              }, 2000);
            }
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
  }, [lat, lng]);
  return (
    <>
      <div className="location" ref={ref} onChange={oneWayHandleInput}>
        <div className="first_Input_Tag_Div">
          <input
            style={{ border: showError ? "" : "none" }}
            className="location first_Input_Tag"
            name="PickUpLocation"
            autoComplete="off"
            type="text"
            value={value}
            onChange={handleChange}
            placeholder="Enter 4 letters to Search PickUp Location"
          />
          <div
            style={{ display: toggleEmptyInput ? "" : "none" }}
            className="empty_input_div"
            onClick={() => {
              setValue("");
              sendingData.pickup_address = "";
              fareParams.pickup_address = "";
              setFirstCityId("");
              setShowETA(null);
              setShowWhen([{ label: "Now", id: "#F0F8FF" }]);
              LocationClearedObj.client = myGateToken ? "mygate" : "website";
              LocationClearedObj.location_type = "pickup";
              LocationClearedObj.pickup_zone = cityEtaDetails.zone_name;
              LocationClearedObj.city = cityEtaDetails.city;
              clevertap.event.push("location_cleared", LocationClearedObj);
            }}
          >
            <MdClose />
          </div>
        </div>

        {oneWaytext >= 4
          ? status === "OK" && (
              <div
                style={{ width: "92%" }}
                className="debounceBox"
                id="debounceBox"
              >
                {oneWayrenderSuggestions()}
                {/* {oneWayRenderSuggestions()} */}
              </div>
            )
          : null}
      </div>
      <div
        style={{
          display: showError ? "" : "none",
          color: "#127e40",

          marginBottom: "0.5rem",
          fontfamily: "Circular-Std-Book",
        }}
      >
        Sorry, service not available
      </div>
      <div
        style={{
          display: pickUpError ? "" : "none",
          color: "#127e40",
          marginBottom: "0.5rem",
          fontSize: "14px",
          fontfamily: "Circular-Std-Book",
        }}
      >
        Pick up and drop address must be in the same city.
      </div>
    </>
  );
}

export default OneWayInput;
