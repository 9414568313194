import React, { useContext, useEffect, useState } from "react";
import { carServiceContext } from "../../../context/CarServiceContextProvider";
import { ANCHOR, Drawer } from "baseui/drawer";
import axios from "axios";
import { SIZE } from "baseui/input";
import { useRef } from "react";

// userid pickup_latitude pickup_longitude pickup_datetime
function PromoModal() {
  const inputRef = useRef(null);
  const {
    isnewPromoModalOpen,
    closeNewPromoModal,
    promoCode,
    setPromoCode,
    showSuccessMessage,
    setShowSuccessMessage,
    carServiceData,
    setCouponCodeToggle,
    setCarServicePromoData,
  } = useContext(carServiceContext);

  const [loading, setLoading] = useState(true);
  const [successPromoMessage, setSuccessPromoMessage] = useState("");
  const [showErrorColor, setShowErrorColor] = useState(true);
  //   const BASE_URL_HULK = process.env.REACT_APP_HULK_URL;
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [coupanData, setCoupanData] = useState([]);
  const shimmerElements = Array(5).fill(0);

  //  this is used to fetching the all Coupan Data
  const fetchCoupanData = () => {
    //  this is same as car Wash
    const epoch_time = carServiceData.slot_time;
    const dateObj = new Date(epoch_time);

    const day = dateObj.getDate();
    const month = dateObj.getMonth() + 1;
    const year = dateObj.getFullYear();
    const hours = dateObj.getHours();
    const minutes = dateObj.getMinutes();

    const formattedTime = `${day}/${month}/${year} ${hours}:${minutes}`;

    axios({
      method: "GET",
      //   baseURL: BASE_URL_HULK,
      // url:`https://apps.driveu.in/discount/promo-codes/?user_id=8b2cf815-acdd-468a-8355-02d5b80d4fa0&category=booking&latitude=12.981996&longitude=77.610084&booking_type=12hr&date_and_time=10/09/2022%2010:00`,
      url: `https://apps.driveu.in/discount/promo-codes/?user_id=${carServiceData.user_id}&category=pitstop&latitude=${carServiceData.latitude}&longitude=${carServiceData.longitude}&date_and_time=${formattedTime}`,
      withCredentials: false,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(({ data }) => {
        if (data.status === "success") {
          setCoupanData(data.data);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err.message);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchCoupanData();
  }, []);

  const hadlePromoText = (e) => {
    setPromoCode(e.target.value);
  };

  //  this is used as apply promo code
  const handleFillPromoCode = (coupan) => () => {
    //  fromWhere used for the modal open or not
    setPromoCode(coupan);
    axios({
      method: "GET",
      url: `${BASE_URL}/discount/validate-promo-code/?category=pitstop&promo_code=${coupan}&user_id=${carServiceData.user_id}`,
      // url: `${BASE_URL}/discount/validate-promo-code/?category=&promo_code=${promoCode}&user_id=`,
      withCredentials: false,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then(({ data }) => {
        setShowSuccessMessage(true);
        setSuccessPromoMessage(data.message);
        setCarServicePromoData(data);
        if (data.status === "success") {
          carServiceData.promo_code = coupan;
          setCouponCodeToggle(true);
          closeNewPromoModal();
        } else {
          setShowErrorColor(false);
        }
      })
      .catch((err) => {
        console.log(err.message);
        setShowErrorColor(false);
      });
  };
  const handleClick = () => {
    inputRef.current.inputMode = "text";
  };

  return (
    <Drawer
      isOpen={isnewPromoModalOpen}
      onClose={closeNewPromoModal}
      anchor={ANCHOR.bottom}
      size={SIZE.auto}
      overrides={{
        DrawerBody: {
          style: ({ $theme }) => ({
            marginBottom: "5px",
            marginLeft: "20px",
            marginRight: "20px",
          }),
        },
        DrawerContainer: {
          style: ({ $theme }) => ({
            height: "80%",
          }),
        },
      }}
    >
      {loading ? (
        <div>
          <div className="stickyPromoCode">
            <div className="promoCodeBottomSheet">
              <div className="shimmer"></div>
              <div className="promoBottomSheetText shimmer"></div>
            </div>
            <div>
              <div className="Coupon">
                <div className="inputCouponCode">
                  <input
                    id="couponDiv"
                    className="couponDetails couponCodeBottomSheetInput"
                    type="text"
                    placeholder="Enter a Coupon Code"
                    autoComplete="off"
                    inputMode="none"
                  />
                  <button id="couponBtn">Apply</button>
                </div>
              </div>
            </div>
          </div>
          <>
            {shimmerElements.map((_, index) => {
              return (
                <div className="bottomCouponContainer" key={index}>
                  <div className="bottomCouponWrapper">
                    <div className="firstBlockCouponWrapper shimmer">
                      <div className="couponCodeBottomSheet"></div>
                      <div className="firstF1 shimmer"></div>
                    </div>
                    <div className="secondBlockCouponWrapper shimmer">
                      <div className="bottomS1 shimmer"></div>
                      <div className="bottomS2 shimmer"></div>
                      <div className="bottomS3 shimmer"></div>
                    </div>
                    <div className="thirdBlockCouponWrapper shimmer">
                      <div className="thirdT1 shimmer"></div>
                      <ul className="thirdT2 shimmer">
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                      </ul>
                    </div>
                    <div className="fourthBlockCouponWrapper shimmer"></div>
                    <div className="fiveT1 shimmer"></div>
                  </div>
                </div>
              );
            })}
          </>
        </div>
      ) : (
        <div>
          <div className="stickyPromoCode">
            <div className="promoCodeBottomSheet">
              <div>Apply Promocode</div>
              <div className="promoBottomSheetText">
                Enter a code below or select from the list
              </div>
            </div>
            <div>
              <div className="Coupon">
                <div onClick={handleClick} className="inputCouponCode">
                  <input
                    id="couponDiv"
                    className="couponDetails couponCodeBottomSheetInput"
                    type="text"
                    ref={inputRef}
                    value={promoCode}
                    onChange={hadlePromoText}
                    placeholder="Enter promo code"
                    inputMode="none"
                    autoComplete="off"
                  />

                  <button
                    onClick={handleFillPromoCode(promoCode)}
                    id="couponBtn"
                    style={{
                      color: promoCode.length !== 0 ? "#137e40" : "",
                      fontWeight: "500",
                    }}
                  >
                    Apply
                  </button>
                </div>
                <div
                  style={{
                    display: showSuccessMessage ? "" : "none",
                    color: showErrorColor ? "green" : "red",
                    fontfamily: "Circular-Std-Book",
                  }}
                >
                  {successPromoMessage}
                </div>
              </div>
            </div>
          </div>

          <>
            {coupanData.map((elem, index) => {
              return (
                <div className="bottomCouponContainer" key={index}>
                  <div className="bottomCouponWrapper">
                    <div className="firstBlockCouponWrapper">
                      <div className="couponCodeBottomSheet" id="getCouponCode">
                        {elem.promo_code}
                      </div>
                      <button
                        className="firstF1PopUp"
                        style={{
                          color:
                            elem.is_applicable === false ? "grey" : "#137e40",
                        }}
                        disabled={elem.is_applicable ? false : true}
                        onClick={handleFillPromoCode(elem.promo_code)}
                      >
                        APPLY
                      </button>
                    </div>
                    <div className="secondBlockCouponWrapper">
                      <div className="bottomS1">
                        <img
                          src={elem.logo_url}
                          alt="Offer Logo"
                          width="100%"
                          height="100%"
                        />
                      </div>
                      <div className="bottomS2">{elem.title}</div>
                      <div className="bottomS3">{elem.description}</div>
                    </div>
                    <div className="thirdBlockCouponWrapper">
                      <div className="thirdT1">Terms and conditions</div>
                      <ul className="thirdT2">
                        {elem.offer_terms.map((termsData, index) => {
                          return <li key={index}>&#x2022;&nbsp;{termsData}</li>;
                        })}
                      </ul>
                    </div>
                    <div className="fourthBlockCouponWrapper">
                      {elem.validity_text}
                    </div>
                    <div className="fiveT1"></div>
                  </div>
                </div>
              );
            })}
          </>
        </div>
      )}
    </Drawer>
  );
}

export default PromoModal;
