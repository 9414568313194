import React, { useContext, useState } from "react";
import dateStyle from "./dateStyle.module.css";
import packageStyle from "../../DailyBooking.module.css";
import "react-datepicker/dist/react-datepicker.css";
import { format, parse } from "date-fns";
import { BookingFlowDataContext } from "../../../context/BookingFlowDataContextProvider";
import { Button } from "baseui/button";
import CalenderBox from "./CalenderBox/CalenderBox";
import { ApplicationContext } from "../../../context/ApplicationContextProvider";
import DailyDate from "./DailyDate/DailyDate";
import axios from "axios";
import { BookingFlowParaContext } from "../../../context/BookingFlowParaProvider";
import { toast } from "react-hot-toast";
import {
  continueReviewDailyCTA,
  dailyFinalReviewDailyCTA,
  dailyPageOpen,
} from "../../../utils/EventObject";
import clevertap from "clevertap-web-sdk";
import useOnlineStatus from "../../../CustomHooks/useOnlineStatus";
// Same as Du Round Trip

function CustomDatePicker({ setIsOpenCalender }) {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const {
    sendingDailyBookingDataOneWay,
    trackPickUpDateTimeArrOneWay,
  } = useContext(BookingFlowDataContext);
  const {
    mappedDatesOneWay,
    setShowPaymentBoxOneWay,
    duDailyParams,
  } = useContext(ApplicationContext);
  const { setDailyFareData } = useContext(BookingFlowParaContext);
  const [isFareLoading, setIsFareLoading] = useState(false);
  const isOnline = useOnlineStatus();
  // CALCULATION
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);
  const futureDate = new Date(tomorrow);
  futureDate.setDate(tomorrow.getDate() + 30); // Get the date 30 days from today

  // Function to parse a date string in the format dd/mm/yyyy hh:mm
  const dateStrings = trackPickUpDateTimeArrOneWay;

  function parseDate(dateString) {
    const [datePart, timePart] = dateString.split(" ");
    const [day, month, year] = datePart.split("/").map(Number);
    const [hour, minute] = timePart.split(":").map(Number);
    return new Date(year, month - 1, day, hour, minute); // Month is 0-indexed
  }

  // Parse and sort the Date objects
  const sortedDates = dateStrings.map(parseDate).sort((a, b) => a - b);

  // Function to format a Date object back into the desired format
  function formatDate(date) {
    const day = date
      .getDate()
      .toString()
      .padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-indexed
    const year = date.getFullYear();
    const hour = date
      .getHours()
      .toString()
      .padStart(2, "0");
    const minute = date
      .getMinutes()
      .toString()
      .padStart(2, "0");
    return `${day}/${month}/${year} ${hour}:${minute}`;
  }

  // Format the sorted Date objects back into the desired format
  const sortedDateStrings = sortedDates.map(formatDate);

  // Output the sorted date strings

  const handleGetDailyFareDetails = () => {
    sendingDailyBookingDataOneWay.user_id = duDailyParams.user_id;
    continueReviewDailyCTA["start date"] = [...mappedDatesOneWay][0];
    continueReviewDailyCTA["pickup dates"] = [...mappedDatesOneWay].join(",");
    dailyFinalReviewDailyCTA["start date"] = [...mappedDatesOneWay][0];
    dailyFinalReviewDailyCTA["pickup dates"] = [...mappedDatesOneWay].join(",");
    dailyFinalReviewDailyCTA.src = duDailyParams.src;
    clevertap.event.push(
      "Continue to review CTA clicked",
      continueReviewDailyCTA
    );
    setIsFareLoading(true);
    axios({
      method: "GET",
      baseURL: BASE_URL,
      url: `${BASE_URL}/web/fare-estimate-du-daily`,
      params: {
        city_id: sendingDailyBookingDataOneWay.city,
        service_type: sendingDailyBookingDataOneWay.service_type,
        pickup_datetime: sortedDateStrings.join(","),
        user_id: duDailyParams.user_id,
        car_type: sendingDailyBookingDataOneWay.car_type,
        booking_type: sendingDailyBookingDataOneWay.booking_type,
        is_insured: sendingDailyBookingDataOneWay.is_insured,
        pickup_latitude: sendingDailyBookingDataOneWay.pickup_latitude,
        pickup_longitude: sendingDailyBookingDataOneWay.pickup_longitude,
        drop_latitude: sendingDailyBookingDataOneWay.drop_latitude,
        drop_longitude: sendingDailyBookingDataOneWay.drop_longitude,
      },
      withCredentials: false,
      headers: {
        "Content-Type": `application/json`,
      },
    })
      .then(({ data }) => {
        if (data.status === "success") {
          setDailyFareData(data);
          setIsOpenCalender(false);
          setShowPaymentBoxOneWay(true);
          dailyFinalReviewDailyCTA["Fare Estimate"] = data.total_cost;
          dailyFinalReviewDailyCTA["Token Advance"] = data.advance_to_be_paid;
          dailyPageOpen.page = "form_2";
          clevertap.event.push("Daily page opened", dailyPageOpen);
          clevertap.event.push("Daily review opened", dailyFinalReviewDailyCTA);
        } else if (data.status === "error") {
          toast.error(data.message);
        } else {
          toast.error("No Internet Connection");
        }
        setIsFareLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
        toast.error(err.message);
        setIsFareLoading(false);
      });
  };

  return (
    <div className={packageStyle.dateBottomSheet}>
      <div>
        <>
          <div style={{ color: "#000", fontWeight: "600" }}>
            When would you like to start your DriveU Daily?
          </div>
          <div className={dateStyle.dateLangDiv}>
            <label htmlFor="pick up date">
              Select start date <sup>*</sup>
            </label>

            <CalenderBox />
          </div>
        </>
        <div>
          <p
            style={{ color: "#6b6b76", fontWeight: "450", fontSize: "0.75rem" }}
          >
            {sortedDateStrings.length === 0
              ? "You can flexibly choose any 3 to 7 dates in a week based on your requirement after date selection"
              : `You can select any 3 to 7 days of the week starting from ${format(
                  parse(
                    sortedDateStrings[0].split(" ")[0],
                    "dd/MM/yyyy",
                    new Date()
                  ),
                  "dd MMM yyyy"
                )}. Consecutive or staggered welcome.`}
          </p>
          <DailyDate />
        </div>
      </div>

      <div className={packageStyle.dateBtnSheet}>
        <div>
          {3 - mappedDatesOneWay.size <= 0 ? (
            <p style={{ color: "green" }}>
              You’re one step closer to traffic tranquility!
            </p>
          ) : (
            <p style={{ color: "red" }}>
              Oops, you need to select at least {3 - mappedDatesOneWay.size}{" "}
              days!
            </p>
          )}
        </div>
        {isFareLoading ? (
          <Button style={{ width: "100%" }} isLoading>
            Continue to Review & Pay
          </Button>
        ) : (
          <Button
            style={{ width: "100%" }}
            disabled={mappedDatesOneWay.size <= 2 || !isOnline}
            onClick={handleGetDailyFareDetails}
          >
            Continue to Review & Pay
          </Button>
        )}
      </div>
    </div>
  );
}

export default CustomDatePicker;
