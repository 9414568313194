/**
 * `featuresObject` is an array of objects where each object represents a feature to be displayed in the UI.
 * It is used within React components to dynamically render a list of features, each with a corresponding image and description.
 *
 * Structure of each object in the array:
 * - `id` (number): A unique identifier for the feature.
 * - `content` (string): A text description of the feature.
 * - `image` (React component): A React component that renders an image associated with the feature.
 *
 * The images (`Calender`, `Watch`, `DailyTick`) are imported from the `ImportPng` file, which should be located
 * in the `Components/AllImportFiles` directory. These components use PNG images to visually represent each feature.
 *
 * Example usage within a React component:
 * ```
 * {featuresObject.map((feature) => (
 *   <div key={feature.id}>
 *     <feature.image />
 *     <p>{feature.content}</p>
 *   </div>
 * ))}
 * ```
 *
 * This example demonstrates how to iterate over the `featuresObject` to render the feature image and description
 * in a React component. Ensure the `ImportPng` file correctly exports the `Calender`, `DailyTick`, and `Watch` components
 * for this to work as expected.
 */

import {
  Calender,
  DailyTick,
  Watch,
} from "../../Components/AllImportFiles/ImportPng";

export const featuresObject = [
  {
    id: 1,
    content: "Get the same driver with preferred language every day!",
    image: Calender,
  },
  {
    id: 2,
    content: "Zero Cancellations!",
    image: Watch,
  },
  {
    id: 3,
    content: "Book for as little as 3 days and 4hrs/day!",
    image: DailyTick,
  },
];
