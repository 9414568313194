import React from "react";
import "./GurgaonContent.css";
import { Link } from "react-router-dom";
function GurgaonContent() {
  return (
    <div className="Hyderabad_Content_main_div">
      <div className="Hyderabad_Content_main_div_Heading">
        DriveU in Gurgaon
      </div>
      <div className="Hyderabad_Content_main_div_content">
        he convenience and the luxury of having your own personal driver just a
        few taps away, DriveU brings you’re their professional chauffeur service
        which lets you hire drivers in Delhi on an hourly basis. Choose from
        DriveU’s fleet of trusted, verified and trained drivers at rates
        starting from just Rs.129/hour.{" "}
        <Link to="/" style={{ color: "green" }}>
          DriveU
        </Link>{" "}
        is the perfect solution to a range of your traveling issues. Whether it
        is the daily commute to your work or a{" "}
        <Link to="/drivers-for-weekend" style={{ color: "green" }}>
          weekend getaways
        </Link>{" "}
        , DriveU allows you to sit back and relax, while our drivers make you
        reach your destination safely. Our{" "}
        <Link to="/drivers-for-night" style={{ color: "green" }}>
          night time drivers
        </Link>{" "}
        for late nights, letting you enjoy with the peace of mind of reaching
        back home safely. DriveU realizes the importance of this by providing a
        quick, reliable and easy way to hire drivers in Gurgaon.
      </div>
    </div>
  );
}

export default GurgaonContent;
