import React, { useContext, useEffect } from "react";
import ImageScroll from "../../DriveUDaily/ImageScroll/ImageScroll";
import DailyContent from "../../DriveUDaily/DailyContent/DailyContent";
import clevertap from "clevertap-web-sdk";
import { ApplicationContext } from "../../context/ApplicationContextProvider";
import { dailyPageOpen } from "../../utils/EventObject";
const DriveULandingPage = () => {
  const { duDailyParams } = useContext(ApplicationContext);

  useEffect(() => {
    if (!duDailyParams.user_id === true) {
    } else {
      clevertap.profile.push({
        Site: {
          Name: duDailyParams.name,
          Identity: duDailyParams.mobile, // String or number
          Email: duDailyParams.email, // Email address of the user
          Phone: `+91${duDailyParams.mobile}`, // Phone (with the country code)
        },
      });
      dailyPageOpen.src = duDailyParams.src;
      dailyPageOpen.page = "home";
      clevertap.event.push("Daily page opened", dailyPageOpen);
    }
  }, [duDailyParams]);

  return (
    <>
      <ImageScroll />
      <DailyContent />
    </>
  );
};

export default DriveULandingPage;
