import React, { useContext, useEffect, useState } from "react";
import "./NavbarBooking.css";
import { GiHamburgerMenu } from "react-icons/gi";
import { Link } from "react-router-dom";
import { SIZE } from "baseui/select";
import { HamburgerContext } from "../../context/HamburgerContextProvider";
import { DriveULogo } from "../../Components/AllImportFiles/ImportSvg";
import { Avatar } from "baseui/avatar";
import { expandBorderStyles } from "baseui/styles";
import { Block } from "baseui/block";
import { Drawer, ANCHOR } from "baseui/drawer";
import HamburgurMenu from "../HamburgerBooking/HamburgurMenu";
import { StatefulPopover, TRIGGER_TYPE } from "baseui/popover";
import { Button } from "baseui/button";
import { useCookies } from "react-cookie";
import { DownloadContext } from "../../context/DownloadContextProvider";
import RegistrationForm from "../../Components/Home/PopUpWindow/RegistrationForm/RegistrationForm";
import { BookingFlowParaContext } from "../../context/BookingFlowParaProvider";
import axios from "axios";
import { BookingFlowDataContext } from "../../context/BookingFlowDataContextProvider";
function NavbarBooking() {
  // const navigate = useNavigate();
  const [screenSize, getDimension] = useState({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  });
  const URL = window.location.href.split("/");
  const confirm = URL.includes("my-drives");
  const [stickyClass, setStickyClass] = useState("");
  const { handleHamburger } = useContext(HamburgerContext);
  const [isOpen, setIsOpen] = React.useState(false);
  const { showPopUp, setShowPopUp, setPreviousChoice } = useContext(
    DownloadContext
  );
  const { userInfo } = useContext(BookingFlowDataContext);
  const { isAuthenticated, userName } = useContext(BookingFlowParaContext);
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [cookies, setCookie, removeCookie] = useCookies();
  const handleLoginUser = () => {
    if (
      cookies.user_id === undefined ||
      userInfo.is_authenticated === "" ||
      cookies.user_id === null
    ) {
      setShowPopUp(true);
      setPreviousChoice("NavbarSignUp");
    }
  };
  const handleLogOutUser = () => {
    removeCookie("userid", [
      "/",
      "/my-drives",
      "/book-driver",
      "/my-drives/",
      "/book-driver/",
    ]);
    axios({
      method: "GET",
      baseURL: BASE_URL,
      url: `${BASE_URL}/web/logout/`,
      withCredentials: false,
      headers: {
        "Content-Type": `application/json`,
      },
    })

      .then(({ data }) => {
        // navigate("/");
        setCookie("userid", "", { path: "/" });
        setCookie("sdk_initialize", false, { path: "/" });
        window.location.href = "/";
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      windowHeight > 10 ? setStickyClass("sticky-navBook") : setStickyClass("");
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", stickNavbar);
    return () => window.removeEventListener("scroll", stickNavbar);
  }, []);
  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", setDimension);

    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);
  return (
    <div style={{ borderBottom: "1px solid #e5e5e5" }}>
      <nav className={`nav-booking-flow-wrapper ${stickyClass}`}>
        <div className="booking-block-search">
          <div className="ham-close-menu-booking" onClick={handleHamburger}>
            <GiHamburgerMenu
              className="HamburgerIcon"
              onClick={() => setIsOpen(true)}
            />
            {screenSize.dynamicWidth > 767 ? (
              <Drawer
                onClose={() => setIsOpen(false)}
                isOpen={isOpen}
                anchor={ANCHOR.left}
                overrides={{
                  Close: {
                    style: ({ $theme }) => ({
                      width: "40px",
                      height: "40px",
                    }),
                  },
                }}
              >
                <div className="ham-menu-booking">
                  <HamburgurMenu />
                </div>
              </Drawer>
            ) : (
              <Drawer
                onClose={() => setIsOpen(false)}
                isOpen={isOpen}
                anchor={ANCHOR.left}
                overrides={{
                  Close: {
                    style: ({ $theme }) => ({
                      width: "40px",
                      height: "40px",
                    }),
                  },
                  DrawerContainer: {
                    style: ({ $theme }) => ({
                      outline: "none",
                      width: "80%",
                    }),
                  },
                }}
              >
                <div className="ham-menu-booking">
                  <HamburgurMenu />
                </div>
              </Drawer>
            )}
          </div>
        </div>
        <div className="driveu-logo-booking">
          <Link to="/">
            <DriveULogo className="main-logo-booking" />
          </Link>
        </div>

        <div id="booking-login-block">
          <StatefulPopover
            content={() =>
              isAuthenticated === true ? (
                <Block
                  overrides={{
                    Block: {
                      style: {
                        width: "200px",
                        height: "120px",
                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: "center",
                        flexDirection: "column",
                        backgroundColor: "#ffffff",
                        fontFamily: "Circular-Std-Book",
                      },
                    },
                  }}
                >
                  <Link
                    // to="/my-drives"
                    to={confirm ? "/book-driver/" : "/my-drives"}
                    className="hover-my-drives"
                    style={{ color: "#666" }}
                  >
                    {confirm ? "Book Driver" : "My Drives"}
                  </Link>
                  <a
                    href="/driveu-help"
                    style={{ color: "#666" }}
                    className="hover-logout"
                  >
                    Help
                  </a>
                  <div
                    style={{ color: "#666" }}
                    className="hover-logout"
                    onClick={handleLogOutUser}
                  >
                    Logout
                  </div>
                </Block>
              ) : null
            }
            triggerType={TRIGGER_TYPE.hover}
            returnFocus
            autoFocus
          >
            <div className="booking-login-avatar">
              {isAuthenticated === true ? (
                <Avatar
                  overrides={{
                    Root: {
                      style: ({ $theme }) => ({
                        ...expandBorderStyles($theme.borders.border500),
                      }),
                    },
                  }}
                  name={userName.trimLeft()}
                  size="scale1000"
                />
              ) : null}
              {isAuthenticated === false ? (
                <Button
                  overrides={{
                    BaseButton: {
                      style: ({ $theme }) => ({
                        outline: "none",
                        backgroundColor: $theme.colors.buttonPrimaryFill,
                        fontSize: "14px",
                      }),
                    },
                  }}
                  onClick={handleLoginUser}
                  size={SIZE.mini}
                >
                  Sign Up
                </Button>
              ) : null}
            </div>
          </StatefulPopover>
        </div>
      </nav>
      {showPopUp ? <div className="overlay" /> : null}
      {showPopUp ? <RegistrationForm className="form_number" /> : null}
    </div>
  );
}

export default NavbarBooking;
