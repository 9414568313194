import React, { createContext, useState } from "react";
export const FastagContext = createContext();
function FastagContextProvider({ children }) {
  const [showRecharge, setShowRecharge] = useState(false);
  const [sendLink, setSendLink] = useState("");
  const [cloudUserId, setCloudUserId] = useState("");
  const handleCloseFastag = () => {
    setShowRecharge(false);
  };
  const compareWidth = window.innerWidth;
  return (
    <FastagContext.Provider
      value={{
        showRecharge,
        setSendLink,
        sendLink,
        setShowRecharge,
        handleCloseFastag,
        setCloudUserId,
        cloudUserId,
        compareWidth,
      }}
    >
      {children}
    </FastagContext.Provider>
  );
}
export default FastagContextProvider;
