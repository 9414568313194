import {
  CHANGE_VIEW_MODAL,
  GET_OTP_REQUEST,
  GET_OTP_REQUEST_SUCCESS,
  GET_OTP_SUCCESS,
  GET_VERIFY_REQUEST,
  GET_VERIFY_REQUEST_SUCCESS,
} from "./actiontypes";

let initState = {
  isLoading: false,
  mobile: "",
  otp_code: "",
  viewModal: "mobile",
};

export const otpReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case GET_OTP_REQUEST: {
      return {
        ...state,
        isLoading: true,
        mobile: payload,
      };
    }
    case GET_OTP_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        mobile: payload,
        viewModal: "otp",
      };
    }
    case GET_OTP_REQUEST_SUCCESS: {
      return {
        ...state,
      };
    }
    case GET_VERIFY_REQUEST: {
      return {
        ...state,
        otp_code: payload,
      };
    }
    case GET_VERIFY_REQUEST_SUCCESS: {
      return {
        ...state,
        viewModal: "email",
      };
    }
    case CHANGE_VIEW_MODAL: {
      return {
        ...state,
        viewModal: payload,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};
