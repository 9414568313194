import React from "react";
import { ApplicationContext } from "../../context/ApplicationContextProvider";
import BookingPage from "../Bookingpage/Bookingpage";
import NavbarBooking from "../NavbarBooking/NavbarBooking";
function BookingBlock() {
  const { myGateToken, msilTokenData } = React.useContext(ApplicationContext);
  const pathname = window.location.pathname;
  
  return (
    <>
      {myGateToken === "" &&
      pathname === "/book-driver/" ? null : !myGateToken === true ? (
        <>
          <NavbarBooking />
          <BookingPage />
        </>
      ) : (
        <BookingPage />
      )}
    </>
  );
}

export default BookingBlock;
