// React and Hooks Import
import React, { useContext, useState } from "react";

// BaseUI Components Import
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
  SIZE,
  ROLE,
} from "baseui/modal";
import { Checkbox, LABEL_PLACEMENT } from "baseui/checkbox";
import { Textarea } from "baseui/textarea";
import { useStyletron } from "baseui";

// Context Import
import { ApplicationContext } from "../../../context/ApplicationContextProvider";

// Other Libraries Import
import axios from "axios";

const CancelBookingModal = ({ cancelBookingData, handleTrackData }) => {
  /*
  cancelBookingData(Order Data) and handleTrackData(To Call again function to tracking order) are Props
  */

  const BASE_URL = process.env.REACT_APP_BASE_URL;

  // State variable
  const [selectedDates, setSelectedDates] = useState({
    order_id: "",
    booking_ids: [],
    comments: "",
  });
  const [commentError, setCommentError] = useState(false);
  const [cancelLoading, setCancelLoading] = useState(false);

  //context
  const { setIsOpenCancelModal, isOpenCancelModal, appUserID } = useContext(
    ApplicationContext
  );
  // Styling through css
  const [css] = useStyletron();

  /*
  1. handleCheckboxChange => This Function takes event and data as parameter and 
  set state selectedDates and push each booking id into array for cancelling

  2. handleCancelComments => Comments is required when user wants to cancel and it is needed for cancel booking api

  3. sendSelectedDatesToBackend => This Function handles API Request for cancelling Booking
  */
  const handleCheckboxChange = (event, date) => {
    const { checked } = event.target;

    setSelectedDates((prevSelected) => {
      if (checked) {
        return {
          ...prevSelected,
          booking_ids: [...prevSelected.booking_ids, date],
        };
      } else {
        return {
          ...prevSelected,
          booking_ids: prevSelected.booking_ids.filter(
            (selectedDate) => selectedDate !== date
          ),
        };
      }
    });
  };

  const handleCancelComments = (e) => {
    setCommentError(false);
    const val = e.currentTarget.value.trimStart();
    setSelectedDates((prevSelected) => ({ ...prevSelected, comments: val }));
  };

  const sendSelectedDatesToBackend = () => {
    selectedDates.order_id = cancelBookingData.order_id;
    if (!selectedDates.comments === true) {
      setCommentError(true);
    } else {
      setCancelLoading(true);
      axios({
        method: "POST",
        baseURL: BASE_URL,
        url: `${BASE_URL}/web/cancel-du-daily-bookings/?user_id=${appUserID}`,
        data: selectedDates,
        withCredentials: false,
        headers: {
          "Content-Type": `application/json`,
        },
      })
        .then(({ data }) => {
          if (data.status === "success") {
            setIsOpenCancelModal(false);
            handleTrackData(); // to rerender/update state after Post Request
            setCancelLoading(false);
          } else {
            alert(data.message);
            console.log(data.message);
            setCancelLoading(false);
          }
        })
        .catch((err) => {
          console.log(err.message);
          setCancelLoading(false);
        });
    }
  };

  return (
    <Modal
      onClose={() => setIsOpenCancelModal(false)}
      closeable
      isOpen={isOpenCancelModal}
      animate
      autoFocus
      size={SIZE.default}
      role={ROLE.dialog}
    >
      <ModalHeader>DriveU Daily </ModalHeader>
      <ModalBody>
        <div>
          {cancelBookingData.booking_type_display}, Please choose dates for
          cancellations. Cancellation charges will be deduced as applicable.
        </div>
        &nbsp;
        <div style={{ width: "30%" }}>
          {cancelBookingData.upcoming_bookings.map((elem, idx) => (
            <Checkbox
              checked={selectedDates.booking_ids.includes(elem.booking_id)}
              onChange={(e) => handleCheckboxChange(e, elem.booking_id)}
              labelPlacement={LABEL_PLACEMENT.right}
              key={idx}
            >
              {elem.date}
            </Checkbox>
          ))}
        </div>
        <div
          className={css({
            marginTop: "0.5rem",
          })}
        >
          {!selectedDates.booking_ids.length === true ? null : (
            <>
              <strong>Please share cancellation reason</strong>
              <Textarea
                value={selectedDates.comments}
                onChange={handleCancelComments}
                size={SIZE.mini}
                placeholder="Write your reason here"
                clearOnEscape
                overrides={{
                  InputContainer: {
                    style: ({ $theme }) => ({
                      backgroundColor: "white",
                      height: "80px",
                    }),
                  },
                }}
              />
              {commentError && (
                <div style={{ marginTop: "8px", color: "red" }}>
                  cancellation reason is required
                </div>
              )}
            </>
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        {cancelLoading ? (
          <ModalButton isLoading>Cancel Selected Bookings</ModalButton>
        ) : (
          <ModalButton
            onClick={sendSelectedDatesToBackend}
            disabled={selectedDates.booking_ids.length === 0}
          >
            Cancel Selected Bookings
          </ModalButton>
        )}
      </ModalFooter>
    </Modal>
  );
};
export default CancelBookingModal;
