import axios from "axios";
import React, { useContext } from "react";
import { useState } from "react";
import { BlackDot, GreenDot } from "../../Components/AllImportFiles/ImportSvg";
import { BookingFlowDataContext } from "../../context/BookingFlowDataContextProvider";
import DriverDetails from "../DriverDetails/DriverDetails";
import { Link } from "react-router-dom";
import { useCookies } from "react-cookie";
import "../OrderStatusPage/OrderStatusPage.css"
import { useEffect } from "react";
import CancelReasonBlock from "../CancelReasonBlock/CancelReasonBlock";
import { Modal, ModalBody, ModalHeader } from "baseui/modal";
import RescheduleBlock from "../RescheduleBlock/RescheduleBlock";
function MsilOrderStatusPage() {
  const [activeOrder, setActiveOrder] = useState([]);
  const [pastOrder, setPastOrder] = useState([]);
  const [cookiesUser] = useCookies();
  const [optionBooking, setOptionBooking] = useState(false);
  const [showReschedulePopUp, setShowReschedulePopUp] = useState(false);
  const [showDriverDetailsPopUp, setShowDriverDetailsPopUp] = useState(false);

  const {
    detailsData,
    getBookingId,
    setGetBookingId,
    setDetailsResponse,
    setTrackDriverScreen,
  } = useContext(BookingFlowDataContext);
  const [showPastOrderHeading, setShowPastOrderHeading] = useState(false);
  const [showActiveOrderHeading, setShowActiveOrderHeading] = useState("");
  const [showCancelReasonBlock, setShowCancelReasonBlock] = useState(false);
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const BASE_URL_HULK = process.env.REACT_APP_HULK_URL;
  let one = `${BASE_URL_HULK}/app/active-orders/?category=rides&user_id=${cookiesUser.userid}`;
  let two = `${BASE_URL_HULK}/app/past-orders/?category=rides&user_id=${cookiesUser.userid}`;

  const handleCloseDriverDetailsPopUp = () => {
    setShowDriverDetailsPopUp(false);
  };

  const showHistory = () => {
    const requestOne = axios({
      method: "GET",
      baseURL: BASE_URL,
      url: one,
      withCredentials: false,
      headers: {
        "Content-Type": `application/json`,
      },
    });
    const requestTwo = axios({
      method: "GET",
      baseURL: BASE_URL,
      url: two,
      withCredentials: false,
      headers: {
        "Content-Type": `application/json`,
      },
    });
    // const requestTwo = axios.get(two);
    Promise.all([requestOne, requestTwo])
      .then(
        axios.spread((...responses) => {
          let firstApi = responses[0].data.orders;
          let secondApi = responses[1].data.orders;
          setActiveOrder(firstApi);
          setPastOrder(secondApi);

          responses[0].data.orders.length === 0
            ? setShowActiveOrderHeading(false)
            : setShowActiveOrderHeading(true);

          responses[1].data.orders.length === 0
            ? setShowPastOrderHeading(false)
            : setShowPastOrderHeading(true);
        })
      )
      .catch((errors) => {
        console.log(errors);
      });
  };
  useEffect(() => {
    showHistory();

    setInterval(() => {
      showHistory();
    }, 30000);
  }, []);

  const showDetails = (booking_id, created_at, booking_type) => () => {
    axios
      .get(
        `${BASE_URL_HULK}/app/booking-info-v2/?src=website&booking_id=${booking_id}`
      )
      .then(({ data }) => {
        if (data.status === "success") {
          detailsData.booking_id = booking_id;
          detailsData.created_at = created_at;
          detailsData.bookingType = booking_type;
          setDetailsResponse(data);
          setShowDriverDetailsPopUp(true);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const trackDriver = (bookingId) => () => {
    setTrackDriverScreen("myOrders");
    window.location.href = `${BASE_URL}/track/${bookingId}/`;
  };

  const handleCancelOrder = (bookingId, userStatus) => () => {
    getBookingId.userBookingId = bookingId;
    getBookingId.userBookingStatus = userStatus;
    setShowCancelReasonBlock(true);
  };
  const handleRescheduleBooking = (bookingId) => {
    setGetBookingId({ ...getBookingId, userBookingId: bookingId });
    setShowReschedulePopUp(true);
  };
  const handleCloseBookingBlock = () => {
    setShowCancelReasonBlock(false);
  };
  useEffect(() => {
    if (showCancelReasonBlock) {
      document.body.style.overflow = "hidden";
    }
    return () => {
      document.body.style.overflow = "scroll";
    };
  }, [showCancelReasonBlock]);

  return (
    <>
      <div className="orderStatusMainDiv">
        <div className="statusHeading">MY DRIVES</div>
        <div className="orderDiv">
          <div>
            <div className="activeOrderHeading">Active Orders & Bookings</div>
            <div style={{ display: !showActiveOrderHeading ? "" : "none" }}>



              {/* {activeOrder.map((elem, index) => {
                return (
                  
                );
              })} */}


              <div 
                // key={index}
              >
                    <div className="activeOrder">
                      <div className="activeOrderDiv">
                        <div className="combineDiv">
                          <div className="activeOrderImage">
                            <img
                              src="https://ik.imagekit.io/driveu/Orders_Screen/driver_services_csHuYuoDj.png"
                              alt="Service Type"
                            />
                          </div>
                          <div className="activeOrderduration">
                            <div>{"elem.booking_type"}</div>
                            <div>{"elem.created_at"}</div>
                            <div>{"elem.eta_text"}</div>
                          </div>
                        </div>
                        <div className="statusButton">
                          <div
                            style={{
                              backgroundColor: "elem.status_bg_color",
                              fontSize: "14px",
                            }}
                          >
                            {"elem.status"}
                          </div>
                        </div>
                      </div>
                      <div className="statusAddress">
                        {/* <GreenDot
                          className="LocationDotsSvg"
                          style={{ marginTop: "-6px" }}
                        /> */}
                        <div className="LocationDots">
                          {!"elem.drop_address" === true ? (
                            <GreenDot
                              className="LocationDotsSvg"
                              style={{
                                marginTop:
                                  "elem.booking_type" === "Round Trip"
                                    ? "12px"
                                    : "",
                              }}
                            />
                          ) : (
                            <>
                              <GreenDot className="LocationDotsSvg" />
                              <div className="LocationLine"></div>
                              <BlackDot className="LocationDotsSvg" />
                            </>
                          )}
                        </div>

                        {/* <GreenDot className="LocationDotsSvg" /> */}
                        <div style={{ display: "flex", marginLeft: "0.8rem" }}>
                          {"!elem.drop_address" === true ? (
                            <div> {"elem.pickup_address"}</div>
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "0.8rem",
                              }}
                            >
                              <div> {"elem.pickup_address"}</div>
                              <div> {"elem.drop_address"}</div>
                            </div>
                          )}
                        </div>
                      </div>
                      {/* activeOrder?.is_searching_for_drivers */}
                      <div
                        style={{
                          // display: elem.is_searching_for_drivers ? "none" : "",
                          display: false ? "none" : "",
                        }}
                        className="trackAndCancelButton"
                      >
                        <div
                          onClick={trackDriver("elem.booking_id")}
                          className="trackAndCancelButtonDiv"
                        >
                          Track
                        </div>
                        <div
                          onClick={() => setOptionBooking(true)}
                          className="trackAndCancelButtonDiv"
                        >
                          Modify Booking
                        </div>
                        <Modal
                          onClose={() => setOptionBooking(false)}
                          isOpen={optionBooking}
                        >
                          <ModalHeader>Modify Booking</ModalHeader>
                          <ModalBody>
                            <div style={{ marginBottom: "1rem" }}>
                              Would you like to reschedule your booking to
                              another date and time?
                            </div>
                            <div className="modifyBookingPopUp">
                              <div
                                onClick={handleCancelOrder(
                                  "elem.booking_id",
                                  "elem.booking_status"
                                )}
                              >
                                Cancel Booking
                              </div>
                              <div
                                onClick={() =>
                                  handleRescheduleBooking("elem.booking_id")
                                }
                                className="reschedulePopUp"
                              >
                                Reschedule
                              </div>
                            </div>
                          </ModalBody>
                          &nbsp;
                        </Modal>
                      </div>
                    </div>
                  </div>
            </div>
            <div
              style={{
                display: showActiveOrderHeading === false ? "" : "none",
              }}
            >
              <div className="NoActiveImage">
                <img
                  src="https://ik.imagekit.io/driveu/Automovil/Group_YVECA8Hz4E.png"
                  alt="active_img"
                />
              </div>
              <Link style={{ color: "white" }} to="/book-driver/">
                <div className="BookNowActiveOrderDiv">Book Driver</div>
              </Link>
            </div>
          </div>
          <div>
            <div
              style={{ display: !showPastOrderHeading ? "" : "none" }}
              className="activeOrderHeading"
            >
              Past Orders & Bookings
            </div>
            {pastOrder.map((elem, index) => {
              return (
                <React.Fragment key={index}>
                  {elem.status === "!COMPLETED" ? (
                    <>
                      <div className="pastOrder">
                        <div className="pastOrderDiv">
                          <div className="combinePastDiv">
                            <div className="pastOrderImage">
                              <img src={elem.image_url} alt="Service Type" />
                            </div>
                            <div className="pastOrderduration">
                              <div>{elem.booking_type}</div>
                              <div>{elem.created_at}</div>
                              <div>{elem.eta_text}</div>
                            </div>
                          </div>
                          <div className="paststatusButton">
                            <div
                              style={{
                                backgroundColor: elem.status_bg_color,
                                fontSize: "14px",
                              }}
                            >
                              {elem.status}
                            </div>
                          </div>
                        </div>
                        <div className="paststatusAddress">
                          <div className="LocationDots">
                            {/* <GreenDot className="LocationDotsSvg" />
                            <div className="LocationLine"></div>
                            <BlackDot className="LocationDotsSvg" /> */}
                            {!elem.drop_address === true ? (
                              <GreenDot
                                className="LocationDotsSvg"
                                style={{ marginTop: "-6px" }}
                              />
                            ) : (
                              <>
                                <GreenDot className="LocationDotsSvg" />
                                <div className="LocationLine"></div>
                                <BlackDot className="LocationDotsSvg" />
                              </>
                            )}
                          </div>
                          <div>{elem.pickup_address}</div>
                          <div
                            style={{
                              marginBottom: "0.3rem",
                            }}
                          >
                            {elem.drop_address}
                          </div>
                        </div>

                        <div
                          onClick={showDetails(
                            "elem.booking_id",
                            "elem.created_at",
                            "elem.booking_type"
                          )}
                          className="viewDetalisDiv"
                        >
                          View Details
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="pastOrder">
                      <div className="pastOrderDiv">
                        <div className="combinePastDiv">
                          <div className="pastOrderImage">
                            <img src={"elem.image_url"} alt="DriveU" />
                          </div>
                          <div className="pastOrderduration">
                            <div>{"elem.booking_type"}</div>
                            <div>{"elem.created_at"}</div>
                            {/* <div>{elem.eta_text}</div> */}
                          </div>
                        </div>
                        <div className="paststatusButton">
                          <div
                            style={{
                              backgroundColor: "elem.status_bg_color",
                              fontSize: "14px",
                            }}
                          >
                            {"elem.status"}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </React.Fragment>
              );
            })}
          </div>
        </div>
      </div>
      {showDriverDetailsPopUp ? (
        <DriverDetails
          handleCloseDriverDetailsPopUp={handleCloseDriverDetailsPopUp}
          setShowDriverDetailsPopUp={setShowDriverDetailsPopUp}
        />
      ) : null}
      {showDriverDetailsPopUp ? <div className="overlay" /> : null}
      {/* {showCancelReasonBlock ? <div className="overlay" /> : null} */}
      {showCancelReasonBlock ? (
        <CancelReasonBlock
          handleCloseBookingBlock={handleCloseBookingBlock}
          showHistory={showHistory}
          showCancelReasonBlock={showCancelReasonBlock}
          setShowCancelReasonBlock={setShowCancelReasonBlock}
          setOptionBooking={setOptionBooking}
        />
      ) : null}
      {showReschedulePopUp ? (
        <RescheduleBlock
          showReschedulePopUp={showReschedulePopUp}
          setShowReschedulePopUp={setShowReschedulePopUp}
          setOptionBooking={setOptionBooking}
          showHistory={showHistory}
        />
      ) : null}
    </>
  );
}

export default MsilOrderStatusPage;
