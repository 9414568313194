/**
 This Component is most important be caution when you are changing => This is full Page combine with all components
 showPaymentBox => This state variable control flow of Du Daily Booking
 */

// React and React Router Imports
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// BaseUI Imports
import { Button, SHAPE, SIZE } from "baseui/button";
import { Drawer, ANCHOR } from "baseui/drawer";
import { Textarea } from "baseui/textarea";
import { Checkbox, STYLE_TYPE, LABEL_PLACEMENT } from "baseui/checkbox";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "baseui/modal";

// Icon Imports
import { MdModeEdit } from "react-icons/md";

// Context Imports
import { ApplicationContext } from "../../context/ApplicationContextProvider";
import { BookingFlowDataContext } from "../../context/BookingFlowDataContextProvider";
import { BookingFlowParaContext } from "../../context/BookingFlowParaProvider";

// Component Imports
import DDInput from "./DDInput/DDInput";
import CarTypeDaily from "./CarType/CarTypeDaily";
import PackageType from "./PackageType/PackageType";
import PickupTime from "./PickupTime/PickupTime";
import LanguageCheck from "./LanguageCheck/LanguageCheck";
import CustomDatePicker from "./CustomDatePicker/CustomDatePicker";
import DailyDate from "./CustomDatePicker/DailyDate/DailyDate";
import CalenderBox from "./CustomDatePicker/CalenderBox/CalenderBox";
import DailyFareModal from "./DailyFareModal/DailyFareModal";
import TermConditionNew from "../../parts/Term&Condition/TermConditionNew/TermConditionNew";
import CancelPolicyDU from "../TrackDuDaily/CancelPolicyDU";
import ExtraLargeSpinner from "../../Uis/DailyBigSpinner/ExtraLargeSpinner";

// Utility and Third-Party Library Imports
import toast from "react-hot-toast";
import axios from "axios";
import clevertap from "clevertap-web-sdk";
import {
  continueScheduleCTA,
  dailyFinalReviewDailyCTA,
} from "../../utils/EventObject";
import useOnlineStatus from "../../CustomHooks/useOnlineStatus";

// SVG Imports
import { DailyRoundWay } from "../../Components/AllImportFiles/ImportSvg";

// Stylesheet Imports
import dateStyle from "../DailyBookingDefault/CustomDatePicker/dateStyle.module.css";
import defaultStyle from "../DailyBooking.module.css";

// This Component imported from Baseweb and control nested modal for DriveU Secure/ Insurance
class ModalStateContainer extends React.Component {
  state = {
    isConfirmationOpen: false,
  };
  toggleConfirm = (open = !this.state.isConfirmationOpen, cb = () => {}) => {
    this.setState({ isConfirmationOpen: open }, cb);
  };
  render() {
    return this.props.children({
      isConfirmationOpen: this.state.isConfirmationOpen,
      toggleConfirm: this.toggleConfirm,
    });
  }
}

const DailyBookingDefault = () => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const RAZORPAY_KEY = process.env.REACT_APP_RAZORPAY_KEY;

  const {
    sendingDailyBookingData,
    dailyDUCheckIns,
    setDailyDUCheckIns,
    setFareEstimateDaily,
    pkgType,
    toggleDailyFareEstimate,
    trackPickUpDateTimeArr,
    checkedLanguages,
  } = useContext(BookingFlowDataContext);
  const {
    showPaymentBox,
    mappedDates,
    dailyPickupTime,
    setDailyBookingSuccessResponse,
    duDailyParams,
    openDailyFareModal,
    setOpenDailyFareModal,
    dailyPickUpLocationTracker,
    pickUpLocationError,
  } = useContext(ApplicationContext);
  const { dailyFareData, setDailyFareData } = useContext(
    BookingFlowParaContext
  );

  const isOnline = useOnlineStatus();
  const navigate = useNavigate();

  const [isOpenCalender, setIsOpenCalender] = React.useState(false);
  const [isInsuranceOpen, setOpenInsurance] = useState(false);
  const [isOpenCancelPolicy, setOpenCancelPolicy] = React.useState(false);
  const [openAdvanceInfoModal, setOpenAdvanceInfoModal] = useState(false);
  const [trackDailyPayment, setTrackDailyPayment] = useState(false);
  const [dailyBookLoading, setDailyBookLoading] = useState(false);
  const [trackPaymentError, setTrackPaymentError] = useState(false);
  const [bookingError, setBookingError] = useState(false);
  const [ordersLoading, setOrdersLoading] = useState(false);
  const [showInstructions, setShowInstructions] = useState(false);
  const [addInstructions, setAddInstructions] = useState(false);
  const [instructionsVal, setInstructionsVal] = useState("");
  const [disabledTextArea, setDisabledTextArea] = useState(false);

  const handleScheduleBooking = () => {
    sendingDailyBookingData.src = duDailyParams.src;
    if (
      !sendingDailyBookingData.booking_type === true ||
      !sendingDailyBookingData.city === true ||
      !sendingDailyBookingData.pickup_address === true ||
      sendingDailyBookingData.languages.length === 0
    ) {
      if (
        !sendingDailyBookingData.pickup_address === true ||
        !sendingDailyBookingData.city === true
      ) {
        toast.error("Please choose pickup location");
      } else if (!sendingDailyBookingData.booking_type === true) {
        toast.error("Please select estimated usage hours");
      } else if (sendingDailyBookingData.languages.length === 0) {
        toast.error(
          "Please choose at least one driver language you are comfortable with"
        );
      } else {
        toast.error("Please fill all required field");
      }
    } else {
      continueScheduleCTA.languages = checkedLanguages.join(",");
      dailyFinalReviewDailyCTA.languages = checkedLanguages.join(",");
      continueScheduleCTA.src = duDailyParams.src;
      clevertap.event.push(
        "Continue to schedule driver CTA clicked",
        continueScheduleCTA
      );
      setIsOpenCalender(true);
    }
  };

  const handleDailyInsurance = (e) => {
    const val = e.target.checked === true ? 1 : 0;
    setDailyDUCheckIns(val);
    sendingDailyBookingData.is_insured = val;
  };

  const handleSetDailyFareBreakUp = (fareData) => {
    setFareEstimateDaily(fareData.breakup);
    setOpenDailyFareModal(true);
  };
  const handleAddInsToDailyBookData = () => {
    sendingDailyBookingData.instructions = instructionsVal;
    setAddInstructions(true);
    setDisabledTextArea(true);
  };

  const dateStrings = trackPickUpDateTimeArr;
  function parseDate(dateString) {
    const [datePart, timePart] = dateString.split(" ");
    const [day, month, year] = datePart.split("/").map(Number);
    const [hour, minute] = timePart.split(":").map(Number);
    return new Date(year, month - 1, day, hour, minute); // Month is 0-indexed
  }

  // Parse and sort the Date objects
  const sortedDates = dateStrings.map(parseDate).sort((a, b) => a - b);

  // Function to format a Date object back into the desired format
  function formatDate(date) {
    const day = date
      .getDate()
      .toString()
      .padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-indexed
    const year = date.getFullYear();
    const hour = date
      .getHours()
      .toString()
      .padStart(2, "0");
    const minute = date
      .getMinutes()
      .toString()
      .padStart(2, "0");
    return `${day}/${month}/${year} ${hour}:${minute}`;
  }

  // Format the sorted Date objects back into the desired format
  const sortedDateStrings = sortedDates.map(formatDate);
  const handleUpdateFare = () => {
    axios({
      method: "GET",
      baseURL: BASE_URL,
      url: `${BASE_URL}/web/fare-estimate-du-daily`,
      params: {
        city_id: sendingDailyBookingData.city,
        service_type: sendingDailyBookingData.service_type,
        pickup_datetime: sortedDateStrings.join(","),
        user_id: duDailyParams.user_id,
        car_type: sendingDailyBookingData.car_type,
        booking_type: sendingDailyBookingData.booking_type,
        pickup_latitude: sendingDailyBookingData.pickup_latitude,
        pickup_longitude: sendingDailyBookingData.pickup_longitude,
        is_insured: sendingDailyBookingData.is_insured,
      },
      withCredentials: false,
      headers: {
        "Content-Type": `application/json`,
      },
    })
      .then(({ data }) => {
        if (data.status === "success") {
          setDailyFareData(data);
          dailyFinalReviewDailyCTA["Fare Estimate"] = data.total_cost;
          dailyFinalReviewDailyCTA["Token Advance"] = data.advance_to_be_paid;
          dailyFinalReviewDailyCTA.src = duDailyParams.src;
        } else if (data.status === "error") {
          toast.error(data.message);
        } else {
          toast.error("No Internet Connection");
        }
      })
      .catch((err) => {
        console.log(err.message);
        toast.error(err.message);
      });
  };

  useEffect(() => {
    if (
      sendingDailyBookingData.pickup_datetime.length > 2 &&
      pkgType &&
      showPaymentBox
    ) {
      clevertap.event.push("Daily review opened", dailyFinalReviewDailyCTA);
      handleUpdateFare();
    }
  }, [
    sendingDailyBookingData,
    pkgType,
    dailyDUCheckIns,
    dailyPickupTime,
    toggleDailyFareEstimate,
    isOnline,
  ]);

  const handleCallDailyBookDriver = () => {
    sendingDailyBookingData.pickup_datetime = trackPickUpDateTimeArr;
    sendingDailyBookingData.user_id = duDailyParams.user_id;
    sendingDailyBookingData.estimated_fare = dailyFareData.total_cost;
    clevertap.event.push(
      "Pay for daily driver CTA clicked",
      dailyFinalReviewDailyCTA
    );

    if (
      !sendingDailyBookingData.booking_type === true ||
      !sendingDailyBookingData.city === true ||
      !sendingDailyBookingData.pickup_address === true ||
      sendingDailyBookingData.languages.length === 0
    ) {
      if (
        !sendingDailyBookingData.pickup_address === true ||
        !sendingDailyBookingData.city === true
      ) {
        toast.error("Please choose pickup location");
      } else if (!sendingDailyBookingData.booking_type === true) {
        toast.error("Please select estimated usage hours");
      } else if (sendingDailyBookingData.languages.length === 0) {
        toast.error(
          "Please choose at least one driver language you are comfortable with"
        );
      } else {
        toast.error("Please fill all required field");
      }
    } else {
      handleDUDailyAdvancePayment();
    }
  };

  const handleBookDailyDriver = (dailyBookData) => {
    axios({
      method: "POST",
      baseURL: BASE_URL,
      // url: `${BASE_URL}/web/fare-estimate-du-daily`,
      url: `${BASE_URL}/web/book-du-daily/?src=${duDailyParams.src}&user_id=${duDailyParams.user_id}`,
      data: dailyBookData,
      withCredentials: false,
      headers: {
        "Content-Type": `application/json`,
      },
    })
      .then(({ data }) => {
        if (data.status === "success") {
          setTrackDailyPayment(false);
          setDailyBookLoading(false);
          setTrackPaymentError(false);
          setDailyBookingSuccessResponse(data);
          navigate("/du-daily-success");
        } else if (data.status === "error") {
          setTrackDailyPayment(false);
          setDailyBookLoading(false);
          setBookingError(true);
        }
      })
      .catch((err) => {
        setDailyBookLoading(false);
        setTrackDailyPayment(false);
        console.log(err.message);
        toast.error(err.message);
      });
  };

  // RAZORPAY FUNCTION WRITTEN HERE

  const handleDUDailyAdvancePayment = () => {
    sendingDailyBookingData.amount = dailyFareData.advance_to_be_paid;
    setOrdersLoading(true);
    axios({
      method: "POST",
      url: `${BASE_URL}/orders/?src=website&screen=du_daily&user_id=${sendingDailyBookingData.user_id}`,
      data: sendingDailyBookingData,
      withCredentials: false,
      headers: {
        "Content-Type": `application/json`,
      },
    })
      .then(({ data }) => {
        if (data.status === "created") {
          setOrdersLoading(false);
          showRazorpay(data.order_id);
        } else {
          setOrdersLoading(false);
          setTrackPaymentError(true);
        }
      })
      .catch((err) => {
        console.log(err.message);
        setTrackPaymentError(true);
        setOrdersLoading(false);
        // setLoaderPayment(false);
      });
  };
  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  async function showRazorpay(Order_ID) {
    sendingDailyBookingData.order_id = Order_ID;
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    ); // Don't Remove where razorpay payment integration is there
    const options = {
      key: RAZORPAY_KEY,
      name: "DriveU",
      description: "DU Daily",
      image:
        "https://ik.imagekit.io/driveu/Home_Screen/HjINSPmw0xOlWU_medium_JTMcNKBFl.png",
      order_id: Order_ID,
      handler: function(response) {
        const obj = {
          razorpay_order_id: response.razorpay_order_id,
          razorpay_payment_id: response.razorpay_payment_id,
          razorpay_signature: response.razorpay_signature,
        };
        if (response.razorpay_signature === "") {
          setTrackPaymentError(true);
        } else {
          setDailyBookLoading(true);
          setTrackDailyPayment(true);
          checkingStatus(obj);
        }
      },
      prefill: {
        email: duDailyParams.email,
        contact: duDailyParams.mobile,
      },
      config: {
        display: {
          blocks: {
            banks: {
              name: "Methods with Offers",
              instruments: [
                {
                  method: "wallet",
                  wallets: ["ola money"],
                },
              ],
            },
          },
          hide: [
            { method: "netbanking" },
            { method: "wallet" },
            { method: "paylater" },
            { method: "cardless_emi" },
          ],
          sequence: ["block.banks"],
          preferences: {
            show_default_blocks: true,
          },
        },
      },
      theme: {
        color: "#127e40",
      },
      modal: {
        ondismiss: function() {
          if ("Are you sure, you want to close the form?") {
            // setLoaderPayment(false);
            // setPaymentErr(true);
            setTrackPaymentError(true);
          } else {
            // setLoaderPayment(true);
            // setPaymentErr(false);
            setTrackPaymentError(false);
          }
        },
      },
    };
    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  }
  function checkingStatus(obj) {
    setTimeout(() => {
      axios({
        method: "POST",
        url: `${BASE_URL}/orders/status/?src=website`,
        data: obj,
        headers: {
          "Content-Type": `application/json`,
        },
      })
        .then(({ data }) => {
          if (data.status === "success") {
            // setShowOnlinePaymentPopUp(false);
            // setLoaderPayment(false);

            setTrackPaymentError(false);
            handleBookDailyDriver(sendingDailyBookingData);
          } else {
            // setLoaderPayment(false);
            // setPaymentErr(true);
            setDailyBookLoading(false);
            setTrackDailyPayment(false);
            setTrackPaymentError(true);
          }
        })
        .catch((err) => {
          setDailyBookLoading(false);
          setTrackDailyPayment(false);
          setTrackPaymentError(true);
          console.log(err.message);
        });
    }, 3000);
  }

  return (
    <React.Fragment>
      <div className={defaultStyle.roundTripShow}>
        <DailyRoundWay height={20} width={20} />
        <span>
          <strong>RoundTrip</strong> - same pickup & drop location
        </span>
      </div>
      <div
        className={
          showPaymentBox
            ? defaultStyle.reviewMainContainer
            : defaultStyle.dailyMainContainer
        }
      >
        <div className={defaultStyle.dailyBookingWrapper}>
          <DDInput />
          <PackageType />
          <CarTypeDaily />
          <PickupTime />
          <LanguageCheck />
        </div>
        {/* BOTTOM SHEET */}

        <>
          {showPaymentBox ? (
            <div className={defaultStyle.dailyPaymentWrapper}>
              <>
                <div className={dateStyle.dateLangDiv}>
                  <label htmlFor="pick up date">
                    Select Start Date <sup>*</sup>
                  </label>

                  <CalenderBox />
                </div>
                <DailyDate />
                <div className={defaultStyle.bookingShowDate}>
                  {3 - mappedDates.size <= 0 ? null : (
                    <p style={{ color: "red" }}>
                      Oops, you need to select at least {3 - mappedDates.size}
                      days!
                    </p>
                  )}
                </div>

                <div className={defaultStyle.dailyInsurance}>
                  <div>
                    <strong>
                      {dailyDUCheckIns
                        ? "Trips will be DU Secured"
                        : "Secure Your Booking"}
                    </strong>
                    &nbsp;
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      onClick={() => setOpenInsurance((s) => !s)}
                    >
                      <path
                        d="M6.33203 3.66536H7.66537V4.9987H6.33203V3.66536ZM6.33203 6.33203H7.66537V10.332H6.33203V6.33203ZM6.9987 0.332031C3.3187 0.332031 0.332031 3.3187 0.332031 6.9987C0.332031 10.6787 3.3187 13.6654 6.9987 13.6654C10.6787 13.6654 13.6654 10.6787 13.6654 6.9987C13.6654 3.3187 10.6787 0.332031 6.9987 0.332031ZM6.9987 12.332C4.0587 12.332 1.66536 9.9387 1.66536 6.9987C1.66536 4.0587 4.0587 1.66536 6.9987 1.66536C9.9387 1.66536 12.332 4.0587 12.332 6.9987C12.332 9.9387 9.9387 12.332 6.9987 12.332Z"
                        fill="#B5B5BA"
                      />
                    </svg>
                    <ModalStateContainer>
                      {({ isConfirmationOpen, toggleConfirm }) => (
                        <React.Fragment>
                          <Modal
                            onClose={() => setOpenInsurance(false)}
                            isOpen={isInsuranceOpen}
                          >
                            <ModalHeader
                              style={{
                                fontFamily: "Circular-Std-Bold",
                                color: "#666",
                              }}
                            >
                              DriveU Secure
                            </ModalHeader>

                            <ModalBody>
                              <center
                                style={{
                                  fontFamily: "Circular-Std-Book",
                                }}
                              >
                                <h4 style={{ marginBottom: "0.5rem" }}>
                                  Vehicle Damage Protection Plan
                                </h4>
                                <table>
                                  <tbody className="insTableBody">
                                    <tr>
                                      <td>DU Secure Fee&nbsp;</td>
                                      <td>&nbsp;₹15/-</td>
                                    </tr>
                                    <tr>
                                      <td>GST&nbsp;</td>
                                      <td>&nbsp;₹2.70/-</td>
                                    </tr>
                                    <tr>
                                      <td>Total&nbsp;</td>
                                      <td>&nbsp;₹17.7/-</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </center>
                              <br />
                              <ol
                                style={{
                                  fontFamily: "Circular-Std-Book",
                                  marginLeft: "24px",
                                }}
                              >
                                <li>Vehicle damages up to ₹15,000*</li>
                                <li>
                                  Claim to be raised within 3 days of trip end
                                </li>
                              </ol>
                            </ModalBody>
                            <ModalFooter>
                              <center
                                style={{
                                  fontFamily: "Circular-Std-Book",
                                  color: "#666",
                                  marginBottom: "16px",
                                }}
                              >
                                <div
                                  style={{
                                    opacity: "0.7",
                                    color: "#127e40",
                                  }}
                                  onClick={() => toggleConfirm(true)}
                                >
                                  View Terms and Conditions
                                </div>
                              </center>
                            </ModalFooter>
                          </Modal>

                          <Modal
                            onClose={() => toggleConfirm(false)}
                            isOpen={isConfirmationOpen}
                          >
                            <ModalHeader>
                              DRIVEU TERMS AND CONDITIONS
                            </ModalHeader>
                            <ModalBody>
                              <div
                                style={{
                                  width: "100%",
                                  height: "500px",
                                  overflow: "scroll",
                                }}
                              >
                                <TermConditionNew />
                              </div>
                            </ModalBody>
                          </Modal>
                        </React.Fragment>
                      )}
                    </ModalStateContainer>
                    {!dailyDUCheckIns && (
                      <h6 style={{ color: "#61616c" }}>
                        Get Vehicle Damage Protection Plan
                      </h6>
                    )}
                  </div>

                  <Checkbox
                    checked={dailyDUCheckIns}
                    checkmarkType={STYLE_TYPE.toggle_round}
                    onChange={handleDailyInsurance}
                    labelPlacement={LABEL_PLACEMENT.left}
                  />
                </div>
                <>
                  <div className={defaultStyle.InstructionsDiv}>
                    {!showInstructions ? (
                      <>
                        <span>
                          <strong>Any instructions for driver?</strong>
                        </span>

                        <Button
                          onClick={() => setShowInstructions(true)}
                          shape={SHAPE.pill}
                          size={SIZE.mini}
                          overrides={{
                            BaseButton: {
                              style: ({ $theme }) => ({
                                backgroundColor:
                                  $theme.colors.buttonPrimaryText,
                                outline: `${$theme.colors.buttonSecondaryActive} solid`,
                                color: "#127e40",
                              }),
                            },
                          }}
                        >
                          + Add
                        </Button>
                      </>
                    ) : (
                      <div className={defaultStyle.textAreaBox}>
                        <Textarea
                          value={instructionsVal}
                          onChange={(e) => setInstructionsVal(e.target.value)}
                          size={SIZE.mini}
                          placeholder="Add special request for Driver"
                          clearOnEscape
                          overrides={{
                            InputContainer: {
                              style: ({ $theme }) => ({
                                backgroundColor: "white",
                                height: "40px",
                              }),
                            },
                          }}
                          disabled={disabledTextArea}
                        />
                        {addInstructions && instructionsVal.length >= 1 ? (
                          <MdModeEdit
                            className={defaultStyle.addInsBtn}
                            onClick={() => {
                              setAddInstructions(false);
                              setDisabledTextArea(false);
                            }}
                          />
                        ) : instructionsVal.length >= 1 && !addInstructions ? (
                          <div
                            className={defaultStyle.addInsBtn}
                            onClick={handleAddInsToDailyBookData}
                          >
                            save
                          </div>
                        ) : null}
                      </div>
                    )}
                  </div>
                </>
                <>
                  {sendingDailyBookingData.pickup_datetime.length > 2 &&
                  pkgType &&
                  showPaymentBox ? (
                    <div
                      style={{ marginTop: "30px" }}
                      className={defaultStyle.billDetailsContainer}
                    >
                      <strong>Fare Estimate</strong>
                      <div className={defaultStyle.billDetailsWrapper}>
                        <div className={defaultStyle.fareBreakUpDaily}>
                          <div>
                            <strong>Est. Package Cost</strong>
                            <strong>₹{dailyFareData.total_cost}</strong>
                          </div>
                          {dailyFareData.fare_details.map((fareData, idx) => (
                            <div
                              key={idx}
                              className={defaultStyle.fareDetailsDU}
                            >
                              <span>
                                {fareData.text}&nbsp;
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="14"
                                  height="14"
                                  viewBox="0 0 14 14"
                                  fill="none"
                                  onClick={() =>
                                    handleSetDailyFareBreakUp(fareData)
                                  }
                                >
                                  <path
                                    d="M6.33203 3.66536H7.66537V4.9987H6.33203V3.66536ZM6.33203 6.33203H7.66537V10.332H6.33203V6.33203ZM6.9987 0.332031C3.3187 0.332031 0.332031 3.3187 0.332031 6.9987C0.332031 10.6787 3.3187 13.6654 6.9987 13.6654C10.6787 13.6654 13.6654 10.6787 13.6654 6.9987C13.6654 3.3187 10.6787 0.332031 6.9987 0.332031ZM6.9987 12.332C4.0587 12.332 1.66536 9.9387 1.66536 6.9987C1.66536 4.0587 4.0587 1.66536 6.9987 1.66536C9.9387 1.66536 12.332 4.0587 12.332 6.9987C12.332 9.9387 9.9387 12.332 6.9987 12.332Z"
                                    fill="#B5B5BA"
                                  />
                                </svg>
                              </span>
                              <span>₹{fareData.total}</span>
                            </div>
                          ))}
                        </div>
                        <hr />
                        <div className={defaultStyle.fareBreakUpDaily}>
                          <div>
                            <strong>Token Advance</strong>
                          </div>
                          <div>
                            <span className={defaultStyle.fareDetailsDU}>
                              Advance to be paid &nbsp;
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="14"
                                height="14"
                                viewBox="0 0 14 14"
                                fill="none"
                                onClick={() => setOpenAdvanceInfoModal(true)}
                              >
                                <path
                                  d="M6.33203 3.66536H7.66537V4.9987H6.33203V3.66536ZM6.33203 6.33203H7.66537V10.332H6.33203V6.33203ZM6.9987 0.332031C3.3187 0.332031 0.332031 3.3187 0.332031 6.9987C0.332031 10.6787 3.3187 13.6654 6.9987 13.6654C10.6787 13.6654 13.6654 10.6787 13.6654 6.9987C13.6654 3.3187 10.6787 0.332031 6.9987 0.332031ZM6.9987 12.332C4.0587 12.332 1.66536 9.9387 1.66536 6.9987C1.66536 4.0587 4.0587 1.66536 6.9987 1.66536C9.9387 1.66536 12.332 4.0587 12.332 6.9987C12.332 9.9387 9.9387 12.332 6.9987 12.332Z"
                                  fill="#B5B5BA"
                                />
                              </svg>
                            </span>
                            <strong style={{ fontSize: "0.875rem" }}>
                              ₹{dailyFareData.advance_to_be_paid}
                            </strong>
                          </div>
                        </div>
                        <hr />
                        <div className={defaultStyle.fareText}>
                          <div>
                            <strong>To Pay</strong>
                            <span>₹{dailyFareData.advance_to_be_paid}</span>
                          </div>
                          <ul>
                            <li>
                              Actual cost of trips & package will be
                              recalculated after each trip end
                            </li>
                            <li>
                              Driver needs to be paid at the end of each trip
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </>

                <div
                  className={defaultStyle.bookingShowDate}
                  style={{ color: "red", width: "50%", margin: "1rem auto" }}
                  onClick={() => setOpenCancelPolicy(true)}
                >
                  <u>Cancellation Policy</u>
                </div>
              </>
              <>
                <div className={defaultStyle.paymentDailyBtn}>
                  <>
                    {trackDailyPayment && dailyBookLoading ? (
                      <div className={defaultStyle.payBtn}>
                        <div>
                          <>
                            {sendingDailyBookingData.pickup_datetime.length >
                              2 &&
                              pkgType &&
                              showPaymentBox && (
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    width: "100%",
                                  }}
                                >
                                  <div>Advance Payment</div>
                                  <strong>
                                    ₹{dailyFareData.advance_to_be_paid}
                                  </strong>
                                </div>
                              )}
                          </>
                          <p
                            style={{
                              display:
                                trackPaymentError && bookingError === false
                                  ? ""
                                  : "none",
                              color: "red",
                            }}
                          >
                            Payment failed. Please try again!!
                          </p>
                          <p
                            style={{
                              display:
                                bookingError && trackPaymentError === false
                                  ? ""
                                  : "none",
                              color: "red",
                            }}
                          >
                            Booking is already created!!
                          </p>
                        </div>
                        <Button style={{ width: "100%" }} isLoading>
                          Pay for Daily Driver
                        </Button>
                      </div>
                    ) : (
                      <div className={defaultStyle.payBtn}>
                        <div>
                          <p
                            style={{
                              display:
                                trackPaymentError && bookingError === false
                                  ? ""
                                  : "none",
                              color: "red",
                            }}
                          >
                            Payment failed. Please try again!!
                          </p>
                          <p
                            style={{
                              display:
                                bookingError && trackPaymentError === false
                                  ? ""
                                  : "none",
                              color: "red",
                            }}
                          >
                            Booking is already created!!
                          </p>
                          <>
                            {sendingDailyBookingData.pickup_datetime.length >
                              2 &&
                            pkgType &&
                            showPaymentBox ? (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  width: "100%",
                                }}
                              >
                                <div>Advance Payment</div>
                                <strong>
                                  ₹{dailyFareData.advance_to_be_paid}
                                </strong>
                              </div>
                            ) : (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  width: "100%",
                                }}
                              >
                                <div>Advance Payment</div>
                                <strong>₹0</strong>
                              </div>
                            )}
                          </>
                        </div>
                        <Button
                          style={{ width: "100%" }}
                          onClick={handleCallDailyBookDriver}
                          disabled={
                            mappedDates.size <= 2 ||
                            dailyPickUpLocationTracker ||
                            pickUpLocationError
                          }
                        >
                          Pay for Daily Driver
                        </Button>
                      </div>
                    )}
                  </>
                </div>
              </>
            </div>
          ) : (
            <div className={defaultStyle.scheduleBtn}>
              <Button
                style={{ width: "90%" }}
                onClick={handleScheduleBooking}
                disabled={dailyPickUpLocationTracker || pickUpLocationError}
              >
                Continue to Schedule Driver
              </Button>
            </div>
          )}
        </>
        <Drawer
          isOpen={isOpenCalender}
          autoFocus
          onClose={() => setIsOpenCalender(false)}
          anchor={ANCHOR.bottom}
          // size={SIZE.full}
        >
          <CustomDatePicker setIsOpenCalender={setIsOpenCalender} />
        </Drawer>
        <Modal
          onClose={() => setOpenCancelPolicy(false)}
          isOpen={isOpenCancelPolicy}
          overrides={{
            Dialog: {
              style: {
                height: "80vh",
                display: "flex",
                flexDirection: "column",
                overflow: "scroll",
              },
            },
          }}
        >
          <ModalHeader>Cancellation Policy</ModalHeader>
          <ModalBody>
            <CancelPolicyDU />
          </ModalBody>
        </Modal>
        {openDailyFareModal && (
          <DailyFareModal
            openDailyFareModal={openDailyFareModal}
            setOpenDailyFareModal={setOpenDailyFareModal}
          />
        )}
        {/* advance_amount_info_text */}
        <Modal
          onClose={() => setOpenAdvanceInfoModal(false)}
          isOpen={openAdvanceInfoModal}
        >
          <ModalHeader>Why we need token advance?</ModalHeader>
          <ModalBody>{dailyFareData.advance_amount_info_text}</ModalBody>
        </Modal>
        {trackDailyPayment && dailyBookLoading ? (
          <div className={defaultStyle.overlay}>
            <ExtraLargeSpinner />
          </div>
        ) : null}
        {ordersLoading ? (
          <div className={defaultStyle.overlay}>
            <ExtraLargeSpinner />
          </div>
        ) : null}
      </div>
    </React.Fragment>
  );
};

export default DailyBookingDefault;
