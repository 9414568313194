import axios from "axios";
import React, { useState, useContext } from "react";
import {
  BlackDot,
  CloseBtn,
  GreenDot,
} from "../../Components/AllImportFiles/ImportSvg";
import { BookingFlowDataContext } from "../../context/BookingFlowDataContextProvider";
import { BookingFlowParaContext } from "../../context/BookingFlowParaProvider";
import LoadingSpinner from "../../Uis/Spinner/Spinner";

import { RatingPage } from "../RatingPage/RatingPage";
import "./DriverDetails.css";
function DriverDetails({
  handleCloseDriverDetailsPopUp,
  setShowDriverDetailsPopUp,
}) {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const RAZORPAY_KEY = process.env.REACT_APP_RAZORPAY_KEY;
  const {
    detailsResponse,
    onlinePaymentData,
    userInfo,
    setShowOnlinePaymentPopUp,
  } = useContext(BookingFlowDataContext);
  const [loaderPayment, setLoaderPayment] = useState(false);
  const [paymentErr, setPaymentErr] = useState(false);
  const { setShowPaymentDetails } = useContext(BookingFlowParaContext);
  const Booking_Type = detailsResponse.booking_details.booking_type;
  const handleShowFareDetails = () => {
    if (detailsResponse.showCloseButton === false) {
      setShowOnlinePaymentPopUp(false);
      setShowPaymentDetails(true);
    } else {
      setShowPaymentDetails(true);
      setShowDriverDetailsPopUp(false);
    }
  };
  const handleDriverOnlinePayment = () => {
    onlinePaymentData.user_id = userInfo.user_id;
    onlinePaymentData.amount = +detailsResponse.payable_fare;
    setLoaderPayment(true);
    axios({
      method: "POST",
      url: `${BASE_URL}/orders/?src=website&screen=paymentPopup&user_id=${onlinePaymentData.user_id}`,
      data: onlinePaymentData,
      withCredentials: false,
      headers: {
        "Content-Type": `application/json`,
      },
    })
      .then(({ data }) => {
        if (data.status === "created") {
          showRazorpay(data.order_id);
        } else {
          setLoaderPayment(false);
          setPaymentErr(true);
        }
      })
      .catch((err) => {
        console.log(err.message);
        setLoaderPayment(false);
      });
  };
  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  async function showRazorpay(Order_ID) {
    onlinePaymentData.order_id = Order_ID;
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );
    const options = {
      key: RAZORPAY_KEY,
      name: "DriveU",
      description: "Book Driver",
      image:
        "https://ik.imagekit.io/driveu/Home_Screen/HjINSPmw0xOlWU_medium_JTMcNKBFl.png",
      order_id: Order_ID,
      handler: function(response) {
        var obj = {
          razorpay_order_id: response.razorpay_order_id,
          razorpay_payment_id: response.razorpay_payment_id,
          razorpay_signature: response.razorpay_signature,
        };
        if (response.razorpay_signature === "") {
          setLoaderPayment(false);
          setPaymentErr(true);
        } else {
          setLoaderPayment(true);
          setPaymentErr(false);
          checkingStatus(obj);
        }
      },
      prefill: {
        email: userInfo.email,
        contact: userInfo.username,
      },
      config: {
        display: {
          blocks: {
            banks: {
              name: "Methods with Offers",
              instruments: [
                {
                  method: "wallet",
                  wallets: ["ola money"],
                },
              ],
            },
          },
          hide: [
            { method: "netbanking" },
            { method: "wallet" },
            { method: "paylater" },
            { method: "cardless_emi" },
          ],
          sequence: ["block.banks"],
          preferences: {
            show_default_blocks: true,
          },
        },
      },
      theme: {
        color: "#3399cc",
      },
      modal: {
        ondismiss: function() {
          if ("Are you sure, you want to close the form?") {
            setLoaderPayment(false);
            setPaymentErr(true);
          } else {
            setLoaderPayment(true);
            setPaymentErr(false);
          }
        },
      },
    };
    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  }
  function checkingStatus(obj) {
    setTimeout(
      () =>
        axios({
          method: "POST",
          url: `${BASE_URL}/orders/status/?src=website`,
          data: obj,
          headers: {
            "Content-Type": `application/json`,
          },
        })
          .then(({ data }) => {
            if (data.status === "success") {
              setShowOnlinePaymentPopUp(false);
              setLoaderPayment(false);
            } else {
              setLoaderPayment(false);
              setPaymentErr(true);
            }
          })
          .catch((err) => {
            console.log(err.message);
          }),
      3000
    );
  }
  return (
    <div>
      <div className="viewDetails">
        <CloseBtn
          style={{
            display: detailsResponse.showCloseButton === false ? "none" : "",
          }}
          className="closeDriverDetails"
          onClick={handleCloseDriverDetailsPopUp}
        />
        <div className="viewDetailsDiv">
          <div className="combineViewDiv">
            <div className="detailsImage">
              <img
                src={detailsResponse.driver_details.photo_url}
                alt="DriveU"
              />
            </div>
            <div className="detailsduration">
              <div>{detailsResponse.driver_details.driver_name}</div>
              {/* <div>{detailsData.bookingType}</div> */}
              <div>
                {Booking_Type.includes("hr") ? "Round Trip" : "One Way"}
              </div>
              <div className="driverRating">
                <RatingPage
                  value={+detailsResponse.booking_details.booking_rating}
                />
              </div>
            </div>
          </div>
          <div className="detailsButton">
            <div
              style={{
                backgroundColor: "rgb(65, 152, 102)",
                fontSize: "14px",
              }}
            >
              {detailsResponse.booking_details.booking_status_display}
            </div>
          </div>
        </div>
        <div className="detailsAddress">
          <div className="detailsDateAndDuration">
            <div>{detailsResponse.trip_details.trip_started_at_title}</div>
            <div>Duration : {detailsResponse.trip_details.usage_time}</div>
          </div>

          <div className="viewDetailsTitleAndAddress">
            <div className="LocationDotsPopUp">
              <GreenDot className="LocationDotsSvgPopUp" />
              <div className="LocationLinePopUp"></div>
              <BlackDot className="LocationDotsSvgPopUp" />
            </div>
            <div style={{ marginLeft: "0.5rem" }}>
              <div className="viewAddressHeading">
                {detailsResponse.trip_details.trip_started_at}
              </div>
              <div className="viewAddress">
                {detailsResponse.pickup_details.pickup_address}
              </div>
            </div>
            <div style={{ marginLeft: "0.5rem" }}>
              <div className="viewAddressHeading">
                {detailsResponse.trip_details.trip_ended_at}
              </div>
              <div
                style={{
                  marginBottom: "0.3rem",
                }}
                className="viewAddress"
              >
                {detailsResponse.drop_details.drop_address}
              </div>
            </div>
          </div>
          <div className="fareANDpayment">
            <div className="viewDetailsFare">
              {/* <div>Paid</div> */}
              <div style={{ fontWeight: "bold" }}>
                {detailsResponse.showCloseButton === false ? "Total" : "Paid"}
                &nbsp;:- &nbsp; ₹{detailsResponse.payable_fare}
              </div>
              <div
                style={{
                  fontWeight: "bold",
                  color: "rgb(65, 152, 102)",
                  textDecoration: "underline",
                  marginRight: "1.5rem",
                  cursor: "pointer",
                }}
                onClick={handleShowFareDetails}
              >
                Fare Details
              </div>
            </div>
            {loaderPayment ? (
              <button
                style={{
                  display:
                    detailsResponse.showCloseButton === false ? "" : "none",
                }}
                className="onlinePaymentButton"
              >
                <center>
                  <LoadingSpinner />
                </center>
              </button>
            ) : (
              <button
                style={{
                  display:
                    detailsResponse.showCloseButton === false ? "" : "none",
                }}
                onClick={handleDriverOnlinePayment}
                className="onlinePaymentButton"
              >
                Proceed to Pay ₹{detailsResponse.payable_fare}
              </button>
            )}
            <p style={{ display: paymentErr ? "" : "none", color: "red" }}>
              Payment failed. Please try again!!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DriverDetails;
