import React from "react";
import "./Review.css";
const ReviewComponent = () => {
  return (
    <div className="happy_driver_main_div">
      <div className="happy_driver_heading_text">
        Happy drivers, happy riders.
      </div>
      <div className="happy_driver_subHeading_text">
        We built DriveU around the belief that when people are treated better,
        they provide better service.
      </div>
      <div className="testimonial">
        <div className="first_testimonial">
          <div className="first_testimonial_first">
            <div className="first_testimonial_img">
              <img
                src="https://images.generated.photos/1zWY4TNgniVbWP7-I28vM8VMKiIOS4FgmHoxW0KcnAU/rs:fit:512:512/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/MTU5ODU3LmpwZw.jpg"
                alt="Customer"
              />
            </div>
            <div className="first_testimonial_text">
              I Recommend everyone who require Drivers for outstation please
              download DriveU app they reply immediately and make sure the
              availability of the drivers also provide the best Drivers
            </div>
          </div>
          <div className="first_testimonial_sec">
            Pradeep Pandey <strong>/</strong>{" "}
            <strong className="slash">Outstation Trip</strong>
          </div>
        </div>

        <div className="sec_testimonial">
          <div className="sec_testimonial_first">
            <div className="sec_testimonial_img">
              <img
                src="https://images.generated.photos/rWbrBApxOtnD0sH_nd_6a_JeWYvvC1K7PzZhkwoCbzM/rs:fit:512:512/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/MDI4OTQzLmpwZw.jpg"
                alt="Customer"
              />
            </div>
            <div className="sec_testimonial_text">
              I had my first drive and it's great , Driver came on time, had a
              professional and polite service , he was also thorough on the
              routes which was added advantage.
            </div>
          </div>
          <div className="sec_testimonial_sec">
            Sudhakar Surya <strong>/</strong>{" "}
            <strong className="slash">Round Trip</strong>
          </div>
        </div>

        <div className="thi_testimonial">
          <div className="thi_testimonial_first">
            <div className="thi_testimonial_img">
              <img
                src="https://randomuser.me/api/portraits/men/69.jpg"
                alt="Customer"
              />
            </div>
            <div className="thi_testimonial_text">
              DriveU is reliable. I have done some 5 to 6 rides so far and all
              of them were good and punctual. They took very good care of the
              car as their own. Must try
            </div>
          </div>
          <div className="thi_testimonial_sec">
            Sathishbabu M. <strong>/</strong>{" "}
            <strong className="slash">After-Party Drop</strong>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewComponent;
