import React, { useState, useEffect, useContext } from "react";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import { Spinner, SIZE } from "baseui/spinner";
import useOnclickOutside from "react-cool-onclickoutside";
import axios from "axios";
import { BookingFlowDataContext } from "../../../context/BookingFlowDataContextProvider";
import { BookingFlowParaContext } from "../../../context/BookingFlowParaProvider";
import { ApplicationContext } from "../../../context/ApplicationContextProvider";
import inputStyle from "./DDinput.module.css";
import { MdClose } from "react-icons/md";

function DUPickUp() {
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const [dailyLocationText, setDailyLocationText] = useState(0);
  const [showError, setShowError] = useState(false);
  const [toggleEmptyInput, setToggleEmptyInput] = useState(false);
  const [locationLoading, setLocationLoading] = useState(false);
  const [formData, setFormData] = useState({});
  const [pickUpError, setPickUpError] = useState(false);
  const [previousInputValue, setPreviousInputValue] = useState("");

  const { lat, lng } = useContext(BookingFlowParaContext);
  const {
    pubName,
    duDailyParams,
    setDailyPickUpLocationTracker,
    setPickUpLocationErrorOneWay,
  } = useContext(ApplicationContext);

  const {
    setToggleDailyFareEstimate,
    setFirstCityId,
    secondCityId,
    sendingDailyBookingDataOneWay,
    setSendingDailyBookingDataOneWay,
  } = useContext(BookingFlowDataContext);
  const {
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    debounce: 300,
  });

  const handleChange = (e) => {
    const { name } = e.target;
    setFormData({
      ...formData,
      [name]: e.target.value,
    });
  };
  const oneWayHandleInput = (e) => {
    if (value !== previousInputValue) {
      // User deleted text, handle it here
      setPickUpLocationErrorOneWay(true);
    }
    const locationValue = e.target.value;
    setValue(locationValue);
    setDailyLocationText(locationValue.length);
  };

  const ref = useOnclickOutside(() => {
    clearSuggestions();
  });

  const handleCallNearestDriver = async (lat, lng) => {
    setLocationLoading(true);
    try {
      const latLngResponse = await axios.get(
        `${BASE_URL}/web/du-daily-availability/?user_id=${duDailyParams.user_id}&pickup_latitude=${lat}&pickup_longitude=${lng}`
      );
      const data = latLngResponse.data;
      if (data.status === "error") {
        setValue("");
        setShowError(true);
        setTimeout(() => {
          setShowError(false);
        }, [2000]);
        setLocationLoading(false);
      } else if (data.status === "success") {
        setDailyPickUpLocationTracker(false);
        setFirstCityId(data.city_id);
        if (!secondCityId) {
          setShowError(false);
          setSendingDailyBookingDataOneWay((prev) => ({
            ...prev,
            city: data.city_id,
          }));
        } else if (data.city_id !== secondCityId) {
          setPickUpError(true);
          setValue("");
          setTimeout(() => {
            setPickUpError(false);
          }, 2000);
        } else {
          setSendingDailyBookingDataOneWay((prev) => ({
            ...prev,
            city: data.city_id,
          }));
        }

        setLocationLoading(false);
      }
    } catch (error) {
      console.log(error.message);
      setLocationLoading(false);
    }
  };

  const handleCallNearestDriverWithLatLng = async (lat, lng) => {
    try {
      const latLngResponse = await axios.get(
        `${BASE_URL}/web/du-daily-availability/?user_id=${duDailyParams.user_id}&pickup_latitude=${lat}&pickup_longitude=${lng}`
      );
      const data = latLngResponse.data;
      if (data.status === "error") {
        setValue("");
        setShowError(true);
        setTimeout(() => {
          setShowError(false);
        }, [2000]);
        setLocationLoading(false);
      } else if (data.status === "success") {
        setDailyPickUpLocationTracker(false);
        setLocationLoading(false);
        getReverseGeocodingData(lat, lng);
        setFirstCityId(data.city_id);
        if (!secondCityId) {
          setShowError(false);
          setSendingDailyBookingDataOneWay((prev) => ({
            ...prev,
            city: data.city_id,
          }));
        } else if (data.city_id !== secondCityId) {
          setPickUpError(true);
          setValue("");
          setTimeout(() => {
            setPickUpError(false);
          }, 2000);
        }
        sendingDailyBookingDataOneWay.city = data.city_id;
      }
    } catch (error) {
      setLocationLoading(false);
      console.log(error.message);
    }
  };

  const dailyHandleSelect = ({ description }) => () => {
    setPreviousInputValue(value);
    setPickUpLocationErrorOneWay(false);
    setValue(description, false);
    clearSuggestions();
    getGeocode({ address: description }).then((results) => {
      const { lat, lng } = getLatLng(results[0]);
      const updatedData = {
        ...sendingDailyBookingDataOneWay,
        pickup_address: description,
        pickup_latitude: lat,
        pickup_longitude: lng,
        user_id: duDailyParams.user_id,
      };

      // Update the state with the new data
      setSendingDailyBookingDataOneWay(updatedData);
      handleCallNearestDriver(lat, lng);
    });
  };

  const dailyLocationSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <div key={place_id.toString()}>
          <ul
            className={inputStyle.roundsuggestion}
            key={place_id.toString()}
            onClick={dailyHandleSelect(suggestion)}
          >
            <strong style={{ marginRight: "5px" }} id="bold">
              {main_text}
            </strong>
            <small id="book">{secondary_text}</small>
          </ul>
        </div>
      );
    });
  function getReverseGeocodingData(lat, lng) {
    sendingDailyBookingDataOneWay.pickup_longitude = lng;
    sendingDailyBookingDataOneWay.pickup_latitude = lat;
    sendingDailyBookingDataOneWay.user_id = duDailyParams.user_id;
    var latlng = new window.google.maps.LatLng(lat, lng);
    // This is making the Geocode request
    var geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ latLng: latlng }, (results, status) => {
      if (status !== window.google.maps.GeocoderStatus.OK) {
      }
      // This is checking to see if the Geoeode Status is OK before proceeding
      if (status === window.google.maps.GeocoderStatus.OK) {
        var address = results[0].formatted_address;

        if (pubName) {
          // sendingDailyBookingData.pickup_address = pubName;
          setSendingDailyBookingDataOneWay((prev) => ({
            ...prev,
            pickup_address: pubName,
          }));
          setValue(pubName);
        } else {
          // sendingDailyBookingData.pickup_address = address;
          setSendingDailyBookingDataOneWay((prev) => ({
            ...prev,
            pickup_address: address,
          }));
          setValue(address);
        }
      }
    });
  }
  const handleClearInput = () => {
    setValue("");
    setFirstCityId("");
    sendingDailyBookingDataOneWay.pickup_address = "";
    sendingDailyBookingDataOneWay.city = "";
    sendingDailyBookingDataOneWay.pickup_latitude = 0.0;
    sendingDailyBookingDataOneWay.pickup_longitude = 0.0;
    setDailyPickUpLocationTracker(true);
  };

  useEffect(() => {
    if (value.length === 0) {
      setFirstCityId("");
      setToggleEmptyInput(false);
      setToggleDailyFareEstimate(false);
    } else {
      setToggleEmptyInput(true);
      setToggleDailyFareEstimate(true);
    }
  }, [value]);

  useEffect(() => {
    if (lat === null || lng === null) {
      console.log("No Data");
    } else {
      handleCallNearestDriverWithLatLng(lat, lng);
    }
  }, [lat, lng]);

  return (
    <>
      <div
        ref={ref}
        onChange={oneWayHandleInput}
        className={inputStyle.secondInputLocation}
      >
        <div className={inputStyle.inputWrapper}>
          <label htmlFor="">
            Choose pick-up location<sup>*</sup>
          </label>
          <div className={inputStyle["roundWay_Input_Tag_Div"]}>
            <input
              className={`${inputStyle["first_Input_Tag"]}`}
              name="PickUpLocation"
              type="text"
              value={value}
              onChange={handleChange}
              placeholder="Enter 4 letters to Search Pick-up Location"
              autoComplete="off"
            />
            <div
              style={{ display: toggleEmptyInput ? "" : "none" }}
              className={inputStyle["dailyCloseBtn"]}
              onClick={handleClearInput}
            >
              {locationLoading ? <Spinner $size={SIZE.small} /> : <MdClose />}
            </div>
          </div>
        </div>

        <>
          {dailyLocationText >= 4
            ? status === "OK" && (
                <div
                  style={{ width: "92%" }}
                  className={inputStyle.debounceBox}
                  id="debounceBox"
                >
                  {dailyLocationSuggestions()}
                </div>
              )
            : null}
        </>
      </div>
      <div
        className={inputStyle.errorDivDU}
        style={{ display: showError || pickUpError ? "block" : "none" }}
      >
        {showError
          ? "Sorry, service not available"
          : pickUpError
          ? "Pick up and drop address must be in the same city."
          : null}
      </div>
    </>
  );
}

export default DUPickUp;
