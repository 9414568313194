import React from "react";
import "./FareEstimate.css";
import { Modal, ModalHeader, ModalBody } from "baseui/modal";
function CarServiceFareEstimate({
  showCarServiceFareBreakUp,
  setShowCarServiceFareBreakUp,
  carServiceFareDetails,
}) {
  return (
    <>
      <Modal
        onClose={() => setShowCarServiceFareBreakUp(false)}
        isOpen={showCarServiceFareBreakUp}
      >
        {carServiceFareDetails === null ? (
          <Modal
            onClose={() => setShowCarServiceFareBreakUp(false)}
            isOpen={showCarServiceFareBreakUp}
          >
            <ModalHeader>INVOICE BREAKUP</ModalHeader>
          </Modal>
        ) : (
          <>
            <ModalHeader>INVOICE BREAKUP</ModalHeader>
            <ModalBody>
              <table className="fareTable">
                <tbody>
                  {carServiceFareDetails.data.map((elem, idx) => (
                    <tr className="fareTableRow" key={idx}>
                      <td className="fareTableCol" style={{ color: "#09081a" }}>
                        {elem.item_name}&nbsp;
                        {+elem.quantity > 1 ? +elem.quantity : ""}
                      </td>
                      <td className="fareTableCol" style={{ color: "#09081a" }}>
                        {+elem.rate}
                      </td>
                    </tr>
                  ))}
                  <tr className="fareTableRow">
                    <td className="fareTableCol" style={{ color: "#09081a" }}>
                      Total GST
                    </td>
                    <td className="fareTableCol" style={{ color: "#09081a" }}>
                      {+carServiceFareDetails.total_gst}
                    </td>
                  </tr>
                  <tr className="fareTableRow">
                    <td className="fareTableCol" style={{ color: "#09081a" }}>
                      Total Amount
                    </td>
                    <td className="fareTableCol" style={{ color: "#09081a" }}>
                      {+carServiceFareDetails.total_amount}
                    </td>
                  </tr>
                </tbody>
              </table>
            </ModalBody>
          </>
        )}
      </Modal>
    </>
  );
}

export default CarServiceFareEstimate;
