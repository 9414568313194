export const seoJson = [
  {
    path: "/car-drivers-for-hire-gurgaon/dlf-cyberhub",
    content: {
      title:
        "Hire DriveU Verified car drivers for your car near DLF Cyberhub, Gurgaon for local and outstation trips on hourly basis",
      description:
        "Hire background-verified, tested and trained professional car drivers, starting at ₹129 per hour, trusted by 7.5 Lakh+ Car Owners. Book an experienced DriveU driver near DLF Cyberhub, Gurgaon for one-way and round trips. Hourly Packages for local and outstation trips also available. Get a car driver for office commute, shopping & errands, medical visits, after-party drops and outstation trips.",
      heading: "HIRE DRIVERS NEAR DLF CYBERHUB",
      sub_heading: "Professional Drivers available 24x7",
    },
  },
  {
    path: "/car-drivers-for-hire-gurgaon/narsinghpur",
    content: {
      title:
        "Hire DriveU Verified car drivers for your car near Narsinghpur, Gurgaon for local and outstation trips on hourly basis",
      description:
        "Hire background-verified, tested and trained professional car drivers, starting at ₹129 per hour, trusted by 7.5 Lakh+ Car Owners. Book an experienced DriveU driver near Narsinghpur, Gurgaon for one-way and round trips. Hourly Packages for local and outstation trips also available. Get a car driver for office commute, shopping & errands, medical visits, after-party drops and outstation trips.",
      heading: "HIRE DRIVERS NEAR NARSINGHPUR",
      sub_heading: "Professional Drivers available 24x7",
    },
  },
  {
    path: "/car-drivers-for-hire-gurgaon/sector54",
    content: {
      title:
        "Hire DriveU Verified car drivers for your car near Sector 54, Gurgaon for local and outstation trips on hourly basis",
      description:
        "Hire background-verified, tested and trained professional car drivers, starting at ₹129 per hour, trusted by 7.5 Lakh+ Car Owners. Book an experienced DriveU driver near Sector 54, Gurgaon for one-way and round trips. Hourly Packages for local and outstation trips also available. Get a car driver for office commute, shopping & errands, medical visits, after-party drops and outstation trips.",
      heading: "HIRE DRIVERS NEAR SECTOR 54",
      sub_heading: "Professional Drivers available 24x7",
    },
  },
  {
    path: "/car-drivers-for-hire-gurgaon/sohna-road",
    content: {
      title:
        "Hire DriveU Verified car drivers for your car near Sohna Road, Gurgaon for local and outstation trips on hourly basis",
      description:
        "Hire background-verified, tested and trained professional car drivers, starting at ₹129 per hour, trusted by 7.5 Lakh+ Car Owners. Book an experienced DriveU driver near Sohna Road, Gurgaon for one-way and round trips. Hourly Packages for local and outstation trips also available. Get a car driver for office commute, shopping & errands, medical visits, after-party drops and outstation trips.",
      heading: "HIRE DRIVERS NEAR SOHNA ROAD",
      sub_heading: "Professional Drivers available 24x7",
    },
  },
  {
    path: "/car-drivers-for-hire-gurgaon/golf-course-extension",
    content: {
      title:
        "Hire DriveU Verified car drivers for your car near Golf Course Extension, Gurgaon for local and outstation trips on hourly basis",
      description:
        "Hire background-verified, tested and trained professional car drivers, starting at ₹129 per hour, trusted by 7.5 Lakh+ Car Owners. Book an experienced DriveU driver near Golf Course Extension, Gurgaon for one-way and round trips. Hourly Packages for local and outstation trips also available. Get a car driver for office commute, shopping & errands, medical visits, after-party drops and outstation trips.",
      heading: "HIRE DRIVERS NEAR GOLF COURSE EXTENSION",
      sub_heading: "Professional Drivers available 24x7",
    },
  },
  {
    path: "/car-drivers-for-hire-gurgaon/ambience-mall",
    content: {
      title:
        "Hire DriveU Verified car drivers for your car near Ambience Mall, Gurgaon for local and outstation trips on hourly basis",
      description:
        "Hire background-verified, tested and trained professional car drivers, starting at ₹129 per hour, trusted by 7.5 Lakh+ Car Owners. Book an experienced DriveU driver near Golf Course Extension, Gurgaon for one-way and round trips. Hourly Packages for local and outstation trips also available. Get a car driver for office commute, shopping & errands, medical visits, after-party drops and outstation trips.",
      heading: "HIRE DRIVERS NEAR AMBIENCE MALL",
      sub_heading: "Professional Drivers available 24x7",
    },
  },
  {
    path: "/car-drivers-for-hire-gurgaon/sector82",
    content: {
      title:
        "Hire DriveU Verified car drivers for your car near Sector 82, Gurgaon for local and outstation trips on hourly basis",
      description:
        "Hire background-verified, tested and trained professional car drivers, starting at ₹129 per hour, trusted by 7.5 Lakh+ Car Owners. Book an experienced DriveU driver near Sector 82, Gurgaon for one-way and round trips. Hourly Packages for local and outstation trips also available. Get a car driver for office commute, shopping & errands, medical visits, after-party drops and outstation trips.",
      heading: "HIRE DRIVERS NEAR SECTOR 82",
      sub_heading: "Professional Drivers available 24x7",
    },
  },
  {
    path: "/car-drivers-for-hire-gurgaon/sushant-lok",
    content: {
      title:
        "Hire DriveU Verified car drivers for your car near Sushant Lok, Gurgaon for local and outstation trips on hourly basis",
      description:
        "Hire background-verified, tested and trained professional car drivers, starting at ₹129 per hour, trusted by 7.5 Lakh+ Car Owners. Book an experienced DriveU driver near Sushant Lok, Gurgaon for one-way and round trips. Hourly Packages for local and outstation trips also available. Get a car driver for office commute, shopping & errands, medical visits, after-party drops and outstation trips.",
      heading: "HIRE DRIVERS NEAR SUSHANT LOK",
      sub_heading: "Professional Drivers available 24x7",
    },
  },
  {
    path: "/car-drivers-for-hire-gurgaon/sikanderpur-ghosi",
    content: {
      title:
        "Hire DriveU Verified car drivers for your car near Sikanderpur Ghosi, Gurgaon for local and outstation trips on hourly basis",
      description:
        "Hire background-verified, tested and trained professional car drivers, starting at ₹129 per hour, trusted by 7.5 Lakh+ Car Owners. Book an experienced DriveU driver near Sikanderpur Ghosi, Gurgaon for one-way and round trips. Hourly Packages for local and outstation trips also available. Get a car driver for office commute, shopping & errands, medical visits, after-party drops and outstation trips.",
      heading: "HIRE DRIVERS NEAR SIKANDERPUR GHOSI",
      sub_heading: "Professional Drivers available 24x7",
    },
  },
  {
    path: "/car-drivers-for-hire-gurgaon/palam-vihar",
    content: {
      title:
        "Hire DriveU Verified car drivers for your car near Palam Vihar, Gurgaon for local and outstation trips on hourly basis",
      description:
        "Hire background-verified, tested and trained professional car drivers, starting at ₹129 per hour, trusted by 7.5 Lakh+ Car Owners. Book an experienced DriveU driver near Palam Vihar, Gurgaon for one-way and round trips. Hourly Packages for local and outstation trips also available. Get a car driver for office commute, shopping & errands, medical visits, after-party drops and outstation trips.",
      heading: "HIRE DRIVERS NEAR PALAM VIHAR",
      sub_heading: "Professional Drivers available 24x7",
    },
  },
  {
    path: "/car-drivers-for-hire-gurgaon/nirvana-country",
    content: {
      title:
        "Hire DriveU Verified car drivers for your car near Nirvana Country, Gurgaon for local and outstation trips on hourly basis",
      description:
        "Hire background-verified, tested and trained professional car drivers, starting at ₹129 per hour, trusted by 7.5 Lakh+ Car Owners. Book an experienced DriveU driver near Nirvana Country, Gurgaon for one-way and round trips. Hourly Packages for local and outstation trips also available. Get a car driver for office commute, shopping & errands, medical visits, after-party drops and outstation trips.",
      heading: "HIRE DRIVERS NEAR NIRVANA COUNTRY",
      sub_heading: "Professional Drivers available 24x7",
    },
  },
  {
    path: "/car-drivers-for-hire-gurgaon/manesar",
    content: {
      title:
        "Hire DriveU Verified car drivers for your car near Manesar, Gurgaon for local and outstation trips on hourly basis",
      description:
        "Hire background-verified, tested and trained professional car drivers, starting at ₹129 per hour, trusted by 7.5 Lakh+ Car Owners. Book an experienced DriveU driver near Manesar, Gurgaon for one-way and round trips. Hourly Packages for local and outstation trips also available. Get a car driver for office commute, shopping & errands, medical visits, after-party drops and outstation trips.",
      heading: "HIRE DRIVERS NEAR MANESAR",
      sub_heading: "Professional Drivers available 24x7",
    },
  },
  {
    path: "/car-drivers-for-hire-gurgaon/southcity",
    content: {
      title:
        "Hire DriveU Verified car drivers for your car near South City, Gurgaon for local and outstation trips on hourly basis",
      description:
        "Hire background-verified, tested and trained professional car drivers, starting at ₹129 per hour, trusted by 7.5 Lakh+ Car Owners. Book an experienced DriveU driver near South City, Gurgaon for one-way and round trips. Hourly Packages for local and outstation trips also available. Get a car driver for office commute, shopping & errands, medical visits, after-party drops and outstation trips.",
      heading: "HIRE DRIVERS NEAR SOUTH CITY",
      sub_heading: "Professional Drivers available 24x7",
    },
  },
  {
    path: "/car-drivers-for-hire-gurgaon/dlf-city",
    content: {
      title:
        "Hire DriveU Verified car drivers for your car near DLF City, Gurgaon for local and outstation trips on hourly basis",
      description:
        "Hire background-verified, tested and trained professional car drivers, starting at ₹129 per hour, trusted by 7.5 Lakh+ Car Owners. Book an experienced DriveU driver near DLF City, Gurgaon for one-way and round trips. Hourly Packages for local and outstation trips also available. Get a car driver for office commute, shopping & errands, medical visits, after-party drops and outstation trips.",
      heading: "HIRE DRIVERS NEAR DLF CITY",
      sub_heading: "Professional Drivers available 24x7",
    },
  },
];
