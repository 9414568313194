import React, { Fragment, useContext, useState } from "react";
import "../../BookingFlow/Bookingpage/DriveUVideo/DriveUVideo.css";
import { BsFillPlayCircleFill } from "react-icons/bs";
import { ApplicationContext } from "../../context/ApplicationContextProvider";
const Video = ({ src }) => {
  return (
    <div className="imageVideoMainContainer">
      <video
        autoPlay
        controls
        preload="auto"
        src={src}
        muted
        style={{ position: "relative" }}
        playsInline
      />
    </div>
  );
};

const Image = ({ onClick, ImageUrl }) => (
  <div className="imageVideoMainContainer">
    <img src={ImageUrl} loading="lazy" alt="Car Service" />
    <div onClick={onClick} className="play-button">
      <BsFillPlayCircleFill style={{ fontSize: "40px" }} />
    </div>
  </div>
);

function CarWashVideo({ vidLink, ImageLink }) {
  const [isVideoModalOpen, setVideoModalOpen] = useState(false);
  const { myGateToken } = useContext(ApplicationContext);

  const handleClick = (e) => {
    e.preventDefault();
    setVideoModalOpen(true);
  };
  const videoStyle = {
    width: "100%",
    margin: "auto",
    marginTop: " 0%",
    position: "relative",
  };
  return (
    <Fragment>
      <div style={videoStyle}>
        <p style={{ marginBottom: "1rem", fontWeight: "900" }}>How it works?</p>
        <>
          {isVideoModalOpen ? (
            <Video src={vidLink} />
          ) : (
            <Image
              onClick={handleClick}
              myGateToken={myGateToken}
              ImageUrl={ImageLink}
            />
          )}
        </>
      </div>
    </Fragment>
  );
}

export default CarWashVideo;
