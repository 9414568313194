import React from "react";
import ImageScrollPartner from "../../DuDailyPartner/DuDailyLandingPage/ImageScrollPartner";
import DuPartnerContent from "../../DuDailyPartner/DuDailyLandingPage/DuPartnerContent/DuPartnerContent";
const DuDailyPartnerLandingPage = () => {
  return (
    <div style={{ height: "100vh" }}>
      <ImageScrollPartner />
      <DuPartnerContent />
    </div>
  );
};

export default DuDailyPartnerLandingPage;
