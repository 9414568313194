import React, { useContext, useEffect, useRef, useState } from "react";
import "./CarModal.css";
import { BsFillArrowLeftCircleFill } from "react-icons/bs";
import { Modal, ModalHeader, ModalBody, SIZE } from "baseui/modal";
import { carServiceContext } from "../../../../context/CarServiceContextProvider";
import axios from "axios";
import MyGateLoader from "../../../../Uis/MyGateLoader/MyGateLoader";

function CarModal() {
  const BASE_URL_HULK = process.env.REACT_APP_HULK_URL;
  const inputRef = useRef(null);
  const {
    isCarModalOpen,
    closeCarModal,
    setIsManufactureModalOpen,
    maufactureID,
    setFuelData,
    setIsFuelModalOpen,
    setIsCarModalOpen,
    setVehicleKey,
    carServiceData,
  } = useContext(carServiceContext);
  const [loader, setLoader] = useState(true);
  const [carModal, setCarModal] = useState([]);
  const [carModalValue, setCarModalValue] = useState("");

  const handleManufactureModal = () => {
    closeCarModal();
    setIsManufactureModalOpen(true);
  };

  useEffect(() => {
    const fetchCarModelData = () => {
      axios({
        method: "GET",
        // baseURL: BASE_URL_HULK,
        url: `${BASE_URL_HULK}/care/car-models/?make_id=${maufactureID}`,
        withCredentials: false,
        headers: {
          "Content-Type": `application/json`,
        },
      })
        .then(({ data }) => {
          let filtrModal = data.models.filter((elem, index) => {
            return elem.automovill_id !== null;
          });

          setCarModal(filtrModal);
          setLoader(false);
        })
        .catch((err) => {
          console.log(err.message);
        });
    };
    fetchCarModelData();
  }, []);

  const handleFuelModal = (elem) => () => {
    carServiceData.car_id = elem.id;
    carServiceData.automovill_id = elem.automovill_id;
    setVehicleKey(elem.automovill_id);
    setIsCarModalOpen(false);
    setIsFuelModalOpen(true);
    setFuelData(elem);
  };

  const handleFuelValue = (e) => {
    setCarModalValue(e.target.value);
  };

  const handleClick = () => {
    inputRef.current.inputMode = "text";
  };

  return (
    <>
      <Modal
        onClose={closeCarModal}
        isOpen={isCarModalOpen}
        size={SIZE.default}
      >
        {/* Select Model */}
        <ModalHeader className="carServiceModalHeaderMainContainer">
          <BsFillArrowLeftCircleFill
            onClick={handleManufactureModal}
            className="carServiceModalBackButton"
          />
          <div>Select Model</div>
        </ModalHeader>
        <ModalBody style={{ flex: "1 1 0" }}>
          <div className="carServiceModalMainContainer">
            <div
              onClick={handleClick}
              className="carServiceModalInputMainContainer"
            >
              <input
                className="carServiceModalInputContainer"
                type="text"
                ref={inputRef}
                placeholder="Search Models"
                value={carModalValue}
                onChange={handleFuelValue}
                inputMode="none"
              />
            </div>

            {/* Modal option  */}

            {loader ? (
              <MyGateLoader />
            ) : (
              <div className="carServiceModalOptionsMainContainer">
                {carModal
                  .filter((elem) => {
                    if (carModalValue === "") {
                      return elem.model;
                    } else if (
                      elem.model
                        .toLowerCase()
                        .includes(carModalValue.toLowerCase())
                    ) {
                      return elem.model;
                    } else {
                      return false;
                    }
                  })
                  .map((elem) => {
                    return (
                      <div
                        key={elem.id}
                        onClick={handleFuelModal(elem)}
                        className="carServiceModalfirstOptionMainContainer"
                      >
                        <img src={elem.model_image} alt={elem.model} />
                        <div className="carServiceModalfirstOptionText">
                          {elem.model}
                        </div>
                      </div>
                    );
                  })}
              </div>
            )}
          </div>
        </ModalBody>
      </Modal>
    </>
  );
}

export default CarModal;
