import axios from "axios";
import { Button } from "baseui/button";
import { ANCHOR, Drawer } from "baseui/drawer";
import React, { Fragment, useContext } from "react";
import { useCookies } from "react-cookie";
import { ApplicationContext } from "../../context/ApplicationContextProvider";
import { BookingFlowDataContext } from "../../context/BookingFlowDataContextProvider";
import { DownloadContext } from "../../context/DownloadContextProvider";
import useBookDriverApi from "../../CustomHooks/useBookDriverApi";
import LoadingSpinner from "../../Uis/Spinner/Spinner";
import clevertap from "clevertap-web-sdk";
import { carServiceContext } from "../../context/CarServiceContextProvider";
import "./ConsentPopUp.css";
import toast from "react-hot-toast";
const ConsentPopUp = ({ couponCodeToggle, type }) => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const {
    myGateToken,
    setMyGateTokenData,
    myGatePopUp,
    setMyGatePopUp,
    setConsentFlag,
    msilTokenData
  } = useContext(ApplicationContext);

  const {
    loading,
    setLoading,
    setCarServiceConset,
    carServiceData,
    setIsSlot,
  } = useContext(carServiceContext);

  const { firstCityId, secondCityId, activeKey } = useContext(
    BookingFlowDataContext
  );
  const { previousChoice } = useContext(DownloadContext);

  const {
    sendingData,
    sendingRoundWayData,
    setUserInfo,
    sendingOutstationData,
  } = React.useContext(BookingFlowDataContext);
  const [, setCookie] = useCookies();

  const {
    handleBookDriverBooking,
    handleBookDriverBookingRoundTrip,
    handleBookDriverOutstation,
  } = useBookDriverApi();

  const handleCarServiceConset = () => {
    axios({
      method: "POST",
      baseURL: BASE_URL,
      data: { transient_token: carServiceData.session_id },
      url: `${BASE_URL}/web/my-gate-auth/`,
      withCredentials: false,
      headers: {
        "Content-Type": `application/json`,
      },
    })
      .then(({ data }) => {
        if (data.status === "success") {
          carServiceData.user_id = data.user_id;
          carServiceData.customer_mobile = data.username;
          carServiceData.email = data.email;
          setCarServiceConset(true);
          setLoading(false);
          setIsSlot(true);
          setMyGatePopUp(false);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const handleGetUserData = () => {
    if (type === "carService") {
      setLoading(true);
      handleCarServiceConset();
    } else {
      sendingData.src = "my_gate";
      sendingRoundWayData.src = "my_gate";
      sendingOutstationData.src = "my_gate";
      setLoading(true);
      axios({
        method: "POST",
        baseURL: BASE_URL,
        data: { transient_token: myGateToken },
        url: `${BASE_URL}/web/my-gate-auth/`,
        withCredentials: false,
        headers: {
          "Content-Type": `application/json`,
        },
      })
        .then((res) => {
          if (
            res.statusText === "OK" &&
            res.status === 200 &&
            res.data.status === "success"
          ) {
            // clevertap.setLocation(lat, lng);
            setUserInfo(res.data);
            setMyGateTokenData(res.data);
            setMyGatePopUp(false);
            setCookie("userid", res.data.uuid, { path: "/" });
            setConsentFlag(true);
            // fetchUserProfile(res.data.uuid);
            setLoading(false);
            clevertap.profile.push({
              Site: {
                Name: res.data.name,
                Identity: res.data.mobile, // String or number
                Email: res.data.email, // Email address of the user
                Phone: `+91${res.data.mobile}`, // Phone (with the country code)
              },
            });
            clevertap.event.push("consent_initiated", {
              "Service Name": "driver_service",
              status: "success",
              client: "mygate",
            });

            if (
              +activeKey === 0 &&
              firstCityId !== undefined &&
              secondCityId !== undefined &&
              previousChoice === "MyGateRequestBooking"
            ) {
              handleBookDriverBooking();
            } else if (
              +activeKey === 1 &&
              previousChoice === "MyGateRequestBookingRoundWay"
            ) {
              handleBookDriverBookingRoundTrip();
            } else if (
              +activeKey === 2 &&
              previousChoice === "MyGateRequestBookingOutstation"
            ) {
              handleBookDriverOutstation();
            }
          } else if (res.data.status === "error") {
            clevertap.event.push("consent_initiated", {
              "Service Name": "driver_service",
              status: "failed",
              client: "mygate",
            });
            toast.error(res.data.message);
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log(err.message);
          setLoading(false);
          toast.error(err.message);
        });
    }
  };
  const handleCloseConset = () => {
    setMyGatePopUp(false);
    setLoading(false);
  };
  return (
    <Fragment>
      <Drawer
        onClose={handleCloseConset}
        isOpen={myGatePopUp}
        anchor={ANCHOR.bottom}
        overrides={{
          Close: {
            style: ({ $theme }) => ({
              width: "40px",
              height: "40px",
            }),
          },
          DrawerContainer: {
            style: ({ $theme }) => ({
              outline: "none",
              width: "100%",
              height: "40%",
            }),
          },
        }}
      >
        <div className="shareCredentialsContainer">
          <div className="myGateAllowTextContainer">
            <center>
              <div>
                <img
                  src="https://ik.imagekit.io/driveu/DriveU_X_My_Gate_Icon_ordKTSZABa.png"
                  alt="MYGATE-DRIVEU"
                  width="22%"
                  height="auto"
                />
              </div>
            </center>

            <div style={{ fontSize: "18px", fontWeight: "700" }}>
              Connect With DriveU
            </div>
            <center>
              <div style={{ fontSize: "16px", fontWeight: "700" }}>
                DriveU requires your name, mobile number and complete address
                for your order fulfillment.
              </div>
            </center>
          </div>
          <div className="myGateAllowBtn">
            {loading ? (
              <Button
                overrides={{
                  BaseButton: {
                    style: ({ $theme }) => ({
                      width: "100%",
                      backgroundColor: "#f93356",
                    }),
                  },
                }}
                style={{ backgroundColor: "#f93356" }}
              >
                <LoadingSpinner />
              </Button>
            ) : (
              <Button
                onClick={handleGetUserData}
                overrides={{
                  BaseButton: {
                    style: ({ $theme }) => ({
                      width: "100%",
                      backgroundColor: "#f93356",
                    }),
                  },
                }}
                style={{ backgroundColor :msilTokenData.token && msilTokenData.utm_source == "msil" ? "#2C3394" : myGateToken ? " #f93356" : "#127e40" }}
              >
                Allow
              </Button>
            )}
          </div>
        </div>
      </Drawer>
    </Fragment>
  );
};

export default ConsentPopUp;
