import {
  GET_OTP_REQUEST,
  GET_OTP_REQUEST_SUCCESS,
  GET_VERIFY_REQUEST_SUCCESS,
  GET_VERIFY_REQUEST,
  CHANGE_VIEW_MODAL,
} from "./actiontypes";
import axios from "axios";
import crypto from "crypto";

const BASE_URL = process.env.REACT_APP_BASE_URL;
const OTP_SECURE_KEY = process.env.REACT_APP_OTP_SECURE_KEY;

function encryptData(data, key) {
  return crypto
    .createHmac("sha256", key)
    .update(data)
    .digest("hex");
}

export const getOtpRequest = (payload) => {
  return {
    type: GET_OTP_REQUEST,
    payload: payload,
  };
};
export const getOtpRequestSuccess = (payload) => {
  return {
    type: GET_OTP_REQUEST_SUCCESS,
    payload: payload,
  };
};
export const getVerifyRequest = (payload) => {
  return {
    type: GET_VERIFY_REQUEST,
    payload: payload,
  };
};
export const getVerifyRequestSuccess = (payload) => {
  return {
    type: GET_VERIFY_REQUEST_SUCCESS,
    payload: payload,
  };
};

export const changeViewModal = (payload) => {
  return {
    type: CHANGE_VIEW_MODAL,
    payload,
  };
};
export const getOtp = (userNumber) => {
  const currEpochTime = Date.now();
  const encryptedData = encryptData(
    userNumber.mobile.toString() + currEpochTime,
    OTP_SECURE_KEY
  );
  const objData = {
    mobile: userNumber.mobile,
    gCaptcha: encryptedData,
    time_stamp: currEpochTime,
  };

  return (dispatch) => {
    dispatch(getOtpRequest(userNumber.mobile));
    return axios({
      method: "post",
      baseURL: BASE_URL,
      url: "/web/send-otp/",
      data: objData,
      headers: {
        "Content-Type": `multipart/form-data`,
      },
    })
      .then((response) => {
        dispatch(getOtpRequestSuccess(response.data));
      })
      .catch((error) => {
        throw error;
      });
  };
};
