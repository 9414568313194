import React, { useRef, useEffect, useContext } from "react";
import SuccessDailyBooking from "../../Assets/lottie/SuccessLottie.json";
import Lottie from "lottie-web";
import duPageStyle from "./Page.module.css";
import { ApplicationContext } from "../../context/ApplicationContextProvider";
// import { useNavigate } from "react-router-dom";
const DailyThankyou = () => {
  const successDailyLoader = useRef(null);
  const { dailyBookingSuccessResponse } = useContext(ApplicationContext);
  // const navigate = useNavigate();
  useEffect(() => {
    const anim = Lottie.loadAnimation({
      container: successDailyLoader.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: SuccessDailyBooking,
    });
    return () => anim.destroy();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      window.location.href = dailyBookingSuccessResponse.redirect_url;
      // const queryParams = new URLSearchParams();
      // queryParams.append("order_id", dailyBookingSuccessResponse.booking_id);
      // queryParams.append("user_id", duDailyParams.user_id);

      // navigate(
      //   `/track-du-daily/?order_id${dailyBookingSuccessResponse.booking_id}&user_id=${duDailyParams.user_id}`
      // );
      // navigate(`/track-du-daily/?${queryParams.toString()}`);
    }, 3000);
  }, []);

  return (
    <div className={duPageStyle.successWrapper}>
      <div
        id="loader_lottie"
        ref={successDailyLoader}
        className={duPageStyle.successText}
      />
      <strong>Daily Driver Requested</strong>
      <div>Please wait for confirmation </div>
    </div>
  );
};

export default DailyThankyou;
