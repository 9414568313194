import React, { createContext, useRef } from "react";

export const SellCarContext = createContext();
function SellCarContextProvider({ children }) {
  const sellRef = useRef();
  const handleSellCar = () => {
    sellRef.current.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <SellCarContext.Provider value={{ handleSellCar, sellRef }}>
      {children}
    </SellCarContext.Provider>
  );
}

export default SellCarContextProvider;
