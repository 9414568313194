import React, { Fragment, useContext, useState } from "react";
import "../../BookingFlow/Bookingpage/DriveUVideo/DriveUVideo.css";
import { BsFillPlayCircleFill } from "react-icons/bs";
import { ApplicationContext } from "../../context/ApplicationContextProvider";
import { CarServiceMyGate } from "../../Components/AllImportFiles/ImportPng";
const Video = ({ src }) => {
  return (
    <div className="imageVideoMainContainer">
      <video
        autoPlay
        controls
        preload="auto"
        src={src}
        muted
        style={{ position: "relative" }}
        playsInline
      />
    </div>
  );
};

const Image = ({ onClick }) => (
  <div className="imageVideoMainContainer">
    <img src={CarServiceMyGate} loading="lazy" alt="Car Service" />
    <div
      onClick={onClick}
      //   style={{
      //     position: "absolute",
      //     top: myGateToken ? "35%" : "21%",
      //     left: "0%",
      //     backgroundColor: "transparent",
      //     color: "white",
      //   }}
      className="play-button"
    >
      <BsFillPlayCircleFill style={{ fontSize: "40px" }} />
    </div>
  </div>
);

function CarServiceVideo({ vidLink }) {
  const [isVideoModalOpen, setVideoModalOpen] = useState(false);
  const { myGateToken } = useContext(ApplicationContext);

  const handleClick = (e) => {
    e.preventDefault();
    setVideoModalOpen(true);
  };
  const videoStyle = {
    width: "100%",
    margin: "auto",
    marginTop: " 0%",
    position: "relative",
  };
  return (
    <Fragment>
      <div style={videoStyle}>
        {isVideoModalOpen ? (
          <Video src={vidLink} />
        ) : (
          <Image onClick={handleClick} myGateToken={myGateToken} />
        )}
      </div>
    </Fragment>
  );
}

export default CarServiceVideo;
