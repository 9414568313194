import React, { useContext, useEffect, useState } from "react";
import "./BuyAndSell.css";
import { DownloadContext } from "../../../context/DownloadContextProvider";
import { isMobile } from "react-device-detect";

const redirectUrl = [
  "https://play.google.com/store/apps/details?id=com.humblemobile.consumer&referrer=utm[…]3Dwebsite%26utm_medium%3Dstorebadge%26utm_campaign%3Dhomepage",
  "https://itunes.apple.com/in/app/driveu-hire-car-drivers-in/id1037756294",
];
function BuyAndSell() {
  const { myRef } = useContext(DownloadContext);

  const utmSource = localStorage.getItem("utm_source") || "website";
  const utmMedium = localStorage.getItem("utm_medium") || "storebadge";
  const utmCampaign = localStorage.getItem("utm_campaign") || "homepage";

  const [playStore, setPlayStore] = useState(`https://play.google.com/store/apps/details?id=com.humblemobile.consumer&utm_source=${utmSource}&utm_medium=${utmMedium}&utm_campaign=${utmCampaign}&page_title=${window.location.pathname}`);
  const [appStore, setAppStore] = useState(`https://apps.apple.com/in/app/driveu-hire-drivers-services/id1037756294?utm_source=${utmSource}&utm_medium=${utmMedium}&utm_campaign=${utmCampaign}&page_title=${window.location.pathname}`);
  useEffect(() => {
    const generateLink = window.generatedLink;


    if (generateLink) {
      if (isMobile) {
        setPlayStore(generateLink);
        setAppStore(generateLink);
      }
    }
  }, []);
  return (
    <div className="BuyAndSell_MainDiv" ref={myRef}>
      <img
        src="https://mcusercontent.com/6636107ab23ed3f25d40b366e/images/6e9c25ee-4bdd-e700-53ab-52076c524cf9.png"
        alt="App Download"
        loading="lazy"
        width="100%"
        height="100%"
        className="MobileMapImage"
      />

      <div className="BuyAndSell_TextDiv">
        <div className="textDownloadDiv">
          <h2 className="Buy_Sell_Heading">Simplify&nbsp;Car&nbsp;Ownership</h2>
          <div className="Buy_Sell_Content">
            Download the DriveU app on iOS / Android phones for a seamless car
            ownership experience. Track all your bookings and get rewarded for
            every transaction.
          </div>
        </div>
        <div className="App_Store_Image">
          <a href={playStore}>
            <img
              src="https://ik.imagekit.io/driveu/Website/1_kx2JKsHQC.png"
              alt="PlayStore"
              className="GooglePlay"
              loading="lazy"
            />
          </a>
          <a href={appStore}>
            <img
              src="https://ik.imagekit.io/driveu/Website/2_9snS8wDMo.png"
              alt="AppStore"
              className="GooglePlay ApplePlay"
              loading="lazy"
            />
          </a>
        </div>
      </div>
    </div>
  );
}

export default BuyAndSell;
