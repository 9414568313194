import React from "react";
import "./TermConditionNew.css";
function TermConditionNew() {
  return (
    <div className="TANDCwrapper">
      <h1 className="termHead">
        DriveU Terms & Conditions Updated effective from January 2024
      </h1>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              This Site/ Application/ Services is/are operated /provided by
              Humble Mobile Solutions Private Limited.
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              These terms and conditions (&#x201C;<strong>User Terms</strong>
              &#x201D;) apply to Your visit to and use, of the Site whether
              through a computer or a mobile phone, the Service and the
              Application, as well as to all information, recommendations and or
              services provided to You on or through the Site, the Service and
              the Application. This document is an electronic record in terms of
              Information Technology Act, 2000 and rules thereunder as
              applicable and the provisions pertaining to electronic records in
              various statutes as amended by the Information Technology Act,
              2000. This electronic record is generated by a computer system and
              does not require any physical or digital signatures. By clicking
              on the (&#x201C;<strong>I ACCEPT</strong>&#x201D;) button, You are
              consenting to be bound by these User Terms. PLEASE ENSURE THAT YOU
              READ AND UNDERSTAND ALL THESE USER TERMS BEFORE YOU USE THE SITE.
              If You do not accept any of the User Terms, then please do not use
              the Site or avail any of the services being provided therein. YOUR
              AGREEMENT TO THESE USER TERMS SHALL OPERATE AS A BINDING AGREEMENT
              BETWEEN YOU AND DRIVEU IN RESPECT OF THE USE AND SERVICES OF THE
              SITE.
            </span>
          </span>
        </span>
      </p>
      <div>
        <p>
          Your acceptance of the User Terms shall be deemed to include your
          acceptance of the privacy policy available at
          https://www.driveu.in/privacy-policy.
        </p>
        <p>
          By accepting these User Terms, you also allow DriveU to send you
          promotional emails and SMS alerts from time to time.
        </p>
      </div>
      {/* <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              These terms and conditions of use (‘’
              <span style={{ fontWeight: "800" }}>Terms of Use</span>’’) of the
              DriveU website and mobile app, is a legal agreement("
              <span style={{ fontWeight: "800" }}>Agreement</span>") between
              Humble Mobile Solutions Pvt. Ltd. (“
              <span style={{ fontWeight: "800" }}>Humble Mobile</span>”), a
              company incorporated under the Companies Act, 2013 (India) having
              its offices at 112/2 KHB Colony, 17th Main, 5th Block,
              Koramangala, Bengaluru – 560095, Karnataka and
              yourself/yourselves. If you continue to browse the website and/or
              use the mobile application you are agreeing to comply with and be
              bound by the Terms of Use.
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              If you do not agree with the Terms of Use, please do not access
              and use this Website / Application (as hereinafter defined) or our
              Service through all other means.
            </span>
          </span>
        </span>
      </p> */}
      <p className="NumberText">
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span className="sub-head">1. DEFINITIONS:</span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              All of the defined and capitalized terms in these User Terms will
              have the meaning assigned to them here below:
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              &#x201C;<strong>Account</strong>&#x201D; shall mean the account
              created by the Customer on the Application for availing the
              Services provided by DriveU.
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              &#x201C;<strong>Additional Fee/charges</strong>&#x201D; shall mean
              any toll duty, inter-state taxes, etc. as may not be included in
              the Fare but payable to any third party / government authorities
              for undertaking the Booking under Applicable Law
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              &#x201C;<strong>Applicable Laws</strong>&#x201D; shall mean and
              include all applicable statutes, enactments, acts of legislature
              or parliament, laws, ordinances, rules, by-laws, regulations,
              notifications, guidelines, policies, directions, directives and
              orders of any governmental authority, tribunal, or a court of
              India.
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              &#x201C;<strong>Application</strong>&#x201D; shall mean the mobile
              application &#x201C;<strong>DriveU</strong>&#x201D; updated by
              DriveU from time to time.
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              &#x201C;<strong>Convenience & Platform Fee</strong>&#x201D; shall
              mean the fee payable by the Customer to DriveU for the Service
              i.e., for availing the technology services offered by DriveU.
              Convenience and Platform Fee shall be chargeable on every booking
              made through the Site. The Convenience and Platform Fee shall be
              exclusive of all applicable taxes on the Convenience and Platform
              Fee, if any.
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              &#x201C;<strong>Cancellation Fee</strong>&#x201D; shall mean the
              fare and Convenience Fee payable towards cancellation of a Booking
              by a Customer in terms of Clause 7 of these User Terms and the
              Cancellation Terms and Conditions available at
              https://www.driveu.in/refund-policy. The Cancellation Fee shall be
              exclusive of all applicable taxes on the Cancellation Fee, if any.
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              &#x201C;<strong>TPSP</strong>&#x201D; shall mean a Third Party
              Service Provider and/or Driver and/or Wash Executives and/or an
              operator associated with us, offering the services within the City
              of Operation as requested by the Customer on the
              Application/Website/Phone call.
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              &#x201C;<strong>City of Operation</strong>&#x201D; shall mean a
              city in which the Customers and TPSPs avail and render the various
              services respectively. For clarity, the services rendered by the
              TPSPs and availed by the Customers shall be in the same city.
            </span>
          </span>
        </span>
      </p>

      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              &#x201C;<strong>Customer/ You</strong>&#x201D; means a person who
              has an Account on the Application.
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              &#x201C;<strong>Driver</strong>&#x201D; shall mean independent
              third-party service providers (TPSPs) who have opted to enlist
              themselves with DriveU to offer their services within the City of
              Operation. The drivers are screened and trained prior to being
              listed as a driver on the DriveU platform. At no point can the
              driver represent himself to be an employee of Humble Mobile
              Solutions Private Limited/DriveU.
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              &#x201C;<strong>E-Wallet</strong>&#x201D; shall mean a pre-paid
              instrument, which can be used to make payments.
            </span>
          </span>
        </span>
      </p>
      <div>
        <p>
          <span style={{ fontSize: "10.5pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#444545" }}>
                &#x201C;<strong>Fare</strong>&#x201D; shall mean such amount in
                Indian Rupees, may comprise one or more of the following
                components that shall be levied to the ride based on various
                parameters including but not limited to distance travelled, time
                taken for the ride, city in which the ride is hailed, time of
                the day, availability of drivers nearby-
              </span>
            </span>
          </span>
        </p>
        <br />
        <div className="definitionList">
          <ol>
            <li>Minimum Fare</li>
            <li>Night Charges (if applicable)</li>
            <li>Extra per kilometer Charges</li>
            <li>Extra per minute charges</li>
            <li>Platform Fee</li>
            <li>Convenience Fee</li>
            <li>DU Secure Fee (if applicable)</li>
            <li>Cancellation Charges (if applicable)</li>
            <li>Past dues (if any)</li>
            <li>Waiting Charges (if any)</li>
            <li>Applicable Taxes</li>
          </ol>
        </div>
        <br />
      </div>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              &#x201C;<strong>Force Majeure Event</strong>&#x201D; shall mean
              any event arising due to any cause beyond the reasonable control
              of DriveU
            </span>
          </span>
        </span>
      </p>

      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              &#x201C;<strong>DriveU</strong>&#x201D; or &#x201C;
              <strong>us</strong>&#x201D; &#x201C;<strong>we</strong>&#x201D; or
              &#x201C;<strong>our</strong>&#x201D; or &#x201C;
              <strong>E-commerce Operator</strong>&#x201D; shall mean Humble
              Mobile Solutions Private Limited, a company incorporated under the
              provisions of the Companies Act, 2013 and having its corporate
              office at 19, 3rd Floor, 1st Cross, KHB Colony, 5th Block,
              Koramangala, Bangalore-560095, which expression shall, unless it
              be repugnant to the context or meaning thereof, be deemed to mean
              and include all its successors, affiliates and permitted assigns.
            </span>
          </span>
        </span>
      </p>

      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              &#x201C;<strong>Registration Data</strong>&#x201D; shall mean and
              may include the present, valid, true and accurate name, email ID,
              phone number and such other information as may be required by
              DriveU from the Customer from time to time for registration on the
              Application.
            </span>
          </span>
        </span>
      </p>

      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              &#x201C;<strong>Booking</strong>&#x201D; shall mean the service
              requested by the Customer facilitated through the Site or mobile
              application or phone call.
            </span>
          </span>
        </span>
      </p>

      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              &#x201C;<strong>Service(s)</strong>&#x201D; shall mean the service
              requested by the Customer facilitated through the Site or mobile
              application or phone call.
            </span>
          </span>
        </span>
      </p>

      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              &#x201C;<strong>Site</strong>&#x201D; shall mean the Application
              and the website https://www.driveu.in operated by DriveU or any
              other software that enables the use of the Application or such
              other URL as may be specifically provided by DriveU.
            </span>
          </span>
        </span>
      </p>

      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              &#x201C;<strong>Total Booking Fee</strong>&#x201D; shall mean and
              include the Fare, the Convenience Fee, the Cancellation Fee and
              Additional Fee and taxes as may be applicable from time to time.
            </span>
          </span>
        </span>
      </p>

      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              &#x201C;<strong>T&Cs</strong>&#x201D; and &#x201C;
              <strong>User Terms</strong>&#x201D; shall mean these Customer
              terms and conditions.
            </span>
          </span>
        </span>
      </p>

      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              &#x201C;<strong>Vehicle</strong>&#x201D; shall mean a motor cab as
              defined under the Motor Vehicles Act, 1988
            </span>
          </span>
        </span>
      </p>

      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span className="sub-head">2. ELIGIBILITY</span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              You will be "Eligible" to use the Services only when You fulfil
              all of the following conditions:
            </span>
          </span>
        </span>
      </p>
      <div className="definitionList">
        <ol>
          <li>You have attained at least 18 (eighteen) years of age.</li>
          <li>
            You are competent to enter into a contract under the Applicable
            Laws.
          </li>
        </ol>
      </div>

      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              If You reside in a jurisdiction that restricts the use of the
              Service because of age, or restricts the ability to enter into
              contracts such as this User Terms due to age, You must abide by
              such age limits.
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span className="sub-head">3. REGISTRATION AND ACCOUNT</span>
          </span>
        </span>
      </p>

      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              You understand and acknowledge that You can register on the Site
              only after complying with the requirements of this Clause 3 and by
              entering Your Registration Data.
            </span>
          </span>
        </span>
      </p>

      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              You shall ensure that the Registration Data provided by You is
              accurate, complete, current, valid and true and is updated from
              time to time. We shall bear no liability for false, incomplete,
              old or incorrect Registration Data provided by You.
            </span>
          </span>
        </span>
      </p>

      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              You are solely responsible for maintaining the confidentiality of
              Your Registration Data and will be liable for all activities and
              transactions that occur through Your Account, whether initiated by
              You or any third party. Your Account cannot be transferred,
              assigned or sold to a third party. We shall not be liable for any
              loss that You may incur as a result of someone else using Your
              password or Account, either with or without Your knowledge.
            </span>
          </span>
        </span>
      </p>

      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              We reserve the right to suspend or terminate Your Account with
              immediate effect and for an indefinite period, if We have a reason
              to believe that the Registration Data or any other data provided
              by You is incorrect or false, or that the security of Your Account
              has been compromised in any way, or for any other reason We may
              find just or equitable
            </span>
          </span>
        </span>
      </p>

      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              Except for the Registration Data or any other data submitted by
              You during the use of any other service offered through Site
              (<strong>Permitted Information</strong>), DriveU does not want You to, and You
              should not, send any confidential or proprietary information to
              DriveU on the Site or otherwise, unless otherwise is required by
              Applicable Laws. In accepting these User Terms You agree that any
              information or materials that You or individuals acting on Your
              behalf provide to DriveU other than the Permitted Information will
              not be considered confidential or proprietary.
            </span>
          </span>
        </span>
      </p>

      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              It is Your responsibility to check to ensure that You download the
              correct application for Your device. We are not liable if You do
              not have a compatible mobile device or if You download the wrong
              version of the Application for Your mobile device. We reserve the
              right to terminate the Service and the use of the Application
              should You be using the Service or Application with an
              incompatible or unauthorized device.
            </span>
          </span>
        </span>
      </p>

      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              We allow You to open only one Account in association with the
              Registration Data provided by You. In case of any unauthorized use
              of Your Account please immediately reach Us at{" "}
              <a href="care@driveu.in">care@driveu.in.</a>
            </span>
          </span>
        </span>
      </p>

      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              In case, You are unable to access Your Account, please inform Us
              at <a href="care@driveu.in">care@driveu.in</a> and make a written
              request for blocking Your Account. We will not be liable for any
              unauthorized transactions made through Your Account prior to the
              expiry of 72 (seventy two) hours after You have made a request in
              writing for blocking Your Account, and shall not have any
              liability in case of Force Majeure Event.
            </span>
          </span>
        </span>
      </p>

      <p className="NumberText">
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span className="sub-head">4. SERVICES</span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              The Application/Site permits you to avail the various services
              offered by TPSPs. The Service allows You to send a request through
              DriveU to a Driver or TPSPS on the DriveU network. The Driver/TPSP
              has sole and complete discretion to accept or reject each request
              for Service. If the Driver/TPSP accepts a request, DriveU notifies
              You and provides information regarding the Driver/TPSP - including
              Driver/TPSP name, telephone contact details of the Driver/TPSP and
              such other details as DriveU may determine.
            </span>
          </span>
        </span>
      </p>

      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              DriveU shall procure reasonable efforts to bring You into contact
              with a Driver/TPSP, subject to the availability of Driver/TPSP in
              or around Your location at the moment of Your request for such
              services.
            </span>
          </span>
        </span>
      </p>

      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              DriveU does not guarantee the availability of service
              providers/TPSPs at all times. While we strive to ensure timely
              access to our network of TPSPs/service providers, availability may
              vary based on factors beyond our control, including geographic
              location, time, demand, or other unforeseen circumstances. DriveU
              is not liable for any inconvenience caused due to the
              unavailability of service providers.
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              By using the Application or the Service, You further agree that
            </span>
          </span>
        </span>
      </p>

      <div className="definitionList">
        <ol>
          <li>
            You will only use the Service or download the Application for Your
            sole, personal use and will not resell or assign it to a third party
          </li>
          <li>
            You will not use an account that is subject to any rights of a
            person other than You without appropriate authorization;
          </li>
          <li>You will not use the Service or Site for unlawful purposes;</li>
          <li>
            You will not try to harm the Service, Site or our network in any way
            whatsoever;
          </li>
          <li>
            You will provide DriveU with such information and documents which
            DriveU may reasonably request;
          </li>
          <li>You will only use an authorized network to avail the Service;</li>
          <li>
            You are aware that when requesting Services, whether by message, via
            Site or mobile application or calling the call center of DriveU,
            standard messaging charges, data charges, voice charges, as
            applicable, of the Your and Our phone network service providers,
            will apply
          </li>
          <li>
            You will comply with all Applicable Law from Your country of
            domicile and residence and the country, state and/or city in which
            You are present while using the Site or Service;
          </li>

          <li>
            You are aware of and shall comply with the Information Technology
            Act, 2000 and the rules, regulations and guidelines notified
            thereunder.
          </li>
        </ol>
      </div>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              DriveU reserves the right to immediately terminate the Service and
              the use of the Application in the event of non-compliance with any
              of the above requirements. Further, DriveU will store the
              information provided by You or record your calls for contacting
              You for all Service-related matters. You shall promptly inform
              DriveU on any change in the information provided by You
            </span>
          </span>
        </span>
      </p>

      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              DriveU or an authorised representative of DriveU, shall provide
              information regarding services, discounts and promotions provided
              by DriveU to You by way of an SMS or email to Your registered
              mobile number/registered email ID. You also have the option to
              discontinue receiving such information at any point of time. To
              discontinue receiving such information, You may at any point of
              time visit the specific link provided in the Site to discontinue
              the same.
            </span>
          </span>
        </span>
      </p>

      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              DRIVEU WILL BE ENTITLED TO PROCESS AND TRANSFER YOUR INFORMATION
              AS AND WHEN IT DEEMS FIT AND IT MAY STORE OR TRANSFER YOUR
              INFORMATION IN A SERVER OUTSIDE INDIA OR THE COUNTRY WHERE YOU ARE
              LOCATED IN ORDER TO PERFORM DRIVEU’S OBLIGATIONS UNDER THESE
              CUSTOMER T&C.
            </span>
          </span>
        </span>
      </p>

      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              You agree to grant DriveU a non-exclusive, worldwide, perpetual,
              irrevocable, royalty-free, sub-licensable (through multiple tiers)
              right to exercise the copyright, publicity, database rights or any
              other rights You have in your information, in any media now known
              or not currently known, with respect to Your information. YOU
              AGREE AND PERMIT DRIVEU TO SHARE YOUR INFORMATION AND/OR PERMITTED
              INFORMATION, WITH THIRD PARTIES.
            </span>
          </span>
        </span>
      </p>

      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              DriveU will be entitled to enter into any tie-up in terms of
              joint-venture or otherwise with any other institution engaged in
              the business of providing services analogous and/or similar to
              those herein contained. In such case, depending upon the modality
              and the mechanism as may be devised, You will be provided with the
              services by DriveU jointly and/or severally with the party/ies in
              joint venture. You hereby give Your irrevocable consent and
              permission to such a tie-up. In the event of such a tie-up, the
              terms and conditions herein contained will, mutatis mutandis,
              become applicable in respect of such tie-up arrangement also.
            </span>
          </span>
        </span>
      </p>

      <p className="NumberText">
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span className="sub-head">5. CONFIRMATION OF BOOKING:</span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              DriveU shall, upon receiving the booking request from You in the
              manner set out above, proceed to confirm or decline the booking
              based on the availability of service providers/TPSPs at the pickup
              time, which shall be informed to You vide an SMS or email. In the
              event the booking is confirmed, You shall check the booking
              details including but not limited to pick up time and pick up
              place, and if there is incorrect detail, the same needs to be
              informed to us immediately by calling our call centre.
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              You shall bear the consequences and damages for any delay that may
              be caused to You due to Your failure to check the confirmation SMS
              or email or failure to inform DriveU of the incorrect details
              immediately.
            </span>
          </span>
        </span>
      </p>
      <p className="NumberText">
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span className="sub-head">6. PAYMENT:</span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              DriveU shall provide an estimate of the Total Fare to You at the
              beginning of every booking. Such an estimate provided by DriveU
              shall be subject to change on account of several factors and shall
              be different to the actual Total Fare levied at the end of ride.
              The Customer shall pay the actual Total Fare shown at the end of
              the ride in accordance with terms mentioned herein.
            </span>
          </span>
        </span>
      </p>

      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              You understand and accept that the Total Fare including the
              applicable taxes, shall be payable by You to DriveU immediately
              upon completion of the Ride. Any difference arising at the end of
              the booking from the estimate shall be payable by You to DriveU
              immediately upon completion of the Ride.
            </span>
          </span>
        </span>
      </p>

      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              Convenience Fee / Platform Fee charged by DriveU towards the
              platform services provided by DriveU, shall be determined and
              amended at the sole and absolute discretion of DriveU. You shall
              be required to pay such Additional Fee, which will form part of
              the receipt of the Total Booking Fare.
            </span>
          </span>
        </span>
      </p>

      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              You shall be required to pay such Cancellation Fee in terms of
              Clause 7, which will form part of the receipt of the Total Booking
              Fee.
            </span>
          </span>
        </span>
      </p>

      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              In addition to the Total Booking Fee, You may be required to pay
              such other charges incurred by you during the Booking in cash,
              which are not included in the receipt of the Total Booking Fee.
            </span>
          </span>
        </span>
      </p>

      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              DriveU shall provide a receipt of the Total Booking Fee payable by
              You at the end of the Booking, however, separate tax invoices
              raised by the TPSPs for the Fare and Additional Fee, and the
              Convenience Fee raised by DriveU shall be provided to You on
              request. You may raise a request for a copy of the invoices from
              the Support page.
            </span>
          </span>
        </span>
      </p>

      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              All applicable taxes in respect of the Fare, Convenience and
              Platform Fee, Additional Fee, Cancellation Fee shall be borne and
              payable by You to the TPSPs or DriveU, as the case may be.
            </span>
          </span>
        </span>
      </p>

      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              You shall choose to pay the Total Ride Fee by the modes of payment
              available to You by DriveU on the Site, in addition to Cash
              payment to the Driver after the completion of the Ride
            </span>
          </span>
        </span>
      </p>

      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              You may choose to pay the Total Fare payable by You, in cash
              directly to the TPSP upon completion of the booking, only if the
              chosen method of payment is Cash or in such other case the
              Application may permit you to pay Cash.
            </span>
          </span>
        </span>
      </p>

      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              Credit Card/ Debit Card/ Net Banking Payment: You will be required
              to provide relevant payment details including Credit Card / Debit
              Card / Net Banking details (“Card Details”) to process payment of
              the Total Ride Fee and You authorize DriveU and an entity
              authorized by DriveU for providing payment gateway/processor
              services (“Payment Processor”) to access the Card details for
              processing the payment of Total Ride Fee. In this respect, it is
              clarified that the Payment Processor whose services are utilized
              for the purposes of the Site and/or Application and/or Services
              shall be compliant with PCI-DSS (Payment Card Industry – Data
              Security Standard) or such other standard notified by the relevant
              authority from time to time. By using the services of the Payment
              Processor, You understand and agree to the Terms and Conditions of
              the Payment Processor as may be issued by the Payment Processor
              from time to time. Your authorization
            </span>
          </span>
        </span>
      </p>

      <div className="definitionList">
        <ol>
          <li>
            permits DriveU and the Payment Processor to debit or credit the bank
            account or debit/credit card account associated with Your payment
            details;
          </li>
          <li>
            permits DriveU and the Payment Processor to use Your Card Details
            for the processing of transactions initiated by You by accessing you
            account;
          </li>
          <li>
            subject to the prevalent laws, will remain in effect as long as You
            maintain an Account (and if You delete Your Card Details or Account,
            DRIVEU or the Payment Processor will not be able to process any
            further transactions initiated by You); and
          </li>
          <li>
            is subject to any other terms and conditions of the Payment
            Processor specified through the Application, SMS or other methods
            from time to time.
          </li>
        </ol>
      </div>

      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Medium" }}>
            <span style={{ color: "#444545" }}>
              DriveU offers You the facility of making an online payment through
              an E-Wallet (DU Money) powered by a third-party payment processor
              (“Wallet Service Provider”).
            </span>
          </span>
        </span>
      </p>

      <div className="definitionList">
        <ol>
          <li>
            You may avail the Services of the Wallet, upon successful
            registration and creation of an Account on the Site, unless
            restricted due to Applicable Law or due to internal policies of
            DriveU.
          </li>
          <li>
            The currency of issue of the Wallet shall be Indian Rupees (INR).
          </li>
          <li>
            You can reload the Wallet by using only those modes of payment as
            enabled by Us and permitted under Applicable Law. These payment
            modes may be revised by Us from time to time at Our sole discretion.
          </li>
          <li>
            When You opt to load money into Your Wallet, You will be required to
            provide certain information ("Payment Details") for the purpose of
            reloading. You acknowledge and agree that by providing such Payment
            Details, You authorise Us or any third party appointed for such
            purpose, to reload Your Wallet. The Payment Details You provide must
            be true, complete, current and accurate. We do not bear any
            liability for the consequences if You provide false, incomplete or
            incorrect Payment Details.
          </li>
          <li>
            Subject to the payment mode made available to You by Us, You hereby
            represent and warrant that You are legally and fully entitled to use
            any debit card, credit card, bank or any other mode that You may use
            to load funds into Your Wallet. We shall not be held responsible in
            case You don’t have sufficient funds or authorised access to use
            such payment modes to load Your Wallet.
          </li>
          <li>
            You hereby agree that, for the purpose of fraud prevention and
            security of the Wallet operations, We have the right to reduce
            and/or modify the re-loading limits and such other limits as may be
            prescribed in relation to Your Wallet. Please visit Our Site
            regularly and read these User Terms to be updated of any changes.
            You can also visit the RBI website for further details on the PPI
            Master Directions.
          </li>
          <li>
            You hereby authorize Us to hold, receive and disburse funds in Your
            Wallet in accordance with any payment instructions We receive from
            You.
          </li>
          <li>
            All Transactions shall be subject to: (i) the amount of the
            outstanding balance existing in the Wallet at the time of initiating
            a Transaction, (ii) the RBI Regulations, including any variations in
            the prescribed limits, and/or (iii) any other limits/ conditions as
            may be prescribed by Us from time to time.
          </li>
          <li>
            In case of any suspicious transactions, DriveU may also impose such
            other security measures as it deems fit, to ensure that the balance
            in the Wallet Account is not misused, wrongfully diverted etc.
            DriveU will, on a best efforts basis, try and intimate You of any
            such measures/ restrictions. Please contact customer care in case of
            any queries in this regard.
          </li>
          <li>
            You may not withdraw and/or redeem (for cash) any outstanding
            balance in the Wallet, unless otherwise expressly provided by Us.
            You may not transfer Your Wallet Account to any third party.
          </li>
          <li>
            In the event there is no transaction carried out on Your Wallet
            consecutively for a period of 1 (one) year or any other time limit
            as may be prescribed under Applicable Laws, Your Wallet will be made
            inactive. Once Your Wallet is made inactive, it can be reactivated
            only after completing the applicable due diligence process.
          </li>
          <li>
            Wallets are not and shall not be used as a domestic remittance
            service and as such no Customer will be entitled to redeem and/or
            cause to be redeemed the same for cash.
          </li>
          <li>
            Wallet services are being offered as a service using mobile phones,
            mobile application, including mobile application operated through
            the Site, using SMS and may also be offered by other methods as
            intimated by Us to Customers from time to time. However, different
            mobile service providers may have different restrictions /
            limitations in their packages offered to their mobile subscribers.
            DriveU is not responsible for any such limited service offering from
            any mobile service provider or for such differences in between any
            two or more such providers. You may be charged a fee for using SMS
            and mobile services by Your mobile operator, and DriveU is not
            responsible for such charges.
          </li>
          <li>
            DriveU does not permit, facilitate or allow the following services
            on the Site and payments for the following services on or through
            the use of the Site and/or the Wallets:
            <ul>
              <li>Online gambling</li>
              <li>Remittance services</li>
              <li>Any other service prohibited under Applicable Laws.</li>
            </ul>
          </li>
          <li>
            You understand that advertising plays an important role in the
            provision of this Service, and that We will display advertisements
            and other information whether as part of the Services or otherwise,
            which You may use. Subject to Applicable Laws, We may periodically
            send promotional materials to you using email/SMS/Whatsapp/any other
            channel about services offered by Us and Our advertisers and any
            communication so received by You from Us will not amount to spam,
            unsolicited communication or a violation of Your registration on the
            national do not call registry.
          </li>
          <li>
            In many cases, We provide services of third parties, or our Services
            in conjunction with that of third parties. In those cases, We
            provide such Services subject to the terms, conditions and
            limitations imposed by those third parties, which shall also be
            binding on You. If those third parties change, suspend or stop
            providing such services, we may similarly change, suspend or stop
            providing the Services to You without notice. We may nevertheless
            endeavour to provide such a Service in another way or by using
            another third party. You shall have no claims against Us in this
            regard. You authorise Us to provide any of Your Personal Information
            (as defined below) to those third parties to the extent that it may
            be necessary to enable Us and the third parties to provide the
            services to You. To the extent that there is a conflict between the
            third party's terms and conditions and these User Terms, these User
            Terms, with regard to DriveU, will prevail.
          </li>
          <li>
            We reserve the right to reject or refuse any third party service
            used by You in conjunction with our Service
          </li>
          <li>
            We will take every reasonable effort not to share any of the Payment
            Details, Registration Data or other personal information
            (collectively, "Personal Information") You provide Us, except as
            required under RBI Regulations and Applicable Laws, or to the extent
            necessary for Us to implement any payment instructions We receive
            from You or as per the terms of the Privacy Policy. Please read Our
            Privacy Policy for further details regarding the use of Your
            Personal Information. We will bear no liability for the consequences
            in any event where Your Personal Information has been willingly or
            inadvertently shared by You with any third party.
          </li>
        </ol>
      </div>

      <p className="NumberText">
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span className="sub-head">Refunds</span>
          </span>
        </span>
      </p>
      <div className="definitionList">
        <ul>
          <li>
            In the event that You desire a refund on any amount that has been
            debited from Your Wallet account please email care@driveu.in and
            make a refund request clearly explaining the circumstances of Your
            refund request.
          </li>
          <li>
            Where We determine that a refund request is valid, We shall make
            reasonable efforts to honour the refund request and return the
            requisite funds to Your Wallet, subject to Applicable Laws.
          </li>
          <li>
            Please note that RBI Regulations do not permit Us to refund any
            balance by way of cash redemption for the funds existing in Your
            Wallet to You.
          </li>
          <li>
            In case Your Wallet is erroneously loaded/ reloaded, after assessing
            the validity of the written request from You or at our sole
            discretion, We may process to credit the funds to the same source
            from where these were received, subject to Applicable Laws and
            policies.
          </li>
          <li>
            We will make reasonable efforts to respond to Your refund request at
            the earliest. Please note that We will not be responsible for
            delays, which may be caused by any third parties such as banks and
            Merchants, on whom We rely while processing Your refund request, or
            for any delay caused in the case of any Force Majeure events which
            are beyond Our reasonable control. Therefore, We bear no liability
            for the processing of the refund requests.
          </li>
        </ul>
      </div>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              E-Wallet money will not be applicable on bookings made through the
              phone or while the user is not logged into his/her Account on the
              Site.
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              The processing of payments, in connection with Your use of the
              E-Wallet will be subject to the terms, conditions, and privacy
              policies of the Wallet Service Provider that DriveU engages for
              the purpose.
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              DriveU will not be responsible for any errors by the Wallet
              Service Provider in any manner and any dispute arising out of such
              errors shall be directly settled between You and the Wallet
              Service Provider. Further, even in the case of E Wallet payments,
              all Additional Fee (defined below) shall have to be paid by You in
              cash, to the authority or person concerned or if already paid by
              the Driver, to the Driver.
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              When You choose to make an E-Wallet payment, DriveU shall collect
              the Total Ride Fare or any part of the Total Ride Fare on behalf
              of the Driver/ TPSP who will be responsible for providing such
              Services.
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              E- Wallet is a Closed System Payment Instrument as defined by RBI.
              This payment instrument is issued by DriveU, for use for services
              provided by DriveU only. These instruments do not permit cash
              withdrawal or redemption.
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              Any payment related issue, except when such issue is due to an
              error or fault in the Site, shall be resolved between You and the
              Payment Processor. DriveU shall not be responsible for any
              unauthorized use of Your E-Wallet during or after availing the
              Services on the Site.
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              Subject to these Customer Terms, the Total Fare paid by You is
              final and non-refundable, unless otherwise determined by DriveU.
              If any amount paid by You is fully or partially refundable for any
              reason, such amounts will be refunded to You by DRIVEU to the same
              account from which the payment was made or if the same could not
              be processed successfully then as vouchers to be used in the
              Application. You may contact DRIVEU and/or its Affiliates for any
              issues arising therefrom
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              Any payment processing-related issue not caused by an error or
              fault with the Application must be resolved by You and the
              relevant Payment Processor.
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              You agree that use of certain Promo Codes/ Vouchers may result in
              different charges for the same services and shall not have a
              bearing on charges applied to You unless the Promo Codes have been
              specifically made available to You.
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              In the event of a default or failure to pay the Total Ride Fee for
              any reason by You: (i) DRIVEU may restrict You from booking a new
              Ride through the Application until the outstanding Total Ride Fee
              in respect of the previous Ride(s) has been paid by You; (ii) You
              agree and acknowledge that DRIVEU shall recover any outstanding
              monies payable by You for Ride(s) in respect of which Total Ride
              Fee has not been paid; and (iii) You will be responsible, and must
              pay, for all costs incurred by DRIVEU (including costs for which
              DRIVEU may be contingently liable) in any attempt to collect any
              monies owed by You to DriveU’s TPSPs under these Customer Terms
              including debt collection agent costs, repossession costs,
              location search costs, process server costs and solicitor costs on
              a solicitor/client basis.
            </span>
          </span>
        </span>
      </p>
      <p className="NumberText">
        <span style={{ fontSize: "11pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span className="sub-head">7. CANCELLATION POLICY</span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              You agree and acknowledge that You may cancel Your request for the
              services at any point of time subject to a Cancellation Fee as
              explained below:
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              In Driver / Car Wash services Cancellation fee will be charged
            </span>
          </span>
        </span>
      </p>
      <ul>
        <li style={{ listStyleType: "disc" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                If you cancel after 5 minutes after the TPSP is allotted or
              </span>
            </span>
          </span>
        </li>
        <br />
        <li style={{ listStyleType: "disc" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                If a TPSP cancels after waiting at your location for more than
                10 minutes
              </span>
            </span>
          </span>
        </li>
        <br />
        <li style={{ listStyleType: "disc" }}>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: "Circular-Std-Book" }}>
              <span style={{ color: "#000000" }}>
                Notwithstanding the foregoing, if the TPSP is delayed by more
                than five (5) minutes to pick You, You will not be charged any
                Cancellation Fee
              </span>
            </span>
          </span>
        </li>
        <br />
      </ul>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              You shall be notified of the applicable Cancellation Fee in
              advance whenever You attempt to cancel a booking/service request.
              The notification shall be on the Application and/or the Site.
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              DriveU shall provide a receipt of the Cancellation Fee, if any,
              payable by You for every cancellation in terms of the table above
              for such cancellations, however, separate invoices raised by the
              TPSPs for the Cancellation Fee, and DriveU for the Convenience Fee
              on cancellations shall be provided to You on request. You may
              raise a request for a copy of the invoices from Support page.
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              The Cancellation Fee shall be payable by You at the completion of
              Your subsequent Booking.
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              The mode of payment of the Cancellation Fee shall be in terms of
              Clause 7 of these User Terms.
            </span>
          </span>
        </span>
      </p>
      <p className="NumberText">
        <span style={{ fontSize: "11pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span className="sub-head">8. USER VIOLATION OF USER TERMS</span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              You shall not smoke and drink in the Vehicles or misbehave with
              the Driver or act in Violation of Applicable Law. In the event You
              are found to be involved in the activities set out above, You
              shall be liable to pay a fine to us and we shall also have the
              right to terminate the Booking. In the event You fail to pay fine
              after the completion of the Booking, we may at our discretion,
              take such steps as may be available to us under Applicable Law.
              You shall also be blacklisted as a result of non-payment of the
              fine or misbehaving as the case may be, and in such event, Your
              Account may be terminated by DriveU.
            </span>
          </span>
        </span>
      </p>
      <p className="NumberText">
        <span style={{ fontSize: "11pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span className="sub-head">
              9. CUSTOMER RELATIONSHIP MANAGEMENT
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              All issues, opinions, suggestions, questions and feedback while
              availing our Services shall be communicated to us through email
              care@driveu.in. In case of a Booking booked on our Application,
              You shall be required to rate the Booking after its termination.
              You agree to be fair, accurate and non-disparaging while leaving
              comment, feedbacks, testimonials or reviews on or about the
              Bookings or Services.
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              Reporting of any issue needs to be within 24 hours of the
              happening of the issue, failing which, such issue will not be
              addressed.ment
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              Any issue reported on channels other than the above may be
              addressed by DriveU only on a best-effort basis. DriveU takes no
              liability for inability to get back on other channels.
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              DriveU shall endeavor to respond to Your issues within 2 (two)
              working days of Your reporting the same and endeavor to resolve it
              at the earliest possible. It is hereby clarified that issues are
              resolved on severity basis, and certain may be resolved earlier
              than the other. However, DriveU shall not be liable for any
              damages or losses in the event You are not satisfied with any such
              resolution.
            </span>
          </span>
        </span>
      </p>
      <p className="NumberText">
        <span style={{ fontSize: "11pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span className="sub-head">10. FORCE MAJEURE</span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              We shall not be liable for any failure to perform any obligations
              under this User Terms, if the performance is prevented, hindered
              or delayed by a Force Majeure Event and in such case our
              obligations under this User Terms shall be suspended for so long
              as the Force Majeure Event continues.
            </span>
          </span>
        </span>
      </p>
      <p className="NumberText">
        <span style={{ fontSize: "11pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span className="sub-head">11. INDEMNIFICATION</span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              By accepting these User Terms and using the Service, You agree
              that You shall defend, indemnify and hold DriveU, their
              affiliates, their licensors, and each of their officers,
              directors, other users, employees, attorneys and agents harmless
              from and against any and all claims, costs, damages, losses,
              liabilities and expenses (including attorneys' fees and costs)
              arising out of or in connection with: (a) Your Violation or breach
              of any term of these User Terms or any Applicable Law or
              regulation, whether or not referenced herein; (b) Your Violation
              of any rights of any third party, including the TPSPs or (c) Your
              use or misuse of the Application or Service.
            </span>
          </span>
        </span>
      </p>
      <p className="NumberText">
        <span style={{ fontSize: "11pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span className="sub-head">12. LIABILITY</span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              The information, recommendations and/or Services provided to You
              on or through the Site, the Application and DriveU call center are
              for general information purposes only and does not constitute
              advice. DriveU will reasonably keep the Site and its contents
              correct and up to date but does not guarantee that (the contents
              of) the Site is free of errors, defects, malware and viruses or
              that the Site is correct, up to date and accurate
            </span>
          </span>
        </span>
      </p>

      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              DriveU shall not be liable for You missing
              trains/flights/events/exams/delays or other time sensitive
              commitments as the Service is dependent on many factors not in
              DriveU’s control. You must create Your Booking after taking into
              account the check-in time, traffic and weather conditions,
              political rallies, natural calamities, traffic barricades, car
              breakdowns and other un- expected delays.
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              DriveU shall not be liable for any damages resulting from the use
              of or inability to use the Site, including damages caused by wrong
              usage of the Site, error in call centre number, network issues,
              malware, viruses or any incorrectness or incompleteness of the
              Information or the or Application.
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              You shall take full responsibility of Your items and luggage. In
              case of lost items inside the Vehicle during the journey, DriveU
              will try to locate the items on a "best-effort" basis but is not
              responsible for the same in case of loss or damage to the same. If
              You lost any goods from the Vehicle or have any complaint in
              respect of the Services or the use of the service, You have to
              inform DriveU of the same in writing within 24 (twenty four) hours
              of using the Services of DriveU. In the event it is found, You
              shall be informed about the DriveU office from where You can
              collect it after 3 (three) working days from the date You were
              informed. DriveU however shall not be responsible for delivering
              the same back to You. If requested, DriveU may deliver the
              misplaced item/s back to You after 3 (three) working days from the
              date You were informed, subject to the place of delivery being
              twenty (20) kms from the DriveU office. DriveU shall charge You a
              flat fee of Rs 500 for such delivery.
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              DriveU is not responsible for filing FIRs, petitions, or any legal
              actions against Third-Party Service Providers (TPSPs) in cases of
              misconduct, theft, illegal activity, or any other wrongful acts
              committed by TPSPs. DriveU’s responsibility is limited to
              providing necessary documentation and records related to TPSPs
              that are maintained by DriveU. It is the sole responsibility of
              the customer to initiate and pursue any legal action, and all
              related costs must be borne by the customer.
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              In the event of any theft, misconduct, or unlawful activity by a
              TPSP (third-party service provider) enlisted on our DriveU
              platform at the customer's premises, DriveU shall not be held
              responsible for any resulting damages or losses. The customer must
              report such incidents to DriveU within 12 hours of the occurrence.
              While DriveU will make every reasonable effort to assist the
              customer in resolving such issues, including providing necessary
              documentation for legal support, DriveU does not assume liability
              for the actions of third-party service providers. The
              responsibility for filing any legal claims or complaints against
              the TPSP with the nearby police station or court lies solely with
              the customer, who must use the documentation provided by DriveU.
              DriveU may offer assistance with legal formalities as requested by
              the authorities; however, DriveU is not liable for any legal fees
              or costs incurred by the customer in pursuing such claims
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              DriveU does not assure the complete sustainability of its Service
              and shall not be held responsible or liable for the same, in any
              manner.
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              DriveU shall not be responsible for any loss of communication /
              information of status update and benefits under the program. All
              this information will be sent on mobile number and/or email ID
              registered with DriveU. DriveU will not be responsible for
              appropriateness of mobile or email or any other communication
              medium. You shall be responsible for immediately reporting the
              errors, if any, occurred in the information sent to You regarding
              booking confirmation.
            </span>
          </span>
        </span>
      </p>

      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              Third-Party Websites, Applications, and Services: The DriveU
              Services may contain hypertext links to websites and applications
              operated by parties (including the onboarded Car Servicing and
              other service providers) other than DriveU. Such hypertext links
              are provided for User's reference only, and DriveU does not
              control such websites and is not responsible for their content.
              DriveU's inclusion of any hypertext links to such websites or
              applications does not imply any endorsement of the material on
              such websites or applications or any association with their
              operators. DriveU assumes no liability whatsoever for any such
              third-party websites, applications, or any content, features,
              products, or services made available through such third- party
              websites or applications
            </span>
          </span>
        </span>
      </p>

      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              IN NO EVENT SHALL DRIVEU BE LIABLE FOR ANY DIRECT, INDIRECT,
              PUNITIVE, INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES OR FOR ANY
              DAMAGES WHATSOEVER, WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE,
              STRICT LIABILITY OR OTHERWISE, EVEN IF DRIVEU HAS BEEN ADVISED OF
              THE POSSIBILITY THEREOF.
            </span>
          </span>
        </span>
      </p>

      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              IN ADDITION, AND WITHOUT LIMITING THE FOREGOING, TO THE FULLEST
              EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL DRIVEU’s
              AGGREGATE LIABILITY ARISING OUT OF OR IN CONNECTION WITH THESE
              USER TERMS OR THE SERVICES RENDERED HEREUNDER, WHETHER IN
              CONTRACT, TORT (INCLUDING NEGLIGENCE, PRODUCT LIABILITY, OR OTHER
              THEORY), WARRANTY, OR OTHERWISE, EXCEED THE AMOUNT OF Rs. 1000/-
              (Rupees One Thousand only).
            </span>
          </span>
        </span>
      </p>

      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              If Applicable Law does not permit the exclusion of certain
              warranties or the limitation or exclusion of liability, the scope
              and duration of such warranty exclusions and the extent of the
              liability of DriveU shall be the minimum permitted under
              Applicable Law.
            </span>
          </span>
        </span>
      </p>

      <p className="NumberText">
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span className="sub-head">13. APPLICATION LICENSE</span>
          </span>
        </span>
      </p>

      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              Subject to Your compliance with these User Terms, DriveU grants
              You a limited, revocable, non-exclusive, non-transferable and
              non-sub licensable license to download and install a copy of the
              Application on a single mobile device that You own or control and
              to run such copy of the Application solely for Your own personal
              use and to use the Site
            </span>
          </span>
        </span>
      </p>

      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              You shall not (i) license, sublicense, sell, resell, transfer,
              assign, distribute or otherwise commercially exploit or make
              available to any third party the Service or Site in any way; (ii)
              modify or make derivative works based upon the Service or
              Application; (iii) create Internet "links" to the Service or
              "frame" or "mirror" any Site on any other server or wireless or
              Internet-based device; (iv) reverse engineer or access the Site in
              order to (a) design or build a competitive product or service, (b)
              design or build a product using similar ideas, features, functions
              or graphics of the Service or Site, or (c) copy any ideas,
              features, functions or graphics of the Service or Site, or (v)
              launch an automated program or script, including, but not limited
              to, web spiders, web crawlers, web robots, web ants, web indexers,
              bots, viruses or worms, or any program which may make multiple
              server requests per second, or unduly burdens or hinders the
              operation and/or performance of the Service or Site.
            </span>
          </span>
        </span>
      </p>

      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              You shall not: (i) send spam or otherwise duplicative or
              unsolicited messages in Violation of applicable laws; (ii) send or
              store infringing, obscene, threatening, libelous, or otherwise
              unlawful or tortious material, including material harmful to
              children or violative of third party privacy rights; (iii) send or
              store material containing software viruses, worms, Trojan horses
              or other harmful computer code, files, scripts, agents or
              programs; (iv) interfere with or disrupt the integrity or
              performance of the Site, the Application or Service or the data
              contained therein; or (v) attempt to gain unauthorized access to
              the Site, the Application or Service or its related systems or
              networks
            </span>
          </span>
        </span>
      </p>

      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              DriveU will have the right to investigate and prosecute Violations
              of any of the above to the fullest extent of the law. DriveU may
              involve and cooperate with law enforcement authorities in
              prosecuting users who violate these User Terms. You acknowledge
              that DriveU has no obligation to monitor Your access to or use of
              the Site, Service or Posted Content, but has the right to do so
              for the purpose of operating the Site and Service, to ensure Your
              compliance with these User Terms, or to comply with Applicable Law
              or the order or requirement of a court, administrative agency or
              other Governmental body. DriveU reserves the right, at any time
              and without prior notice, to remove or disable access to any
              content that DriveU, at its sole discretion, considers to be in
              Violation of these User Terms or otherwise harmful to the Site,
              the Service or Application.
            </span>
          </span>
        </span>
      </p>

      <p className="NumberText">
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span className="sub-head">14. CONTENT POSTED BY CUSTOMERS</span>
          </span>
        </span>
      </p>

      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              DriveU may accept posting of any notes, messages, e-mails, photos,
              drawings, profiles, opinions, ideas, images, videos, audio files
              or other materials or information given by you on the Site
              ("Posted Content") by You. You represent that You have obtained
              all permissions and consents required to post the Posted Content
              and such Posted Content complies with all requirements of the
              Posted Content. DriveU shall not in any manner be responsible for
              or endorse the Posted Content.
            </span>
          </span>
        </span>
      </p>

      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              You agree that when posting Posted Content, You will not:
            </span>
          </span>
        </span>
      </p>

      <div className="definitionList">
        <ol>
          <li>
            Publish, post, upload, distribute or disseminate any inappropriate,
            profane, defamatory, infringing, obscene, indecent or unlawful
            topic, blasphemous, pornographic, libellous, invasive of another's
            privacy, hateful, or racially, ethnically objectionable,
            disparaging, relating or encouraging money laundering or gambling,
            or otherwise unlawful material or information.
          </li>
          <li>
            Upload files that contain software or other material protected by
            intellectual property laws (or by rights of privacy or publicity)
            unless You own or control the rights thereto or have received all
            necessary consents
          </li>
          <li>
            Upload files that contain viruses, corrupted files, or any other
            similar software or programs that may damage the operation of
            another’s computer.
          </li>
          <li>
            Advertise or offer to sell or buy any goods or services for any
            business purpose, unless the Site specifically allows such messages.
          </li>
          <li>
            Conduct or forward surveys, contests, pyramid schemes or chain
            letters.
          </li>
          <li>
            Download any file posted by another user that You know, or
            reasonably should know, cannot be legally distributed in such
            manner.
          </li>
          <li>
            Falsify or delete any author attributions, legal or other proper
            notices or proprietary designations or labels of the origin or
            source of software or other material contained in a file that is
            uploaded.
          </li>
          <li>
            Deceive or mislead the addressee about the origin of a messages or
            communicate any information which is grossly offensive or menacing
            in nature
          </li>
          <li>
            Restrict or inhibit any other user from using and enjoying the Site.
          </li>
          <li>
            Violate any code of conduct or other guidelines which may be
            applicable for any particular Posted Content.
          </li>
          <li>
            Harvest or otherwise collect information about others, including
            e-mail addresses, without their consent.
          </li>
          <li>
            Threaten the unity, integrity, defence, security or sovereignty of
            India, friendly relations with foreign states, or public order or
            causes incitement to the commission of any cognizable offence or
            prevents investigation of any offence or is insulting any other
            nation.
          </li>
          <li>
            Violate any Applicable Laws or regulations including the Information
            Technology Act, 2000 and the rules, regulations and guidelines
            notified thereunder.
          </li>
        </ol>
      </div>

      <p className="NumberText">
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span className="sub-head">
              15. INTELLECTUAL PROPERTY OWNERSHIP
            </span>
          </span>
        </span>
      </p>

      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              DriveU alone (and its licensors, where applicable) shall own all
              right, title and interest, including all related intellectual
              property rights, in and to
            </span>
          </span>
        </span>
      </p>
      <div className="definitionList">
        <ol>
          <li>
            the Site, Application and the Service and any suggestions, ideas,
            enhancement requests, feedback, recommendations;
          </li>
          <li>
            text, graphics, user interfaces, visual interfaces, photographs,
            trademarks, logos, sounds, music, artwork and computer code; or
          </li>
          <li>
            other information provided by You or any other party relating to the
            Site, Application or the Service.
          </li>
        </ol>
      </div>

      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              Third party trademarks may appear on this Site/ Application and
              all rights therein are reserved to the registered owners of those
              trademarks. For use of any third party's intellectual property,
              You need to get permission directly from the owner of the
              intellectual property for any use.
            </span>
          </span>
        </span>
      </p>

      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              These User Terms do not constitute a sale and do not convey to You
              any rights of ownership in or related to the Site, the Application
              or the Service, or any intellectual property rights owned by
              DriveU. You shall be solely responsible for any Violations of any
              laws and for any infringements of any intellectual property rights
              caused by use of the Services or the Site/ Application.
            </span>
          </span>
        </span>
      </p>

      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              You may use information on the Site purposely made available by
              DriveU for downloading from the Site, provided that You:
            </span>
          </span>
        </span>
      </p>

      <div className="definitionList">
        <ol>
          <li>
            do not remove any proprietary notice language in all copies of such
            documents and make no modifications to the information
          </li>
          <li>
            use such information only for Your personal, non-commercial
            informational purpose and do not copy or post such information on
            any networked computer or broadcast it in any media; or
          </li>
          <li>
            do not make any additional representations or warranties relating to
            such information
          </li>
        </ol>
      </div>

      <p className="NumberText">
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span className="sub-head">16. LINKS</span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              If permitted by DriveU, You may establish a hypertext link to the
              Site, provided that the link does not state or imply any
              sponsorship or endorsement of Your site by Us. You must not use on
              Your site or in any other manner any DriveU trademarks or service
              marks or any Content belonging to DriveU and appearing on the
              Site, including any logos or characters, without our express
              written consent. You must not frame or otherwise incorporate into
              another third party website or present in conjunction with or
              juxtaposed against such a website any of the content or other
              materials on the Site without our prior written consent.
            </span>
          </span>
        </span>
      </p>
      <p className="NumberText">
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span className="sub-head">
              17. TERM AND TERMINATION OF LICENSE AGREEMENT
            </span>
          </span>
        </span>
      </p>
      <div className="definitionList">
        <ol>
          <li>
            Unless terminated in accordance with this Clause 17, the agreement
            between DriveU and You is perpetual in nature upon downloading the
            Application and for each Booking booked through the Site.
          </li>
          <li>
            You are entitled to terminate the agreement at all times by deletion
            of Your Account, thus disabling the use by You of the Site. You can
            close Your Account at any time by following the instructions on the
            Site.
          </li>
          <li>
            DriveU is entitled to terminate the agreement at all times and with
            immediate effect (by disabling Your use of the Site and the Service)
            if You: (a) violate or breach any term of these User Terms, or (b)
            in the opinion of DriveU, misuse the Application or the Service.
            DriveU is not obliged to give notice of the termination of the
            agreement in advance. After termination DriveU will give notice
            thereof in accordance with these User Terms.
          </li>
          <li>
            Termination of this agreement will not prejudice accrued rights of
            either DriveU or You.
          </li>
          <li>
            Clauses 11 (Indemnification), 12 (Liability), 13 (Application
            License), 14 (Contents posted on Site/ Application), 15
            (Intellectual Property Ownership), 17 (Term and Termination), 22
            (Representations and Warranties) 25 (Notice) and 26 (Applicable Law
            and Dispute Resolution) and such other provisions which are intended
            to survive the termination, shall survive the expiry/termination of
            these User Terms in accordance with their terms
          </li>
        </ol>
      </div>
      <p className="NumberText">
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span className="sub-head">
              18. INVALIDITY OF ONE OR MORE PROVISIONS
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              The invalidity of any term of these User Terms shall not affect
              the validity of the other provisions of these User Terms. If and
              to the extent that any provision of these User Terms is invalid,
              or is unacceptable in the given circumstances, a provision shall
              apply between the parties instead that is acceptable considering
              all the circumstances, taking into account the content and the
              purpose of these User Terms.
            </span>
          </span>
        </span>
      </p>

      <p className="NumberText">
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span className="sub-head">19. CONFLICT</span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              In the event of any contradiction or inconsistency between this
              User Terms and any other agreement executed between You and
              DriveU, the terms of the User Terms shall prevail unless the
              exception has been expressly agreed to in writing by making
              reference to the relevant Clause sought to be modified under this
              User Terms.
            </span>
          </span>
        </span>
      </p>
      <p className="NumberText">
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span className="sub-head">20. DISCLAIMER</span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              You agree that DriveU is merely an electronic platform to
              facilitate aggregation of TPSPs and does not in any manner provide
              various services. DriveU does not endorse, advise or recommend You
              to avail the Services of any TPSPs. DriveU also does not guarantee
              or provide assurance in respect of the behavior, actions or data
              of the users posted on the Site.
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              We do not authorize anyone to make a warranty on Our behalf and
              You shall not rely on any statement of warranty as a warranty by
              Us.
            </span>
          </span>
        </span>
      </p>

      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              DriveU and their representatives, officers, employees, agents and
              contractors shall not be liable for any loss, damage, claim,
              expense, cost (including legal costs) or liability arising
              directly or indirectly from Your use or non-use of the Service or
              the Site, or Your reliance upon the Service or the information
              contained upon the Site (whether arising from DriveU or any other
              person's negligence or otherwise).
            </span>
          </span>
        </span>
      </p>

      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              This Site, Application and all content on the Site and the
              Application are provided on an "as is" basis without warranties of
              any kind, either express or implied, including without limitation
              warranties of title or implied warranties of merchantability or
              fitness for a particular purpose. You acknowledge, by Your access
              of the Site and/or Application, that Your access of the Site
              and/or Application and availing of Services is at Your sole risk,
              that You assume full responsibility for Your access and use of the
              Site and/or Application, and that DriveU shall not be liable for
              any damages of any kind related to Your access and use of this
              Site and/or Application.
            </span>
          </span>
        </span>
      </p>

      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              All images, audio, video and text in the Site and/or Application
              are only for illustrative purposes. None of the models, actors or
              products in the images, if any is endorsing the Services in any
              manner. Any resemblance of any matter to anybody or anything is
              purely unintentional and/or coincidental.
            </span>
          </span>
        </span>
      </p>

      <p className="NumberText">
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span className="sub-head">
              21. MODIFICATION OF THE SERVICE AND USER TERMS
            </span>
          </span>
        </span>
      </p>

      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              DriveU reserves the right, at its sole discretion, to modify or
              replace, in part or full, any of these User Terms, or change,
              suspend, block, discontinue or restrict your use to all or any
              feature of the Service or Application at any time.
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              DriveU shall not be required to notify You of any changes made to
              these User Terms. The revised User Terms shall be made available
              on the Site. You are requested to regularly visit the Site to view
              the most current User Terms. You can determine when DriveU last
              modified the User Terms by referring to the <strong>Last Updated</strong> legend
              above. It shall be Your responsibility to check these User Terms
              periodically for changes. DriveU may require You to provide Your
              consent to the updated User Terms in a specified manner prior to
              any further use of the Site and the Services. If no such separate
              consent is sought, Your continued use of the Site, following the
              changes to the User Terms, will constitute Your acceptance of
              those changes. Your use of the Site and the Services is subject to
              the most current version of the User Terms made available on the
              Site at the time of such use.
            </span>
          </span>
        </span>
      </p>

      <p className="NumberText">
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span className="sub-head">22. REPRESENTATIONS AND WARRANTIES</span>
          </span>
        </span>
      </p>

      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
            As a precondition to your use of the Services, you represent and warrant that:
            </span>
          </span>
        </span>
      </p>

      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              The information you provide to DriveU is accurate and complete.
              DriveU service is only available for private cars for
              non-commercial purposes within the city limits as designated on
              our Website. Private cars for non-commercial purposes bear license
              plates with black lettering over white colored background
              (Commercial vehicles have license plates with black lettering over
              yellow colored background). You will ensure that DriveU service is
              being utilized only for non-commercial purposes in a private car.
              DriveU is entitled, at all times, to verify the information that
              you have provided and to refuse the Service or use of the
              Application / Website without providing reasons.
            </span>
          </span>
        </span>
      </p>

      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              You will only access the Service using authorized means. You are
              responsible to check and ensure you download the correct
              Application for your device or the correct Website in your
              computer. DriveU shall not be liable if you do not have a
              compatible mobile device or if you download the wrong version of
              the Application for your mobile device or Website for the
              computer. DriveU reserves the right to terminate the Service and
              the use of the Application/ Website should you use the Service or
              Application with an incompatible or unauthorized device.
            </span>
          </span>
        </span>
      </p>

      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              You have the legal right and authority to possess and operate the
              vehicle when engaging our Services and you confirm, represent and
              warrant that the said vehicle is in good operating condition and
              meets the industry safety standards and all applicable statutory
              requirements for a motor vehicle of its kind
            </span>
          </span>
        </span>
      </p>

      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              You will be solely responsible for any and all liability which
              results from or is alleged as a result of the condition of your
              vehicle, legal compliance, etc., including, but not limited to,
              personal injuries, death and property damages.
            </span>
          </span>
        </span>
      </p>

      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              You will be solely responsible for the full functionality of your
              vehicle. If your vehicle fails to function (electrical, mechanical
              or other) in any way while the Services are being availed of by
              you, you will be responsible for all storage fees, roadside
              assistance, alternate transportation and repair of any kind and
              neither Humble Mobile/DriveU nor the Driver shall be responsible
              in any manner whatsoever. You have the legal right to designate
              the Driver as your agent and delegate actual authority to the
              Driver to operate your vehicle and make decisions on your behalf
              for the purposes of providing Services offered through the DriveU
              platform.
            </span>
          </span>
        </span>
      </p>

      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              You are named or scheduled on the insurance policy covering the
              vehicle you use when engaging our Services. You have a valid
              policy of liability insurance (in coverage amounts consistent with
              all applicable legal requirements) for the operation of your
              vehicle to cover any anticipated losses related to your
              participation in the Services or the operation of your vehicle by
              the Driver. In the event of a motor vehicle accident you will be
              solely responsible for compliance with any applicable statutory or
              department of motor vehicles requirements and for all necessary
              contacts with your insurance provider. Other than any personal
              criminal liability attaching to the Driver you will be solely
              responsible for all consequences arising out of the use of the
              Service or the Driver. In any event DriveU shall have no
              responsibility or liability on this account whatsoever.
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              You are required to remain with your vehicle at all times and must
              not leave it unattended with TPSP. DriveU shall not be held liable
              for any actions by TPSPs, including but not limited to personal
              use or misuse of your vehicle, in your absence.
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              You specifically authorize us to use, store or otherwise process
              your 'Sensitive personal data or information’ (as such term is
              defined in Information Technology (Reasonable security practices
              and procedures and sensitive personal data or information) Rules,
              2011) in order to provide the Services to you. Subject to
              applicable law all information provided to us by you shall be
              deemed to be our information to use as we desire
            </span>
          </span>
        </span>
      </p>

      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              You will obey all applicable laws related to the matters set forth
              herein and will be solely responsible for any violations of the
              same.
            </span>
          </span>
        </span>
      </p>

      <p className="NumberText">
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span className="sub-head">
              23. TERMS AND CONDITIONS FOR DRIVEU BLACK MEMBERS:
            </span>
          </span>
        </span>
      </p>

      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              DriveU Black is an invite-only membership-based program available
              in select cities which allows its members to avail Offer(s) (as
              defined below) for the services provided by DriveU
            </span>
          </span>
        </span>
      </p>

      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              DriveU Black Membership: As a member of DriveU Black, You will be
              entitled to avail Offer(s) on the Bill Value (as defined below),
              provided that the Bill Value for such order is above the minimum
              order value (if applicable) as determined by the DriveU.
            </span>
          </span>
        </span>
      </p>

      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              For the purpose of DriveU Black, “Bill Value” shall mean the total
              amount set out in the bill for selected services availed by the
              member from DriveU platform, and shall not include applicable
              taxes, service charge and other charges as may be applicable and
              “Offer(s)” shall include, but not be restricted to, either (i)
              percentage of discount or flat discount that the DriveU agrees to
              extend to the Customer on the Bill Value for each transaction;
              and/or (ii) other benefits and offers as may be communicated on
              the DriveU Platform.
            </span>
          </span>
        </span>
      </p>

      {/* li tag implemented here  */}

      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              Benefits and features under DriveU Black membership:
            </span>
          </span>
        </span>
      </p>

      <div className="definitionList">
        <ol>
          <li>
            Offer(s) can be redeemed for the selected services defined by DriveU
            and such offers and services may be updated periodically;
          </li>
          <li>
            Offer(s) may be changed or added from time to time. You are advised
            to check the Offer(s) being offered by DriveU at the time of
            creating your booking.
          </li>
          <li>Offer(s) cannot be exchanged for cash.</li>
          <li>
            Offer(s) can only be availed in the cities in which DriveU Black is
            available.
          </li>
          <li>
            You will be responsible to pay DriveU all costs and charges payable
            for all the other items which are not covered under the Offer(s).
          </li>
          <li>
            There is no limit on the number of times the Offer(s) can be availed
            in a day.{" "}
          </li>
          <li>
            You are not permitted to avail the Offer(s) on more than one devices
            at a time.
          </li>
          <li>
            The term of Your DriveU Black membership shall be subject to the
            membership plan opted by You.
          </li>
        </ol>
      </div>

      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              Fees: In order to avail DriveU Black membership, members are
              required to pay a membership fee which shall be based on the
              membership plan opted by such member.
            </span>
          </span>
        </span>
      </p>

      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              Payments: To purchase and/or renew your membership plan, you can
              choose a payment method, as available on the 'Payment' section of
              the DriveU Platform. Your access to DriveU Black shall be subject
              to receipt of successful payments by DriveU. The membership fee
              shall be inclusive of all applicable taxes. For some payment
              methods, the issuer may charge you certain fees, such as foreign
              transaction fees or other fees, relating to the processing of your
              payment method. DriveU shall require additional information and/or
              documents from you in accordance to the applicable local laws in
              your or as per the internal requirements of DriveU.
            </span>
          </span>
        </span>
      </p>

      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              Terms: These Terms will begin on the date of purchase of the
              membership plan and will be valid till such time your membership
              plan expires.
            </span>
          </span>
        </span>
      </p>

      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              Subscription and Renewal: The membership, once purchased, is
              non-transferable and non- refundable.
            </span>
          </span>
        </span>
      </p>

      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              Modification to DriveU Black: DriveU reserves the right to offer,
              alter, extend or withdraw, as the case may be, any offers or
              discounts or promotions extended by DriveU at any time with or
              without giving any prior notice to the Customer. In such cases,
              such revision will be updated on the DriveU Platform accordingly.
            </span>
          </span>
        </span>
      </p>

      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              DriveU reserves the right to terminate / suspend Your membership
              to the DriveU Black , if DriveU determines in its sole discretion
              that (i) You have violated the terms and conditions of DriveU
              Black set out herein, (ii) have been involved in activities that
              are in contravention of the DriveU Black terms and/or any terms
              for the usage of DriveU Platform; or (iii) have engaged in
              activities which are fraudulent/unlawful in nature while availing
              any of Services of DriveU. You will not be eligible for any refund
              if the DriveU Black membership has been terminated/suspended by
              DriveU for such cases.
            </span>
          </span>
        </span>
      </p>

      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              Exclusion Days: All benefits of DriveU Black can be used on any
              day of the week except on the days listed herein below ("Exclusion
              Days"). Exclusion period applies from the start of operational
              hours for the day up till 6 a.m. of the following day. In addition
              to the Exclusion Days mentioned herein, DriveU Black shall not be
              extended by DriveU on the days prohibited by law. DriveU, at its
              discretion, may or may not extend DriveU Black benefits to the
              Customer on an Exclusion Day. DriveU Black members are advised to
              make prior enquiry with the DriveUs and/or check the DriveU
              Platform before ordering and/or visiting the DriveU, to confirm
              whether the DriveU Black can be redeemed or not. DriveU reserves
              the right to add Exclusion Days to DriveU Black at its discretion
              which will be updated on DriveU Platform from time to time.
            </span>
          </span>
        </span>
      </p>
      <br />
      <table>
        <tr>
          <th>No.</th>
          <th>Date</th>
          <th>Event</th>
        </tr>

        <tr>
          <td>1</td>
          <td>December 31 and January 1</td>
          <td>New Year's Eve/Day</td>
        </tr>

        <tr>
          <td>2</td>
          <td>As per lunar calendar</td>
          <td>Pongal (applicable in Tamil Nadu only)</td>
        </tr>

        <tr>
          <td>3</td>
          <td>February 14 </td>
          <td>Valentine's Day</td>
        </tr>

        <tr>
          <td>4</td>
          <td>As per lunar calendar</td>
          <td>Durga Puja (applicable in West Bengal only)</td>
        </tr>

        <tr>
          <td>5</td>
          <td>As per lunar calendar</td>
          <td>Diwali Eve/Day</td>
        </tr>
        <tr>
          <td>6</td>
          <td>December 24 and December 25</td>
          <td>Christmas Eve/Day</td>
        </tr>
      </table>
      <br />

      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              Personal Information: DriveU Black members will be required to
              share certain personal information with DriveU including their
              name, phone number, email address, payment details, in order to
              purchase DriveU Black. DriveU will use these details in accordance
              with the Privacy Policy published on
              https://www.driveu.in/privacy-policy
            </span>
          </span>
        </span>
      </p>

      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              Liability Limitation: Notwithstanding anything set out herein,
              DriveU’s aggregate liability for any or all claims arising from or
              in connection with your use of DriveU Black shall be limited to
              the membership fee paid by you at the time of purchasing the
              membership.
            </span>
          </span>
        </span>
      </p>

      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              Call Recording: DriveU may contact DriveU Black members via
              telephone, SMS or other electronic messaging or by email with
              information about your DriveU Black experience or any feedback
              thereon. Any calls that may be made by DriveU, by itself or
              through a third party, to the members pertaining to the experience
              of a Customer may be recorded for internal training and quality
              purposes by DriveU or any third party appointed by DriveU
            </span>
          </span>
        </span>
      </p>

      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              Assignment: DriveU may assign or transfer any of its rights or
              obligations under these Terms and conditions to any of its
              affiliates or any third party at any time.
            </span>
          </span>
        </span>
      </p>

      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              Contact Us: You may contact us at care@driveu.in for any further
              queries with regard to DriveU Black.
            </span>
          </span>
        </span>
      </p>
      <p className="NumberText">
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span className="sub-head">
              24. TERMS AND CONDITIONS FOR DU SECURE - VEHICLE DAMAGE PROTECTION
              PLAN:
            </span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              DU Secure Vehicle Damage Protection Plan will be applicable only
              to the trips booked through DriveU platform. The cover under this
              plan starts when the driver boards the DriveU’s Customer car and
              lasts till the customer alights from the car and ends the DriveU
              trip. The maximum coverage period is from the start to the end
              time of the trip.
            </span>
          </span>
        </span>
      </p>

      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              Assets Covered: Car of Customer of DriveU driven by the DriveU
              Driver Partner for the particular trip.
            </span>
          </span>
        </span>
      </p>

      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              Coverage: The sum assured displayed in the app at the time you
              choose DU Secure, or the actual repair bill, whichever is lower,
              will apply in the event of any damages during service delivery
            </span>
          </span>
        </span>
      </p>

      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              Claim Approval: The approval of your claim is at the sole
              discretion of the insurer, based on the documents you submit.
              DriveU has no influence over the claim approval process, aside
              from ensuring the timely submission of the documents you provide
              to DriveU
            </span>
          </span>
        </span>
      </p>

      <p className="NumberText">
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span className="sub-head">General Conditions:</span>
          </span>
        </span>
      </p>

      <div className="definitionList">
        <ol>
          <li>
            Written notice within 3 days of any event should be given that may
            give rise to a claim under the Plan, in accordance with the claims
            procedure under the Plan. Any claims initiated after 3 days of the
            event shall be rejected by DriveU.
          </li>
          <li>
            Repair invoice needs to be uploaded within 15 days from the date of
            Invoice Upload request by DriveU. If the repair invoice is uploaded
            after 15 days of the upload request, such claims shall be rejected
            by DriveU.
          </li>
          <li>
          All claims made under the plan will be subject to the applicable deductible, any sub-limits and
          the availability of the sum coverage.
          </li>
          <li>
            The plan does not cover any contractual and consequential liability,
            except as covered in the Plan or coverage mentioned by DriveU to its
            customer.
          </li>
          <li>Geographical Limit: India.</li>
          <li>Plan is non-transferable.</li>
        </ol>
      </div>

      <p className="NumberText">
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span className="sub-head">Special Conditions:</span>
          </span>
        </span>
      </p>

      <div className="definitionList">
        <ol>
          <li>
            The cover under this plan starts when the driver boards the DriveU’s
            Customer car and lasts till the customer alights from the car and
            ends the DriveU trip. The maximum period of coverage is 12hrs for
            local trips, 24hrs for one-way outstation trips and 36hrs for
            outstation trips.
          </li>
          <li>
            Only visible, external and accidental damages to the vehicle are
            covered under the plan.
          </li>
          <li>
            Damages occurred during the trip will only be eligible for the
            claim.
          </li>
          <li>
            Repairs from GST authorized service centers will only be eligible
            for the claim.
          </li>
          <li>
            Any loss occurring due to normal wear and tear of the vehicle will
            not be eligible for a claim.
          </li>
          <li>
            Any form of mechanical or electrical breakdown, failure of chassis,
            or body parts, or breakage due to rusting or other weather
            conditions will not be considered for a claim.
          </li>
          <li>
            Replacement of parts as a result of normal wear and tear, such as
            spark plugs, belts, brake pads and brake shoes, brake discs, clutch
            disc/pressure plate, filters, wiper blades, bulbs, fuses, brushes,
            hoses, Tire / tube, battery, audio system, audio video navigation
            (AVN), catalytic converter, silencer / exhaust pipe will not be
            covered.
          </li>
          <li>
            Damage to Tyres, Electrical equipment, or Electronics will not be
            covered.
          </li>
          <li>
            Rubber parts like Wheel Cylinder kit, all doors
            weather-strips/beadings & A/C "O" rings, Clutch cable, Accelerator
            cable, Speedometer cable & Tail Door Opener cable, leakage of A/C
            refrigerant will not be covered.
          </li>
          <li>
            Normal aging, deterioration or rusting of plated parts, paint coat,
            rubber parts, upholstery and soft trim, etc will not be covered.
          </li>
          <li>
            Interior / exterior trim, accessories, exhaust systems and normal
            wear and tear of any other component will not be covered.
          </li>
          <li>
            Vehicles without valid motor vehicle insurance will not be covered.
          </li>
        </ol>
      </div>

      <p className="NumberText">
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span className="sub-head">Exclusions:</span>
          </span>
        </span>
      </p>

      <div className="definitionList">
        <ol>
          <li>
            Fraud or criminal activity on the part of the customer or the
            Beneficiary
          </li>
          <li>
            Loss resulting from detention or confiscation by customs or other
            Government or public authorities
          </li>
          <li>
            Any failure of the vehicle to operate as a result of problems with
            respect to mechanical and electrical failures
          </li>
          <li>
            Loss arising due to any unlawful or illegal activities including
            criminal acts or acts of war or the insured equipment is used with
            permission of the bonafide beneficiary having knowledge of such
            facts
          </li>
          <li>Consequential loss of any kind or description</li>
          <li>
            Liability to any party of any nature including but not limited to
            liability of Authorized Service Centre to other parties.
          </li>
          <li>
            Loss or damage as a result of manufacturing defect owing to which a
            insured equipment fails to operate
          </li>
          <li>
            Loss or damage that is covered by a supplier, dealer or factory
            warranty
          </li>
          <li>
            Any loss if the ownership of the insured equipment is transferred
            Any consequence arising from War, War like operations ( whether war
            declared or not ), Act of foreign enemy, hostilities, Civil War
          </li>
          <li>
            Rebellion, Insurrection, Civil Commotion, Military unsurped power,
            Seizure, Capture ,Confiscation, Arrest, Restraints and or detainment
            by order of any Government or any other Authority
          </li>
          <li>
            Intentional overloading of the insured equipment. Loss or damage due
            to any experiments or tests and/or alterations resulting into any
            abnormal conditions
          </li>
          <li>Loss or damage to accessories of whatsoever nature</li>
          <li>
            Loss or damage caused by any third party. Such damages need to be
            claimed through the vehicle insurance of the customer.
          </li>
          <li>
            Commercial or yellow board vehicles are not eligible for the
            insurance.
          </li>
          <li>
            Loss or damage caused to any third party. Such damages need to be
            claimed through the vehicle insurance of the customer
          </li>
        </ol>
      </div>

      <p className="NumberText">
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span className="sub-head">25. NOTICE:</span>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              DriveU may give notice by means of a general notice on the Service
              or Application, or by electronic mail to Your email address or a
              message on Your registered mobile number, or by written
              communication sent by regular mail to Your address on record in
              DriveU’s account information.
            </span>
          </span>
        </span>
      </p>

      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              You may contact DriveU by electronic mail at Our email address
              legal@driveu.in or by written communication sent by regular mail
              to Our address at <strong>19, 3rd Floor, 1st Cross, KHB Colony, 5th Block,
              Koramangala, Bangalore-560095</strong>.
            </span>
          </span>
        </span>
      </p>

      <p className="NumberText">
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span className="sub-head">26. ASSIGNMENT:</span>
          </span>
        </span>
      </p>

      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              You shall not assign Your rights under these User Terms without
              prior written approval of DriveU. DriveU can assign its rights
              under the User Terms to any affiliate.
            </span>
          </span>
        </span>
      </p>

      <p className="NumberText">
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span className="sub-head">
              27. APPLICABLE LAW AND DISPUTE RESOLUTION
            </span>
          </span>
        </span>
      </p>

      <p>
        <span style={{ fontSize: "10.5pt" }}>
          <span style={{ fontFamily: "Circular-Std-Book" }}>
            <span style={{ color: "#444545" }}>
              These User Terms are subject to the laws of India. Any dispute,
              claim or controversy arising out of or relating to these User
              Terms or the breach, termination, enforcement, interpretation or
              validity thereof or the use of the Site, the Service or the
              Application (collectively, <strong>Disputes</strong>) the parties shall attempt
              to settle the same amicably, through negotiation and consultation
              at such offices of DriveU as DriveU may designate. In the event
              the dispute is not resolved internally between after at least 30
              (thirty) days of negotiation, in good faith, the same shall be
              subject to binding and final arbitration in accordance with the
              Arbitration and Conciliation Act, 1996 as amended from time to
              time or in case the Arbitration and Conciliation Act, 1996 is no
              longer in force, as per any law relating to arbitration in force
              at the time of such reference. The reference shall be made to a
              sole arbitrator mutually appointed by DriveU and You. The place of
              the arbitration shall be Bengaluru, Karnataka, unless otherwise
              mutually agreed by DriveU and You in writing. Subject to the
              above, any Dispute will be subject to the exclusive jurisdiction
              of courts in Bangalore, India.
            </span>
          </span>
        </span>
      </p>
    </div>
  );
}

export default TermConditionNew;
