import React, { useContext, useState, useEffect } from "react";
import { Button, SIZE } from "baseui/button";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ROLE,
  ModalButton,
} from "baseui/modal";
import { BookingFlowDataContext } from "../../context/BookingFlowDataContextProvider";
import axios from "axios";
import { TimePicker } from "baseui/timepicker";
import "./reschedule.css";
import LoadingSpinner from "../../Uis/Spinner/Spinner";
import { ApplicationContext } from "../../context/ApplicationContextProvider";
import CarWashSlots from "./CarWashSlots";
import { BookingFlowParaContext } from "../../context/BookingFlowParaProvider";
import toast from "react-hot-toast";
import { IoMdArrowDropdown } from "react-icons/io";
import { DownArrowSvg } from "../../Components/AllImportFiles/ImportSvg";

function RescheduleBlock({
  showReschedulePopUp,
  setShowReschedulePopUp,
  setOptionBooking,
  showHistory,
  myGateBookingID,
  mapData,
}) {
  const BASE_URL_HULK = process.env.REACT_APP_HULK_URL;
  const {
    getBookingId,
    showETA,
    showWhen,
    etaOneWayTime,
    setOneWayETATimeString,
  } = useContext(BookingFlowDataContext);
  const { carWashDate } = useContext(BookingFlowParaContext);
  const { appCarWashTrackSrc, myGateWashToken, setWashErrorMessage } =
    useContext(ApplicationContext);
  const [showDate, setShowDate] = useState(false);
  const [todayDate, setTodayDate] = useState("");
  const [rescheduleBookLoader, setRescheduleBookLoader] = useState(false);
  const [dateArr, setDateArr] = useState([]);
  const [compareBaseTime, setCompareBaseTime] = useState("");
  const [defaultDate, setDefaultDate] = useState("");
  const [showMinTime, setShowMinTime] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [dateTimeValue] = useState({
    CurrentYear: "",
    CurrentDate: "",
    currentMonth: "",
  });
  const monthDay = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const weekDay = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
  const minutes = 15;
  const ms = 1000 * 60 * minutes;
  const currentTime = new Date().getTime();
  // 0.5 * 20 * 60 * 1000
  const date = new Date(currentTime + 1.12 * 60 * 60 * 1000);
  let currentPickUpDate = new Date(Math.round(date.getTime() / ms) * ms);
  const [val, setVal] = useState(currentPickUpDate);
  let new_Date = new Date();
  let DATE = new_Date.getDate();
  let YEAR = new_Date.getFullYear();
  let MONTH = new_Date.getMonth() + 1;
  let StartingDate = DATE.toString().length === 1 ? `0${DATE}` : DATE;
  let StartingMONTH = MONTH.toString().length === 1 ? `0${MONTH}` : MONTH;
  let StartingMinute =
    val.getMinutes() === 0 ? `0${val.getMinutes()}` : val.getMinutes();
  const [dateTimeString, setDateTimeString] = useState(
    `${StartingDate}/${StartingMONTH}/${YEAR} ${val.getHours()}:${StartingMinute}`
  );
  const handleChangeSetTimeVal = (value) => {
    setVal(value);
  };
  const handleDate = () => {
    setShowDate(!showDate);
  };
  const handleDateString = (currentYear, currentMonth, currentDate) => (e) => {
    setTodayDate(currentDate);
    setCompareBaseTime(currentDate);
    setShowDate(false);
    dateTimeValue.CurrentYear = currentYear;
    dateTimeValue.CurrentDate =
      currentDate.toString().length === 1 ? `0${currentDate}` : currentDate;
    dateTimeValue.currentMonth =
      (monthDay.indexOf(currentMonth) + 1).toString().length === 1
        ? `0${monthDay.indexOf(currentMonth) + 1}`
        : monthDay.indexOf(currentMonth) + 1;
    setDefaultDate(e.target.innerText);
  };
  const handleRescheduleBooking = () => {
    setRescheduleBookLoader(true);
    axios({
      method: "POST",
      baseURL: BASE_URL_HULK,
      url: `${BASE_URL_HULK}/app/reschedule/`,
      data: {
        booking_id: myGateBookingID || getBookingId.userBookingId,
        pickup_datetime: dateTimeString,
      },
      withCredentials: false,
      headers: {
        "Content-Type": `multipart/form-data`,
      },
    })
      .then(({ data }) => {
        if (data.status === "success") {
          showHistory();
          setShowReschedulePopUp(false);
          setOptionBooking(false);
        } else if (data.status === "error") {
          setErrorMessage(data.message);
          toast.error(data.message);
        } else {
          toast.error("Something went wrong. Please try again");
        }
        setRescheduleBookLoader(false);
      })
      .catch((err) => {
        console.log(err.message);
        setRescheduleBookLoader(false);
      });
  };

  const handleRescheduleCarWashBooking = () => {
    if (!carWashDate.epoch_date === true) {
      setWashErrorMessage(true);
    } else {
      setRescheduleBookLoader(true);
      axios({
        method: "POST",
        baseURL: BASE_URL_HULK,
        url: `${BASE_URL_HULK}/wash/reschedule-booking/?my_gate_session_id=${myGateWashToken}`,
        data: {
          booking_id: myGateBookingID,
          scheduled_at: carWashDate.epoch_date,
        },
        withCredentials: false,
        headers: {
          "Content-Type": `multipart/form-data`,
        },
      })
        .then(({ data }) => {
          if (data.status === "success") {
            setRescheduleBookLoader(false);
            setShowReschedulePopUp(false);
            setOptionBooking(false);
            showHistory();
          }
        })
        .catch((err) => {
          console.log(err.message);
          setRescheduleBookLoader(false);
        });
    }
  };
  useEffect(() => {
    if (val.getDate() === +todayDate) {
      setVal(currentPickUpDate);
    }
  }, [defaultDate]);
  useEffect(() => {
    var arr = [];
    for (var i = 0; i < 5; i++) {
      var date = new Date();
      var newDate = date.setDate(date.getDate() + i);
      let show = new Date(newDate);
      let onlyDate = show.getDate();
      let year = show.getFullYear();
      var month = monthDay[show.getMonth()];
      var week = weekDay[show.getDay()];
      if (onlyDate.toString().length === 2) {
        arr.push({ week, onlyDate, month, year });
      } else {
        onlyDate = "0" + onlyDate;
        arr.push({ week, onlyDate, month, year });
      }
    }
    let string = arr[0].week + " " + arr[0].onlyDate + " " + arr[0].month;
    setDateArr(arr);
    setDefaultDate(string);
    dateTimeValue.CurrentYear = arr[0].year;
    dateTimeValue.CurrentDate =
      arr[0].onlyDate.toString().length === 1
        ? `0${arr[0].onlyDate}`
        : arr[0].onlyDate;
    dateTimeValue.currentMonth =
      (monthDay.indexOf(arr[0].month) + 1).toString().length === 1
        ? `0${monthDay.indexOf(arr[0].month) + 1}`
        : monthDay.indexOf(arr[0].month) + 1;
  }, []);
  useEffect(() => {
    if (val.getMinutes() === 0) {
      setDateTimeString(
        `${dateTimeValue.CurrentDate}/${dateTimeValue.currentMonth}/${
          dateTimeValue.CurrentYear
        } ${val.getHours()}:0${val.getMinutes()}`
      );
    } else if (val.getMinutes() !== 0) {
      setDateTimeString(
        `${dateTimeValue.CurrentDate}/${dateTimeValue.currentMonth}/${
          dateTimeValue.CurrentYear
        } ${val.getHours()}:${val.getMinutes()}`
      );
    }

    // setOneWayETATimeString
    if (showETA && showWhen[0].label.includes("Now")) {
      let dt = new Date();
      dt.setMinutes(dt.getMinutes() + etaOneWayTime);
      // let a = dt.toLocaleTimeString();
      let hr = dt.getHours();
      let min = dt.getMinutes();

      setOneWayETATimeString(
        `${dateTimeValue.CurrentDate}/${dateTimeValue.currentMonth}/${dateTimeValue.CurrentYear} ${hr}:${min}`
      );
    }
  }, [defaultDate, val, etaOneWayTime]);
  useEffect(() => {
    if (new Date().getDate() === compareBaseTime || compareBaseTime === "") {
      setShowMinTime(false);
    } else {
      setShowMinTime(true);
    }
  }, [compareBaseTime]);

  return (
    <Modal
      onClose={() => {
        setShowReschedulePopUp(false);
        setOptionBooking(false);
      }}
      closeable
      isOpen={showReschedulePopUp}
      animate
      size={SIZE.default}
      role={ROLE.dialog}
    >
      <ModalHeader>Reschedule</ModalHeader>
      <ModalBody>
        <>
          {!mapData === true ? (
            <>
              <div className="OneWayDateAndTime fontMedium">Date & Time</div>

              <div className="DataAndTimeInput">
                <div className="Date" onClick={handleDate}>
                  <div style={{ width: "70%" }}>
                    <input
                      placeholder="Select Date"
                      className="dateInput"
                      type="none"
                      value={defaultDate}
                      readOnly
                    />
                  </div>
                  <div className="dateDropDown">
                  <svg fill="#000000" width="20px" height="20px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M11.178 19.569a.998.998 0 0 0 1.644 0l9-13A.999.999 0 0 0 21 5H3a1.002 1.002 0 0 0-.822 1.569l9 13z"/></svg>
                    {/* <IoMdArrowDropdown size={20} color="black" /> */}
                  </div>
                </div>

                <div onClick={() => setShowDate(false)} className="Time">
                  {val.getDate() === +todayDate ? (
                    <TimePicker
                      className="carDetailOption"
                      value={val}
                      onChange={handleChangeSetTimeVal}
                      minTime={new Date(Math.round(date.getTime() / ms) * ms)}
                      overrides={{
                        Select: {
                          props: {
                            overrides: {
                              Input: {
                                props: {
                                  readOnly: true,
                                },
                              },
                            },
                          },
                        },
                      }}
                    />
                  ) : (
                    <TimePicker
                      className="carDetailOption"
                      value={val}
                      onChange={handleChangeSetTimeVal}
                      minTime={
                        showMinTime
                          ? ""
                          : new Date(Math.round(date.getTime() / ms) * ms)
                      }
                      overrides={{
                        Select: {
                          props: {
                            overrides: {
                              Input: {
                                props: {
                                  readOnly: true,
                                },
                              },
                            },
                          },
                        },
                      }}
                    />
                  )}
                </div>
              </div>

              <div
                style={{ marginTop: "0.5rem" }}
                className="dateDivReschedule"
              >
                {dateArr.map((elem, index) => (
                  <div
                    className="dateOptionReschedule"
                    onClick={handleDateString(
                      elem.year,
                      elem.month,
                      elem.onlyDate
                    )}
                    style={{
                      display: showDate ? "" : "none",
                    }}
                    key={index}
                  >
                    {`${elem.week}\n${elem.onlyDate}\n${elem.month}\n`}
                  </div>
                ))}
              </div>
            </>
          ) : mapData.is_wash_booking === true ? (
            <CarWashSlots mapData={mapData} />
          ) : (
            <>
              <div className="OneWayDateAndTime fontMedium">Date & Time</div>

              <div className="DataAndTimeInput">
                <div className="Date" onClick={handleDate}>
                  <div style={{ width: "70%" }}>
                    <input
                      placeholder="Select Date"
                      className="dateInput"
                      type="none"
                      value={defaultDate}
                    />
                  </div>
                  <div className="dateDropDown">
                  {/* <svg fill="#000000" width="20px" height="9px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M11.178 19.569a.998.998 0 0 0 1.644 0l9-13A.999.999 0 0 0 21 5H3a1.002 1.002 0 0 0-.822 1.569l9 13z"/></svg> */}

                    {/* <img
                      height="5"
                      width="10px"
                      src="../../Assets/Images/Down_Arrow.png"
                      alt="arrow"
                    /> */}
                    {/* <IoMdArrowDropdown size={20} color="black" /> */}

                    <DownArrowSvg/>
                  </div>
                </div>

                <div onClick={() => setShowDate(false)} className="Time">
                  {val.getDate() === +todayDate ? (
                    <TimePicker
                      className="carDetailOption"
                      value={val}
                      onChange={handleChangeSetTimeVal}
                      minTime={new Date(Math.round(date.getTime() / ms) * ms)}
                      overrides={{
                        Select: {
                          props: {
                            overrides: {
                              Input: {
                                props: {
                                  readOnly: true,
                                },
                              },
                            },
                          },
                        },
                      }}
                    />
                  ) : (
                    <TimePicker
                      className="carDetailOption"
                      value={val}
                      onChange={handleChangeSetTimeVal}
                      minTime={
                        showMinTime
                          ? ""
                          : new Date(Math.round(date.getTime() / ms) * ms)
                      }
                      overrides={{
                        Select: {
                          props: {
                            overrides: {
                              Input: {
                                props: {
                                  readOnly: true,
                                },
                              },
                            },
                          },
                        },
                      }}
                    />
                  )}
                </div>
              </div>

              <div
                style={{ marginTop: "0.5rem" }}
                className="dateDivReschedule"
              >
                {dateArr.map((elem, index) => (
                  <div
                    className="dateOptionReschedule"
                    onClick={handleDateString(
                      elem.year,
                      elem.month,
                      elem.onlyDate
                    )}
                    style={{
                      display: showDate ? "" : "none",
                    }}
                    key={index}
                  >
                    {`${elem.week}\n${elem.onlyDate}\n${elem.month}\n`}
                  </div>
                ))}
              </div>
            </>
          )}
          <div style={{ height: "20px", color: "red" }}>
            {!errorMessage ? null : errorMessage}
          </div>
        </>
      </ModalBody>
      <ModalFooter>
        {!mapData === true ? (
          <>
            {rescheduleBookLoader ? (
              <Button size={SIZE.mini} isLoading>
                Confirm
              </Button>
            ) : (
              <Button onClick={handleRescheduleBooking} size={SIZE.mini}>
                Confirm
              </Button>
            )}
          </>
        ) : (
          <>
            {rescheduleBookLoader ? (
              <ModalButton
                style={{
                  backgroundColor:
                    myGateBookingID && appCarWashTrackSrc
                      ? "#127e40"
                      : "#f93356",
                }}
              >
                <LoadingSpinner />
              </ModalButton>
            ) : mapData.is_wash_booking === true ? (
              <ModalButton
                onClick={handleRescheduleCarWashBooking}
                style={{
                  backgroundColor:
                    myGateBookingID && appCarWashTrackSrc
                      ? "#127e40"
                      : "#f93356",
                }}
              >
                Confirm
              </ModalButton>
            ) : (
              <ModalButton
                autoFocus
                onClick={handleRescheduleBooking}
                style={{
                  backgroundColor:
                    myGateBookingID && appCarWashTrackSrc
                      ? "#127e40"
                      : "#f93356",
                }}
              >
                Confirm
              </ModalButton>
            )}
          </>
        )}
      </ModalFooter>
    </Modal>
  );
}

export default RescheduleBlock;
