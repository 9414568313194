import axios from "axios";
import { useEffect, useState } from "react";

const useGetDuPartnerFare = (fareDataDU) => {
  const [fareDUPartner, setFareDUPartner] = useState([]);
  const [isFareLoading, setIsFareLoading] = useState(false);
  const [fareError, setFareError] = useState("");
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  useEffect(() => {
    setIsFareLoading(true);
    axios({
      method: "GET",
      baseURL: BASE_URL,
      url: `/web/du-daily/packages/${fareDataDU.booking_id}/?driver_id=${fareDataDU.driver_id}&imei_number=${fareDataDU.imei_number}`,
      withCredentials: false,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(({ data }) => {
        if (data.status === "success") {
          setFareDUPartner(data);
        } else {
          setFareError(data.message);
        }
        setIsFareLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
        setIsFareLoading(false);
        setFareError(err.message);
      });
  }, []);
  return { fareDUPartner, isFareLoading, fareError };
};

export default useGetDuPartnerFare;
