import React, { useContext, useEffect } from "react";
import { Button, SIZE } from "baseui/button";
import { RadioGroup, Radio, ALIGN } from "baseui/radio";
import { Modal, ModalHeader, ModalBody, ModalFooter, ROLE } from "baseui/modal";
import { BookingFlowDataContext } from "../../context/BookingFlowDataContextProvider";
import axios from "axios";
import { useCookies } from "react-cookie";

function CancelReasonBlock({
  handleCloseBookingBlock,
  showHistory,
  showCancelReasonBlock,
  setShowCancelReasonBlock,
  setOptionBooking,
}) {
  const [cancelReason, setCancelReason] = React.useState([]);
  const { getBookingId } = useContext(BookingFlowDataContext);
  const [getReasonResponse, setGetReasonResponse] = React.useState(false);
  const [showCancelLoader, setShowCancelLoader] = React.useState(false);
  const [value, setValue] = React.useState("");
  const [showErrMsgCancel, setShowErrMsgCancel] = React.useState("");

  const [passBookingData] = React.useState({
    booking_id: getBookingId.userBookingId,
    cancellation_reason: "",
  });
  const [cookiesUser] = useCookies();
  const BASE_URL_HULK = process.env.REACT_APP_HULK_URL;
  const errMsgStyle = {
    fontFamily: "Circular-Std-Book",
    height: "26px",
    marginTop: "0.2rem",
    marginBottom: "0.2rem",
    color: "red",
  };
  const RadioOption = {
    fontSize: "15px",
  };
  const radioOverrides = {
    RadioMarkOuter: {
      style: ({ $theme }) => ({
        backgroundColor: "#127e40",
      }),
    },
    Root: {
      style: ({ $theme }) => ({
        display: "flex",
        alignItems: "center",
        width: "250px",
      }),
    },
  };
  const handleCancelBookingWay = (e) => {
    passBookingData.cancellation_reason = e.currentTarget.value;
    setValue(e.currentTarget.value);
  };
  const fetchCancelReason = () => {
    axios({
      method: "GET",
      baseURL: BASE_URL_HULK,
      url: `${BASE_URL_HULK}/app/cancellation-reasons/`,
      withCredentials: false,
      headers: {
        "Content-Type": `application/json`,
      },
    })
      .then((data) => {
        for (let key in data.data.cancellation_reasons) {
          if (key === getBookingId.userBookingStatus) {
            setGetReasonResponse(true);
            setCancelReason(data.data.cancellation_reasons[key]);
          }
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const handleCancelBooking = () => {
    if (passBookingData.cancellation_reason === "") {
      setShowErrMsgCancel("Please Select a Cancellation Reason");
      setTimeout(() => {
        setShowErrMsgCancel("");
      }, 3000);
    } else {
      setShowCancelLoader(true);
      axios({
        method: "POST",
        baseURL: BASE_URL_HULK,
        url: `${BASE_URL_HULK}/app/cancel_booking/?user_id=${cookiesUser.userid}`,
        data: passBookingData,
        withCredentials: false,
        headers: {
          "Content-Type": `multipart/form-data`,
        },
      })
        .then(({ data }) => {
          if (data.status === "success") {
            // window.location.reload(false);
            setShowCancelLoader(false);
            setOptionBooking(false);
            handleCloseBookingBlock();
            showHistory();
          }
        })
        .catch((err) => {
          setShowCancelLoader(false);
          console.log(err.message);
        });
    }
  };
  useEffect(() => {
    fetchCancelReason();
  }, []);
  return (
    <Modal
      onClose={() => {
        setShowCancelReasonBlock(false);
        setOptionBooking(false);
      }}
      closeable
      isOpen={showCancelReasonBlock}
      animate
      autoFocus
      size={SIZE.default}
      role={ROLE.dialog}
      overrides={{
        Close: {
          style: ({ $theme }) => ({
            outline: `black`,
          }),
        },
      }}
    >
      {getReasonResponse ? (
        <ModalHeader>Cancellation Reason</ModalHeader>
      ) : null}
      <ModalBody>
        <div style={errMsgStyle}>{showErrMsgCancel}</div>
        <div className="RadioGroupBlock">
          {cancelReason.map((elem, index) => {
            return (
              <div key={index} style={{ width: "40%" }}>
                <RadioGroup
                  value={value}
                  onChange={handleCancelBookingWay}
                  name="CancelOrder"
                  align={ALIGN.vertical}
                >
                  <Radio value={elem.slug} overrides={radioOverrides}>
                    <div style={RadioOption}>{elem.title}</div>
                  </Radio>
                </RadioGroup>
              </div>
            );
          })}
        </div>
      </ModalBody>
      <ModalFooter>
        {showCancelLoader ? (
          <div className="CancelReasonBtn">
            {getReasonResponse ? (
              <Button size={SIZE.mini} isLoading>
                Submit
              </Button>
            ) : null}
          </div>
        ) : (
          <div className="CancelReasonBtn">
            {getReasonResponse ? (
              <Button onClick={handleCancelBooking} size={SIZE.mini}>
                Submit
              </Button>
            ) : null}
          </div>
        )}
      </ModalFooter>
    </Modal>
  );
}

export default CancelReasonBlock;
