import React, { Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import { CookiesProvider } from "react-cookie";
import "./index.css";
import App from "./App";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import clevertap from "clevertap-web-sdk";
import ReactGA from 'react-ga';

// redux
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import { rootReducer } from "./Redux/store";
import { createStore, applyMiddleware, compose } from "redux";

// Context providers
// (Note: These context providers are used to wrap the components and provide them with specific contexts)
import DownloadContextProvider from "./context/DownloadContextProvider";
import HamburgerContextProvider from "./context/HamburgerContextProvider";
import SellCarContextProvider from "./context/SellCarContextProvider";
import CoinContextProvider from "./context/CoinContextProvider";
import FastagContextProvider from "./context/FastagContextProvider";
import FooterContextProvider from "./context/FooterContextProvider";
import RSAContextProvider from "./context/RSAContextProvider";
import ReferralContextProvider from "./context/ReferralContextProvider";
import BookingFlowParaContextProvider from "./context/BookingFlowParaProvider";
import BookingFlowDataContextProvider from "./context/BookingFlowDataContextProvider";
import ApplicationContextProvider from "./context/ApplicationContextProvider";
import InsuranceClaimContextProvider from "./context/InsuranceClaimContextProvider";
import WashSubscriptionContextProvider from "./context/WashSubscriptionContextProvider";
import CarServiceContextProvider from "./context/CarServiceContextProvider";


// Base Web themes
import Theme from "./Components/Theme/Theme";
import { BaseProvider, LightTheme } from "baseui";
import { Client as Styletron } from "styletron-engine-atomic";
import { Provider as StyletronProvider } from "styletron-react";
import "mapbox-gl/dist/mapbox-gl.css";

// Create a new Styletron engine
const engine = new Styletron();

// Enable Redux DevTools extension in development mode
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// Initialize Clevertap
// Clevertap Default
// visit clevertapConfig.js for more information
// production:-RZ7-57R-676Z // TEST :- TEST-KZ7-57R-676Z
clevertap.privacy.push({ optOut: false });
clevertap.privacy.push({ useIP: false });
clevertap.init("RZ7-57R-676Z", "in1"); // Change to your Clevertap credentials
clevertap.spa = true;
ReactGA.initialize("GTM-K6NFJN6");

// const clevertapAccount2 = clevertap;
// clevertapAccount2.init("8RZ-466-776Z", "in1"); // Change to your Clevertap credentials
// clevertapAccount2.spa = true;

// Create the Redux store
const store = createStore(rootReducer, composeEnhancer(applyMiddleware(thunk)));

// Create a React root and render the app
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <CookiesProvider>
      <FooterContextProvider>
        <CarServiceContextProvider>
          <DownloadContextProvider>
            <HamburgerContextProvider>
              <SellCarContextProvider>
                <CoinContextProvider>
                  <FastagContextProvider>
                    <RSAContextProvider>
                      <ReferralContextProvider>
                        <BookingFlowParaContextProvider>
                          <BookingFlowDataContextProvider>
                            <ApplicationContextProvider>
                              <InsuranceClaimContextProvider>
                                <WashSubscriptionContextProvider>
                                  <BrowserRouter>
                                    <StyletronProvider value={engine}>
                                      <BaseProvider theme={LightTheme}>
                                        <Suspense fallback={<Theme />}>
                                          <App />
                                        </Suspense>
                                      </BaseProvider>
                                    </StyletronProvider>
                                  </BrowserRouter>
                                </WashSubscriptionContextProvider>
                              </InsuranceClaimContextProvider>
                            </ApplicationContextProvider>
                          </BookingFlowDataContextProvider>
                        </BookingFlowParaContextProvider>
                      </ReferralContextProvider>
                    </RSAContextProvider>
                  </FastagContextProvider>
                </CoinContextProvider>
              </SellCarContextProvider>
            </HamburgerContextProvider>
          </DownloadContextProvider>
        </CarServiceContextProvider>
      </FooterContextProvider>
    </CookiesProvider>
  </Provider>
);

// Report web vitals
reportWebVitals();
