/**
 * The `DailyContent` component is designed to manage and display daily booking options for users,
 * including both one-way and round-trip options. It leverages the `ApplicationContext` and `BookingFlowDataContext`
 * contexts to manage global state across the application and uses Axios for API calls to fetch active package data
 * related to the user's past bookings. Additionally, it integrates CleverTap for event tracking.
 *
 * State Variables:
 * - `packageData`: Stores the fetched package data.
 * - `packageLoading`: Indicates whether the package data is currently being fetched.
 * - `isOpen`: Controls the visibility of the modal drawer for booking type selection.
 *
 * External Dependencies:
 * - Axios for API requests.
 * - CleverTap for event tracking.
 * - React Router for navigation.
 * - `ApplicationContext` and `BookingFlowDataContext` for global state management.
 *
 * The component is styled using CSS modules, with styles imported from `dailyStyles` and `duBookingStyle`.
 *
 * Props: None
 *
 * Example Usage:
 * ```jsx
 * <DailyContent />
 * ```
 *
 * Note: Ensure the environment variable `REACT_APP_BASE_URL` is set in your `.env-cmdrc.json` file for the API requests to work correctly.
 */

import React, { Fragment, useContext, useEffect, useState } from "react";
import dailyStyles from "../Landing.module.css";
import duBookingStyle from "./duBookingType.module.css";
import { featuresObject } from "./Features";
import { Button, SIZE } from "baseui/button";
import { useNavigate } from "react-router-dom";
import ChevronRight from "baseui/icon/chevron-right";
import { ApplicationContext } from "../../context/ApplicationContextProvider";
import axios from "axios";
import clevertap from "clevertap-web-sdk";
import { reserveDailyCTA } from "../../utils/EventObject";
import { Drawer, ANCHOR, SIZE_DIMENSION } from "baseui/drawer";
import { MdArrowForwardIos } from "react-icons/md";
import { BookingFlowDataContext } from "../../context/BookingFlowDataContextProvider";
import {
  DailyOneWay,
  DailyRoundWay,
} from "../../Components/AllImportFiles/ImportSvg";

const DailyContent = () => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [packageData, setPackageData] = useState([] || {});
  const [packageLoading, setPackageLoading] = useState(true);
  const [isOpen, setIsOpen] = React.useState(false);
  const { duDailyParams } = useContext(ApplicationContext);
  const { setSendingDailyBookingDataOneWay } = useContext(
    BookingFlowDataContext
  );
  const navigate = useNavigate();
  const handleFetchActivePackage = () => {
    axios({
      method: "GET",
      baseURL: BASE_URL,
      url: `${BASE_URL}/web/past-du-daily-orders/?user_id=${duDailyParams.user_id}`,
      withCredentials: false,
      headers: {
        "Content-Type": `application/json`,
      },
    })
      .then(({ data }) => {
        // if (data.status === "success") {
          
        //   setPackageData(data);
        //   setPackageLoading(false);
        // } else {
        //   setPackageLoading(false);
        //   console.log(data.message);
        // }

        if (data.status === "success") {
          const sortedOrders = data.orders.sort((a, b) => {
            if (a.btn_text === "Confirmed") return -1;
            if (b.btn_text === "Confirmed") return 1;
            return 0;
          });
          setPackageData({ ...data, orders: sortedOrders });
          setPackageLoading(false);
        } else {
          setPackageLoading(false);
          console.log(data.message);
        }
      })
      .catch((err) => {
        setPackageLoading(false);
        console.log(err.message);
      });
  };
  useEffect(() => {
    if (!duDailyParams.user_id === true) {
      return;
    } else {
      handleFetchActivePackage();
    }
  }, [duDailyParams]);
  const handleRedirectTrackPage = (url) => {
    window.location.href = url;
  };
  const handleNavigateCreateDailyBookings = () => {
    if (duDailyParams.is_one_way === "1") {
      setIsOpen(true);
    } else {
      handleNavigateRoundDU();
    }
    reserveDailyCTA.src = duDailyParams.src;
    clevertap.event.push("Reserve daily driver CTA clicked", reserveDailyCTA);
  };
  const handleNavigateRoundDU = () => {
    navigate("/create-daily-bookings");
  };
  const handleNavigateOneWayDU = () => {
    setSendingDailyBookingDataOneWay((prev) => ({
      ...prev,
      booking_type: "one_way_trip",
    }));
    navigate("/create-daily-bookings-oneway");
  };
  return (
    <div className={dailyStyles.contentWrapper}>
      <div style={{ width: "100%", height: "fit-content" }}>
        <div className={dailyStyles["reservation-card1"]}>
          {featuresObject.map((elem) => {
            return (
              <Fragment key={elem.id}>
                <div className={dailyStyles["reservation-card"]}>
                  <div className={dailyStyles["daily-driver-container1"]}>
                    <img
                      src={elem.image}
                      alt={elem.content}
                      className={dailyStyles["image-container1"]}
                    />
                  </div>
                  <p
                    className={dailyStyles["daily-driver-reservation-heading"]}
                  >
                    {elem.content}
                  </p>
                </div>
              </Fragment>
            );
          })}
        </div>
      </div>
      <>
        {packageLoading ? (
          <div className={dailyStyles.scrollBooking}>
            <h2 style={{ textAlign: "left", marginBottom: "1rem" }}>
              Past DriveU Daily Bookings
            </h2>
            <div className={dailyStyles["scroll-active-package"]}>
              <>
                {[...Array(5)].map((_, index) => {
                  return (
                    <div
                      className={`${dailyStyles.cardShower} ${dailyStyles["shimmer"]}`}
                      key={index}
                    >
                      <div className={dailyStyles["blue-box"]}>
                        <strong>Order ID : </strong>
                        <div>
                          <strong>Schedule : </strong>
                        </div>
                        <div
                          style={{ color: "#84838C", fontSize: "0.625rem" }}
                        ></div>
                      </div>
                      <Button
                        size={SIZE.mini}
                        endEnhancer={() => <ChevronRight size={24} />}
                        overrides={{
                          BaseButton: {
                            style: ({ $theme }) => ({
                              backgroundColor: "#127E40",
                              width: "100%",
                              display: "flex",
                              justifyContent: "space-between",
                              fontSize: "0.75rem",
                            }),
                          },
                        }}
                        onClick={() => {}}
                      ></Button>
                    </div>
                  );
                })}
              </>
            </div>
          </div>
        ) : (
          <>
            {packageData.length === 0 ||
            packageData.orders.length === 0 ? null : (
              <div className={dailyStyles.scrollBooking}>
                <h2 style={{ textAlign: "left", marginBottom: "1rem" }}>
                  Past DriveU Daily Bookings
                </h2>

                <div className={dailyStyles["scroll-active-package"]}>
                  {packageData.orders.map((elem, index) => {
                    return (
                      <div className={dailyStyles.cardShower} key={index}>
                        <div className={dailyStyles["blue-box"]}>
                          <strong>Order ID : {elem.order_id}</strong>
                          <div>
                            <strong>Schedule : {elem.scheduled_at}</strong>
                          </div>
                          <div
                            style={{ color: "#84838C", fontSize: "0.625rem" }}
                          >
                            {elem.pickup_dates}
                          </div>
                        </div>
                        <Button
                          size={SIZE.mini}
                          endEnhancer={() => <ChevronRight size={24} />}
                          overrides={{
                            BaseButton: {
                              style: ({ $theme }) => ({
                                backgroundColor: "#127E40",
                                width: "100%",
                                display: "flex",
                                justifyContent: "space-between",
                                fontSize: "0.75rem",
                              }),
                            },
                          }}
                          onClick={() =>
                            handleRedirectTrackPage(elem.redirect_url)
                          }
                        >
                          {elem.btn_text}
                        </Button>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </>
        )}
      </>

      <div
        className={dailyStyles["reservation-container"]}
        onClick={handleNavigateCreateDailyBookings}
      >
        <div className={dailyStyles["reservation-button-container"]}>
          <Button className={dailyStyles["reserve-button"]}>
            Reserve Daily Driver
          </Button>
        </div>
      </div>
      <Drawer
        isOpen={isOpen}
        autoFocus
        onClose={() => setIsOpen(false)}
        anchor={ANCHOR.bottom}
        size={SIZE_DIMENSION.auto}
      >
        <h2 className={duBookingStyle.bookingSectionText}>
          What is your need for daily driver?
        </h2>
        <div className={duBookingStyle.bookingContainer}>
          <div
            className={duBookingStyle.bookingTypeContainer}
            onClick={handleNavigateRoundDU}
          >
            <div className={duBookingStyle.bookingTextWrapper}>
              <DailyRoundWay />
              <div className={duBookingStyle.dailyTripType}>
                <div>
                  <strong>Round Trip - </strong>
                  <span>Same location</span>
                </div>
                <div>for pickup & drop</div>
              </div>
            </div>
            <MdArrowForwardIos />
          </div>
          <div
            className={duBookingStyle.bookingTypeContainer}
            onClick={handleNavigateOneWayDU}
          >
            <div className={duBookingStyle.bookingTextWrapper}>
              <DailyOneWay />
              <div className={duBookingStyle.dailyTripType}>
                <div>
                  <strong>OneWay Trip - </strong>
                  <span>Different locations</span>
                </div>
                <div>for pickup & drop</div>
              </div>
            </div>
            <MdArrowForwardIos />
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default DailyContent;
