import React, { createContext, useState } from "react";

export const InsuranceClaimContext = createContext();
function InsuranceClaimContextProvider({ children }) {
  const [claimStatus, setClaimStatus] = useState("");
  const [insClaimData, setInsClaimData] = useState({});
  const [processAmount, setProcessAmount] = useState({
    submit_type: "process_amount",
    beneficiary_name: "",
    account_number: "",
    ifsc_code: "",
  });
  const [uploadInvoice, setUploadInvoice] = useState({
    submit_type: "upload_invoice",
    claim_amount: "",
  });
  const [userId, setUserId] = useState();
  const [bookingId, setBookingId] = useState();
  return (
    <InsuranceClaimContext.Provider
      value={{
        claimStatus,
        setClaimStatus,
        insClaimData,
        setInsClaimData,
        processAmount,
        setProcessAmount,
        userId,
        setUserId,
        bookingId,
        setBookingId,
        uploadInvoice,
        setUploadInvoice,
      }}
    >
      {children}
    </InsuranceClaimContext.Provider>
  );
}

export default InsuranceClaimContextProvider;
