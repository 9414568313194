import React, { useState, useEffect, useContext } from "react";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import useOnclickOutside from "react-cool-onclickoutside";
import axios from "axios";
import { MdClose } from "react-icons/md";
import { BookingFlowDataContext } from "../../../context/BookingFlowDataContextProvider";
import { ApplicationContext } from "../../../context/ApplicationContextProvider";
import { SIZE, Spinner } from "baseui/spinner";
import inputStyle from "../DUPickUp/DDinput.module.css";

function OneWaySecondInput() {
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const [dailyLocationTextOneWay, setDailyLocationTextOneWay] = useState(0);
  const [showError, setShowError] = useState(false);
  const [toggleEmptyInput, setToggleEmptyInput] = useState(false);
  const [locationLoading, setLocationLoading] = useState(false);
  const [formData, setFormData] = useState({});
  const [previousInputValue, setPreviousInputValue] = useState("");

  const {
    firstCityId,
    misMatchCityId,
    setMisMatchCityId,
    setSecondCityId,
    sendingDailyBookingDataOneWay,
    setSendingDailyBookingDataOneWay,
    toggleFareEstimate,
  } = useContext(BookingFlowDataContext);

  const {
    duDailyParams,
    setDailyDropLocationTracker,
    setDropLocationErrorOneWay,
  } = useContext(ApplicationContext);

  const handleChange = (e) => {
    const { name } = e.target;
    setFormData({
      ...formData,
      [name]: e.target.value,
    });
  };
  const {
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    debounce: 300,
  });
  const oneWayHandleSecondInput = (e) => {
    if (value !== previousInputValue) {
      // User deleted text, handle it here
      setDropLocationErrorOneWay(true);
    }
    const locationValue = e.target.value;
    setValue(locationValue);
    setDailyLocationTextOneWay(locationValue.length);
  };

  const handleCallNearestDriverAPI = async (lat, lng) => {
    setLocationLoading(true);
    try {
      const latLngResponse = await axios.get(
        `${BASE_URL}/web/du-daily-availability/?user_id=${duDailyParams.user_id}&pickup_latitude=${lat}&pickup_longitude=${lng}`
      );
      const data = latLngResponse.data;

      if (data.status === "error") {
        setValue("");
        setShowError(true);
        setTimeout(() => {
          setShowError(false);
        }, 2000);
        setLocationLoading(false);
      } else if (data.status === "success") {
        setDailyDropLocationTracker(false);
        setSecondCityId(data.city_id);

        if (!firstCityId) {
          setMisMatchCityId(false);
          setSendingDailyBookingDataOneWay((prev) => ({
            ...prev,
            city: data.city_id,
          }));
        } else if (data.city_id !== firstCityId) {
          setMisMatchCityId(true);
          setValue("");
          setTimeout(() => {
            setMisMatchCityId(false);
          }, 2000);
        } else {
          setSendingDailyBookingDataOneWay((prev) => ({
            ...prev,
            city: data.city_id,
          }));
        }
        setLocationLoading(false);
      }
    } catch (error) {
      console.log(error.message);
      setLocationLoading(false);
    }
  };

  const dailyHandleOneWaySelect = ({ description }) => () => {
    setPreviousInputValue(value);
    setDropLocationErrorOneWay(false);
    setValue(description, false);
    clearSuggestions();
    getGeocode({ address: description }).then((results) => {
      const { lat, lng } = getLatLng(results[0]);

      const updatedData = {
        ...sendingDailyBookingDataOneWay,
        drop_address: description,
        drop_latitude: lat,
        drop_longitude: lng,
        user_id: duDailyParams.user_id,
      };

      // Update the state with the new data
      setSendingDailyBookingDataOneWay(updatedData);
      handleCallNearestDriverAPI(lat, lng);
    });
  };

  const dailyHandleSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <div key={place_id.toString()}>
          <ul
            className="suggestion"
            style={{ padding: "10px" }}
            key={place_id.toString()}
            onClick={dailyHandleOneWaySelect(suggestion)}
          >
            <strong style={{ marginRight: "5px" }} id="bold">
              {main_text}
            </strong>
            <small id="book">{secondary_text}</small>
          </ul>
        </div>
      );
    });
  const ref = useOnclickOutside(() => {
    clearSuggestions();
  });
  const handleClearInput = () => {
    setValue("");
    setSecondCityId("");
    sendingDailyBookingDataOneWay.drop_address = "";
    sendingDailyBookingDataOneWay.city = "";
    sendingDailyBookingDataOneWay.drop_latitude = 0.0;
    sendingDailyBookingDataOneWay.drop_longitude = 0.0;
    setDailyDropLocationTracker(true);
  };

  useEffect(() => {
    setValue("");
    setSecondCityId("");
    sendingDailyBookingDataOneWay.drop_address = "";
    sendingDailyBookingDataOneWay.city = "";
    sendingDailyBookingDataOneWay.drop_latitude = 0.0;
    sendingDailyBookingDataOneWay.drop_longitude = 0.0;
  }, []);

  useEffect(() => {
    if (value.length === 0) {
      setToggleEmptyInput(false);
      setSecondCityId("");
      toggleFareEstimate.oneWaySecondToggle = false; // This condition changed based on value and match city id if not same it will throw error
    } else {
      setToggleEmptyInput(true);
      toggleFareEstimate.oneWaySecondToggle = true; // This condition changed based on value and match city id if not same it will throw error
    }
  }, [value]);
  return (
    <>
      <div
        className={inputStyle.secondInputLocation}
        ref={ref}
        onChange={oneWayHandleSecondInput}
      >
        <div className={inputStyle.inputWrapper}>
          <label htmlFor="">
            Choose drop location<sup>*</sup>
          </label>
          <div className={inputStyle["roundWay_Input_Tag_Div"]}>
            <input
              style={{ border: showError ? "" : "none" }}
              className={`${inputStyle["first_Input_Tag"]}`}
              name="DropOffLocation"
              autoComplete="off"
              type="text"
              value={value}
              onChange={handleChange}
              placeholder="Enter 4 letters to Search Drop Location"
            />
            <div
              style={{ display: toggleEmptyInput ? "" : "none" }}
              className={inputStyle["dailyCloseBtn"]}
              onClick={handleClearInput}
            >
              {locationLoading ? <Spinner $size={SIZE.small} /> : <MdClose />}
            </div>
          </div>
        </div>

        <>
          {dailyLocationTextOneWay >= 4
            ? status === "OK" && (
                <div
                  style={{ width: "92%" }}
                  className={inputStyle.debounceBox}
                  id="debounceBox"
                >
                  {dailyHandleSuggestions()}
                </div>
              )
            : null}
        </>
      </div>
      <div
        style={{
          display: misMatchCityId ? "" : "none",
          color: "red",
          marginTop: "-0.5rem",
          marginBottom: "0.5rem",
          fontSize: "14px",
          fontfamily: "Circular-Std-Book",
        }}
        className={inputStyle.errorDivDU}
      >
        Pick up and drop address must be in the same city.
      </div>
      <div
        style={{
          display: showError ? "" : "none",
          color: "red",
          marginTop: "-0.5rem",
          marginBottom: "0.5rem",
          fontfamily: "Circular-Std-Book",
        }}
        className={inputStyle.errorDivDU}
      >
        Sorry, service not available
      </div>
    </>
  );
}

export default OneWaySecondInput;
