import axios from "axios";
import React, { createContext, useState } from "react";
export const carServiceContext = createContext();

function CarServiceContextProvider({ children }) {
  const [fuelTypeLoader, setFuelTypeLoader] = useState(false);
  // setMyGatePopUp

  const [loading, setLoading] = useState(false);
  //  car modal option
  const [selectCar, setSelectCar] = useState("Select car");

  //  car service array Data
  const [servicePageData, setServicePageData] = useState({});

  //  manufacturer modal
  const [isManufactureModalOpen, setIsManufactureModalOpen] = useState(false);
  const closeManufactureModal = () => {
    setIsManufactureModalOpen(false);
  };

  const [maufactureID, setManufactureId] = useState("");

  //  carModal modal
  const [isCarModalOpen, setIsCarModalOpen] = useState(false);
  const closeCarModal = () => {
    setIsCarModalOpen(false);
  };

  //  "petrol", "diesel", "cng", "electric";

  //  fuel modal
  const [isFuelModalOpen, setIsFuelModalOpen] = useState(false);
  const closeFuelModal = () => {
    setIsFuelModalOpen(false);
  };
  const [fuelData, setFuelData] = useState({});

  //  viewDetail modal
  const [isViewDetailModalOpen, setIsViewDetailModalOpen] = useState(false);
  const closeViewDetailModal = () => {
    setIsViewDetailModalOpen(false);
  };

  //  carsrvice slots state
  const [isSlot, setIsSlot] = useState(false);
  const closeSlot = () => {
    setCarNumber("");
    carServiceData.vehicle_number = "";
    setIsSlot(false);
    setCouponCodeToggle(false);
    setPromoCode("");
    carServiceData.promo_code = "";
  };

  //  success modal
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const closeSuccessModal = () => {
    setIsSuccessModalOpen(false);
  };

  // service filter arr
  const [filterServiceArr, setFilterServiceArr] = useState([]);
  const [filterOffer, setFilterOffer] = useState([]);
  // open filter service

  const [toogleService, setToogleService] = useState(false);

  //  view Modal
  const [viewModalData, setViewModalData] = useState({});

  //  coupan arr
  const [coupanArr, setCoupanArr] = useState([]);

  // filter car service arr
  const [filterCarBanner, setFilterCarBanner] = useState([]);

  //  automovill id
  const [vehicleKey, setVehicleKey] = useState("");

  //  conset
  const [carServiceConset, setCarServiceConset] = useState(false);

  //  slot info modals
  const [paymentLoader, setPaymentLoader] = useState(false);
  const [disableButton, setDisableButton] = useState(true);

  const [hideDateTime, setHideDateTime] = useState(false);
  const [serviceNotError, setServiceNotError] = useState("");
  // epoch Date Array
  const [epochDateArr, setEpocDateArr] = useState([]);
  // Time Slot array
  const [timeSlots, setTimeSlots] = useState([]);
  // Auto Today Date Selection
  const [selectedDate, setSelectedDate] = useState(-1);

  //
  const [timeCompState, setTimeCompState] = useState(-1);

  //  tabs name for the price filter
  const [priceFilter, setPriceFilter] = useState("");

  // message in promo
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  //  promo code valu

  const [promoCode, setPromoCode] = useState("");

  //  viewDetials Price
  const [viewDetailPrice, setViewDetailPrice] = useState({
    name: "",
    price: "",
    eta: "",
  });

  // filter arr loading
  const [filterLoading, setFilterLoading] = useState(false);

  // knowing from where we are opening the manufacture modal
  const handleServicePrice = (fuelType) => {
    setFuelTypeLoader(true);
    axios({
      method: "GET",
      url: `https://www.driveu.in/web/care-pitstop-home/?latitude=${carServiceData.latitude}&longitude=${carServiceData.longitude}&vehicle_key=${vehicleKey}&fuel_type=${fuelType}`,
      withCredentials: false,
      headers: {
        "Content-Type": `application/json`,
      },
    })
      .then(({ data }) => {
        if (data.services.length === 0) {
          closeViewDetailModal();
        }
        // changing map to forEach
        data.services.forEach((elem) => {
          if (elem.name === viewDetailPrice.name) {
            carServiceData.price = elem.price;
            viewDetailPrice.price = elem.price;
          }
        });

        // if (filterServiceArr.length === 0) {
        //   setServicePageData({ ...servicePageData, data });
        // } else {
        const PricefilterArr = data.services.filter((el) => {
          if (priceFilter === "General Checkup") {
            return el.extra_data.category_name === "General Health Checkup";
          } else {
            return el.extra_data.category_name === priceFilter;
          }
        });
        setFilterServiceArr(PricefilterArr);
        setServicePageData({ ...servicePageData, data });
        // }

        setSelectCar(fuelData.model);
        closeFuelModal();
        setFuelTypeLoader(false);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const [carServiceData, setCarServiceData] = useState({
    longitude: "",
    latitude: "",
    session_id: "",
    src: "my_gate",
    address: "",
    promo_code: "",
    customer_mobile: "",
    email: "",
    user_id: "",
    automovill_id: "",
    car_id: "",
    is_doorstep: "0",
    fuel_type: "",
    transmission: "",
    slot_time: "",
    slot_id: "0",
    vehicle_number: "",
    comments: "",
    price: "",
    service_name: "",
    city_id: "",
  });

  const [filterDataIndex, setFilterDataIndex] = useState({
    index1: "",
    index2: "",
    index3: "",
    index4: "",
    index5: "",
    index6: "",
  });

  //
  const [category, setCategory] = useState("");

  //
  const [handleLottie, setHandleLottie] = useState(false);

  const [trackingCarService, setTrackingCarService] = useState("");

  const [carNumber, setCarNumber] = useState("");

  //  new Promo Code
  const [isnewPromoModalOpen, setIsNewPromoModalOpen] = useState(false);
  const closeNewPromoModal = () => {
    setIsNewPromoModalOpen(false);
    setIsSlot(true);
  };
  const [couponCodeToggle, setCouponCodeToggle] = useState(false);
  const [carServicePromoData, setCarServicePromoData] = useState(null);

  return (
    <carServiceContext.Provider
      value={{
        // select car
        selectCar,
        setSelectCar,
        // manufacture modal
        isManufactureModalOpen,
        setIsManufactureModalOpen,
        closeManufactureModal,
        maufactureID,
        setManufactureId,

        // carModal
        isCarModalOpen,
        setIsCarModalOpen,
        closeCarModal,

        //  fuel Type
        isFuelModalOpen,
        setIsFuelModalOpen,
        closeFuelModal,
        fuelData,
        setFuelData,

        //  view Detail Modal
        isViewDetailModalOpen,
        setIsViewDetailModalOpen,
        closeViewDetailModal,

        //  service Data
        servicePageData,
        setServicePageData,

        //  loading in conset popup car service or driver serivce state is manage by this
        loading,
        setLoading,

        //  filter service arr
        filterServiceArr,
        setFilterServiceArr,

        //  toogle
        toogleService,
        setToogleService,

        //  view modal
        viewModalData,
        setViewModalData,

        //  coupan arr

        coupanArr,
        setCoupanArr,

        //  filter car banner
        filterCarBanner,
        setFilterCarBanner,
        //  filter offer
        filterOffer,
        setFilterOffer,

        //  vehicle Key
        vehicleKey,
        setVehicleKey,

        //  conset
        carServiceConset,
        setCarServiceConset,

        //  slot data
        isSlot,
        setIsSlot,
        closeSlot,

        //  slot info modals
        paymentLoader,
        setPaymentLoader,

        disableButton,
        setDisableButton,

        //

        hideDateTime,
        setHideDateTime,
        serviceNotError,
        setServiceNotError,

        //
        epochDateArr,
        setEpocDateArr,
        timeSlots,
        setTimeSlots,
        selectedDate,
        setSelectedDate,
        timeCompState,
        setTimeCompState,
        //
        handleServicePrice,

        //
        priceFilter,
        setPriceFilter,

        // car service Data
        carServiceData,
        setCarServiceData,

        //  promo msg

        showSuccessMessage,
        setShowSuccessMessage,

        //
        promoCode,
        setPromoCode,

        //
        viewDetailPrice,
        setViewDetailPrice,

        //  filter arr loading
        filterLoading,
        setFilterLoading,

        //  success modal
        isSuccessModalOpen,
        setIsSuccessModalOpen,
        closeSuccessModal,

        //  for finding the index in the separate service
        filterDataIndex,
        setFilterDataIndex,

        //

        category,
        setCategory,

        //
        handleLottie,
        setHandleLottie,

        //
        trackingCarService,
        setTrackingCarService,

        //
        fuelTypeLoader,
        setFuelTypeLoader,

        //
        carNumber,
        setCarNumber,

        //  new Promo
        isnewPromoModalOpen,
        setIsNewPromoModalOpen,
        closeNewPromoModal,

        couponCodeToggle,
        setCouponCodeToggle,

        carServicePromoData,
        setCarServicePromoData,
      }}
    >
      {children}
    </carServiceContext.Provider>
  );
}

export default CarServiceContextProvider;
