import React, { Fragment } from "react";
import dailyStyles from "../../../DriveUDaily/Landing.module.css";
import { featuresObject } from "./content";
import { Button } from "baseui/button";
import { Link } from "react-router-dom";

const DuPartnerContent = () => {
  return (
    <>
      <div className={dailyStyles.contentPartnerWrapper}>
        <div style={{ width: "100%", height: "fit-content" }}>
          <div className={dailyStyles["reservation-card1"]}>
            {featuresObject.map((elem) => {
              return (
                <Fragment key={elem.id}>
                  <div className={dailyStyles["reservation-card"]}>
                    <div className={dailyStyles["daily-driver-container1"]}>
                      <img
                        src={elem.image}
                        alt={elem.content}
                        className={dailyStyles["image-container1"]}
                      />
                    </div>
                    <p
                      className={
                        dailyStyles["daily-driver-reservation-heading"]
                      }
                    >
                      {elem.content}
                    </p>
                  </div>
                </Fragment>
              );
            })}
          </div>
        </div>
        <div className={dailyStyles.scrollBooking}></div>
        <Link
          to="/du-daily-partner-all-bookings"
          className={dailyStyles["reservation-container"]}
        >
          <div className={dailyStyles["reservation-button-container"]}>
            {/* Button Component starts here. We've generated code using MUI Base. See other options in "Component library" dropdown in Settings */}
            <Button className={dailyStyles["reserve-button"]}>
              Check Weekly Packages
            </Button>
          </div>
        </Link>
      </div>
    </>
  );
};

export default DuPartnerContent;
