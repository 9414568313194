import React from "react";
import "./CarServiceLoader.css";

export default function MyGateLoaderCarServiceLoader() {
  return (
    <div className="my-gate-car-service-spinner-container">
      <div className="my-gate-car-service-loading-spinner"></div>
    </div>
  );
}
