import React, { useState, useEffect, useContext } from "react";
import "../../OneWayBooking/OneWaySecondInput/OneWaySecondInput.css";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import useOnclickOutside from "react-cool-onclickoutside";
import axios from "axios";
import { MdClose } from "react-icons/md";
import clevertap from "clevertap-web-sdk";
import { BookingFlowDataContext } from "../../../context/BookingFlowDataContextProvider";
import { ApplicationContext } from "../../../context/ApplicationContextProvider";
import {
  LocationClearedObj,
  LocationUpdateObj,
} from "../../../utils/EventObject";
import toast from "react-hot-toast";
function OutstationDrop() {
  const [outStationDropText, setOutStationDropText] = useState(0);
  const [formData, setFormData] = useState({});
  const [toggleEmptyInput, setToggleEmptyInput] = useState(false);
  const { myGateToken } = useContext(ApplicationContext);
  const {
    firstCityId,
    misMatchCityId,
    setMisMatchCityId,
    setSecondCityId,
    sendingOutstationData,
    toggleOutstationFare,
    dropCityEtaDetails,
    setDropCityEtaDetails,
    fareParams,
    setFareChangeOutstation,
  } = useContext(BookingFlowDataContext);
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const handleChange = (e) => {
    const { name } = e.target;
    setFormData({
      ...formData,
      [name]: e.target.value,
    });
  };
  const {
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    debounce: 300,
  });
  const roundHandleInput = (e) => {
    setValue(e.target.value);
    let a = e.target.value.length;
    setOutStationDropText(a);
  };
  useEffect(() => {
    if (value.length === 0) {
      setToggleEmptyInput(false);
      toggleOutstationFare.outstationSecondToggle = false;
    } else {
      setToggleEmptyInput(true);
      toggleOutstationFare.outstationSecondToggle = true;
    }
  }, [value]);

  const roundHandleSelect = ({ description }) => () => {
    setValue(description, false);
    clearSuggestions();
    getGeocode({ address: description }).then((results) => {
      const { lat, lng } = getLatLng(results[0]);
      sendingOutstationData.drop_address = description;
      sendingOutstationData.drop_latitude = lat;
      sendingOutstationData.drop_longitude = lng;

      fareParams.drop_address = description;
      fareParams.drop_latitude = lat;
      fareParams.drop_longitude = lng;

      axios
        .get(`${BASE_URL}/web/nearest-driver/?latitude=${lat}&longitude=${lng}`)
        .then(({ data }) => {
          if (data.status === "success") {
            setSecondCityId(data.city_id);
            setDropCityEtaDetails({ ...data });
            if (data.city_id === firstCityId) {
              sendingOutstationData.drop_address = "";
              fareParams.drop_address = "";
              setMisMatchCityId(true);
              setValue("");
              setTimeout(() => {
                setMisMatchCityId(false);
              }, 2000);
            } else if (!firstCityId === true) {
              setMisMatchCityId(false);
            } else {
              setFareChangeOutstation("FareChange");
            }
          } else if (data.status === "error") {
            toast.error(data.message);
          }
          LocationUpdateObj.location_type = "drop";
          LocationUpdateObj.pickup_zone = data.zone_name || "N/A";
          LocationUpdateObj.city = data.city || "N/A";
          LocationUpdateObj.client = myGateToken ? "mygate" : "website";
          clevertap.event.push("location_updated", LocationUpdateObj);
        })
        .catch((err) => {
          console.log(err.message);
        });
    });
  };

  const handleOutstationDropSuggestion = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <div key={place_id.toString()}>
          <ul
            className="suggestion"
            style={{ padding: "10px" }}
            key={place_id.toString()}
            onClick={roundHandleSelect(suggestion)}
          >
            <strong style={{ marginRight: "5px" }} id="bold">
              {main_text}
            </strong>
            <small id="book">{secondary_text}</small>
          </ul>
        </div>
      );
    });
  const ref = useOnclickOutside(() => {
    clearSuggestions();
  });
  return (
    <>
      <div
        className="location secondInput"
        ref={ref}
        onChange={roundHandleInput}
      >
        <div className="second_Input_Tag_Div">
          <input
            style={{ border: "none" }}
            className="location second_Input_Tag"
            name="DropOffLocation"
            autoComplete="off"
            type="text"
            value={value}
            onChange={handleChange}
            placeholder="Enter 4 letters to Search Drop Location"
          />
          <div
            style={{ display: toggleEmptyInput ? "" : "none" }}
            className="empty_input_div"
            onClick={() => {
              setValue("");
              setSecondCityId("");
              setFareChangeOutstation("");
              sendingOutstationData.drop_address = "";
              fareParams.distance = 0;
              fareParams.duration = 0;
              sendingOutstationData.distance = 0;
              sendingOutstationData.duration = 0;
              fareParams.drop_address = "";
              toggleOutstationFare.outstationSecondToggle = false;
              LocationClearedObj.client = myGateToken ? "mygate" : "website";
              LocationClearedObj.location_type = "drop";
              LocationClearedObj.pickup_zone = dropCityEtaDetails.zone_name;
              LocationClearedObj.city = dropCityEtaDetails.city;
              clevertap.event.push("location_cleared", LocationClearedObj);
            }}
          >
            <MdClose />
          </div>
        </div>

        {outStationDropText >= 4
          ? status === "OK" && (
              <div
                style={{ width: "92%" }}
                className="debounceBox"
                id="debounceBox"
              >
                {handleOutstationDropSuggestion()}
              </div>
            )
          : null}
      </div>
      <div
        style={{
          display: misMatchCityId ? "" : "none",
          color: "red",
          marginTop: "-0.5rem",
          marginBottom: "0.5rem",
          fontSize: "14px",
          fontfamily: "Circular-Std-Book",
        }}
      >
        Pick up and drop address must be a different city.
      </div>
    </>
  );
}

export default OutstationDrop;
