import axios from "axios";
import { useEffect, useState } from "react";

const useGetActiveBooking = (url) => {
  const [activeBookData, setActiveBookData] = useState([]);
  const [isActiveLoading, setIsActiveLoading] = useState(false);
  const [activeError, setActiveError] = useState("");

  useEffect(() => {
    setIsActiveLoading(true);
    axios({
      method: "GET",
      url: url,
      withCredentials: false,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(({ data }) => {
        if (data.status === "success") {
          setActiveBookData(data.data);
        } else {
          setActiveError("No Active Booking Found");
        }
        setIsActiveLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
        setIsActiveLoading(false);
      });
  }, []);
  return { activeBookData, isActiveLoading, activeError };
};

export default useGetActiveBooking;
