import axios from "axios";
import { useEffect, useState } from "react";

const useGetReserveBooking = (url) => {
  const [reserveBookData, setReserveBookData] = useState([]);
  const [isReserveLoading, setIsReserveLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    setIsReserveLoading(true);
    axios({
      method: "GET",
      url: url,
      withCredentials: false,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(({ data }) => {
        if (data.status === "success") {
          setReserveBookData(data.data);
        } else {
          setError("No Reserve Booking Found");
        }
        setIsReserveLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
        setIsReserveLoading(false);
      });
  }, []);
  return { reserveBookData, isReserveLoading, error };
};

export default useGetReserveBooking;
