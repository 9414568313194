
import React, { useState } from 'react';
import {Link} from "react-router-dom"
import msilCss from "./msilOrderProfile.module.css";


const MsilOrderProfilePage = () => {
  const [bool, setBool] = useState(false);
  return (
    
    <div className={msilCss.orderProfileMainDiv}>
      <div className={msilCss.profileInfo}>
        <div className={msilCss.profileInfoImg}>
          <img
            src="https://ik.imagekit.io/driveu/Automovil/Group_9hrtyMowm.png"
            alt="img"
          />
        </div>
        <div>{"userInfo.full_name"}</div>

        <div>{"userInfo.email"}</div>
        <div>
          <span
            style={{
            //   display: Object.keys(userInfo).length === 0 ? "none" : "",

                display : bool ? "block": "none",
            }}
          >
            +91 -
          </span>
          &nbsp;{"userInfo.username"}
        </div>
      </div>

      <Link
        to="/book-driver/"
        style={{ color: "#97979e", textDecoration: "none" }}
      >
        <div
          // to="/book-driver"
          style={{ borderTop: "none", borderBottom: "none" }}
          className={msilCss.infoOption}
        >
          Book Driver
        </div>
      </Link>

      <Link
        to="/recharge-fastag"
        style={{ color: "#97979e", textDecoration: "none" }}
      >
        <div
          // to="/book-driver"
          style={{ borderTop: "none", borderBottom: "none" }}
          className={msilCss.infoOption}
        >
          Recharge FASTag
        </div>
      </Link>
      <a
        href="https://www.driveu.in/car-care"
        style={{ color: "#97979e", textDecoration: "none" }}
      >
        <div
          // to="/book-driver"
          style={{ borderTop: "none", borderBottom: "none" }}
          className={msilCss.infoOption}
        >
          Book Car Wash
        </div>
      </a>
    </div>
  )
}

export default MsilOrderProfilePage
