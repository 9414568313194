import React, { Fragment, useContext } from "react";
import { ReactComponent as DuCrew } from "../../../Assets/Svg/DUCrew_Website.svg";
import {
  CarDetailing,
  CarServices,
  CarWash,
  DriveUCoins,
  FastTag,
  HireDriver,
  // RoadSide,
  // SellCar,
} from "../../../Components/AllImportFiles/ImportSvg";
import { Link, useNavigate } from "react-router-dom";
import "./ServicesDD.css";
import RegistrationForm from "../../../Components/Home/PopUpWindow/RegistrationForm/RegistrationForm";
import { DownloadContext } from "../../../context/DownloadContextProvider";
import { BookingFlowParaContext } from "../../../context/BookingFlowParaProvider";
// import { Helmet } from "react-helmet";

function ServicesDD() {
  const navigate = useNavigate();
  const { showPopUp, setShowPopUp, setPreviousChoice } =
    useContext(DownloadContext);
  const { isAuthenticated } = useContext(BookingFlowParaContext);
  const handleHireDrivers = () => {
    if (isAuthenticated === true) {
      navigate("/book-driver/");
    } else if (isAuthenticated === false) {
      setShowPopUp(true);
      setPreviousChoice("hiredrivers");
    }
  };
  return (
    <Fragment>
      {/* <Helmet>
        <meta property="og:title" content="DriveU Crew: Book Multiple Drivers" />
        <meta
          property="og:description"
          content="Need to hire multiple drivers for your parties, functions, pub valet or any events?"
        />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta
          property="og:image"
          content="https://ik.imagekit.io/driveu/DuCrew.fyvYWYmU_W0vtBeuYz.svg?updatedAt=1718259350923"
        />
        <meta property="og:url" content="https://www.driveu.in/driveu-crew/" />
        <meta property="og:site_name" content="DriveU" />
      </Helmet> */}
      {showPopUp ? (
        <RegistrationForm />
      ) : (
        <div className="services-wrapper">
          <div className="services-content-box">
            <div className="main-services" onClick={handleHireDrivers}>
              <div className="services-svg">
                <HireDriver className="svg-option" />
              </div>
              <div className="services-dd-content">
                <section className="section-services">Hire Drivers</section>
                <article className="article-services">
                  Get driven by professional drivers
                </article>
              </div>
            </div>
            <Link to="/recharge-fastag" className="main-services">
              <div className="services-svg">
                <FastTag className="svg-option" />
              </div>
              <div className="services-dd-content">
                <section className="section-services">FASTag Recharge</section>
                <article className="article-services">
                  Get your FASTag balance & recharge on the go!
                </article>
              </div>
            </Link>
            <Link to="/car-servicing" className="main-services">
              <div className="services-svg">
                <CarServices className="svg-option" />
              </div>
              <div className="services-dd-content">
                <div className="section-services">Car Servicing</div>
                <article className="article-services">
                  Book car repair & maintenance services
                </article>
              </div>
            </Link>
            <Link to="/car-wash" className="main-services">
              <div className="services-svg">
                <CarWash className="svg-option" />
              </div>
              <div className="services-dd-content">
                <section className="section-services">Car Wash</section>
                <article className="article-services">
                  Doorstep pressure & eco car wash by professionals
                </article>
              </div>
            </Link>
            {/* <Link to="/sell-used-cars" className="main-services">
              <div className="services-svg">
                <SellCar className="svg-option" />
              </div>
              <div className="services-dd-content">
                <section className="section-services">Sell Car</section>
                <article className="article-services">
                  Get resale value of your car & book an inspection
                </article>
              </div>
            </Link> */}

            {/* <Link to="/driveu-coins" className="main-services">
              <div className="services-svg">
                <DriveUCoins className="svg-option" />
              </div>
              <div className="services-dd-content">
                <section className="section-services">DriveU Coins</section>
                <article className="article-services">
                  Earn rewards on every transaction & claim offers
                </article>
              </div>
            </Link> */}

            <a
              href="https://www.driveu.in/driveu-crew/"
              className="main-services"
            >
              <div className="services-svg">
                <DuCrew className="svg-option" />
              </div>
              <div className="services-dd-content">
                <section className="section-services">DriveU Crew</section>
                <article className="article-services">
                  Hire multiple drivers for any events
                </article>
              </div>
            </a>

            {/* <Link to="/roadside-assistance-plans" className="main-services">
              <div className="services-svg">
                <RoadSide className="svg-option" />
              </div>
              <div className="services-dd-content">
                <section className="section-services">
                  Roadside Assistance
                </section>
                <article className="article-services">
                  PAN India 24x7 roadside assistance plans
                </article>
              </div>
            </Link> */}
          </div>
        </div>
      )}
    </Fragment>
  );
}

export default ServicesDD;
