import { useState, useEffect } from "react";

/**
 * Custom hook to track the online/offline status of the browser.
 * @returns {boolean} The online status: `true` if online, `false` if offline.
 */
function useOnlineStatus() {
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    // Define the event listeners
    const goOnline = () => setIsOnline(true);
    const goOffline = () => setIsOnline(false);

    // Add event listeners for online and offline events
    window.addEventListener("online", goOnline);
    window.addEventListener("offline", goOffline);

    // Cleanup function to remove event listeners
    return () => {
      window.removeEventListener("online", goOnline);
      window.removeEventListener("offline", goOffline);
    };
  }, []); // Empty dependency array means this effect runs only once on mount

  return isOnline;
}

export default useOnlineStatus;
