import React, { useEffect, useRef } from "react";
import lottie from "lottie-web";
import LottieLoader from "../../Assets/lottie/LottieLoader.json";
import "./LottieLoaderanim.css";
function LottieLoaderanim() {
  const animationLoader = useRef(null);
  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: animationLoader.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: LottieLoader,
    });
    return () => anim.destroy();
  }, []);
  return (
    <div className="ResponsiveLottie">
      <div
        id="loader_lottie"
        ref={animationLoader}
        style={{ width: "100%", height: "100%" }}
      />
    </div>
  );
}

export default LottieLoaderanim;
