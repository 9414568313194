import React, { useContext } from "react";
import "./ViewDetailModal.css";
import { Drawer, ANCHOR, SIZE } from "baseui/drawer";
import { carServiceContext } from "../../../context/CarServiceContextProvider";
import { ApplicationContext } from "../../../context/ApplicationContextProvider";

export default ({ data }) => {
  const {
    isViewDetailModalOpen,
    closeViewDetailModal,
    selectCar,
    setIsManufactureModalOpen,
    carServiceConset,
    setIsSlot,
    viewDetailPrice,
    filterDataIndex,
    carServiceData,
  } = useContext(carServiceContext);

  const { setMyGatePopUp } = useContext(ApplicationContext);

  // let detialsLength = data.data.extra_data.details.length;

  //  this is used to open the popup like select car and the Book now
  const handleBookNow = () => {
    carServiceData.service_name = data.data.name;

    if (selectCar === "Select car") {
      setIsManufactureModalOpen(true);
    } else if (selectCar !== "Select car" && !carServiceConset) {
      closeViewDetailModal();
      setMyGatePopUp(true);
    } else {
      setIsSlot(true);
    }
  };

  return (
    <Drawer
      isOpen={isViewDetailModalOpen}
      onClose={closeViewDetailModal}
      anchor={ANCHOR.bottom}
      size={SIZE.auto}
      overrides={{
        DrawerBody: {
          style: ({ $theme }) => ({
            marginBottom: "5px",
            marginLeft: "10px",
            marginRight: "10px",
          }),
        },
        DrawerContainer: {
          style: ({ $theme }) => ({
            height: "88%",
          }),
        },
      }}
    >
      <div className="carServiceViewDetailMainContainer">
        {/* heading div */}
        <div
          style={{
            height: selectCar === "Select car" ? "12%" : "16%",
          }}
          className="carServiceViewDetailHeadingMainContainer"
        >
          <div>
            <div className="viewDetailsHeading">{data.data.name}</div>
            <div className="viewDetailTime">
              Car Service • Garage • {data.data.extra_data.eta}
            </div>
          </div>

          <div
            style={{
              display: selectCar === "Select car" ? "none" : "",
            }}
          >
            {/* viewDetailPrice */}
            <span className="viewDetailPrice">
              ₹
              {data.data.price === null
                ? viewDetailPrice.price
                : data.data.price}
            </span>
            &nbsp; (Incl of all taxes)
          </div>
        </div>

        {/* car service bottom container ★ ✩ */}
        <div className="carServiceViewDetailBottomMainContainer">
          {/*  driveu Coin  title message*/}
          <div className="carServiceViewDetailsCoinsMainContainer">
            <div className="carServiceViewDetailsCoinsHeading">
              <div className="carServiceGreenTick">
                <img
                  src="https://ik.imagekit.io/driveu/star_MzBFywi3xt.png?updatedAt=1684925166073"
                  alt=""
                />
              </div>
              &nbsp;
              <div className="carServiceGreenTickText">
                {data.data.extra_data.details[filterDataIndex.index1].title}
              </div>
              &nbsp;
              {/* emoji_url */}
              <img
                className="emojiImageMainContainer"
                src={
                  data.data.extra_data.details[filterDataIndex.index1].emoji_url
                }
                alt="emoji_img"
              />
            </div>
            <div className="carServiceViewDetailsCoinsSubHeading">
              {data.data.extra_data.details[filterDataIndex.index1].message}
            </div>
          </div>

          {/*  whats included tick_marks text title */}
          <div className="viewDetailsIncludedMainContainer">
            <div className="viewDetailsIncludedHeadingContainer">
              <div className="carServiceGreenTick">
                <img
                  src="https://ik.imagekit.io/driveu/star_MzBFywi3xt.png?updatedAt=1684925166073"
                  alt=""
                />
              </div>
              &nbsp;
              <div className="carServiceGreenTickText">
                {data.data.extra_data.details[filterDataIndex.index2].title}
              </div>
            </div>

            <div className="viewDetailServiceOptionContainer">
              {data.data.extra_data.details[
                filterDataIndex.index2
              ].tick_marks.map((elem) => (
                <div key={elem.text} className="viewDetailsOption">
                  <div className="carServiceTickMainContainer">
                    <img
                      src="https://ik.imagekit.io/driveu/noun_tick_801900_1_fQ4pdQ2Mg.png?updatedAt=1684925165881"
                      alt="img"
                    />
                  </div>
                  <div className="carServiceTickMainContainerText">
                    {elem.text}
                  </div>
                </div>
              ))}
            </div>
          </div>
          {/* view details image  */}
          <div
            style={{
              display:
                data.data.extra_data.details[filterDataIndex.index2].images
                  .length === 0
                  ? "none"
                  : "",
            }}
            className="carServiceViewDetailImageMainContainer"
          >
            <img
              src={
                data.data.extra_data.details[filterDataIndex.index2].images
                  .length === 0
                  ? ""
                  : data.data.extra_data.details[filterDataIndex.index2]
                      .images[0].image_url
              }
              alt="img"
            />
          </div>

          {/*view detail how it work */}
          <div className="viewDetailWorkMainContaier">
            <div className="viewDetailWorkHeadingMainContaier">
              <div className="carServiceGreenTick">
                <img
                  src="https://ik.imagekit.io/driveu/star_MzBFywi3xt.png?updatedAt=1684925166073"
                  alt=""
                />
              </div>
              &nbsp;
              <div className="carServiceGreenTickText">
                {data.data.extra_data.details[filterDataIndex.index3].title}
              </div>
            </div>
            <div className="viewDetailWorkURLMainContaier">
              <div className="viewDetailWorkURLContaier">
                <div>•</div>
                <div style={{ textDecoration: "underline", color: "#1b587c" }}>
                  {
                    data.data.extra_data.details[filterDataIndex.index3]
                      .points[0].text
                  }
                </div>
              </div>
            </div>
          </div>

          {/*message  points title when is it needed */}
          <div
            style={{ display: filterDataIndex.index4 < 0 ? "none" : "" }}
            className="viewDetailNeededMainContainer"
          >
            <div className="viewDetailNeededHeadingMainContainer">
              <div className="carServiceGreenTick">
                <img
                  src="https://ik.imagekit.io/driveu/star_MzBFywi3xt.png?updatedAt=1684925166073"
                  alt=""
                />
              </div>
              &nbsp;
              <div className="carServiceGreenTickText">
                {filterDataIndex.index4 < 0
                  ? ""
                  : data.data.extra_data.details[filterDataIndex.index4].title}
              </div>
            </div>
            <div className="viewDetailNeededSubHeadingMainContainer">
              {filterDataIndex.index4 < 0
                ? ""
                : data.data.extra_data.details[filterDataIndex.index4].message}
            </div>
            <div
              style={{
                display:
                  filterDataIndex.index4 < 0 ||
                  data.data.extra_data.details[filterDataIndex.index4].points
                    .length === 0
                    ? "none"
                    : "",
              }}
              className="viewDetailNeededOptionMainContainer"
            >
              {filterDataIndex.index4 < 0
                ? ""
                : data.data.extra_data.details[
                    filterDataIndex.index4
                  ].points.map((elem) => (
                    <div
                      key={elem.text}
                      className="viewDetailNeededOptionContainer FAQSCarServiceMainContainer"
                    >
                      <div>•</div>
                      <div>{elem.text}</div>
                    </div>
                  ))}
            </div>
          </div>

          {/* about the service  */}
          <div
            style={{ display: filterDataIndex.index5 < 0 ? "none" : "" }}
            className="viewDetailAboutMainContainer"
          >
            <div className="viewDetailAboutHeadingMainContainer">
              <div className="carServiceGreenTick">
                <img
                  src="https://ik.imagekit.io/driveu/star_MzBFywi3xt.png?updatedAt=1684925166073"
                  alt=""
                />
              </div>
              &nbsp;
              <div className="carServiceGreenTickText">
                {filterDataIndex.index5 < 0
                  ? ""
                  : data.data.extra_data.details[filterDataIndex.index5].title}
              </div>
            </div>

            <div className="viewDetailNeededSubHeadingMainContainer">
              {filterDataIndex.index5 < 0
                ? ""
                : data.data.extra_data.details[filterDataIndex.index5].message}
            </div>

            <div className="viewDetailAboutOptionMainContainer">
              {filterDataIndex.index5 < 0
                ? ""
                : data.data.extra_data.details[
                    filterDataIndex.index5
                  ].points.map((elem) => (
                    <div
                      key={elem.text}
                      className="viewDetailAboutOptionContainer FAQSCarServiceMainContainer"
                    >
                      <div>•</div>
                      <div>{elem.text}</div>
                    </div>
                  ))}
            </div>
          </div>

          {/* FAQs  faqs  question answer*/}
          <div className="viewDetailFAQMainContainer">
            <div className="viewDetailFAQHeadingMainContainer">
              <div className="carServiceGreenTick">
                <img
                  src="https://ik.imagekit.io/driveu/star_MzBFywi3xt.png?updatedAt=1684925166073"
                  alt=""
                />
              </div>
              &nbsp;
              <div className="carServiceGreenTickText">
                {data.data.extra_data.details[filterDataIndex.index6].title}
              </div>
            </div>
            <div className="viewDetailFAQOptionMainContainer">
              {data.data.extra_data.details[filterDataIndex.index6].faqs.map(
                (elem) => (
                  <div
                    className="FAQSCarServiceMainContainer"
                    key={elem.question}
                  >
                    <div
                      style={{ fontWeight: "700" }}
                      className="viewDetailFAQOptionContainer"
                    >
                      <div>•</div>
                      <div className="carServiceFAQQuestionMainContaier">
                        {elem.question}
                      </div>
                    </div>
                    <div className="viewDetailFAQOptionContainer">
                      <div style={{ marginLeft: "5px" }}></div>
                      <div>{elem.answer}</div>
                    </div>
                  </div>
                )
              )}
            </div>
          </div>

          {/* end */}
        </div>

        <div className="carServiceViewDetailBookContainer">
          <div
            style={{
              backgroundColor: "#F93356",
              color: "white",
              border: "1px solid #f93356",
            }}
            onClick={handleBookNow}
            className="carServiceViewDetailButtonContainer"
          >
            {selectCar === "Select car" ? "Select car" : "Book Now"}
          </div>
        </div>
      </div>
    </Drawer>
  );
};
