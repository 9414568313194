import React, { createContext, useState } from "react";
export const HamburgerContext = createContext();

function HamburgerContextProvider({ children }) {
  const [showMediaIcons, setShowMediaIcons] = useState(false);
  const handleHamburger = () => {
    setShowMediaIcons(!showMediaIcons);
  };
  const handleFalse = () => {
    setShowMediaIcons(false);
  };
  return (
    <HamburgerContext.Provider
      value={{ showMediaIcons, handleHamburger, handleFalse }}
    >
      {children}
    </HamburgerContext.Provider>
  );
}
export default HamburgerContextProvider;
