import React, { Fragment, useContext, useState } from "react";
import "./DriveUVideo.css";
import { BsFillPlayCircleFill } from "react-icons/bs";
import { ApplicationContext } from "../../../context/ApplicationContextProvider";
const Video = ({ src }) => {
  return (
    <div className="imageVideoMainContainer">
      <video
        autoPlay
        controls
        preload="auto"
        src={src}
        muted
        style={{ position: "relative" }}
        playsInline
      />
    </div>
  );
};

const Image = ({ onClick }) => (
  <div className="imageVideoMainContainer">
    <img
      src="https://mcusercontent.com/6636107ab23ed3f25d40b366e/images/9f95609f-5c0e-1f34-4dbf-d1b03d3f4d45.png"
      loading="lazy"
      alt="DriveU Video"
    />
    <div
      onClick={onClick}
      //   style={{
      //     position: "absolute",
      //     top: myGateToken ? "35%" : "21%",
      //     left: "0%",
      //     backgroundColor: "transparent",
      //     color: "white",
      //   }}
      className="play-button"
    >
      <BsFillPlayCircleFill style={{ fontSize: "40px" }} />
    </div>
  </div>
);

function DriveuVideo() {
  const videoUrl = "https://apps.driveu.in/static/DriveUDriver.mp4";
  const [isVideoModalOpen, setVideoModalOpen] = useState(false);
  const { myGateToken } = useContext(ApplicationContext);

  const handleClick = (e) => {
    e.preventDefault();
    setVideoModalOpen(true);
  };

  return (
    <Fragment>
      <div className="pressureVideoMainContainer">
        {isVideoModalOpen ? (
          <Video src={videoUrl} />
        ) : (
          <Image onClick={handleClick} myGateToken={myGateToken} />
        )}
      </div>
    </Fragment>
  );
}

export default DriveuVideo;
