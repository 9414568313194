import axios from "axios";
import React, { createContext } from "react";
import { useState, useEffect } from "react";

export const ReferralContext = createContext();
function ReferralContextProvider({ children }) {
  const [referralName, setReferralName] = useState("");
  const [referralAmount, setReferralAmount] = useState(0);
  const [goToPage, setGoToPage] = useState();
  const [referralId, setReferralId] = useState("");
  const [referralMobile, setReferralMobile] = useState("");
  const [errorReferral, setErrorReferral] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const BASE_URL_HULK = process.env.REACT_APP_HULK_URL;
  var locate = window.location.href;
  useEffect(() => {
    let Splitter = locate.split("/");
    if (Splitter[Splitter.length - 3] === "from") {
      axios({
        method: "GET",
        baseURL: BASE_URL_HULK,
        url: `${BASE_URL_HULK}/app/referral-name/?referral_code=${
          Splitter[Splitter.length - 2]
        }`,
        withCredentials: false,
        headers: {
          "Content-Type": `application/json`,
        },
      }).then(({ data }) => {
        if (data.status === "success") {
          setReferralName(data.referrer_name);
          setReferralAmount(data.referral_amount);
          setGoToPage(true);
          setReferralId(Splitter[Splitter.length - 2]);
        } else {
          setErrorReferral(Splitter[Splitter.length - 2]);
        }
      });
    }
  }, [locate]);

  return (
    <ReferralContext.Provider
      value={{
        referralName,
        referralAmount,
        goToPage,
        referralId,
        referralMobile,
        setReferralMobile,
        errorReferral,
        setShowForm,
        showForm,
        showSuccess,
        setShowSuccess,
      }}
    >
      {children}
    </ReferralContext.Provider>
  );
}

export default ReferralContextProvider;
