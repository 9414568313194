import React, { useContext, useState } from "react";
import { MiniCheck } from "../../../Components/AllImportFiles/ImportSvg";
import packageStyle from "../../DailyBooking.module.css";
import { BookingFlowDataContext } from "../../../context/BookingFlowDataContextProvider";
import {
  continueScheduleCTA,
  dailyFinalReviewDailyCTA,
} from "../../../utils/EventObject";
// This Component use for Du Daily Round Trip
const PackageType = () => {
  const { sendingDailyBookingData, pkgType, setPkgType } = useContext(
    BookingFlowDataContext
  );
  const PackageArray = ["4", "6", "8", "10", "12"];
  const [previousEl, setPreviousEl] = useState(null);

  const handlePackageHours = (el) => {
    if (previousEl === el) {
      sendingDailyBookingData.booking_type = "";
      setPkgType(0);
      setPreviousEl(null);
    } else {
      continueScheduleCTA["Estimated Usage"] = `${el}hr`;
      dailyFinalReviewDailyCTA["Estimated Usage"] = `${el}hr`;
      sendingDailyBookingData.booking_type = `${el}hr`;
      setPkgType(el);
      setPreviousEl(el);
    }
  };
  return (
    <div style={{ width: "90%", margin: "auto" }}>
      <div style={{ color: "#000", fontWeight: "600" }}>
        Select estimated usage<sup>*</sup>
      </div>

      <div className={packageStyle.packageWrapper}>
        {PackageArray.map((elem, index) => (
          <div
            className={packageStyle.packageBox}
            style={{
              backgroundColor: pkgType === elem ? "#E0F3EC" : "#ffffff",
              border: pkgType === elem ? "none" : "",
            }}
            onClick={() => handlePackageHours(elem)}
            key={index}
          >
            <div
              className={packageStyle.miniCheck}
              style={{
                display: pkgType === elem ? "" : "none",
              }}
            >
              <MiniCheck />
            </div>
            <span>{elem}</span>
            <span>Hrs</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PackageType;
