import React, { useState, useEffect, useContext } from "react";
import { MdClose } from "react-icons/md";
import "./RoundWayInput.css";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import useOnclickOutside from "react-cool-onclickoutside";
import axios from "axios";
import { BookingFlowDataContext } from "../../../context/BookingFlowDataContextProvider";
import { BookingFlowParaContext } from "../../../context/BookingFlowParaProvider";
import clevertap from "clevertap-web-sdk";
import { ApplicationContext } from "../../../context/ApplicationContextProvider";
import {
  LocationClearedObj,
  LocationUpdateObj,
} from "../../../utils/EventObject";
function RoundWayInput({ handleRemoveSuccessCoupon }) {
  const [roundWayText, setRoundWayText] = useState(0);
  const [showError, setShowError] = useState(false);
  const [toggleEmptyInput, setToggleEmptyInput] = useState(false);
  const { lat, lng, couponLatLngObj } = useContext(BookingFlowParaContext);
  const { pubName, myGateToken } = useContext(ApplicationContext);
  //   const [pickUpError, setPickUpError] = useState(false);
  const [formData, setFormData] = useState({});
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const {
    sendingRoundWayData,
    setToggleRoundWayFareEstimate,
    setRoundWayToggle,
    fareParams,
    // ETA
    setShowRoundETA,
    setEtaRoundWayTime,
    setShowRoundWhen,
    setCityEtaDetails,
    cityEtaDetails,
  } = useContext(BookingFlowDataContext);
  const handleChange = (e) => {
    const { name } = e.target;
    setFormData({
      ...formData,
      [name]: e.target.value,
    });
  };
  const roundWayHandleInput = (e) => {
    setValue(e.target.value);
    let a = e.target.value.length;
    setRoundWayText(a);
  };
  const {
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    debounce: 300,
  });

  const ref = useOnclickOutside(() => {
    clearSuggestions();
  });

  useEffect(() => {
    if (value.length === 0) {
      setToggleEmptyInput(false);
      setToggleRoundWayFareEstimate(false);
    } else {
      setToggleEmptyInput(true);
      setToggleRoundWayFareEstimate(true);
    }
  }, [value]);

  const roundWayHandleSelect = ({ description }) => () => {
    setValue(description, false);
    clearSuggestions();
    getGeocode({ address: description }).then((results) => {
      const { lat, lng } = getLatLng(results[0]);
      sendingRoundWayData.pickup_address = description;
      sendingRoundWayData.pickup_latitude = lat;
      sendingRoundWayData.pickup_longitude = lng;

      fareParams.pickup_address = description;
      fareParams.pickup_latitude = lat;
      fareParams.pickup_longitude = lng;

      axios
        .get(`${BASE_URL}/web/nearest-driver/?latitude=${lat}&longitude=${lng}`)
        // .get(
        //   `https://www.driveu.in/web/nearest-driver/?latitude=${lat}&longitude=${lng}`
        // )
        .then(({ data }) => {
          if (+data.city_id !== +couponLatLngObj.city_id) {
            handleRemoveSuccessCoupon();
          }
          setShowRoundETA(data.show_eta);
          setEtaRoundWayTime(data.eta);

          LocationUpdateObj.location_type = "pickup/drop";
          LocationUpdateObj.pickup_zone = data.zone_name;
          LocationUpdateObj.city = data.city;
          LocationUpdateObj.client = myGateToken ? "mygate" : "website";
          clevertap.event.push("location_updated", LocationUpdateObj);
          if (data.message !== "") {
            setValue("");
            setShowError(true);
            setRoundWayToggle(false);
            setTimeout(() => {
              setShowError(false);
            }, [1000]);
          } else if (data.message === "") {
            sendingRoundWayData.city = data.city_id;
            fareParams.city_id = data.city_id;
            setCityEtaDetails(data);
            setRoundWayToggle(true);
          }
        })
        .catch((err) => {
          setRoundWayToggle(false);
          console.log(err.message);
        });
    });
  };

  const roundWayrenderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <div key={place_id.toString()}>
          <ul
            className="roundsuggestion"
            style={{ padding: "10px" }}
            key={place_id.toString()}
            onClick={roundWayHandleSelect(suggestion)}
          >
            <strong style={{ marginRight: "5px" }} id="bold">
              {main_text}
            </strong>
            <small id="book">{secondary_text}</small>
          </ul>
        </div>
      );
    });
  function getReverseGeocodingData(lat, lng) {
    sendingRoundWayData.pickup_longitude = lng;
    sendingRoundWayData.pickup_latitude = lat;

    fareParams.pickup_longitude = lng;
    fareParams.pickup_latitude = lat;
    var latlng = new window.google.maps.LatLng(lat, lng);
    // This is making the Geocode request
    var geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ latLng: latlng }, (results, status) => {
      if (status !== window.google.maps.GeocoderStatus.OK) {
        // alert(status);
      }
      // This is checking to see if the Geoeode Status is OK before proceeding
      if (status === window.google.maps.GeocoderStatus.OK) {
        var address = results[0].formatted_address;
        // setAddress(address); //inputbox
        // sendingRoundWayData.pickup_address = address;

        if (pubName) {
          sendingRoundWayData.pickup_address = pubName;
          fareParams.pickup_address = pubName;
          setValue(pubName);
        } else {
          sendingRoundWayData.pickup_address = address;
          fareParams.pickup_address = address;
          setValue(address);
        }
        // setValue(address);
      }
    });
  }
  useEffect(() => {
    if (lat === null || lng === null) {
    } else {
      axios
        .get(`${BASE_URL}/web/nearest-driver/?latitude=${lat}&longitude=${lng}`)
        .then(({ data }) => {
          // show_eta
          setCityEtaDetails({ ...data });
          setShowRoundETA(data.show_eta);
          setEtaRoundWayTime(data.eta);
          if (data.message !== "") {
            setValue("");
            setShowError(true);
            setRoundWayToggle(false);
            setTimeout(() => {
              setShowError(false);
            }, [1000]);
          } else if (data.message === "") {
            getReverseGeocodingData(lat, lng);
            sendingRoundWayData.city = data.city_id;
            fareParams.city = data.city_id;
            setRoundWayToggle(true);
          }
        })
        .catch((err) => {
          setRoundWayToggle(false);
          console.log(err.message);
        });
    }
  }, [lat, lng]);
  const handleClearInput = () => {
    setRoundWayToggle(false);
    setValue("");
    sendingRoundWayData.pickup_address = "";
    fareParams.pickup_address = "";
    setShowRoundETA(null);
    setShowRoundWhen([{ label: "Now", id: "#F0F8FF" }]);
    LocationClearedObj.client = myGateToken ? "mygate" : "website";
    LocationClearedObj.location_type = "pickup/drop";
    LocationClearedObj.pickup_zone = cityEtaDetails.zone_name;
    LocationClearedObj.city = cityEtaDetails.city;
    clevertap.event.push("location_cleared", LocationClearedObj);
  };
  return (
    <>
      <div
        className="location secondInputLocation"
        ref={ref}
        onChange={roundWayHandleInput}
      >
        <div className="roundWay_Input_Tag_Div">
          <input
            style={{ border: showError ? "" : "none" }}
            className="location first_Input_Tag"
            name="RoundWayLocation"
            autoComplete="off"
            type="text"
            value={value}
            onChange={handleChange}
            placeholder="Enter 4 letters to Search PickUp Location"
            required
          />
          <div
            style={{ display: toggleEmptyInput ? "" : "none" }}
            className="round_empty_input_div"
            onClick={handleClearInput}
          >
            <MdClose />
          </div>
        </div>

        {roundWayText >= 4
          ? status === "OK" && (
              <div
                style={{ width: "92%" }}
                className="debounceBox"
                id="debounceBox"
              >
                {roundWayrenderSuggestions()}
              </div>
            )
          : null}
      </div>
      <div
        style={{
          display: showError ? "" : "none",
          color: "#127e40",
          marginBottom: "0.5rem",
          marginTop: "-0.5rem",
        }}
      >
        Sorry, service not available
      </div>
    </>
  );
}

export default RoundWayInput;
