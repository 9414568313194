import * as React from "react";
import { Modal, ModalHeader, ModalBody, SIZE, ROLE } from "baseui/modal";
import { useForm } from "react-hook-form";
import "./InsuranceConfirm.css";
import { BookingFlowParaContext } from "../../context/BookingFlowParaProvider";
import { BookingFlowDataContext } from "../../context/BookingFlowDataContextProvider";
import { DownloadContext } from "../../context/DownloadContextProvider";
import useBookDriverApi from "../../CustomHooks/useBookDriverApi";
import { ApplicationContext } from "../../context/ApplicationContextProvider";
function InsuranceConfirm() {
  const { premium, isAuthenticated, premiumOutstation } = React.useContext(
    BookingFlowParaContext
  );
  const { setMyGatePopUp, consentFlag, myGateToken } = React.useContext(
    ApplicationContext
  );
  const { setShowPopUp, setPreviousChoice } = React.useContext(DownloadContext);
  const {
    handleBookDriverBooking,
    handleBookDriverBookingRoundTrip,
    handleBookDriverOutstation,
  } = useBookDriverApi();
  const {
    setIsInsurance,
    isOpenIns,
    setIsOpenIns,
    sendingData,
    sendingRoundWayData,
    sendingOutstationData,
    setOneWayChecked,
    setTwoWayChecked,
    activeKey,
    setOutstationCheckIns,
    fareParams,
  } = React.useContext(BookingFlowDataContext);
  const form = useForm({
    defaultValues: {
      Insurance: "",
    },
    mode: "onChange",
  });
  const { register } = form;
  const handleSubmitInsurance = (e) => {
    const insPrice = e.target.value;
    // insPrice = insPrice.split(",");
    if (insPrice === "No,I don't want to") {
      if (activeKey === "0") {
        setIsInsurance(false);
        setIsOpenIns(false);
        sendingData.is_insured = false;
        sendingRoundWayData.is_insured = false;
        sendingOutstationData.is_insured = false;
        fareParams.is_insured = false;
        // handleBookDriverBooking();
        if (
          // myGateToken &&
          // consentFlag === false &&
          // !cookiesHireDrivers.userid === true
          myGateToken &&
          consentFlag === false
        ) {
          setMyGatePopUp(true);
        } else {
          if (isAuthenticated === true) {
            handleBookDriverBooking();
          } else if (isAuthenticated === false) {
            setShowPopUp(true);
            setPreviousChoice("InsuranceOneWay");
          }
        }
      } else if (activeKey === "1") {
        setIsInsurance(false);
        setIsOpenIns(false);
        sendingData.is_insured = false;
        sendingRoundWayData.is_insured = false;
        sendingOutstationData.is_insured = false;
        fareParams.is_insured = false;
        if (
          // myGateToken &&
          // consentFlag === false &&
          // !cookiesHireDrivers.userid === true
          myGateToken &&
          consentFlag === false
        ) {
          setMyGatePopUp(true);
        } else {
          if (isAuthenticated === true) {
            handleBookDriverBookingRoundTrip();
          } else if (isAuthenticated === false) {
            setShowPopUp(true);
            setPreviousChoice("InsuranceRoundTrip");
          }
        }
      } else if (activeKey === "2") {
        setIsInsurance(false);
        setIsOpenIns(false);
        sendingData.is_insured = false;
        sendingRoundWayData.is_insured = false;
        sendingOutstationData.is_insured = false;
        fareParams.is_insured = false;

        if (
          // myGateToken &&
          // consentFlag === false &&
          // !cookiesHireDrivers.userid === true
          myGateToken &&
          consentFlag === false
        ) {
          setMyGatePopUp(true);
        } else {
          if (isAuthenticated === true) {
            handleBookDriverOutstation();
          } else if (isAuthenticated === false) {
            setShowPopUp(true);
            setPreviousChoice("InsuranceOutstation");
          }
        }
      }
    } else if (insPrice === "Yes,Insure my trip") {
      if (activeKey === "0") {
        setIsInsurance(true);
        setIsOpenIns(false);
        setOneWayChecked(true);
        setTwoWayChecked(true);
        setOutstationCheckIns(true);
        sendingData.is_insured = true;
        sendingRoundWayData.is_insured = true;
        sendingOutstationData.is_insured = true;
        fareParams.is_insured = true;

        if (
          // myGateToken &&
          // consentFlag === false &&
          // !cookiesHireDrivers.userid === true
          myGateToken &&
          consentFlag === false
        ) {
          setMyGatePopUp(true);
        } else {
          if (isAuthenticated === true) {
            handleBookDriverBooking();
          } else if (isAuthenticated === false) {
            setShowPopUp(true);
            setPreviousChoice("InsuranceOneWay");
          }
        }
      } else if (activeKey === "1") {
        setIsInsurance(true);
        setIsOpenIns(false);
        setOneWayChecked(true);
        setTwoWayChecked(true);
        setOutstationCheckIns(true);
        sendingData.is_insured = true;
        sendingRoundWayData.is_insured = true;
        sendingOutstationData.is_insured = true;
        fareParams.is_insured = true;
        if (
          // myGateToken &&
          // consentFlag === false &&
          // !cookiesHireDrivers.userid === true
          myGateToken &&
          consentFlag === false
        ) {
          setMyGatePopUp(true);
        } else {
          if (isAuthenticated === true) {
            handleBookDriverBookingRoundTrip();
          } else if (isAuthenticated === false) {
            setShowPopUp(true);
            setPreviousChoice("InsuranceRoundTrip");
          }
        }
      } else if (activeKey === "2") {
        setIsInsurance(true);
        setIsOpenIns(false);
        setOneWayChecked(true);
        setTwoWayChecked(true);
        setOutstationCheckIns(true);
        sendingData.is_insured = true;
        sendingRoundWayData.is_insured = true;
        sendingOutstationData.is_insured = true;
        fareParams.is_insured = true;
        if (
          // myGateToken &&
          // consentFlag === false &&
          // !cookiesHireDrivers.userid === true
          myGateToken &&
          consentFlag === false
        ) {
          setMyGatePopUp(true);
        } else {
          if (isAuthenticated === true) {
            handleBookDriverOutstation();
          } else if (isAuthenticated === false) {
            setShowPopUp(true);
            setPreviousChoice("InsuranceOutstation");
          }
        }
      }
    }
  };
  return (
    <div>
      <Modal
        onClose={() => setIsOpenIns(false)}
        closeable
        isOpen={isOpenIns}
        animate
        autoFocus
        size={SIZE.default}
        role={ROLE.dialog}
      >
        <ModalHeader>
          <center>
            <img
              src="https://www.driveu.in/static/web/v3/images/insurance/icon_dusecure%403x.png"
              alt="Insurance"
            />
          </center>
        </ModalHeader>
        <ModalBody>
          <center
            style={{
              fontFamily: "Circular-Std-Book",
            }}
          >
            <strong style={{ color: "#09081a", opacity: 0.7 }}>
              Are you sure?
            </strong>
            <p>Get vehicle damage protection plan for your car.</p>
          </center>
        </ModalBody>
        <ModalBody>
          <form
            style={{
              fontFamily: "Circular-Std-Book",
              padding: "16px",
            }}
          >
            <label>
              <div>
                <div className="confirmIns">
                  <input
                    type="radio"
                    value="No,I don't want to"
                    {...register("Insurance")}
                    onClick={handleSubmitInsurance}
                  />
                  &nbsp;
                  <strong className="insMobileFont">No, I don't want to</strong>
                </div>

                <div className="insMobileFont">
                  I’m willing to risk it and cover the same via my own car
                  insurance.
                </div>
              </div>
            </label>

            <label>
              <div style={{ marginTop: "0.5rem" }}>
                <div className="confirmIns">
                  <input
                    type="radio"
                    value="Yes,Insure my trip"
                    {...register("Insurance")}
                    onClick={handleSubmitInsurance}
                  />
                  &nbsp;
                  <strong className="insMobileFont">
                    Yes, Secure my trip.
                  </strong>
                </div>

                <div className="insMobileFont">
                  {activeKey === "2"
                    ? ` You will be secured and ₹${+premiumOutstation} +GST will be added to
                    your bill.`
                    : ` You will be secured and ₹${+premium} +GST will be added to
                    your bill.`}
                </div>
              </div>
            </label>
          </form>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default InsuranceConfirm;
