import { withStyle } from "baseui";
import { Spinner } from "baseui/spinner";
const ExtraLargeSpinner = withStyle(Spinner, {
  width: "96px",
  height: "96px",
  borderLeftWidth: "8px",
  borderRightWidth: "8px",
  borderTopWidth: "8px",
  borderBottomWidth: "8px",
  borderTopColor: "green",
  position: "fixed",
  top: "0%",
  left: "0%",
  bottom: "0%",
  right: "0%",
  margin: "auto",
});
export default ExtraLargeSpinner;
