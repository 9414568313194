import React from "react";
import "./GurgaonCityPage.css";
import PopularPlace from "./PopularPlace/PopularPlace";
import CustomerStatics from "./CustomerStatics/CustomerStatics";
import WhyDriveUnique from "../../WhyDriveUnique/WhyDriveUnique";
import GurgaonContent from "./GurgaonContent/GurgaonContent";
import ReviewComponent from "../../ReviewPage/ReviewComponent";

function GurgaonCityPage() {
  return (
    <div className="Gurgaon-Container">
      <CustomerStatics />
      <GurgaonContent />
      <WhyDriveUnique cityLine={"Gurgaon"} />
      <ReviewComponent />
      <PopularPlace />
    </div>
  );
}

export default GurgaonCityPage;
