import React from "react";
import "../../MyGate/TrackPage.css";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
function StaticFAQs({ id, quesData }) {
  const [selected, setSelected] = React.useState(false);
  const handleSelected = () => {
    setSelected((p) => !p);
  };
  return (
    <div id={id} onClick={handleSelected} className="supportWrapper">
      <div className="supportWrapperArrow">
        <div>{quesData.question}</div>
        <IoIosArrowUp
          style={{ display: selected ? "" : "none" }}
          className="FAQArrowColor"
        />
        <IoIosArrowDown
          style={{ display: selected ? "none" : "" }}
          className="FAQArrowColor"
        />
      </div>

      <div
        style={{
          display: selected ? "" : "none",
          fontWeight: "400",
          color: "#09081a",
          opacity: "0.6",
          marginTop: "5px",
          width: "100%",
        }}
      >
        <div>{quesData.answer}</div>
      </div>
    </div>
  );
}

export default StaticFAQs;
