import React, { useContext } from "react";
import ActiveDuCard from "../../DuDailyPartner/ReservationBlock/ActiveDuCard/ActiveDuCard";
import ReserveCard from "../../DuDailyPartner/ReservationBlock/ReserveCard/ReserveCard";
import cardBookStyle from "./cardBooking.module.css";
import { Banner, HIERARCHY, KIND } from "baseui/banner";
import useGetReserveBooking from "../../CustomHooks/useGetReserveBooking";
import { ApplicationContext } from "../../context/ApplicationContextProvider";
import { Spinner } from "baseui/spinner";
import useGetActiveBooking from "../../CustomHooks/useGetActiveBooking";
const AllTypesBooking = () => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const { driverData } = useContext(ApplicationContext);
  const url = `${BASE_URL}/web/du-daily/packages/?driver_id=${driverData.driver_id}&imei_number=${driverData.imei_number}`;
  const activeURL = `${BASE_URL}/web/du-daily/active-packages/?driver_id=${driverData.driver_id}&imei_number=${driverData.imei_number}`;
  const { reserveBookData, isReserveLoading, error } = useGetReserveBooking(
    url
  );
  const { activeBookData, isActiveLoading, activeError } = useGetActiveBooking(
    activeURL
  );

  return (
    <div className={cardBookStyle.cardContainer}>
      <div className={cardBookStyle.cardWrapper}>
        <strong>Active DriveU Daily Package</strong>
        <div className={cardBookStyle.mapCardScroll}>
          {/* {[...Array(10)].map((_, index) => {
            return <ActiveDuCard key={index} />;
          })} */}
          {activeError ? (
            <Banner hierarchy={HIERARCHY.low} kind={KIND.negative}>
              <h2>Error: Something went wrong.</h2>
              <p>Please try again later.</p>
            </Banner>
          ) : (
            <>
              {isActiveLoading ? (
                <Spinner $color="green" />
              ) : (
                <>
                  {activeBookData.length === 0 ? (
                    //  43 : hierarchy={HIERARCHY.low} kind={KIND.negative}
                    <Banner>
                      {activeError ||
                        "No Active Booking. Please reserve a package."}
                    </Banner>
                  ) : (
                    <>
                      {activeBookData.map((data, index) => {
                        return <ActiveDuCard key={index} activeData={data} />;
                      })}
                    </>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>

      <div className={cardBookStyle.cardWrapper}>
        <strong>Check other Packages to reserve</strong>
        {/* <ReserveCard /> */}
        <div className={cardBookStyle.mapCardScroll}>
          {error ? (
            <Banner hierarchy={HIERARCHY.low} kind={KIND.negative}>
              <h2>Error: Something went wrong.</h2>
              <p>Please try again later.</p>
            </Banner>
          ) : (
            <>
              {isReserveLoading ? (
                <Spinner $color="green" />
              ) : (
                <>
                  {reserveBookData.length === 0 ? (
                    <Banner 
                      overrides={{
                        Root: {
                          style: ({ $theme }) => ({
                            backgroundColor: 'white', 
                          }),
                        },
                      }}
                    >
                      DU Daily Packages Not Available 
                    </Banner>
                  ) : (
                    <>
                      {reserveBookData.map((data, index) => {
                        return <ReserveCard key={index} reserveData={data} />;
                      })}
                    </>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default AllTypesBooking;
