import React from "react";

const ShimmerBottom = () => {
  const shimmerElements = Array(5).fill(0);
  return (
    <div>
      <div className="stickyPromoCode">
        <div className="promoCodeBottomSheet">
          <div className="shimmer"></div>
          <div className="promoBottomSheetText shimmer"></div>
        </div>
        <div>
          <div className="Coupon">
            <div className="inputCouponCode">
              <input
                id="couponDiv"
                className="couponDetails couponCodeBottomSheetInput"
                onChange={() => {}}
                type="text"
                placeholder="Enter a Coupon Code"
                autoComplete="off"
                value=""
                inputMode="none"
              />
              <button onClick={() => {}} id="couponBtn">
                Apply
              </button>
            </div>
            <div></div>
          </div>
        </div>
      </div>
      <>
        {shimmerElements.map((_, index) => {
          return (
            <div className="bottomCouponContainer" key={index}>
              <div className="bottomCouponWrapper">
                <div className="firstBlockCouponWrapper shimmer">
                  <div className="couponCodeBottomSheet"></div>
                  <div className="firstF1 shimmer"></div>
                </div>
                <div className="secondBlockCouponWrapper shimmer">
                  <div className="bottomS1 shimmer"></div>
                  <div className="bottomS2 shimmer"></div>
                  <div className="bottomS3 shimmer"></div>
                </div>
                <div className="thirdBlockCouponWrapper shimmer">
                  <div className="thirdT1 shimmer"></div>
                  <ul className="thirdT2 shimmer">
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                  </ul>
                </div>
                <div className="fourthBlockCouponWrapper shimmer"></div>
                <div className="fiveT1 shimmer"></div>
              </div>
            </div>
          );
        })}
      </>
    </div>
  );
};

export default ShimmerBottom;
