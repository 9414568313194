import React from "react";
import "./MyGateLoader.css";

export default function MyGateLoader() {
  return (
    <div className="my-gate-spinner-container">
      <div className="my-gate-payment-loading-spinner"></div>
    </div>
  );
}
