/**
 * `ImageScroll` is a React component that renders a carousel of images using the `react-slick` slider.
 * It is designed to display a series of images (such as banners or ads) in a scrollable format.
 *
 * Props: None
 *
 * State Variables:
 * - None. However, the component uses a local array `imageArr` to store the URLs of images to be displayed.
 *
 * Dependencies:
 * - `react-slick`: A React carousel component that provides the slider functionality.
 * - `imageStyle`: CSS module for styling. It's imported from `../Landing.module.css` and used to apply styles to the slider wrapper.
 *
 * Usage:
 * This component does not accept any props and is used simply by including it in a JSX layout:
 * ```jsx
 * <ImageScroll />
 * ```
 *
 * Styling:
 * The component uses the `imageWrapper` class from `imageStyle` to style the div that wraps the `Slider` component.
 *
 * Important Notes:
 * - The `imageArr` array contains the URLs of the images to be displayed in the slider.
 * - The `settings1` object configures the `Slider` behavior, including autoplay, speed, and whether dots are shown for navigation.
 * - Each image in the carousel is rendered with a `fetchpriority` attribute set to "high" to prioritize their loading.
 */

import React from "react";
import Slider from "react-slick";
import imageStyle from "../Landing.module.css";
const ImageScroll = () => {
  const imageArr = [
    "https://ik.imagekit.io/driveu/Daily/illustration_lRXdu9GEy.png",
    "https://ik.imagekit.io/driveu/Daily/Banner1_wmzoLkkvY.png",
    "https://ik.imagekit.io/driveu/Daily/Banner2_Acv77zFHk.png",
    "https://ik.imagekit.io/driveu/Daily/Banner3_p0sfoC_sTI.png",
    "https://ik.imagekit.io/driveu/Daily/Banner4_9BiHmV4LL1.png",
  ];

  const settings1 = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: false,
    autoplay: true,
    autoplaySpeed: 2000,
    appendDots: (dots) => (
      <div
        style={{
          width: "100%",
        }}
      >
        <ul
          style={{
            marginBottom: "2rem",
          }}
        >
          {dots}
        </ul>
      </div>
    ),
  };
  return (
    <div className={imageStyle.imageWrapper}>
      <Slider {...settings1}>
        {imageArr.map((elem, index) => {
          return (
            <img
              fetchpriority="high"
              key={index}
              src={elem}
              alt="DU DAILY CONSUMER BANNER"
              width={"100%"}
              height={"100%"}
            />
          );
        })}
      </Slider>
    </div>
  );
};

export default ImageScroll;
