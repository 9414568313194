import React, { useContext, useEffect, useRef } from "react";
import "./CityDrawer.css";
import { Drawer, ANCHOR } from "baseui/drawer";
import { ApplicationContext } from "../../../context/ApplicationContextProvider";
import { IoSearchOutline } from "react-icons/io5";
import MyGateLoader from "../../../Uis/MyGateLoader/MyGateLoader";

function CityDrawer() {
  const {
    isCityPrice,
    closeCityPrice,
    cityArr,
    cityFuelPrice,
    citySearchLoader,
    cityValue,
    setCityValue,
    setCityName,
  } = useContext(ApplicationContext);

  const searchFocus = useRef();

  const handleCityPrice = (elem) => () => {
    setCityName(elem.title);
    cityFuelPrice(elem.value, false);
  };
  const handleCityArr = (e) => {
    searchFocus.current.focus();
    setCityValue(e.target.value);
  };

  useEffect(() => {
    if (isCityPrice) {
      document.body.style.overflow = "hidden";
    }
    return () => {
      document.body.style.overflow = "scroll";
    };
  }, [isCityPrice]);

  return (
    <Drawer
      isOpen={isCityPrice}
      autoFocus
      onClose={closeCityPrice}
      anchor={ANCHOR.bottom}
      // size={SIZE.auto}
      overrides={{
        DrawerBody: {
          style: ({ $theme }) => ({
            // marginLeft: "none",
            marginRight: "none",
            width: "95%",
            marginLeft: "0.5rem",
          }),
        },
      }}
    >
      {citySearchLoader ? (
        <MyGateLoader />
      ) : (
        <>
          <div className="cityMainContainerHeading">Select city</div>
          <div className="cityInputMainContainer">
            <IoSearchOutline className="cityInputSearch" />
            <input
              style={{ border: "none", backgroundColor: "white" }}
              type="text"
              ref={searchFocus}
              onChange={handleCityArr}
              value={cityValue}
              placeholder="Search city"
              autoComplete="off"
              autofocus
            />
          </div>

          <div className="cityOptionMainContainer">
            {cityArr
              .filter((elem) => {
                if (cityValue === "") {
                  return elem.title;
                } else if (
                  elem.title.toLowerCase().includes(cityValue.toLowerCase())
                ) {
                  return elem.title;
                } else {
                  return false;
                }
              })
              .map((elem) => (
                <div
                  onClick={handleCityPrice(elem)}
                  className="cityOptionContainer"
                  key={elem.title}
                >
                  {elem.title}
                </div>
              ))}
          </div>
        </>
      )}
    </Drawer>
  );
}

export default CityDrawer;
