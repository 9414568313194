//  this is also same as my gate car wash go through for the reference

import React, { useContext, useEffect } from "react";
import "./SlotInfo.css";
import axios from "axios";
import toast from "react-hot-toast";
import { Drawer, ANCHOR, SIZE } from "baseui/drawer";
import { carServiceContext } from "../../../../context/CarServiceContextProvider";
import LoadingSpinner from "../../../../Uis/Spinner/Spinner";
import { Button } from "baseui/button";
import SlotForm from "../SlotForm/SlotForm";
import { AiOutlinePercentage } from "react-icons/ai";
import { MdKeyboardArrowRight } from "react-icons/md";
import { FaCheckCircle } from "react-icons/fa";

const SlotInfo = () => {
  const notify = () => toast("Please select the time slot.");

  // const BASE_URL = process.env.REACT_APP_BASE_URL;
  // const [showRemovePromo, setShowRemovePromo] = useState(false);
  // const [successPromoMessage, setSuccessPromoMessage] = useState("");
  // const [showPromoLoader, setShowPromoLoader] = useState(false);
  // const [showRemovePromoLoader, setShowRemovePromoLoader] = useState(false);

  const {
    // promoCode,
    setPromoCode,
    isSlot,
    closeSlot,
    paymentLoader,
    setPaymentLoader,
    disableButton,
    serviceNotError,
    hideDateTime,
    carServiceData,
    // showSuccessMessage,
    setShowSuccessMessage,
    setIsSuccessModalOpen,
    setTrackingCarService,
    carNumber,
    setIsSlot,
    setIsNewPromoModalOpen,
    couponCodeToggle,
    setCouponCodeToggle,
    carServicePromoData,
  } = useContext(carServiceContext);

  const handlePaymentGateWay = () => {
    setPaymentLoader(true);
    axios({
      method: "POST",
      url: `https://apps.driveu.in/care/pitstop-orders/?user_id=${carServiceData.user_id}`,
      data: carServiceData,
      withCredentials: false,
      headers: {
        "Content-Type": `multipart/form-data`,
      },
    })
      .then(({ data }) => {
        if (data.status === "success") {
          setTrackingCarService(data.track_url);
          setPaymentLoader(false);
          closeSlot();
          setIsSuccessModalOpen(true);
        }
      })
      .catch((err) => {
        console.log(err.message);
        setPaymentLoader(false);
      });
  };

  const handleRemovePromo = () => {
    setPromoCode("");
    setShowSuccessMessage(false);
    carServiceData.promo_code = "";
    setCouponCodeToggle(false);
  };

  useEffect(() => {
    if (isSlot) {
      document.body.style.overflow = "hidden";
    }
    return () => {
      document.body.style.overflow = "scroll";
    };
  }, [isSlot]);

  const handleApplyPromocode = () => {
    if (carServiceData.slot_time === "") {
      notify();
    } else {
      setIsSlot(false);
      setIsNewPromoModalOpen(true);
    }
  };

  useEffect(() => {
    if (hideDateTime || serviceNotError.length !== 0) {
      handleRemovePromo();
    }
  }, [serviceNotError, hideDateTime]);

  return (
    <Drawer
      isOpen={isSlot}
      onClose={closeSlot}
      anchor={ANCHOR.bottom}
      size={SIZE.auto}
      overrides={{
        DrawerBody: {
          style: ({ $theme }) => ({
            marginBottom: "5px",
            marginLeft: "10px",
            marginRight: "10px",
          }),
        },
        DrawerContainer: {
          style: ({ $theme }) => ({
            height: hideDateTime ? "auto" : "87%",
          }),
        },
      }}
    >
      <div style={{ height: hideDateTime ? "auto" : "80vh" }}>
        <div
          style={{
            height: hideDateTime ? "auto" : "78%",
          }}
        >
          <SlotForm />
        </div>

        <div className="carServiceCoupanMainContainer">
          {couponCodeToggle === false ? (
            <div
              style={{
                display:
                  serviceNotError.length !== 0 || hideDateTime ? "none" : "",
                width: "98%",
                margin: "auto",
              }}
              onClick={handleApplyPromocode}
              className="carServiceNewPromoMaincontainer"
            >
              <div className="newPromoContainer">
                <AiOutlinePercentage
                  style={{ color: "white", backgroundColor: "#f93356" }}
                  className="newPromoDiscountImage"
                />
                <div className="fontMedium couponText">
                  <span>Apply Coupon</span>
                  <MdKeyboardArrowRight
                    style={{ color: "#191C19", fontSize: "1rem" }}
                  />
                </div>
              </div>

              <div className="actionOffer">Click above to see offers</div>
            </div>
          ) : (
            <>
              <div
                style={{
                  display:
                    serviceNotError.length !== 0 || hideDateTime ? "none" : "",
                  width: "98%",
                  margin: "auto",
                  borderRadius: "10px",
                }}
                className="newPromoSuccessContainer "
              >
                <div className="successPromoContainer">
                  <div className="successPromoF1">
                    <div className="PromoF1">
                      <div className="PromoF11">
                        <FaCheckCircle style={{ color: "#f93356" }} />
                      </div>
                      <div className="PromoF12">
                        {carServicePromoData.promo_code_text}
                      </div>
                    </div>
                    <div className="PromoF2">
                      {carServicePromoData.title === ""
                        ? "Code Applied"
                        : carServicePromoData.title}
                    </div>
                  </div>
                  <div
                    style={{ color: "#f93356" }}
                    className="successPromoF2"
                    onClick={handleRemovePromo}
                  >
                    Remove
                  </div>
                </div>
                <hr className="successLineL1" />
                <div
                  style={{ color: "#f93356" }}
                  className="otherOffersO1"
                  onClick={handleApplyPromocode}
                >
                  Other Offers
                </div>
              </div>
            </>
          )}

          {/* promo code end  */}
          <div className="confirmButton">
            {paymentLoader ? (
              <Button
                className="carServicebutton"
                style={{ backgroundColor: "#F93356", color: "white" }}
              >
                <LoadingSpinner />
              </Button>
            ) : (
              <Button
                onClick={handlePaymentGateWay}
                type="submit"
                className="carServicebutton"
                style={{
                  backgroundColor: "#F93356",
                  color: "white",
                  opacity: carNumber.length < 9 || disableButton ? "0.6" : "",
                }}
                disabled={carNumber.length < 9 || disableButton}
              >
                PAY AFTER SERVICE
              </Button>
            )}
          </div>
        </div>
      </div>

      {/* <ModalFooter className="justify-center "> */}
    </Drawer>
  );
};

export default React.memo(SlotInfo);
