import React, { useContext, useState, useEffect, useRef } from "react";
import "./FilterCarService.css";
import { carServiceContext } from "../../../context/CarServiceContextProvider";
import Manufacture from "../SelectCarOption/Manufacture/Manufacture";
import CarModal from "../SelectCarOption/CarModal/CarModal";
import FuelType from "../SelectCarOption/FuelType/FuelType";
import ViewDetailModal from "../ViewDetailModal/ViewDetailModal";
import ConsentPopUp from "../../../MyGate/ConsentBox/ConsentPopUp";
import { ApplicationContext } from "../../../context/ApplicationContextProvider";
import SlotInfo from "../SlotModal/SlotInfo/SlotInfo";
import Slider from "react-slick";
import empty_Lottie from "../empty.json";
import Lottie from "lottie-web";

function FilterCarService({ handleDivColor }) {
  const animationRef = useRef(null);
  const [coupanCount, setCoupanCount] = useState(0);
  const {
    carServiceData,
    filterServiceArr,
    selectCar,
    isManufactureModalOpen,
    setIsManufactureModalOpen,
    isCarModalOpen,
    isFuelModalOpen,
    isViewDetailModalOpen,
    setIsViewDetailModalOpen,
    viewModalData,
    setViewModalData,
    //  filter arr
    filterCarBanner,
    filterOffer,
    isSlot,
    //  filter loading
    filterLoading,
    setIsSlot,
    carServiceConset,
    filterDataIndex,
    viewDetailPrice,
  } = useContext(carServiceContext);

  // this is same as home page here we are showing the particular service banner and coupan
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    vertical: false,
    autoplaySpeed: 1500,
    beforeChange: (current, next) => setCoupanCount(next),
    appendDots: (dots) => (
      <div
        style={{
          width: "90%",
          marginLeft: "12%",
          lineHeight: "100%",
        }}
      >
        <ul
          style={{
            margin: "0px",
            marginBottom: "2rem",
            marginRight: "0.5rem",
            float: "right",
            display: filterOffer.length === 1 ? "none" : "",
          }}
        >
          <div className="filterCoupanCountsMainContainer">
            {coupanCount + 1 + "/" + filterOffer.length}
          </div>
          <div>{dots}</div>
        </ul>
      </div>
    ),
  };

  const settings1 = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: false,
    autoplay: true,
    autoplaySpeed: 2000,
    appendDots: (dots) => (
      <div
        style={{
          width: "100%",
        }}
      >
        <ul
          style={{
            margin: "0px",
            marginBottom: "2rem",
          }}
        >
          {dots}
        </ul>
      </div>
    ),
  };

  const { setMyGatePopUp, myGatePopUp } = useContext(ApplicationContext);

  // this is used to handle popup like conset , Select car and Book now
  const handleModalPopUp = (elem) => () => {
    carServiceData.service_name = elem.name;
    carServiceData.price = elem.price;

    viewDetailPrice.name = elem.name;
    viewDetailPrice.price = elem.price;
    viewDetailPrice.eta = elem.extra_data.eta;

    if (selectCar === "Select car") {
      setIsManufactureModalOpen(true);
    } else if (selectCar !== "Select car" && !carServiceConset) {
      setMyGatePopUp(true);
    } else {
      setIsSlot(true);
    }
  };

  //  this is used to handle the index which we are using in the view Modal popup
  const handelViewModal = (elem) => () => {
    viewDetailPrice.name = elem.name;
    viewDetailPrice.eta = elem.extra_data.eta;
    viewDetailPrice.price = elem.price;

    filterDataIndex.index1 = elem.extra_data.details.findIndex(
      (obj) => obj.title === "Get 500 DriveU Coins"
    );
    filterDataIndex.index2 = elem.extra_data.details.findIndex(
      (obj) => obj.title === "What Is Included?"
    );
    filterDataIndex.index3 = elem.extra_data.details.findIndex(
      (obj) => obj.title === "How it works?"
    );
    filterDataIndex.index4 = elem.extra_data.details.findIndex(
      (obj) => obj.title === "When Is It Needed?"
    );
    filterDataIndex.index5 = elem.extra_data.details.findIndex(
      (obj) => obj.title === "About the service"
    );
    filterDataIndex.index6 = elem.extra_data.details.findIndex(
      (obj) => obj.title === "Frequently Asked Questions"
    );
    setViewModalData({ ...viewModalData, data: elem });

    setIsViewDetailModalOpen(true);
  };

  //  this is also same if the service not find then we are showing the error message with the lottie
  useEffect(() => {
    let anim;
    setTimeout(() => {
      anim = Lottie.loadAnimation({
        container: animationRef.current,
        renderer: "svg",
        loop: true,
        autoplay: true,
        animationData: empty_Lottie,
      });
    }, 100);
    return () => anim.destroy();
  }, []);

  return (
    <>
      {isManufactureModalOpen ? <Manufacture /> : null}
      {isCarModalOpen ? <CarModal /> : null}
      {isFuelModalOpen ? <FuelType /> : null}
      {myGatePopUp ? <ConsentPopUp type="carService" /> : null}
      {isViewDetailModalOpen ? <ViewDetailModal data={viewModalData} /> : null}
      {isSlot ? <SlotInfo /> : null}

      {/*  car Service image slider */}

      {filterLoading ? (
        console.log("Loading...")
      ) : (
        <Slider className="carServiceFilterVideoMainContainer" {...settings1}>
          {filterCarBanner.map((elem, index) => {
            return (
              <img
                onClick={handleDivColor(elem)}
                key={index}
                src={elem.banner}
                alt={elem.name}
              />
            );
          })}
        </Slider>
      )}

      {/*  offer div slider */}
      {filterLoading ? (
        console.log("Loading...")
      ) : (
        <Slider
          {...settings}
          style={{ height: filterOffer.length === 1 ? "14%" : "auto" }}
          className="carServiceFilterOfferContainer"
        >
          {filterOffer.map((elem, index) => {
            return (
              <div key={index} className="carServiceFilterOfferMapContainer">
                <div className="carServiceFilterOfferLeftMainContainer">
                  <img
                    id="carServiceFilterCoupanImg"
                    src={elem.icon}
                    alt="img"
                  />
                </div>
                <div
                  style={{ width: filterOffer.length <= 2 ? "76%" : "75%" }}
                  className="carSerivceFilterOfferCentreMainContaier"
                >
                  <div
                    id="carServiceFilterCoupanTitle"
                    className="carSerivceFilterOfferCentreMainHeading"
                  >
                    {elem.title}
                  </div>
                  <div
                    id="carServiceFilterCoupanSubTitle"
                    className="carSerivceFilterOfferCentreSubMainHeading"
                  >
                    {elem.sub_title}
                  </div>
                </div>
              </div>
            );
          })}
        </Slider>
      )}

      {/*  pistop service array in empty */}

      <div
        style={{
          display: filterServiceArr.length === 0 ? "" : "none",
        }}
        className="carServiceFilterEmptyMainContainer"
      >
        <div className="carServiceFilterEmptyLottie" ref={animationRef}></div>
        <div className="carServiceFilterEmptyMainHeading">
          Uh-oh! No Service <br /> under this category.
        </div>
      </div>

      {/*  service map */}
      {filterServiceArr.map((elem) => {
        return (
          <div
            key={elem.name}
            className="carServiceFilterAllOptionMainContainer"
          >
            {/* upward div */}
            <div className="carServiceFilterAllOptionTopMainContainer">
              <div className="carServiceFilterAllOptionTopContainer">
                {/*  left div start */}
                <div className="carServiceFilterAllOptionTopLeftContainer">
                  <div className="carServiceFilterAllOptionTopLeftHeading">
                    {elem.name}
                  </div>
                  <div className="carServiceFilterAllOptionTopLeftRating">
                    <div className="carServiceFilterAllOptionImg">
                      <img
                        src="https://ik.imagekit.io/driveu/Vector_Q9OxOp1a4.png?updatedAt=1684924899241"
                        alt="star"
                      />
                    </div>
                    <div> {elem.extra_data.rating_text}</div>
                  </div>
                  <div
                    style={{
                      display: selectCar === "Select car" ? "none" : "",
                    }}
                    className="carServiceFilterAllOptionTopLeftPrice"
                  >
                    ₹{elem.price}
                  </div>
                  <div className="carServiceFilterAllOptionTopLeftETATime">
                    {elem.extra_data.eta}
                  </div>
                </div>
                {/* right div  */}
                <div className="carServiceFilterAllOptionTopRightContainer">
                  <img src={elem.extra_data.image_url} alt="img" />
                </div>
              </div>
            </div>
            {/* what_is_included downward div dont touch */}
            <div className="carServiceFilterAllOptionBottomMainContainer">
              <div className="carServiceFilterAllOptionBottomOptionContainer">
                {elem.extra_data.what_is_included
                  .filter((item, index) => index < 4)
                  .map((elem) => {
                    return (
                      <div
                        key={elem}
                        className="carServiceFilterAllOptionBottomFirstOptions"
                      >
                        • &nbsp;
                        <div>{elem}</div>
                      </div>
                    );
                  })}
              </div>
              <div className="carServiceFilterViewDetailsMainContainer">
                <div
                  onClick={handelViewModal(elem)}
                  className="carServiceFilterViewDetailsContaier"
                >
                  View Details
                </div>
                <div
                  onClick={handleModalPopUp(elem)}
                  className="carServiceFilterBookNowContaier"
                >
                  {selectCar === "Select car" ? "Select car" : "Book Now"}
                </div>
              </div>
              {/* car service view Details */}
            </div>
          </div>
        );
      })}
    </>
  );
}

export default FilterCarService;
