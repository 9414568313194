export const pageOpenObj = {
  "Service Name": "driver_service",
  pickup_zone: "",
  city: "",
  nearby_driver_eta: "",
  client: "",
};
export const LocationUpdateObj = {
  "Service Name": "driver_service",
  location_type: "",
  pickup_zone: "",
  city: "",
  client: "",
};

export const LocationClearedObj = {
  "Service Name": "driver_service",
  location_type: "",
  pickup_zone: "",
  city: "",
  client: "",
};
export const couponVerifyClickedObj = {
  "Service Name": "driver_service",
  coupon_code: "",
  status: "",
  error_reason: "",
  client: "",
};

export const bookingCancelledObj = {
  "Service Name": "driver_service",
  bookingid: "",
  booking_status: "",
  cancellation_reason: "",
  source: "",
  client: "",
};

export const chatNowInitiatedObj = {
  "Service Name": "",
  bookingid: "",
  booking_status: "",
  FAQ_Statement: "",
  source: "",
  client: "",
};
export const trackBookingScreenObj = {
  "Service Name": "",
  source: "",
  bookingid: "",
  booking_status: "",
  client: "mygate",
};

export const requestDriverObj = {
  "Booking Type": "",
  "Service Type": "classic",
  status: "",
  "Destination Zone": "",
  PromoCode: "",
  "Now or later": "",
  "Estimated Usage": "",
  Date: "",
  "Fare Estimate": "",
  "DU Secure": "",
  "Booking Hour": "",
  client: "",
};

// DAILY EVENTS
export const dailyPageOpen = {
  "Service Name": "du_daily",
  page: "",
  pickup_zone: "",
  city: "",
  src: "",
};
export const reserveDailyCTA = {
  "Service Name": "du_daily",
  src: "",
};
export const continueScheduleCTA = {
  "Service Name": "du_daily",
  pickup_zone: "",
  city: "",
  "Estimated Usage": "",
  "pickup time": "08:00",
  Location: "",
  languages: "",
  src: "",
};
export const continueReviewDailyCTA = {
  "Service Name": "du_daily",
  pickup_zone: "",
  city: "",
  "start date": "",
  "pickup dates": "",
  src: "",
};
export const dailyFinalReviewDailyCTA = {
  "Service Name": "du_daily",
  pickup_zone: "",
  city: "",
  "start date": "",
  "pickup dates": "",
  "pickup time": "08:00",
  languages: "",
  "Estimated Usage": "",
  Location: "",
  "Fare Estimate": "",
  "Token Advance": "",
  src: "",
};

export const duDailyTrackPageEvent = {
  "Service Name": "du_daily",
  "order id": "",
  pickup_zone: "",
  city: "",
};
