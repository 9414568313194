import React from "react";
import style from "./AccountDelete.module.css";

function AccountDelete() {
  return (
    <div className={style.TANDCwrapper}>
      <h1 className={style.termHead}>Account Deletion Information for DriveU</h1>
      <h2>Developer: DriveU</h2>
      <div className={style.accDelContainer}>
        <div  style={{ fontSize: "13.5pt" }}>
          <div style={{ fontFamily: "Circular-Std-Book" }}>
            <h4 className={style.heading4}>
              To request the deletion of your account, please follow these
              steps:
            </h4>
            <div>
              <ol style={{listStylePosition: "inside"}}>
                <li className={style.list}>
                  Email us at care@driveu.in with the subject line "Account
                  Deletion Request".
                </li>
                <div className={style.list}>
                  <ul style={{listStylePosition: "inside"}}>
                    <span>2.</span>&nbsp; &nbsp;<b className={style.bold}>Include the following details </b>
                    <span className={style.span}> in your email:</span>
                    <li className={style.list}>Your registered email address/phone number</li>
                    <li className={style.list}>Your full name</li>
                    <li className={style.list}>Reason for account deletion (optional)</li>
                  </ul>
                </div>
              </ol>
            </div>
            <div>
              <h4 className={style.heading4}>Types of Data Deleted:</h4>
              <ul style={{listStylePosition: "inside"}}>
                <li className={style.list}>Personal information (name, email, phone number)</li>
                <li className={style.list}>Ride history</li>
                <li className={style.list}>Payment information</li>
                <li className={style.list}>Any other data associated with your account</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <p>&nbsp;</p>
    </div>
  );
}

export default AccountDelete;
