import React, {
  Fragment,
  useEffect,
  useState,
  useRef,
  useContext,
} from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
  FocusOnce,
} from "baseui/modal";
import { BsFillArrowLeftCircleFill } from "react-icons/bs";
import { DriverMsg } from "../Components/AllImportFiles/ImportSvg";
import "./TrackPage.css";
import axios from "axios";
import lottie from "lottie-web";
import ScheduleServiceLottie from "../Assets/lottie/ScheduleLottie.json";
import CompletedServiceLottie from "../Assets/lottie/CompletedServiceLottie.json";
import ServiceStartedLottie from "../Assets/lottie/ServiceStartedServiceLottie.json";
import PickupServiceLottie from "../Assets/lottie/PickupServiceLottie.json";
import DriverFAQs from "./DriverFAQs";
import { ALIGN, Radio, RadioGroup } from "baseui/radio";
import { ApplicationContext } from "../context/ApplicationContextProvider";
import LoadingSpinner from "../Uis/Spinner/Spinner";
import { Cash } from "../Components/AllImportFiles/ImportPng";
import toast from "react-hot-toast";
import CarServiceVideo from "./CarServiceVideo/CarServiceVideo";
import CarServiceFAQs from "./CarServiceFAQs";
import CarServiceFareEstimate from "../BookingFlow/OneWayBooking/FairEstimate/CarServiceFareEstimate";
import CarServiceLoader from "../Uis/CarServiceLoader/CarServiceLoader";
import MyGateLoaderCarServiceLoader from "../Uis/CarServiceLoader/MyGateCarServiceLoader";

function CarServiceTrack() {
  // const BASE_URL_HULK = process.env.REACT_APP_HULK_URL;
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [carServiceData, setCarServiceData] = useState([]);
  const [myGateStatusLoader, setMyGateStatusLoader] = useState(true);
  const [isCancelModal, setIsCancelModal] = React.useState(false);
  const completedLoader = useRef(null);
  const pickupLoader = useRef(null);
  const serviceStartedLoader = useRef(null);
  const scheduledLoader = useRef(null);
  const [showErrMsgCancel, setShowErrMsgCancel] = React.useState("");
  const [showCancelLoader, setShowCancelLoader] = React.useState(false);
  const [value, setValue] = React.useState("");
  const [
    showCarServiceFareBreakUp,
    setShowCarServiceFareBreakUp,
  ] = React.useState(false);
  const [carServiceFareDetails, setCarServiceFareDetails] = React.useState(
    null
  );
  const {
    setConsentFlag,
    trackServiceSrc,
    serviceOrderID,
    serviceMyGateToken,
    appUserID,
  } = useContext(ApplicationContext);
  // const { trackDriverScreen } = useContext(BookingFlowDataContext);
  const backBtnStyle = {
    marginTop: "0.5rem",
    height: "30px",
    width: "30px",
    backgroundColor: "#FFFFFF",
    color: "#F93356",
    marginLeft: "0.5rem",
    marginBottom: "0.3rem",
  };

  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: scheduledLoader.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: ScheduleServiceLottie,
    });
    return () => anim.destroy();
  }, [carServiceData]);
  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: pickupLoader.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: PickupServiceLottie,
    });
    return () => anim.destroy();
  }, [carServiceData]);
  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: serviceStartedLoader.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: ServiceStartedLottie,
    });
    return () => anim.destroy();
  }, [carServiceData]);
  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: completedLoader.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: CompletedServiceLottie,
    });
    return () => anim.destroy();
  }, [carServiceData]);

  useEffect(() => {
    const handleFetchServiceDetails = () => {
      axios({
        method: "GET",
        baseURL: BASE_URL,
        // url: `${BASE_URL}/web/care-pitstop-orders/${serviceOrderID}/?user_id=${appUserID}`,
        url: `https://www.driveu.in/web/care-pitstop-orders/${serviceOrderID}/?user_id=${appUserID}`,
        withCredentials: false,
        headers: {
          "Content-Type": `application/json`,
        },
      })
        .then((res) => {
          if (res.status === 200) {
            setCarServiceData(res.data);
            setMyGateStatusLoader(false);
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    };

    if (
      !serviceOrderID === true &&
      !appUserID === true &&
      (!serviceMyGateToken === true || !trackServiceSrc === true)
    ) {
      console.log(null);
    } else {
      handleFetchServiceDetails();
    }
  }, [serviceOrderID, appUserID, serviceMyGateToken, trackServiceSrc]);

  const handleCancelBooking = () => {
    setIsCancelModal((s) => !s);
  };
  const handleCloseMWeb = () => {
    setConsentFlag(false);
    window.open("mygate://close", "_self");
  };
  const errMsgStyle = {
    fontFamily: "Circular-Std-Book",
    height: "26px",
    marginTop: "0.2rem",
    marginBottom: "0.2rem",
    color: "red",
  };
  const RadioOption = {
    fontSize: "15px",
  };
  const radioOverrides = {
    RadioMarkOuter: {
      style: ({ $theme }) => ({
        backgroundColor:
          carServiceData.length === 0
            ? null
            : trackServiceSrc && !serviceMyGateToken === true
            ? "#127e40"
            : "#f93356",
      }),
    },
    Root: {
      style: ({ $theme }) => ({
        display: "flex",
        alignItems: "center",
        width: "250px",
      }),
    },
  };
  const handleCancelBookingWay = (e) => {
    carServiceData.cancellation_reason = e.currentTarget.value;
    setValue(e.currentTarget.value);
  };
  const handleCancelModal = () => {
    setIsCancelModal(false);
    setValue("");
  };
  const handleCancelApiCall = () => {
    if (value === "") {
      setShowErrMsgCancel("Please Select a Cancellation Reason");
      setTimeout(() => {
        setShowErrMsgCancel("");
      }, 3000);
    } else {
      setShowCancelLoader(true);
      axios({
        method: "POST",
        baseURL: BASE_URL,
        // url: `${BASE_URL}/web/care-pitstop-orders/${serviceOrderID}/?user_id=${appUserID}&reason=${value}`,
        url: `https://www.driveu.in/web/care-pitstop-orders/${serviceOrderID}/?user_id=${appUserID}&reason=${value}`,
        withCredentials: false,
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then(({ data }) => {
          if (data.status === "success") {
            setShowCancelLoader(false);
            handleCancelModal();
            (function handleFetchServiceDetails() {
              axios({
                method: "GET",
                baseURL: BASE_URL,
                url: `https://www.driveu.in/web/care-pitstop-orders/${serviceOrderID}/?user_id=${appUserID}`,
                withCredentials: false,
                headers: {
                  "Content-Type": "application/json",
                },
              })
                .then((res) => {
                  if (res.status === 200) {
                    setCarServiceData(res.data);
                    setMyGateStatusLoader(false);
                  }
                })
                .catch((err) => {
                  console.log(err.message);
                });
            })();
          } else if (data.status === "error") {
            toast.error(data.message);
            setShowCancelLoader(false);
          }
        })
        .catch((err) => {
          setShowCancelLoader(false);
          console.log(err.message);
        });
    }
  };
  useEffect(() => {
    const handleGetCarServiceInvoiceData = () => {
      axios({
        method: "GET",
        baseURL: BASE_URL,
        url: `https://apps.driveu.in/care/automovill/invoice-breakup/?order_id=${serviceOrderID}`,
        withCredentials: false,
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          if (res.data.status === "success") {
            setCarServiceFareDetails(res.data);
            // setShowCarServiceFareBreakUp(true);
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    };
    if (!serviceOrderID === true) {
      console.log(null);
    } else {
      handleGetCarServiceInvoiceData();
    }
  }, [serviceOrderID]);
  return (
    <>
      {!trackServiceSrc === true && !serviceMyGateToken === true ? (
        <CarServiceLoader />
      ) : (
        <>
          {myGateStatusLoader &&
          trackServiceSrc &&
          !serviceMyGateToken === true ? (
            <CarServiceLoader />
          ) : myGateStatusLoader && serviceMyGateToken ? (
            <MyGateLoaderCarServiceLoader />
          ) : (
            <>
              <>
                {trackServiceSrc && !serviceMyGateToken === true ? null : (
                  <div
                    style={{
                      position: "sticky",
                      top: "0",
                      backgroundColor: "white",
                      zIndex: "1000",
                    }}
                  >
                    <BsFillArrowLeftCircleFill
                      onClick={handleCloseMWeb}
                      style={backBtnStyle}
                    />
                  </div>
                )}
              </>

              <div className="mapbox-map">
                <div className="mapContainer">
                  {carServiceData.length === 0 ? (
                    <img
                      src="https://ik.imagekit.io/driveu/Map_Loader_0o_KoGrj9P.png?updatedAt=1679661660741"
                      width="100%"
                      height="100%"
                      alt="Loader Map"
                    />
                  ) : carServiceData.booking_status === "Scheduled" ? (
                    <div
                      id="loader_lottie"
                      ref={scheduledLoader}
                      style={{ width: "100%", height: "100%" }}
                    />
                  ) : carServiceData.booking_status === "Pickup Completed" ? (
                    <div
                      id="loader_lottie"
                      ref={pickupLoader}
                      style={{ width: "100%", height: "100%" }}
                    />
                  ) : carServiceData.booking_status === "Service Started" ? (
                    <div
                      id="loader_lottie"
                      ref={serviceStartedLoader}
                      style={{ width: "100%", height: "100%" }}
                    />
                  ) : carServiceData.booking_status === "Completed" ? (
                    <div
                      id="loader_lottie"
                      ref={completedLoader}
                      style={{ width: "100%", height: "100%" }}
                    />
                  ) : carServiceData.booking_status === "Cancelled" ? (
                    <h2>Your booking has been cancelled.</h2>
                  ) : null}
                </div>
              </div>
              <div
                className="locationDetails"
                style={{
                  backgroundColor:
                    carServiceData.length === 0
                      ? null
                      : carServiceData.booking_status === "Cancelled"
                      ? "white"
                      : trackServiceSrc && !serviceMyGateToken === true
                      ? "#127e40"
                      : "#f93356",
                }}
              >
                {carServiceData.length ===
                0 ? null : carServiceData.booking_status ===
                  "Cancelled" ? null : (
                  <div
                    className="bookDriverMsg"
                    style={{
                      backgroundColor:
                        carServiceData.length === 0
                          ? null
                          : trackServiceSrc && !serviceMyGateToken === true
                          ? "#127e40"
                          : "#f93356",
                    }}
                  >
                    <div className="msgPopUpDiv">
                      <DriverMsg />
                    </div>
                    <span className="livePopUpMsg">
                      {carServiceData.booking_messages}
                    </span>
                  </div>
                )}

                <div className="locationWrapper">
                  <div className="bookDriverDetails">
                    <div
                      className="locationFirstBlock"
                      style={{ marginTop: "-1rem" }}
                    >
                      <div className="pickUpDropBlock">
                        {carServiceData.length ===
                        0 ? null : carServiceData.service_type === "Garage" ? (
                          <Fragment>
                            <div
                              className="locationWrapperBlock"
                              style={{ width: "100%" }}
                            >
                              <div className="pickupBlock">
                                <div className="pickup-Place">
                                  Order ID: {carServiceData.order_id}
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div className="pickup-place-first">
                                    {new Date(
                                      carServiceData.scheduled_at
                                    ).toLocaleDateString("en-US", {
                                      year: "numeric",
                                      month: "short",
                                      day: "numeric",
                                    })}
                                    &nbsp;,&nbsp;
                                    {new Date(
                                      carServiceData.scheduled_at
                                    ).toLocaleTimeString("en-IN", {
                                      hour12: true,
                                      hour: "2-digit",
                                      minute: "2-digit",
                                    })}
                                  </div>
                                  <div
                                    className="drop-place car-service-place"
                                    style={{
                                      backgroundColor:
                                        carServiceData.length === 0
                                          ? null
                                          : trackServiceSrc &&
                                            !serviceMyGateToken === true
                                          ? "#127e40"
                                          : "#f93356",
                                    }}
                                  >
                                    {carServiceData.service_type} Service
                                  </div>
                                </div>
                                <div className="pickupBlock">
                                  <div className="pickup-Place">
                                    Location For Pickup:
                                  </div>
                                  <div className="pickup-place-first">
                                    {carServiceData.length === 0
                                      ? null
                                      : carServiceData.address}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Fragment>
                        ) : carServiceData.service_type === "" ? (
                          <Fragment>
                            <div
                              className="locationWrapperBlock"
                              style={{ width: "100%" }}
                            >
                              <div className="pickupBlock">
                                <div className="pickup-Place">
                                  Order ID: {carServiceData.order_id}
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div className="pickup-place-first">
                                    {carServiceData.scheduled_at}
                                  </div>
                                  <div
                                    className="drop-place car-service-place"
                                    style={{
                                      backgroundColor:
                                        carServiceData.length === 0
                                          ? null
                                          : trackServiceSrc &&
                                            !serviceMyGateToken === true
                                          ? "#127e40"
                                          : "#f93356",
                                    }}
                                  >
                                    {carServiceData.service_type} Service
                                  </div>
                                </div>
                                <div className="pickupBlock">
                                  <div className="pickup-Place">
                                    Location For Pickup:
                                  </div>
                                  <div className="pickup-place-first">
                                    {carServiceData.length === 0
                                      ? null
                                      : carServiceData.address}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Fragment>
                        ) : null}
                      </div>
                    </div>
                    <>
                      {carServiceData.length === 0 ? null : (
                        <div className="bookingFirstBlock">
                          <div className="bookScheduledMsg">
                            <span>{carServiceData.service_name}</span>
                          </div>
                          <div className="date-scheduled">
                            <span>{`${carServiceData.car_make} ${
                              carServiceData.model
                            } (${carServiceData.fuel_type
                              .charAt(0)
                              .toUpperCase() +
                              carServiceData.fuel_type.slice(1)})`}</span>
                          </div>
                          <div className="bookTypeApp">
                            <div className="oneWayTrip">
                              {carServiceData.length === 0
                                ? null
                                : `₹ ${carServiceData.total}`}
                            </div>
                            {carServiceData.booking_status === "Completed" ? (
                              <div
                                className="fareBreakUpCarService"
                                onClick={() =>
                                  setShowCarServiceFareBreakUp(true)
                                }
                              >
                                View Invoice
                              </div>
                            ) : null}

                            {showCarServiceFareBreakUp ? (
                              <CarServiceFareEstimate
                                setShowCarServiceFareBreakUp={
                                  setShowCarServiceFareBreakUp
                                }
                                showCarServiceFareBreakUp={
                                  showCarServiceFareBreakUp
                                }
                                carServiceFareDetails={carServiceFareDetails}
                              />
                            ) : null}
                          </div>
                        </div>
                      )}
                    </>
                    {/* <>
                        {carServiceData.length ===
                        0 ? null : carServiceData.discoun t === 0 ? null : (
                          <div className="InsuranceContainer">
                            ORDER CONFIRMATION
                          </div>
                        )}
                      </> */}
                    <>
                      {carServiceData.length ===
                      0 ? null : carServiceData.booking_status ===
                          "Completed" ||
                        carServiceData.booking_status === "Cancelled" ? null : (
                        <>
                          <div className="InsuranceContainer">
                            <div className="InsuranceTitle">
                              <div
                                style={{
                                  width: "20px",
                                  height: "20px",
                                }}
                              >
                                <img
                                  src={Cash}
                                  alt="Car Service"
                                  width="100%"
                                  height="100%"
                                  style={{ objectFit: "contain" }}
                                />
                              </div>
                              <div
                                style={{
                                  fontSize: "16px",
                                  color: "black",
                                  fontWeight: "700",
                                }}
                              >
                                Pay after Service
                              </div>
                            </div>

                            <div className="InsuranceText">
                              <div style={{ width: "8%" }} />
                              <div
                                style={{
                                  width: "92%",
                                  fontSize: "13px",
                                  color: "black",
                                  opacity: "0.6",
                                }}
                              >
                                Please make the payment as per the invoice sent
                                to your email-id.
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                      {carServiceData.length ===
                      0 ? null : carServiceData.booking_status ===
                        "Scheduled" ? (
                        <div className="HelpModifyContainer">
                          <div className="ModifyButtonContainer">
                            {carServiceData.length ===
                            0 ? null : carServiceData.booking_status ===
                              "cancelled" ? (
                              <button
                                className="ModifyBtn"
                                style={{
                                  visibility: "hidden",
                                  backgroundColor:
                                    carServiceData.length === 0
                                      ? null
                                      : trackServiceSrc &&
                                        !serviceMyGateToken === true
                                      ? "#127e40"
                                      : "#f93356",
                                }}
                              >
                                Cancel Booking
                              </button>
                            ) : (
                              <button
                                className="ModifyBtn"
                                onClick={handleCancelBooking}
                                style={{
                                  backgroundColor:
                                    carServiceData.length === 0
                                      ? null
                                      : trackServiceSrc &&
                                        !serviceMyGateToken === true
                                      ? "#127e40"
                                      : "#f93356",
                                }}
                              >
                                Cancel Booking
                              </button>
                            )}

                            <Modal
                              onClose={handleCancelModal}
                              isOpen={isCancelModal}
                            >
                              <FocusOnce>
                                <ModalHeader>Cancel Booking?</ModalHeader>
                              </FocusOnce>
                              <ModalBody>
                                <div style={errMsgStyle}>
                                  {showErrMsgCancel}
                                </div>
                                <div className="RadioGroupBlock">
                                  {carServiceData.length === 0 ? null : (
                                    <>
                                      {carServiceData.cancellation_reasons.map(
                                        (elem, index) => {
                                          return (
                                            <div
                                              key={index}
                                              style={{ width: "40%" }}
                                            >
                                              <RadioGroup
                                                value={value}
                                                onChange={
                                                  handleCancelBookingWay
                                                }
                                                name="CancelOrder"
                                                align={ALIGN.vertical}
                                              >
                                                <Radio
                                                  value={elem.slug}
                                                  overrides={radioOverrides}
                                                >
                                                  <div style={RadioOption}>
                                                    {elem.title}
                                                  </div>
                                                </Radio>
                                              </RadioGroup>
                                            </div>
                                          );
                                        }
                                      )}
                                    </>
                                  )}
                                </div>
                              </ModalBody>
                              <ModalFooter>
                                <ModalButton
                                  kind="tertiary"
                                  onClick={handleCancelModal}
                                >
                                  Cancel
                                </ModalButton>
                                {showCancelLoader ? (
                                  <ModalButton
                                    autoFocus
                                    style={{
                                      backgroundColor:
                                        carServiceData.length === 0
                                          ? null
                                          : trackServiceSrc &&
                                            !serviceMyGateToken === true
                                          ? "#127e40"
                                          : "#f93356",
                                    }}
                                  >
                                    <LoadingSpinner />
                                  </ModalButton>
                                ) : (
                                  <ModalButton
                                    autoFocus
                                    onClick={handleCancelApiCall}
                                    style={{
                                      backgroundColor:
                                        carServiceData.length === 0
                                          ? null
                                          : trackServiceSrc &&
                                            !serviceMyGateToken === true
                                          ? "#127e40"
                                          : "#f93356",
                                    }}
                                  >
                                    Confirm
                                  </ModalButton>
                                )}
                              </ModalFooter>
                            </Modal>
                          </div>
                        </div>
                      ) : null}
                    </>
                    <h2 style={{ textAlign: "left" }}>How it Works?</h2>
                    <>
                      {carServiceData.length === 0 ? null : (
                        <>
                          {Object.entries(
                            DriverFAQs.FAQsData[0].carservicevideolink
                          )
                            .filter(
                              (el) => el[0] === carServiceData.service_name
                            )
                            .map((elem, index) => {
                              return (
                                <Fragment key={index}>
                                  <div className="supportContainer">
                                    {elem[1].map((vidUrl) => {
                                      return (
                                        <CarServiceVideo
                                          id={vidUrl.id}
                                          vidLink={vidUrl.link}
                                          key={vidUrl.id}
                                        />
                                      );
                                    })}
                                  </div>
                                </Fragment>
                              );
                            })}
                        </>
                      )}
                    </>
                    <>
                      {carServiceData.length === 0 ? null : (
                        <>
                          {Object.entries(DriverFAQs.FAQsData[0].carservicefaqs)
                            .filter(
                              (el) => el[0] === carServiceData.service_name
                            )
                            .map((elem, index) => {
                              return (
                                <Fragment key={index}>
                                  <h4>HELP AND SUPPORT</h4>
                                  <div className="supportContainer">
                                    {elem[1].map((quesData) => {
                                      return (
                                        <CarServiceFAQs
                                          key={quesData.id}
                                          id={quesData.id}
                                          quesData={quesData}
                                        />
                                      );
                                    })}
                                  </div>
                                </Fragment>
                              );
                            })}
                        </>
                      )}
                    </>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
}

export default CarServiceTrack;
