import React, { createContext, useRef } from "react";

export const CoinContext = createContext();
function CoinContextProvider({ children }) {
  const coinRef = useRef();
  const handleCoin = () => {
    coinRef.current.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <CoinContext.Provider value={{ handleCoin, coinRef }}>
      {children}
    </CoinContext.Provider>
  );
}

export default CoinContextProvider;
