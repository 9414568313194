import React, { useContext } from "react";
import cardPackageStyle from "./package.module.css";
import { MdCall } from "react-icons/md";
import defaultStyle from "../../DriveUDaily/DailyBooking.module.css";
import useGetDuPartnerFare from "../../CustomHooks/useGetDuPartnerFare";
import { ApplicationContext } from "../../context/ApplicationContextProvider";
import { Spinner } from "baseui/spinner";
import { Badge, COLOR, SHAPE } from "baseui/badge";
import {
  DuLocationGreen,
  DuLocationRed,
} from "../../Components/AllImportFiles/ImportSvg";

const TrackPackagePartner = ({ partnerBookData }) => {
  const instructionsStyle = {
    borderRadius: " 0.3125rem",
    border: "1px solid #e6e6e8",
    background: "#fff",
    marginTop: "1rem",
    padding: "0.75rem",
  };
  const { driverData } = useContext(ApplicationContext);
  const fareDataDU = {
    driver_id: driverData.driver_id,
    imei_number: driverData.imei_number,
    booking_id: partnerBookData.booking_id,
  };
  const { fareDUPartner } = useGetDuPartnerFare(fareDataDU);

  return (
    <div className={cardPackageStyle.packageDetailsContainer}>
      <section>
        <span>Package Details #{partnerBookData.booking_id}</span>
        {partnerBookData.ACTION === "RESERVE" ? null : (
          <Badge
            content={partnerBookData.status}
            shape={SHAPE.pill}
            color={COLOR.positive}
          />
        )}
      </section>
      <div className={cardPackageStyle.mainPartnerDetails}>
        <div className={cardPackageStyle.customerDetails}>
          <DuLocationGreen style={{ width: "10%" }} />
          <span>{partnerBookData.bottom_text}</span>
        </div>

        <>
          {partnerBookData.drop_address && (
            <div
              className={cardPackageStyle.customerDetails}
              style={{ marginTop: "0.5rem" }}
            >
              <DuLocationRed style={{ width: "10%" }} />
              <span>{partnerBookData.drop_address}</span>
            </div>
          )}
        </>

        <div className={cardPackageStyle.activeCardDetailsMain}>
          <div className={cardPackageStyle.cardLayoutDU}>
            {!partnerBookData.customer_number === true ? null : (
              <>
                <div>Customer</div>
                <a
                  href={`tel:${partnerBookData.customer_number}`}
                  className={cardPackageStyle.customerCall}
                >
                  <span>{partnerBookData.customer}</span>
                  <MdCall
                    style={{
                      background: "#009D61",
                      color: "#fff",
                      borderRadius: "50%",
                      padding: "5px",
                    }}
                    size={24}
                  />
                </a>
              </>
            )}
          </div>
          {partnerBookData.details.map((elem, index) => {
            return (
              <div className={cardPackageStyle.cardLayoutDU} key={index}>
                <div>{elem.key}</div>
                <section>{elem.value}</section>
              </div>
            );
          })}
        </div>

        <div className={cardPackageStyle.duBookDetailsBlock}>
          <div style={{ width: "90%" }}>
            {!partnerBookData.booking_days.completed_bookings ===
            true ? null : (
              <div>
                <span style={{ color: "#009D61" }}>Completed: </span>
                <span>
                  {partnerBookData.booking_days.completed_bookings.map(
                    (elem, idx) => (
                      <span key={idx}>
                        {elem.date}
                        {idx !==
                          partnerBookData.booking_days.completed_bookings
                            .length -
                            1 && ","}
                      </span>
                    )
                  )}
                </span>
              </div>
            )}
            {!partnerBookData.booking_days.upcoming_bookings === true ? null : (
              <div>
                <span style={{ color: "#3F69FF" }}>Upcoming: </span>
                <span>
                  {partnerBookData.booking_days.upcoming_bookings.map(
                    (elem, idx) => (
                      <span key={idx}>
                        {elem.date}
                        {idx !==
                          partnerBookData.booking_days.upcoming_bookings
                            .length -
                            1 && ","}
                      </span>
                    )
                  )}
                </span>
              </div>
            )}
            {!partnerBookData.booking_days.cancelled_bookings ===
            true ? null : (
              <div>
                <span style={{ color: "#F15355" }}>Cancelled: </span>
                <span>
                  {partnerBookData.booking_days.cancelled_bookings.map(
                    (elem, idx) => (
                      <span key={idx}>
                        {elem.date}
                        {idx !==
                          partnerBookData.booking_days.cancelled_bookings
                            .length -
                            1 && ","}
                      </span>
                    )
                  )}
                </span>
              </div>
            )}
          </div>
        </div>

        {partnerBookData.instructions ? (
          <div style={instructionsStyle}>
            <strong>Instruction</strong>
            <div>{partnerBookData.instructions}</div>
          </div>
        ) : null}
        {fareDUPartner.length === 0 ? (
          <Spinner $color="green" />
        ) : (
          <div
            style={{ marginTop: "30px" }}
            className={defaultStyle.billDetailsContainer}
          >
            <strong>Earnings Breakup</strong>
            <div className={defaultStyle.billDetailsWrapper}>
              <div className={defaultStyle.fareBreakUpDaily}>
                <>
                  {fareDUPartner.breakup
                    .slice(1, fareDUPartner.breakup.length - 1)
                    .map((fareData, idx) => (
                      <div
                        key={`fareData-${idx}`} // Unique key prop
                        style={{
                          color: fareData.colour,
                        }}
                      >
                        <span>{fareData.key}</span>
                        <span>₹{fareData.value}</span>
                      </div>
                    ))}
                </>
                <hr />
                <>
                  {fareDUPartner.breakup
                    .slice(
                      fareDUPartner.breakup.length - 1,
                      fareDUPartner.breakup.length
                    )
                    .map((fareData, idx) => (
                      <div
                        key={`fareData-${idx}`} // Unique key prop
                        style={{
                          color: "#000",
                          fontWeight: 500,
                        }}
                      >
                        <span>{fareData.key}</span>
                        <span>₹{fareData.value}</span>
                      </div>
                    ))}
                </>
                <hr style={{ opacity: "0.2" }} />
                <>
                  {fareDUPartner.breakup.slice(0, 1).map((fareData, idx) => (
                    <React.Fragment key={`fareData-${idx}`}>
                      {!fareData.value === true ? null : (
                        <div
                          style={{
                            color: "#000",
                            fontWeight: 500,
                          }}
                        >
                          <span>{fareData.key}</span>
                          <span>₹{fareData.value}</span>
                        </div>
                      )}
                    </React.Fragment>
                  ))}
                </>
              </div>

              <hr />
              {Object.keys(fareDUPartner.payment_collection).length ===
              0 ? null : (
                <>
                  <div className={defaultStyle.fareBreakUpDaily}>
                    <div>
                      <strong>Payment Collection</strong>
                    </div>

                    <div>
                      <span>{fareDUPartner.payment_collection.label}</span>
                      {!fareDUPartner.payment_collection.amount ===
                      true ? null : (
                        <strong>
                          ₹{fareDUPartner.payment_collection.amount}
                        </strong>
                      )}
                    </div>
                  </div>
                  <hr />
                </>
              )}

              <div className={defaultStyle.fareText}>
                <ul style={{ color: "#61616C", fontSize: "12px" }}>
                  <li>
                    Actual earnings of trips & package will be recalculated
                    after each trip end
                  </li>
                  <li>Payment collection on last date of booking only</li>
                  <li>
                    For individual trip breakup, go to History check details for
                    a booking under DU Daily Package Order
                  </li>
                </ul>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TrackPackagePartner;
