import { Select } from "baseui/select";
import React, { useRef, useEffect, useContext } from "react";
import carTypeStyle from "../../DailyBooking.module.css";
import { BookingFlowParaContext } from "../../../context/BookingFlowParaProvider";
import { BookingFlowDataContext } from "../../../context/BookingFlowDataContextProvider";
const CarTypeDaily = () => {
  // Context
  const {
    showCarType,
    setShowCarType,
    showCarModel,
    setShowCarModel,
    setDailyBookingType,
    handleGetValueToolTip,
  } = React.useContext(BookingFlowParaContext);
  const { sendingDailyBookingData } = useContext(BookingFlowDataContext);

  // Ref
  const CarTypeRef = useRef(null);

  useEffect(() => {
    if (showCarModel[0].label === "SUV") {
      showCarModel[0].label = "SUV";
    } else if (showCarModel[0].label === "Luxury") {
      showCarModel[0].label = "Luxury";
    }
    setDailyBookingType(`${showCarType[0].label} - ${showCarModel[0].label}`);
    sendingDailyBookingData.car_type = `${showCarType[0].label} - ${showCarModel[0].label}`;
    handleGetValueToolTip();
  }, [showCarType, showCarModel]);

  useEffect(() => {
    // When Ref start
    const carTypeControlKeyBoard =
      CarTypeRef.current.children[0].children[0].children[0].children[1]
        .children[0];

    carTypeControlKeyBoard.setAttribute("inputMode", "none");

    const carModelControlKeyBoard =
      CarTypeRef.current.children[1].children[0].children[0].children[1]
        .children[0];
    carModelControlKeyBoard.setAttribute("inputMode", "none");
  }, []);

  return (
    <div style={{ width: "90%", margin: "auto" }}>
      <div style={{ color: "#000", fontWeight: "600", marginBottom: "0.5rem" }}>
        Your car details<sup>*</sup>
      </div>
      <div className={carTypeStyle.CarType} ref={CarTypeRef}>
        <Select
          clearable={false}
          options={[
            { label: "Manual", id: "#F0F8FF" },
            { label: "Automatic", id: "#FAEBD7" },
          ]}
          value={showCarType}
          searchable={true}
          onChange={(params) => setShowCarType(params.value)}
          required
          overrides={{
            ControlContainer: {
              style: ({ $theme }) => ({
                backgroundColor: $theme.colors.menuFill,
              }),
            },
          }}
        />
        <Select
          clearable={false}
          options={[
            { label: "Hatchback", id: "#F0F8FF" },
            { label: "Sedan", id: "#FAEBD7" },
            { label: "SUV", id: "#00FFFF" },
            { label: "Luxury", id: "#7FFFD4" },
          ]}
          required
          value={showCarModel}
          searchable={true}
          onChange={(params) => setShowCarModel(params.value)}
          overrides={{
            ControlContainer: {
              style: ({ $theme }) => ({
                backgroundColor: $theme.colors.menuFill,
              }),
            },
          }}
        />
      </div>
    </div>
  );
};

export default CarTypeDaily;
